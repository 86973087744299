import React from 'react'
import { useNavigate } from 'react-router-dom'
import { updateSubRoutes, routes } from '../../../utils/routes'
import {
  getConsolidatedConfigSettings,
  isAreaLeader,
  isTeamLeader,
  getCompanyConfigSettings,
} from '../../../utils/auth'

//Commented out activities code just in case we need to bring it back in the near future
function updateStepsGenerator() {
  return [
    updateSubRoutes.intro,
    //updateSubRoutes.activities,
    updateSubRoutes.overdueKPIs,
    updateSubRoutes.meetingTopics,
    updateSubRoutes.allMyActions,
    updateSubRoutes.objectivesTeamMapping,
    updateSubRoutes.teamCapacityAllocation,
    updateSubRoutes.additionalCapacity,
  ]
}

export function stepsLabelsGenerator() {
  return {
    INTRO: 0,
    //ACTIVITIES: 1, since page is completely removed, I needed to move indexes
    OVERDUE_KPIs: 1,
    MEETING_TOPICS: 2,
    ALL_MY_ACTIONS: 3,
    OBJ_TEAM_MAPPING: 4,
    TEAM_CAPACITY_ALLOC: 5,
    ADDITIONAL_CAP: 6,
  }
}

export default function useUpdateWizardSteps(existingStep, state, milestoneData) {
  const updateSteps = updateStepsGenerator()

  const skipTlWizIcsPage = getConsolidatedConfigSettings('skip_tl_wiz_ics_page')
  const tlSkipActivityWiz = getConsolidatedConfigSettings('tl_skip_activity_wiz')
  const showKpiUpdatePageInUW = getCompanyConfigSettings('showKpiUpdatePageInUW') === true

  const navigate = useNavigate()
  const showBGUonly = state?.locationState?.showBGUonly ?? false
  const showOverdueKPIsPageOnly = state?.locationState?.showOverdueKPIsPageOnly ?? false

  const [currentStepIndex, setCurrentStepIndex] = React.useState(parseInt(existingStep))
  const steps = stepsLabelsGenerator()

  const [uwArray, setUwArray] = React.useState([
    true, //INTRO 0
    //true, //ACTIVITIES
    true, //ALL_MY_ACTIONS 1
    true, //OVERDUE_KPIs 2
    true, //MEETING_TOPICS 3
    true, //OBJ_TEAM_MAPPING 4
    true, //TEAM_CAPACITY_ALLOC 5
    true, //ADDITIONAL_CAP 6
  ])

  React.useEffect(() => {
    if (currentStepIndex >= 0) {
      navigate(`/${routes.update}/${updateSteps[currentStepIndex]}`)
    }

    if (showBGUonly) {
      let tempUwArray = [...uwArray]
      tempUwArray[steps.INTRO] = false
      //tempUwArray[steps.ACTIVITIES] = false
      tempUwArray[steps.OBJ_TEAM_MAPPING] = false
      tempUwArray[steps.TEAM_CAPACITY_ALLOC] = false
      tempUwArray[steps.ADDITIONAL_CAP] = false
      setUwArray([...tempUwArray])
    }
    if (showOverdueKPIsPageOnly) {
      let tempUwArray = [...uwArray]
      tempUwArray[steps.INTRO] = false
      tempUwArray[steps.ALL_MY_ACTIONS] = false
      tempUwArray[steps.OVERDUE_KPIs] = true
      tempUwArray[steps.MEETING_TOPICS] = false
      tempUwArray[steps.OBJ_TEAM_MAPPING] = false
      tempUwArray[steps.TEAM_CAPACITY_ALLOC] = false
      tempUwArray[steps.ADDITIONAL_CAP] = false
      setUwArray([...tempUwArray])
    }
  }, [currentStepIndex, existingStep, navigate])

  const hasNoObjToShowActivitiesStepAreaLeader = () => {
    // Filter out objectives that are associated with templates, or don't have any milestones.
    let tempFilteredObjectives = state?.data?.objectives.filter((obj) => obj.hasTemplate === false)

    let objWithAct = []
    for (let obj of tempFilteredObjectives) {
      for (let mil of milestoneData?.milestones ?? []) {
        if (parseInt(obj.id) === parseInt(mil.objectiveId)) {
          objWithAct.push(obj)
          break
        }
      }
    }

    if (objWithAct.length === 0) {
      return true
    } else {
      return false
    }
  }

  const handleNext = (showBGUonly = false, showOverdueKPIsPageOnly = false) => {
    let tempUwArray = [...uwArray]
    tempUwArray[steps.ADDITIONAL_CAP] = false
    tempUwArray[steps.ALL_MY_ACTIONS] = true
    tempUwArray[steps.OVERDUE_KPIs] = true
    if (currentStepIndex <= updateSteps.length) {
      if (showBGUonly) {
        tempUwArray[steps.INTRO] = false
        //tempUwArray[steps.ACTIVITIES] = false
        tempUwArray[steps.OBJ_TEAM_MAPPING] = false
        tempUwArray[steps.TEAM_CAPACITY_ALLOC] = false
        tempUwArray[steps.ADDITIONAL_CAP] = false
      }
      if (skipTlWizIcsPage === true || isAreaLeader()) {
        tempUwArray[steps.OBJ_TEAM_MAPPING] = false
        tempUwArray[steps.TEAM_CAPACITY_ALLOC] = false
        tempUwArray[steps.ADDITIONAL_CAP] = false
      }
      //we will skip kpi page for area leader
      if (showKpiUpdatePageInUW === false) {
        tempUwArray[steps.OVERDUE_KPIs] = false
      }

      // Enabling Activities for AL's too
      // if (isAreaLeader() && hasNoObjToShowActivitiesStepAreaLeader()){
      //   tempUwArray[steps.ACTIVITIES] = false
      // }
      if (showOverdueKPIsPageOnly) {
        let tempUwArray = [...uwArray]
        tempUwArray[steps.INTRO] = false
        tempUwArray[steps.ALL_MY_ACTIONS] = false
        tempUwArray[steps.OVERDUE_KPIs] = true
        tempUwArray[steps.MEETING_TOPICS] = false
        tempUwArray[steps.OBJ_TEAM_MAPPING] = false
        tempUwArray[steps.TEAM_CAPACITY_ALLOC] = false
        tempUwArray[steps.ADDITIONAL_CAP] = false
        setUwArray([...tempUwArray])
      }
      if (tlSkipActivityWiz === true) {
        tempUwArray[steps.MEETING_TOPICS] = false
        //tempUwArray[steps.ACTIVITIES] = false
      }
      let stepIncrease = 1
      while (true) {
        if (currentStepIndex + stepIncrease < updateSteps.length) {
          if (tempUwArray[currentStepIndex + stepIncrease] === true) {
            const nextStep = currentStepIndex + stepIncrease
            setCurrentStepIndex(nextStep)
            break
          }

          stepIncrease++
        }
        if (currentStepIndex + stepIncrease === updateSteps.length) {
          break
        }
      }
      setUwArray([...tempUwArray])
    }
  }

  const handlePrevious = (showBGUonly = false, showOverdueKPIsPageOnly = false) => {
    // let steps = stepsLabelsGenerator()
    let tempUwArray = [...uwArray]
    tempUwArray[steps.ADDITIONAL_CAP] = false
    tempUwArray[steps.OVERDUE_KPIs] = true
    if (currentStepIndex <= updateSteps.length) {
      if (showBGUonly) {
        tempUwArray[steps.INTRO] = false
        //tempUwArray[steps.ACTIVITIES] = false
        tempUwArray[steps.OBJ_TEAM_MAPPING] = false
        tempUwArray[steps.TEAM_CAPACITY_ALLOC] = false
        tempUwArray[steps.ADDITIONAL_CAP] = false
      }
      if (skipTlWizIcsPage === true || isAreaLeader()) {
        tempUwArray[steps.OBJ_TEAM_MAPPING] = false
        tempUwArray[steps.TEAM_CAPACITY_ALLOC] = false
        tempUwArray[steps.ADDITIONAL_CAP] = false
      }
      if (showKpiUpdatePageInUW === false) {
        tempUwArray[steps.OVERDUE_KPIs] = false
      }
      if (showOverdueKPIsPageOnly) {
        let tempUwArray = [...uwArray]
        tempUwArray[steps.INTRO] = false
        tempUwArray[steps.ALL_MY_ACTIONS] = false
        tempUwArray[steps.OVERDUE_KPIs] = true
        tempUwArray[steps.MEETING_TOPICS] = false
        tempUwArray[steps.OBJ_TEAM_MAPPING] = false
        tempUwArray[steps.TEAM_CAPACITY_ALLOC] = false
        tempUwArray[steps.ADDITIONAL_CAP] = false
        setUwArray([...tempUwArray])
      }

      // Enabling Activities for AL's too
      // if (isAreaLeader() && hasNoObjToShowActivitiesStepAreaLeader()){
      //   tempUwArray[steps.ACTIVITIES] = false
      // }
      if (tlSkipActivityWiz === true) {
        tempUwArray[steps.MEETING_TOPICS] = false
        //tempUwArray[steps.ACTIVITIES] = false
      }
      let stepDecrease = 1
      while (true) {
        if (tempUwArray[currentStepIndex - stepDecrease] === true) {
          const prevStep = currentStepIndex - stepDecrease
          setCurrentStepIndex(prevStep)
          // saveUpdateWizardState(state.data, prevStep)
          break
        }

        stepDecrease++
        if (currentStepIndex - stepDecrease === -1) {
          break
        }
      }
      setUwArray([...tempUwArray])
    }
  }

  const isLastStep = () => {
    let lastIndex = -1

    for (let i = 0; i < uwArray.length; i++) {
      if (uwArray[i] === true) {
        lastIndex = i
      }
    }

    if (currentStepIndex === lastIndex) {
      return true
    } else {
      return false
    }
  }

  return {
    currentStepIndex,
    steps: updateSteps,
    handleNext,
    handlePrevious,
    uwArray,
    isLastStep,
  }
}
