import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { shortenName } from '../../../utils/general'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getCompanyConfigSettings } from '../../../utils/auth'
import Swal from 'sweetalert2'
import Toast from '../../../common/toast'
import { linkifyString } from '../../../utils/general'
import { MentionsInput, Mention } from 'react-mentions'
import TextArea from 'rc-textarea'
import { postHttpRequest, getHttpRequest } from '../../../api/query/dynamicAPI'
import { getAllEmployeeList } from '../../../api/services/employee.services'
import CreatableSelect from 'react-select/creatable'
import { routes } from '../../../utils/routes'
// import '../../../../src/App.scss'
import '../../../pages/MeetingMultiView/components/DecisionLog/styles.css'
import '../../../pages/MeetingMultiView/components/MeetingSummary/sub-components/style.css'
import { getCurrentUserEmail, getEmailSentRecord } from '../../../api/services/brainstorm.services'

const Wrapper = styled.div`
  div > textarea {
    border: 1px solid lightgrey;
    rows: 3;
  }
  width: 100%;
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif;

  .body_bottom_text__control {
    height: 3rem !important;
    min-height: 3rem !important;
    max-height: 3rem !important;
    overflow-y: auto;
  }
`

const MeetingMinutesBody = ({ isFromZisi = false }) => {
  console.log('isFromZisi: ', isFromZisi)
  const currentLocation = useLocation()
  const navigate = useNavigate()
  console.log('currentLocation: ', currentLocation.state.mindmap)

  const [mindmap, setMindMap] = React.useState(currentLocation.state.mindmap)
  const [meetingInstanceId] = React.useState(currentLocation.state.meetingInstanceId)
  const [language] = React.useState(currentLocation.state.language)
  const [zisiLinkId] = React.useState(currentLocation.state.zisiLinkId)
  const [overallSummary] = React.useState(currentLocation.state.overallSummary)

  const { t } = useTranslation(['MeetingHub', 'Common'])

  const [emailList, setEmailList] = useState([])
  const [peopleList, setPeopleList] = React.useState([])
  const [additionalLogViewersList, setAdditionalLogViewersList] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const isDevEnv = process.env.REACT_APP_DEVELOPER_ENV == 'true'

  React.useEffect(() => {
    getAdditionalLogViewersList()
    // fill in the checkboxComments with default values for each comment, id is topicIndex + '-' + commentIndex
    let mindmapCopy = structuredClone(mindmap)
    mindmapCopy?.action_plans?.forEach((topic, topicIndex) => {
      if (!topic?.is_parking_lot) {
        topic?.decisions?.forEach((decision, decisionIndex) => {
          // let id = topicIndex + '-' + commentIndex
          // newCheckboxComments[id] = true
          decision['isAccepted'] = decision?.isAccepted ?? true
        })
        topic?.comments?.forEach((comment, commentIndex) => {
          // let id = topicIndex + '-' + commentIndex
          // newCheckboxComments[id] = true
          if (!comment?.is_summary_comment) {
            comment['isAccepted'] = comment?.isAccepted ?? false
          } else {
            comment['isAccepted'] = true
          }
        })
        topic?.actions?.forEach((milestone, milestoneIndex) => {
          // let id = topicIndex + '-' + milestoneIndex + '-action'
          milestone['isAccepted'] = milestone?.isAccepted ?? true
        })
        topic?.recommended_actions?.forEach((milestone, milestoneIndex) => {
          // let id = topicIndex + '-' + milestoneIndex + '-recommended'
          milestone['isAccepted'] = milestone?.isAccepted ?? true
        })
      } else {
        //set all isAccepted to false
        topic?.decisions?.forEach((decision) => {
          decision['isAccepted'] = false
        })
        topic?.comments?.forEach((comment) => {
          comment['isAccepted'] = false
        })
        topic?.actions?.forEach((milestone) => {
          milestone['isAccepted'] = false
        })
        topic?.recommended_actions?.forEach((milestone) => {
          milestone['isAccepted'] = false
        })
      }
    })
    setMindMap(mindmapCopy)
  }, [])

  const updateItemText = (
    text,
    topicIndex,
    itemIndex,
    isDecision,
    isAction,
    isRecommended = false,
  ) => {
    setMindMap((prevMindMap) => {
      let newMindMap = { ...prevMindMap }
      let item = isRecommended
        ? newMindMap.action_plans[topicIndex].recommended_actions[itemIndex]
        : isAction
          ? newMindMap.action_plans[topicIndex].actions[itemIndex]
          : isDecision
            ? newMindMap.action_plans[topicIndex].decisions[itemIndex]
            : newMindMap.action_plans[topicIndex].comments[itemIndex]
      item.text = text
      return newMindMap
    })
  }

  const handleSendDecisionLog = async () => {
    console.log(mindmap)
    if (emailList.length === 0) {
      Toast.fire({
        icon: 'error',
        title: 'Please select at least one recipient to send the log',
      })
      return
    }
    let result = null
    result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureYouWantToSendThisLog'),
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result?.isConfirmed) {
      await sendLog()

      try {
        await postHttpRequest(`/brainstorm/save_brainstorm_session`, {
          meetingInstanceId: meetingInstanceId,
          shouldSaveSingleField: true,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      } catch (error) {
        console.log('error saving brainstorm session', error)
      }
      navigate(`/${routes.brainstorm}`)
    }
  }

  async function sendLog() {
    // setShowLoader(true)
    try {
      const link = window.location.origin + '/shared_session/' + zisiLinkId
      await postHttpRequest('/brainstorm/send_brainstorm_log', {
        brainstormLog: mindmap, // Is this needed?
        emailList: emailList,
        meetingInstanceId: meetingInstanceId,
        language: language,
        zishiLink: link,
      })
      Toast.fire({
        icon: 'success',
        title: 'Log sent successfully!',
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong! Please try again later.',
      })
    }
  }

  async function getReportingLeaders() {
    const result = await getHttpRequest('/team_leaders_for_area_leader', {
      params: {
        notExcludeCos: true,
      },
    })
    let employees =
      result?.employees?.filter((emp) => emp.isDeactivated === false && emp.isDeleted === false) ??
      []
    const currentUserEmail = localStorage.getItem('tfmu')
    setPeopleList([
      {
        value: currentUserEmail,
        label: currentUserEmail,
      },
    ])
    setEmailList([currentUserEmail])

    // setEmailList(employees.map((emp) => emp.email))
  }

  async function getAdditionalLogViewersList() {
    if (isFromZisi) {
      const result = await getCurrentUserEmail()
      const emailRecord = await getEmailSentRecord()
      // Set current user email and previous email records together
      setAdditionalLogViewersList([
        {
          value: result.email,
          label: result.email,
        },
        // Remove current user email and duplicates, and sort.
        ...(emailRecord?.email_record
          ?.filter((email) => email !== result.email)
          .sort()
          .filter((email, index, self) => self.indexOf(email) === index)
          .map((email) => ({
            value: email,
            label: email,
          })) || []),
      ])

      setEmailList([result.email])
      setPeopleList([
        {
          value: result.email,
          label: result.email,
        },
      ])
      return
    }
    // List includes IC's
    const result = await getAllEmployeeList()
    getReportingLeaders()
    if (localStorage.getItem('tfex') === 'true') {
      setAdditionalLogViewersList([])
    } else {
      if (result?.employees) {
        setAdditionalLogViewersList(
          result.employees?.map((e) => ({
            value: e.email,
            label: e.email,
          })),
        )
      }
    }
  }

  const handleAdditionalLogViewers = (e) => {
    setEmailList(e?.map((emp) => emp.value))
    setPeopleList(e)
  }

  const handleCheck = async (e, topicIndex, itemIndex, itemType) => {
    let newMindmap = structuredClone(mindmap)
    //filter parking lot topics
    let parking_lot_topic = newMindmap.action_plans.find((topic) => topic.is_parking_lot)
    newMindmap.action_plans = newMindmap.action_plans.filter((topic) => !topic.is_parking_lot)
    console.log('newMindmap.action_plans: ', newMindmap.action_plans)
    //find all non summary comments
    let nonSummaryComments = newMindmap.action_plans[topicIndex].comments.filter(
      (comment) => !comment.is_summary_comment,
    )
    //filter the comments of the mindmap
    newMindmap.action_plans[topicIndex].comments = newMindmap.action_plans[
      topicIndex
    ].comments.filter((comment) => comment.is_summary_comment)
    switch (itemType) {
      case 0:
        // decisions
        let decision = newMindmap.action_plans[topicIndex].decisions[itemIndex]
        decision['isAccepted'] = !decision['isAccepted']
        break
      case 1:
        // comments
        let comment = newMindmap.action_plans[topicIndex].comments[itemIndex]
        comment['isAccepted'] = !comment['isAccepted']
        break
      case 2:
        // action
        let action = newMindmap.action_plans[topicIndex].actions[itemIndex]
        action['isAccepted'] = !action['isAccepted']
        break
      case 3:
        // recommended action
        let recommendedAction = newMindmap.action_plans[topicIndex].recommended_actions[itemIndex]
        recommendedAction['isAccepted'] = !recommendedAction['isAccepted']
        break
      default:
        break
    }
    //add parking lot topic back to the mindmap
    if (parking_lot_topic) {
      newMindmap.action_plans.push(parking_lot_topic)
    }
    //add non summary comments back to the mindmap
    newMindmap.action_plans[topicIndex].comments = [
      ...nonSummaryComments,
      ...newMindmap.action_plans[topicIndex].comments,
    ]
    setMindMap(newMindmap)
  }

  const renderComments = (comment, topicIndex, itemIndex, isDecision) => {
    let id = topicIndex + '-' + itemIndex + (isDecision ? '-decision' : '-comment')
    return (
      <div key={id} className="decision-log-inner-grid-area-view">
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 0.03fr', gap: '1rem' }}>
          <div
            className="decision-log-comment"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '1rem',
              width: '75vw',
            }}
          >
            <div style={{}}>
              <input
                type="checkbox"
                id={id}
                name={id}
                // checked={checkboxComments[id]}
                checked={comment?.isAccepted ?? true}
                onChange={(e) => handleCheck(e, topicIndex, itemIndex, isDecision ? 0 : 1)}
                disabled={!isDecision}
              />
            </div>

            <div
              style={{
                backgroundColor: 'white',
                color: '#666563',
                border: '1px solid #A9A9A9',
                padding: '0.1rem 0.2rem',
                borderRadius: '0.5rem',
                width: '4.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '13.33px',
                  width: '100%',
                }}
              >
                {isDecision ? t('decisionLog.decision') : t('decisionLog.summary')}
              </div>
            </div>
            <Wrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '5px',
                  boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
                  borderRadius: '4px',
                  backgroundColor: 'white',
                  alignItems: 'center',
                  height: '65px',
                  // overflowY: 'auto',
                }}
              >
                {isDecision ? (
                  <TextArea
                    autoSize
                    type="text"
                    name="activity"
                    style={{
                      minHeight: '4rem',
                      maxHeight: '64px',
                      width: '100%',
                      resize: 'none',
                      border: '1px solid rgb(238, 238, 238)',
                      backgroundColor: 'rgba(238, 238, 238, 0.2)',
                      borderRadius: '2.5px',
                      fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                    }}
                    onChange={(e) => {
                      updateItemText(e.target.value, topicIndex, itemIndex, isDecision, false)
                    }}
                    value={comment.text}
                  />
                ) : (
                  <div
                    style={{
                      minHeight: '4rem',
                      maxHeight: '64px',
                      width: '100%',
                      padding: '3px',
                      backgroundColor: 'rgba(238, 238, 238, 0.2)',
                      borderRadius: '2.5px',
                      fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                      overflowY: 'auto',
                    }}
                  >
                    {comment.text}
                  </div>
                )}
              </div>
            </Wrapper>
          </div>
        </div>
      </div>
    )
  }

  const renderMilestones = (milestone, topicIndex, itemIndex, isRecommended) => {
    let id = topicIndex + '-' + itemIndex + '-' + (isRecommended ? 'recommended' : 'action')
    return (
      <div key={id} className="decision-log-inner-grid-area-view">
        <div key={id} style={{ display: 'grid', gridTemplateColumns: '1fr 0.03fr', gap: '1rem' }}>
          <div
            className="decision-log-milestone"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '1rem',
              width: '75vw',
            }}
          >
            <div style={{}}>
              <input
                type="checkbox"
                id={id}
                name={id}
                // checked={checkboxMiles[id]}
                checked={milestone?.isAccepted ?? true}
                onChange={(e) => handleCheck(e, topicIndex, itemIndex, isRecommended ? 3 : 2)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                border: '1px solid  white',
                padding: '0.1rem 0.2rem',
                width: '4.5rem',
                rowGap: '0.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '13.33px',
                  backgroundColor: '#A9A9A9',
                  borderRadius: '0.5rem',
                  padding: '0.1rem 0.2rem',
                  width: '100%',
                }}
              >
                {t('decisionLog.action')}
              </div>
              {isRecommended && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '13.33px',
                    backgroundColor: 'red',
                    borderRadius: '0.5rem',
                    padding: '0.1rem 0.2rem',
                    width: '100%',
                  }}
                >
                  AI
                </div>
              )}
            </div>
            <Wrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '5px',
                  boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
                  borderRadius: '4px',
                  backgroundColor: 'white',
                  alignItems: 'center',
                  height: '65px',
                  // overflowY: 'auto',
                }}
              >
                <TextArea
                  autoSize
                  // rows={2}
                  type="text"
                  name="activity"
                  style={{
                    minHeight: '4rem',
                    // height: '65px',
                    maxHeight: '64px',
                    width: '100%',
                    resize: 'none',
                    border: '1px solid rgb(238, 238, 238)',
                    backgroundColor: 'rgba(238, 238, 238, 0.2)',
                    borderRadius: '2.5px',
                    fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                  }}
                  onChange={(e) => {
                    updateItemText(
                      e.target.value,
                      topicIndex,
                      itemIndex,
                      false,
                      true,
                      isRecommended,
                    )
                  }}
                  value={milestone.text}
                  disabled={false}
                />
              </div>
            </Wrapper>
          </div>
        </div>
        <div></div>
      </div>
    )
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleCreate = (inputValue) => {
    inputValue = inputValue.trim()
    if (!isValidEmail(inputValue)) {
      Toast.fire({
        icon: 'error',
        title: 'Please enter a valid email address',
      })
      return
    }

    setIsLoading(true)
    setTimeout(() => {
      const newOption = {
        label: inputValue,
        value: inputValue,
      }
      setIsLoading(false)
      setEmailList((emails) => [...emails, inputValue])
      setPeopleList((people) => [...people, newOption])
    }, 500)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '3rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              width: '70rem',
              padding: '0 2rem 0.5rem 2rem',
            }}
          >
            {console.log('emailList', emailList)}
            <>
              <div style={{ width: 'auto', fontWeight: 'bold', marginTop: '0.5rem' }}>
                {t('decisionLog.additionalLogRecipients')}
              </div>
              <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                <CreatableSelect
                  id="selectOption"
                  value={peopleList}
                  onChange={handleAdditionalLogViewers}
                  options={additionalLogViewersList}
                  isLoading={isLoading}
                  getOptionLabel={(option) => option?.label}
                  getOptionValue={(option) => option?.value}
                  onCreateOption={handleCreate}
                  isMulti
                  isClearable
                  menuPortalTarget={document.body}
                  placeholder={t('decisionLog.select')}
                  formatCreateLabel={(inputValue) =>
                    `${t('decisionLog.addNewEmail')} "${inputValue}"`
                  }
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    menu: (base) => ({
                      ...base,
                    }),
                    container: (base) => ({
                      ...base,
                      width: '50rem',
                    }),
                  }}
                />
                <div
                  className="fitted-button"
                  style={{
                    backgroundColor: '#4461F2',
                    color: 'white',
                    padding: '8px 16px',
                    borderRadius: '6px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginLeft: '4rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSendDecisionLog()}
                >
                  <span>{t('decisionLog.sendLog')}</span>
                </div>
              </div>
            </>
          </div>

          {/* show the overall summary */}
          {overallSummary && (
            <div style={{ marginBottom: '1rem', padding: '0 2rem 0 2rem' }}>
              <div className="decision-log-meeting-table">
                <div
                  style={{
                    backgroundColor: '#f5f5f5',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    marginTop: '1rem',
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                    {t('decisionLog.overallSummary')}
                  </div>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{linkifyString(overallSummary)}</div>
                </div>
              </div>
            </div>
          )}

          <div style={{ marginBottom: '1rem', padding: '0 2rem 2rem 2rem' }}>
            <div className="decision-log-meeting-table">
              <div className="decision-log-meeting-header"></div>
              {mindmap && (
                <React.Fragment key={`objective`}>
                  <React.Fragment>
                    <div
                      className="decision-log-meeting-description"
                      style={{ paddingTop: '0.2rem', maxWidth: '90%' }}
                    >
                      <b>
                        <span>{mindmap.problem}</span>
                      </b>
                    </div>
                    <div className="decision-log-meeting-description">
                      <div
                        className="underline-meeting"
                        style={{ width: isFromZisi ? '70%' : '85%' }}
                      ></div>
                    </div>
                    <>
                      {mindmap.action_plans
                        ?.filter((topic) => !topic.is_parking_lot)
                        ?.map((topic, topicIndex) => (
                          <>
                            <div
                              key={topicIndex}
                              className="decision-log-meeting-description"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: 'rgb(237, 82, 46)',
                                  color: 'white',
                                  padding: '0.2rem 0.2rem',
                                  borderRadius: '0.5rem',
                                  width: '5rem',
                                  fontWeight: 'bold',
                                  height: 'fit-content',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '13.33px',
                                  }}
                                >
                                  {t('Common:filter.topic')}
                                </div>
                              </div>
                              <b style={{ maxWidth: '53rem' }}>{linkifyString(topic.topic)}</b>
                              <div></div>
                            </div>
                            <div className="decision-log-meeting-description">
                              <div className="underline-meeting-light"></div>
                            </div>
                            <div>
                              {topic?.comments
                                ?.filter((comment) => comment.is_summary_comment)
                                ?.map((comment, commentIndex) => (
                                  <>{renderComments(comment, topicIndex, commentIndex, false)}</>
                                ))}
                              {topic?.decisions?.map((decision, decisionIndex) => (
                                <>{renderComments(decision, topicIndex, decisionIndex, true)}</>
                              ))}
                              {topic?.actions?.map((milestone, milestoneIndex) => (
                                <>
                                  {renderMilestones(milestone, topicIndex, milestoneIndex, false)}
                                </>
                              ))}
                              {/* {topic?.recommended_actions?.map((aiAction, aiActionIndex) => (
                                <>{renderMilestones(aiAction, topicIndex, aiActionIndex, true)}</>
                              ))} */}
                            </div>
                          </>
                        ))}
                    </>
                  </React.Fragment>
                </React.Fragment>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
            <div
              className="fitted-button"
              style={{
                backgroundColor: '#4461F2',
                color: 'white',
                padding: '8px 16px',
                borderRadius: '6px',
                height: '2.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleSendDecisionLog()}
            >
              <span>{t('decisionLog.sendLog')}</span>
            </div>
          </div>
        </div>
      </div>
      <p>&nbsp;</p>
    </>
  )
}

export default MeetingMinutesBody
