import React, { useState } from 'react'

import { Handle, Position } from '@xyflow/react'
import TextArea from 'rc-textarea'
import { useTranslation } from 'react-i18next'
import './style.scss'

function MindMapTopicNode({ data, isConnectable }) {
  const [inputDescription, setInputDescription] = useState(data.label)
  // to avoid adding 'New: click to edit' to the discarded list
  const isNewTopic = () => inputDescription.trim() === 'New: click to edit'
  // don't show parking lot if it doens't have comments etc
  if (data.topicCount === 1 && data.isParkingLot && !data.hasChildren) {
    return null
  }

  return (
    <div className="nodrag text-updater-node">
      <>
        {' '}
        {!data?.isParkingLot && (
          <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
        )}
      </>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {data.allowBulkConvertToMH && !data?.isParkingLot && (
          <div
            style={{
              width: '80px',
              color: 'black',
              padding: '2px',
              paddingLeft: '2px',
              fontSize: '14px',
              position: 'relative',
              top: '10px',
              border: '0px solid #cccccc',
              // borderTopLeftRadius: '20px',
              borderRadius: '0px 20px 0px 0px',
              fontWeight: 'bold',
              // alignSelf: 'flex-end',
              marginLeft: 'auto',
              backgroundColor: `${data.borderColor}`,
            }}
          >
            {/* {data.type} */}
          </div>
        )}

        {data.allowBulkConvertToMH && data?.isParkingLot && (
          <div
            style={{
              width: '120px',
              color: 'black',
              padding: '2px',
              paddingLeft: '2px',
              fontSize: '14px',
              position: 'relative',
              top: '10px',
              border: '0px solid #cccccc',
              // borderTopLeftRadius: '20px',
              borderRadius: '0px 20px 0px 0px',
              fontWeight: 'bold',
              // alignSelf: 'flex-end',
              marginLeft: 'auto',
              backgroundColor: `${data.borderColor}`,
            }}
          >
            {/* Parking Lot */}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '170px',
            textAlign: 'left',
            backgroundColor: `${data.borderColor}`,
            borderRadius: '10px',
            padding: '10px',
            color: 'black',
            fontSize: '26px',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '7px 5px 5px 7px',

              //boxShadow: 'rgb(204, 204, 204) 0px 0px 6px',
              borderRadius: '4px',
              backgroundColor: 'white',
              alignItems: 'center',
              height: '80px',
              // overflowY: 'auto',
            }}
          >
            <TextArea
              autoSize
              // rows={2}
              type="text"
              name="activity"
              style={{
                minHeight: '4rem',
                // height: '65px',
                maxHeight: '80px',
                width: '100%',
                resize: 'none',
                //border: '1px solid rgb(238, 238, 238)',
                backgroundColor: 'rgba(238, 238, 238, 0.2)',
                borderRadius: '2.5px',
                fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif",
                fontSize: '14px',
              }}
              // onChange={(e) => {
              //   setInputDescription(e.target.value)
              // }}
              // onBlur={() => {
              //   data.updateMindMap(inputDescription, data.label, inputDescription)
              // }}
              // onFocus={() => {
              //   data.lockMindMap()
              // }}
              value={inputDescription}
              disabled={true} //data.isUpdatingMindMap || data.fromExternalLink
            />
          </div>
          {/* {!data.hasChildren && !isNewTopic() && !data.fromExternalLink && (
          <div
            className="topic-box"
            style={{
              width: '25px',
              color: 'black',
              padding: '2px',
              paddingLeft: '2px',
              fontSize: '10px',
              // position: 'relative',
              position: 'absolute',
              right: 0,
              // bottom: '15px',
              bottom: '-20px',
              border: '0px solid #cccccc',
              borderRadius: '0px 0px 20px 0px',
              fontWeight: 'bold',
              backgroundColor: `${data.borderColor}`,
              // alignSelf: 'flex-end',
            }}
            onClick={() => data.removeItem(inputDescription, data.index)}
          >
            <span class="material-symbols-outlined" style={{ fontSize: '20px' }}>
              close
            </span>
          </div>
        )} */}
          {/* Commenting out until prompt gets fixed */}
          {/* {!isNewTopic() && !data.fromExternalLink && (
          <div
            class="fitted-button"
            style={{
              width: '25px',
              color: 'black',
              padding: '2px',
              fontSize: '10px',
              // position: 'relative',
              position: 'absolute',
              // bottom: data.hasChildren ? '70px' : '95px',
              top: '50%',
              // left: '12px',
              right: '-25px',
              border: '0px solid #cccccc',
              fontWeight: 'bold',
              backgroundColor: `${data.borderColor}`,
              // alignSelf: 'flex-end',
              transform: 'translateY(-50%)',
            }}
            onClick={() => {
              if (data.isUpdatingMindMap || data.fromExternalLink) return
              data.addNewItem(data)
            }}
          >
            <span class="material-symbols-outlined">add</span>
          </div>
        )} */}
        </div>

        <div
          style={{
            marginTop: '10px',
            fontSize: '14px',
            width: '170px',
            maxHeight: '110px', // Add max height
            overflowY: data.fromExternalLink ? 'hidden' : 'auto', // Add vertical scrollbar when needed
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
            padding: '0 5px', // Add some padding for better readability
          }}
        >
          {data.summary}
        </div>
      </div>
    </div>
  )
}

export default MindMapTopicNode
