import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../../../components/Button'
import { milestoneSubRoutes, routes } from '../../../../utils/routes'
import { CardContainer, CardContent, StepHeader, StepCaption, ButtonContainer } from '../../styles'
import { useTranslation } from 'react-i18next'
import { getConsolidatedConfigSettings, isAreaLeader } from '../../../../utils/auth'
import { getCompanyConfigSettings } from '../../../../utils/auth'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import { useUpdateContext } from '../../context/updateContext'
import Toast from '../../../../common/toast'
const CardsContainer = styled.div`
  display: flex;
  gap: 2rem;
  width: 80%;
`

const UpdateIntro = () => {
  const { t } = useTranslation(['UpdateWizard', 'Common'])
  const skipTlWizIcsPage = getConsolidatedConfigSettings('skip_tl_wiz_ics_page')
  const tlSkipActivityWiz = getConsolidatedConfigSettings('tl_skip_activity_wiz')
  const all = skipTlWizIcsPage === false && tlSkipActivityWiz === false && isAreaLeader() === false
  const showToturial =
    isAreaLeader() ||
    (getCompanyConfigSettings('showKpiUpdatePageInUW') === true &&
      getConsolidatedConfigSettings('tl_skip_capacity_wiz') === true)
  const uwTutorialOff = getConsolidatedConfigSettings('uw_tutorial_off')

  const [isChecked, setIsChecked] = React.useState(uwTutorialOff)
  const { state, dispatch } = useUpdateContext()
  // ideally we should reset state.tourIndex to 0, but since we don't pass it direactly (0,1,2 instead), I thinkg it's fine for now
  React.useEffect(() => {
    if (showToturial) {
      ;(async () => {
        try {
          let response = await getHttpRequest('/get_employees_user_preferences')
          setIsChecked(response.uwTutorialOff)
        } catch (error) {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      })()
    }
  }, [])
  React.useEffect(() => {
    // update
    // we should only update the state if the tutorial is supposedly shoen
    if (showToturial) {
      ;(async () => {
        try {
          await postHttpRequest('/edit_employees_user_preferences', {
            uwTutorialOff: isChecked,
          })
          dispatch({
            type: 'UPDATE_UW_TUTORIAL_OFF',
            isTutorialOff: isChecked,
          })
        } catch (error) {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      })()
    }
  }, [isChecked])

  const handleUwTutorialCheck = (e) => {
    setIsChecked((prev) => !prev)
  }
  return (
    <>
      <div className="bg-slate-50 p-8 rounded-lg shadow-sm border border-slate-200 w-full max-w-6xl mx-auto">
        <div className="mb-6 border-l-4 border-blue-600 pl-4">
          <StepHeader className="text-left">{t('introStep.header')}</StepHeader>
        </div>

        <div className="space-y-4">
          {/* KPIs Section */}
          {(skipTlWizIcsPage === true || isAreaLeader() || all) && (
            <div className="bg-white p-5 rounded-md border border-slate-200 shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="flex flex-row items-start">
                <div className="bg-emerald-100 p-2 rounded-md mr-4 mb-4">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-emerald-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg> */}
                  <span className="material-symbols-outlined h-7 w-7 text-emerald-700">
                    leaderboard
                  </span>
                </div>
                <div>
                  <h3 className="font-medium text-slate-800 mb-1">{t('introStep.box2Header')}</h3>
                  <ul className="text-slate-600 text-sm space-y-2 mt-2">
                    <li className="flex items-start text-left flex-row">
                      <span>{t('introStep.box2Text')}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          {/* Acciones Section */}
          {(skipTlWizIcsPage === true || isAreaLeader() || all) && (
            <div className="bg-white p-5 rounded-md border border-slate-200 shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="flex flex-row items-start">
                <div className="bg-indigo-100 p-2 rounded-md mr-4 mb-4">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-indigo-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
              </svg> */}
                  <span className="material-symbols-outlined h-7 w-7 text-indigo-700">
                    inventory
                  </span>
                </div>

                <div>
                  <h3 className="font-medium text-slate-800 mb-1">{t('introStep.box1Header')}</h3>
                  <ul className="text-slate-600 text-sm space-y-2 mt-2">
                    <li className="flex items-start text-left flex-row">
                      <span>{t('introStep.box1Text')}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          {/* Capacity Section */}
          {(tlSkipActivityWiz === true || all) && (
            <div className="bg-white p-5 rounded-md border border-slate-200 shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="flex flex-row items-start">
                <div className="bg-indigo-100 p-2 rounded-md mr-4 mb-4">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-indigo-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                </svg> */}
                  <span className="material-symbols-outlined h-7 w-7 text-purple-700">
                    schedule
                  </span>
                </div>
                <div>
                  <h3 className="font-medium text-slate-800 mb-1">{t('introStep.box3Header')}</h3>
                  <ul className="text-slate-600 text-sm space-y-2 mt-2">
                    <li className="flex items-start text-left flex-row">
                      <span>{t('introStep.box3Text')}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default UpdateIntro
