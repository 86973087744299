import React from 'react'

import { useTranslation } from 'react-i18next'
import 'react-tooltip/dist/react-tooltip.css'
import useEditKanbanBoardChangeActivityStatus from '../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import './style.css'
import { linkifyString } from '../../../utils/general'
import { dateFilter, dayMonthFilter } from '../../../utils/time'
import SooDropdownMenu from '../../../components/SooDropdownMenu'
import AddEditActivityModal from '../../../components/AddEditActivityModal'
import { markActivityAsPriority } from './utils.js'
import CardModal from '../../../components/CardModal'
import CommentsModal2 from '../../../components/CommentsModal2'
import { removeMentionMarkupFromText } from '../../../utils/parseMarkup'

const MeetingActions = ({
  data,
  refreshBGUScorecardFn = () => {},
  refreshPendingListData = () => {},
  fromHomePage = false,
  printMode,
  showFlaggedOnly = false,
  startTutorial = () => {},
  searchFilterInput = '',
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [dataToShow, setDataToShow] = React.useState(data?.actions)
  const [isCompletedBtns, setIsCompletedBtns] = React.useState({})
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()

  const [isActivityEditModalOpen, setIsActivityEditModalOpen] = React.useState(false)
  const [objectiveIdSelected, setObjectiveIdSelected] = React.useState(null)
  const [taskIdSelected, setTaskIdSelected] = React.useState(null)

  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [umtId, setUmtId] = React.useState(null)
  const [topicType, setTopicType] = React.useState(null)
  const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false)
  const [objective, setObjective] = React.useState({})
  //Create variables to hold the seen objective and topic ids
  let displayedObjIds = []
  let displayedTopicIds = []

  React.useEffect(() => {
    if (data) {
      let filteredData = data.actions

      if (showFlaggedOnly) {
        filteredData = filteredData.filter((action) => action.isPriority)
      }

      if (searchFilterInput.trim() !== '') {
        filteredData = filteredData.filter(
          (action) =>
            action?.objDescription.toLowerCase().includes(searchFilterInput.toLowerCase()) ||
            action?.meetingTopicDescription
              .toLowerCase()
              .includes(searchFilterInput.toLowerCase()) ||
            action?.description.toLowerCase().includes(searchFilterInput.toLowerCase()) ||
            action?.createdByAssignee?.name.toLowerCase().includes(searchFilterInput.toLowerCase()),
        )
      }

      setDataToShow(filteredData)
    }
  }, [data, showFlaggedOnly, searchFilterInput])

  React.useEffect(() => {
    if (dataToShow) {
      startTutorial(dataToShow.length > 0)
    }
  }, [dataToShow])

  const handleCompleteClick = (objectiveId, milestoneId, index, isOpen) => async (e) => {
    if (isCompletedBtns[e.target.id] === undefined || isCompletedBtns[e.target.id] === false) {
      if (!isOpen) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, isOpen)
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, isOpen)
      }
    } else {
      if (isCompletedBtns[e.target.id] && !isOpen) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, isOpen)
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, isOpen)
      }
    }
  }

  const changeActivityStatus = async (objectiveId, milestoneId, newStatus, index, isOpen) => {
    const postObject = {
      objectiveId,
      milestoneId,
      newStatus,
    }

    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        setIsCompletedBtns((prev) => ({
          ...prev,
          [milestoneId]: prev[milestoneId] === undefined ? true : !prev[milestoneId],
        }))
        if (!isOpen) {
          dataToShow[index].isOpen = true
        } else {
          dataToShow[index].isOpen = false
        }
        refreshBGUScorecardFn()
      },
      onError: () => {},
    })
  }

  // Sort activities by date. We need to extract the activity from the objective first.
  const cmpSortDataByActionDate = (action1, action2) => {
    if (action1.createdAt == action2.createdAt) return 0
    return action1.createdAt > action2.createdAt ? -1 : 1 // Reverse sort.
  }

  const updateDatasetWithFlagVal = (newFlagVal, milestoneId) => {
    let newData = dataToShow.map((action) => {
      if (action.milestoneId == milestoneId) {
        action.isPriority = newFlagVal
      }
      return action
    })

    setDataToShow(newData)
  }

  const topicTypeToStyle = (topicType) => {
    if (topicType == 1) {
      return 'chart-log-blocker'
    } else if (topicType == 2) {
      return 'chart-log-decision'
    } else if (topicType == 3) {
      return 'chart-log-update'
    } else if (topicType == 4) {
      return 'chart-log-admin-topic'
    }
  }

  return (
    <>
      {isTopicModalOpen === true && umtId && topicType && (
        <CardModal
          isModalOpen={isTopicModalOpen}
          closeModal={() => {
            refreshPendingListData()
            setIsTopicModalOpen(false)
            setUmtId(null)
            setTopicType(null)
            refreshBGUScorecardFn()
          }}
          umtId={umtId}
          topicType={topicType}
          hasTopicType={true}
          fromHomePage={fromHomePage}
        />
      )}

      {isActivityEditModalOpen === true && objectiveIdSelected && taskIdSelected && (
        <AddEditActivityModal
          isModalOpen={isActivityEditModalOpen}
          closeModal={() => {
            setIsActivityEditModalOpen(false)
            setObjectiveIdSelected(null)
            setTaskIdSelected(null)
          }}
          objectiveId={objectiveIdSelected}
          activityId={taskIdSelected}
          refreshBGUScorecardFn={refreshBGUScorecardFn}
          setIsRefreshObjective={refreshPendingListData}
          fromModal={true}
          hasTopicType={true}
        />
      )}

      {isCommentModalOpen && (
        <CommentsModal2
          objective={{ id: objectiveIdSelected, ...objective }}
          taskId={taskIdSelected}
          handleCancel={() => {
            setIsCommentModalOpen(false)
          }}
          setIsRefreshObjective={() => {}}
        />
      )}
      <div
        style={{ marginLeft: '1rem', display: 'flex', fontWeight: 'bold', marginBottom: '1rem' }}
      >
        <span>{t('scorecard.openCloseActionTable')}</span>
      </div>

      <div>
        <div style={{ marginLeft: '1.5rem' }}>
          {dataToShow && dataToShow.length > 0 ? (
            <div
              className={
                printMode === false
                  ? 'reminder-email-meeting-action-table'
                  : 'reminder-email-meeting-action-table-print'
              }
            >
              <div className="chart-log-meeting-header title-border">
                {t('scorecard.objectiveTitle')}
              </div>
              <div
                className="chart-log-meeting-header title-border"
                id={`email-reminder-meeting-topic`}
              >
                {t('scorecard.topicTitle')}
              </div>
              <div
                className="chart-log-meeting-header title-border"
                id={`email-reminder-meeting-action`}
              >
                {t('scorecard.actionStatementNew')}
              </div>
              <div className="chart-log-meeting-header title-border">
                {t('scorecard.createdBy')}
              </div>
              <div className="chart-log-meeting-header title-border">
                {t('scorecard.createdOn')}{' '}
                <span style={{ fontWeight: 'normal' }}> &#40;mm/dd&#41; </span>
              </div>
              <div className="chart-log-meeting-header title-border">
                {t('scorecard.dueDate')}{' '}
                <span style={{ fontWeight: 'normal' }}> &#40;mm/dd&#41; </span>
              </div>
              {printMode === false && (
                <div
                  className="chart-log-meeting-header title-border"
                  id={`email-reminder-meeting-checkbox`}
                >
                  {t('scorecard.complete')}
                </div>
              )}
              {/* {printMode === false && (
                <div className="chart-log-meeting-header title-border">
                  {t('scorecard.priority')}
                </div>
              )} */}
              {printMode === false && (
                <div
                  className="chart-log-meeting-header title-border"
                  id={'email-reminder-meeting-dropdown'}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {t('scorecard.more')}
                </div>
              )}
              {printMode === true && <div></div>}

              {dataToShow?.map((action, actionIndex) => {
                const isObjIdAlreadyDisplayed = displayedObjIds.includes(action.objectiveId)

                let topicStr = 'tId' + action.umtId + '__' + 'tType' + action.topicType

                const isTopicIdAlreadyDisplayed = displayedTopicIds.includes(topicStr)

                if (!isObjIdAlreadyDisplayed) {
                  displayedObjIds.push(action.objectiveId)
                }
                if (!isTopicIdAlreadyDisplayed) {
                  displayedTopicIds.push(topicStr)
                }
                return (
                  <>
                    <React.Fragment key={`objective-${action.milestoneId}`}>
                      <>
                        <>
                          <div
                            style={{ textAlign: 'left', wordWrap: 'break-word' }}
                            className={'actions-activities-table-cell'}
                            data-testid="action-item"
                          >
                            {action.isObjCompleted && !isObjIdAlreadyDisplayed ? (
                              <div
                                style={{ color: 'grey' }}
                                data-testid={`completed-${action.milestoneId}`}
                              >
                                <b>{t('Common:completedCaps')}</b>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div>
                              {!isObjIdAlreadyDisplayed && (
                                <span data-testid={`objDescription-${action.milestoneId}`}>
                                  {action.objDescription}
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            className={'actions-activities-table-cell actions-description-cell'}
                            style={{ wordWrap: 'break-word' }}
                            onClick={() => {
                              setTopicType(action.topicType)
                              setUmtId(action.umtId)
                              setIsTopicModalOpen(true)
                            }}
                          >
                            {!isTopicIdAlreadyDisplayed && (
                              <div
                                className={topicTypeToStyle(action.topicType)}
                                style={{
                                  fontSize: '16px',
                                }}
                                data-testid={`topicDescription-${action.milestoneId}`}
                              >
                                {linkifyString(action.meetingTopicDescription)}
                              </div>
                            )}
                          </div>
                          <div
                            style={{ textAlign: 'left', wordWrap: 'break-word' }}
                            className={'actions-activities-table-cell'}
                            data-testid={`actionDescription-${action.milestoneId}`}
                          >
                            {linkifyString(removeMentionMarkupFromText(action.description))}
                          </div>
                          <div style={{ textAlign: 'left', wordWrap: 'break-word' }}>
                            <span key={action?.createdByAssignee?.name}>
                              {action?.createdByAssignee?.name ? (
                                action.createdByAssignee.name.replace(
                                  /(\b\w+\b)\s(\b\w)\w*\b/,
                                  '$1 $2',
                                )
                              ) : (
                                <>---</>
                              )}
                            </span>
                          </div>
                          <div style={{ textAlign: 'left' }}>
                            {dayMonthFilter(action.createdAt)}
                          </div>
                          <div style={{ textAlign: 'left' }}>
                            {action.dueDate == '0' ? '--' : dayMonthFilter(action.dueDate)}
                          </div>
                          {printMode === false && (
                            <div
                              style={{ display: 'flex', justifyContent: 'center' }}
                              className={'actions-activities-table-cell'}
                            >
                              <div
                                data-testid={`complete-${action.milestoneId}`}
                                class="fitted-button checklist-done"
                                style={{ display: 'flex', alignSelf: 'flex-start' }}
                                onClick={(e) => {
                                  handleCompleteClick(
                                    action.objectiveId,
                                    action.milestoneId,
                                    actionIndex,
                                    action.isOpen,
                                  )(e)
                                }}
                                onKeyDown={(e) => {
                                  handleCompleteClick(
                                    action.objectiveId,
                                    action.milestoneId,
                                    actionIndex,
                                    action.isOpen,
                                  )(e)
                                }}
                              >
                                <span
                                  data-testid={`completeBtn-${action.milestoneId}`}
                                  class={
                                    action.isOpen
                                      ? 'material-symbols-outlined checklist-done-grey'
                                      : 'material-symbols-outlined checklist-done-green '
                                  }
                                >
                                  done
                                </span>
                              </div>
                            </div>
                          )}
                          {/* {printMode === false && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {!action.isPriority && (
                                <button class="icon-button-no-border">
                                  <span
                                    data-testid={`priority-${action.milestoneId}`}
                                    class="material-symbols-outlined icon-size"
                                    onClick={() => {
                                      markActivityAsPriority(true, action.milestoneId)
                                      updateDatasetWithFlagVal(true, action.milestoneId)
                                    }}
                                    onKeyDown={() => {
                                      markActivityAsPriority(true, action.milestoneId)
                                      updateDatasetWithFlagVal(true, action.milestoneId)
                                    }}
                                  >
                                    flag
                                  </span>
                                </button>
                              )}
                              {action.isPriority && (
                                <button class="icon-button-no-border">
                                  <span
                                    data-testid={`priority-${action.milestoneId}`}
                                    class="material-symbols-outlined icon-size"
                                    style={{ color: 'red' }}
                                    onClick={() => {
                                      markActivityAsPriority(false, action.milestoneId)
                                      updateDatasetWithFlagVal(false, action.milestoneId)
                                    }}
                                    onKeyDown={() => {
                                      markActivityAsPriority(false, action.milestoneId)
                                      updateDatasetWithFlagVal(false, action.milestoneId)
                                    }}
                                  >
                                    flag
                                  </span>
                                </button>
                              )}
                            </div>
                          )} */}
                          {printMode === false && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: 'fit-content',
                              }}
                            >
                              <SooDropdownMenu
                                fromModal={true}
                                fromAMA={true}
                                onClickEdit={() => {
                                  setObjectiveIdSelected(action.objectiveId)
                                  setTaskIdSelected(action.milestoneId)
                                  setIsActivityEditModalOpen(true)
                                }}
                                onClickComment={() => {
                                  //setObjective({})
                                  setObjectiveIdSelected(action.objectiveId)
                                  setTaskIdSelected(action.milestoneId)
                                  setIsCommentModalOpen(true)
                                }}
                                onClickSeeTopic={() => {
                                  setTopicType(action.topicType)
                                  setUmtId(action.umtId)
                                  setIsTopicModalOpen(true)
                                }}
                              />
                            </div>
                          )}
                          {printMode === true && (
                            <>
                              {action.isPriority ? (
                                <button class="icon-button-no-border">
                                  <span
                                    class="material-symbols-outlined icon-size"
                                    style={{ color: 'red' }}
                                  >
                                    flag
                                  </span>
                                </button>
                              ) : (
                                <div></div>
                              )}
                            </>
                          )}
                        </>
                      </>
                    </React.Fragment>
                  </>
                )
              })}
            </div>
          ) : (
            <div style={{ display: 'flex' }}>{t('Common:emailReminder.noItemsMsg')}</div>
          )}
        </div>
      </div>
    </>
  )
}

export default MeetingActions
