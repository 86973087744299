import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  getKpiList,
  getSuggestedAndExistingKpisForObj,
} from '../../../../api/services/kpi.services'
import { useKPIContext } from '../../context/kpiContext'
import InPageLoader from '../../../../components/InPageLoader'
import AddEditKPIModal from '../../../../components/KPI/AddEditKPIModal'

const KPISuggestedKPIsBase = ({
  objId,
  objStatement,
  setSuggestedKpis = () => {},
  suggestedKpis = [],
  isFromBRP = false,
  exsitingSuggestedKpis = [],
}) => {
  const { t } = useTranslation(['KPIWizard', 'Common'])

  const [existingKpis, setExistingKpis] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [newKpi, setNewKpi] = React.useState({
    name: '',
    kpiType: 1,
    unit: '',
    frequency: 0,
    target: '',
    targetDate: '',
    dataAvailableToMeasure: false,
    include: false,
    startDate: '',
  })
  const [isOpenAddEditKPIModal, setIsOpenAddEditKPIModal] = React.useState(false)
  const [selectedKpi, setSelectedKpi] = React.useState(null)

  const kpiTypeList = [
    { label: t('Common:kpi.percent'), value: 1 },
    { label: t('Common:kpi.number'), value: 2 },
    { label: t('Common:kpi.milestone'), value: 3 },
  ]

  const kpiFrequencyList = [
    { label: '', value: 0 },
    { label: t('Common:kpi.weekly'), value: 1 },
    { label: t('Common:kpi.biweekly'), value: 2 },
    { label: t('Common:kpi.monthly'), value: 3 },
    { label: t('Common:kpi.quarterly'), value: 4 },
    { label: t('Common:kpi.bimonthly'), value: 5 },
    { label: t('Common:kpi.semiannually'), value: 6 },
    { label: t('Common:kpi.annually'), value: 7 },
  ]

  const convertFrequencyEnumToText = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('Common:kpi.weekly')
      case 2:
        return t('Common:kpi.biweekly')
      case 3:
        return t('Common:kpi.monthly')
      case 4:
        return t('Common:kpi.quarterly')
      case 5:
        return t('Common:kpi.bimonthly')
      case 6:
        return t('Common:kpi.semiannually')
      case 7:
        return t('Common:kpi.annually')
      default:
        return t('Common:kpi.unknown')
    }
  }

  const convertTypeEnumToText = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('Common:kpi.percent')
      case 2:
        return t('Common:kpi.number')
      case 3:
        return t('Common:kpi.milestone')
      default:
        return t('Common:kpi.unknown')
    }
  }

  React.useEffect(() => {
    getKpis()
  }, [])

  async function getKpis() {
    console.log('exsitingSuggestedKpis', exsitingSuggestedKpis)
    if (exsitingSuggestedKpis.length == 0 && !suggestedKpis && !existingKpis) {
      setIsLoading(true)
      let result = await getSuggestedAndExistingKpisForObj(parseInt(objId), isFromBRP, objStatement)
      setIsLoading(false)
      if (result && result?.response?.existing_kpis && result?.response?.suggested_kpis) {
        setSuggestedKpis(result.response.suggested_kpis.kpis)
        setExistingKpis(result.response.existing_kpis.kpis)
      } else {
        //todo: deal with error
      }
    } else {
      setSuggestedKpis(exsitingSuggestedKpis)
    }
  }

  const handleSuggestedKpiFieldChanged = (index, event, field) => {
    let tempSuggestedKpis = [...suggestedKpis]

    if (field === 'dataAvailableToMeasure' || field === 'include') {
      tempSuggestedKpis[index][field] = event
    } else if (field === 'kpiType' || field === 'frequency') {
      tempSuggestedKpis[index][field] = parseInt(event.target.value)
    } else {
      tempSuggestedKpis[index][field] = event.target.value
    }

    if (field === 'kpiType' && event.target.value === 3) {
      delete tempSuggestedKpis[index]['unit']
      delete tempSuggestedKpis[index]['target']
    }

    if (
      field === 'dataAvailableToMeasure' &&
      event === false &&
      tempSuggestedKpis[index]['kpiType'] !== 3
    ) {
      tempSuggestedKpis[index]['include'] = false
    }
    setSuggestedKpis(tempSuggestedKpis)
  }

  const handleNewKpiFieldChanged = (event, field) => {
    let tempNewKpi = { ...newKpi }

    if (field === 'dataAvailableToMeasure' || field === 'include') {
      tempNewKpi[field] = event
    } else if (field === 'kpiType' || field === 'frequency') {
      tempNewKpi[field] = parseInt(event.target.value)
    } else {
      tempNewKpi[field] = event.target.value
    }

    if (field === 'kpiType' && event.target.value === 3) {
      delete tempNewKpi['unit']
      delete tempNewKpi['target']
    }

    if (field === 'dataAvailableToMeasure' && event === false && tempNewKpi['kpiType'] !== 3) {
      tempNewKpi['include'] = false
    }
    setNewKpi(tempNewKpi)
  }

  const addNewKpi = () => {
    setSuggestedKpis([...suggestedKpis, newKpi])
    setNewKpi({
      name: '',
      kpiType: 1,
      unit: '',
      frequency: 0,
      target: '',
      targetDate: '',
      dataAvailableToMeasure: false,
      include: false,
      startDate: '',
    })
  }

  const reload = () => {
    loadKpisList()
  }

  async function loadKpisList() {
    setIsLoading(true)
    let result = await getKpiList(parseInt(objId))
    setIsLoading(false)
    if (result && result.kpis) {
      setExistingKpis(result.kpis)
    }
  }

  return (
    <div className="wizard-step">
      {isLoading && <InPageLoader inComponent={true} />}
      {isOpenAddEditKPIModal && (
        <AddEditKPIModal
          closeModal={() => setIsOpenAddEditKPIModal(false)}
          mode="edit"
          objId={parseInt(objId)}
          kpi={selectedKpi}
          reload={() => {
            reload()
          }}
        />
      )}
      {!isLoading && (
        <div className="wizard-step__single-answer-container" style={{ padding: '1rem' }}>
          {!isFromBRP && (
            <div style={{ display: 'flex', width: '100%' }}>
              <h2
                className="wizard-step__title"
                style={{
                  margin: '1rem 0 1rem 0',
                  textAlign: 'left',
                  color: 'rgb(91,132,203)',
                  maxWidth: '100%',
                }}
              >
                {objStatement}
              </h2>
            </div>
          )}
          <div className="kpi-wizard-kpis-container">
            {suggestedKpis?.length > 0 && (
              <>
                <h3
                  className="wizard-step__title"
                  style={{ margin: '1rem 0 1rem 0', textAlign: 'left' }}
                >
                  {t('suggestedKpis')}
                </h3>
                <div className="kpi-wizard-kpis-suggested-table">
                  <div className="kpi-wizard-kpis-row header-row">
                    <div className=""></div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.name')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.type')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.unit')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.updateFrequency')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.target')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.startDate')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.reachBy')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.dataAvailableToMeasure')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.include')}
                    </div>
                  </div>

                  {suggestedKpis?.map((kpi, index) => (
                    <div key={index} className="kpi-wizard-kpis-row">
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {kpi?.error === true && (
                          <span
                            className="material-symbols-outlined icon-size"
                            style={{ color: '#EB001B' }}
                          >
                            <span class="material-symbols-outlined">priority_high</span>
                          </span>
                        )}
                      </div>
                      <div
                        className={`actions-activities-table-cell ${
                          kpi.errorFields && kpi.errorFields.name ? 'input-error' : ''
                        }`}
                        style={{ wordWrap: 'break-word' }}
                      >
                        <input
                          name="name"
                          className="kpi-wizard-input-box"
                          value={kpi?.name}
                          onChange={(e) => handleSuggestedKpiFieldChanged(index, e, 'name')}
                        />
                      </div>
                      <div
                        className={`actions-activities-table-cell ${
                          kpi.errorFields && kpi.errorFields.kpiType ? 'input-error' : ''
                        }`}
                        style={{ wordWrap: 'break-word' }}
                      >
                        <select
                          as="kpiType"
                          name="kpiType"
                          className="kpi-wizard-input-box"
                          value={kpi?.kpiType}
                          onChange={(e) => handleSuggestedKpiFieldChanged(index, e, 'kpiType')}
                        >
                          {kpiTypeList.map((type) => (
                            <option key={type.value} value={type.value} label={type.label} />
                          ))}
                        </select>
                      </div>
                      <div
                        className={`actions-activities-table-cell ${
                          kpi.errorFields && kpi.errorFields.unit ? 'input-error' : ''
                        }`}
                        style={{ wordWrap: 'break-word' }}
                      >
                        <input
                          name="unit"
                          className="kpi-wizard-input-box"
                          value={kpi?.unit}
                          onChange={(e) => handleSuggestedKpiFieldChanged(index, e, 'unit')}
                          disabled={kpi?.kpiType === 3}
                          style={
                            kpi?.kpiType === 3
                              ? {
                                  backgroundColor: '#EDEDED',
                                }
                              : {}
                          }
                        />
                      </div>
                      <div
                        className={`actions-activities-table-cell ${
                          kpi.errorFields && kpi.errorFields.frequency ? 'input-error' : ''
                        }`}
                        style={{ wordWrap: 'break-word' }}
                      >
                        <select
                          as="frequency"
                          name="frequency"
                          className="kpi-wizard-input-box"
                          value={kpi?.frequency}
                          onChange={(e) => handleSuggestedKpiFieldChanged(index, e, 'frequency')}
                        >
                          {kpiFrequencyList.map((type) => (
                            <option key={type.value} value={type.value} label={type.label} />
                          ))}
                        </select>
                      </div>

                      <div
                        className={`actions-activities-table-cell ${
                          kpi.errorFields && kpi.errorFields.target ? 'input-error' : ''
                        }`}
                        style={{ wordWrap: 'break-word' }}
                      >
                        <input
                          name="target"
                          className="kpi-wizard-input-box"
                          type="number"
                          value={kpi?.target}
                          onChange={(e) => handleSuggestedKpiFieldChanged(index, e, 'target')}
                          disabled={kpi?.kpiType === 3}
                          style={
                            kpi?.kpiType === 3
                              ? {
                                  backgroundColor: '#EDEDED',
                                }
                              : {}
                          }
                        />
                      </div>
                      <div
                        className={`actions-activities-table-cell ${
                          kpi.errorFields && kpi.errorFields.startDate ? 'input-error' : ''
                        }`}
                        style={{ wordWrap: 'break-word' }}
                      >
                        <input
                          name="startDate"
                          className="kpi-wizard-input-box"
                          type="text"
                          value={kpi?.startDate}
                          placeholder="yyyy-mm-dd"
                          onChange={(e) => {
                            const value = e.target.value
                            // Try to parse the typed date
                            const date = new Date(value)
                            if (!isNaN(date.getTime())) {
                              // If valid date, format it as YYYY-MM-DD
                              const formattedDate = date.toISOString().split('T')[0]
                              handleSuggestedKpiFieldChanged(
                                index,
                                { target: { value: formattedDate } },
                                'startDate',
                              )
                            } else {
                              // If not a valid date, still update the field to allow typing
                              handleSuggestedKpiFieldChanged(index, e, 'startDate')
                            }
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            const pastedText = e.clipboardData.getData('text')

                            // Try to parse the pasted date
                            const date = new Date(pastedText)
                            if (!isNaN(date.getTime())) {
                              // If valid date, format it as YYYY-MM-DD
                              const formattedDate = date.toISOString().split('T')[0]
                              handleSuggestedKpiFieldChanged(
                                index,
                                { target: { value: formattedDate } },
                                'startDate',
                              )
                            }
                          }}
                          // Optional: Add onBlur to format the date when the user leaves the field
                          onBlur={(e) => {
                            const value = e.target.value
                            const date = new Date(value)
                            if (!isNaN(date.getTime())) {
                              const formattedDate = date.toISOString().split('T')[0]
                              handleSuggestedKpiFieldChanged(
                                index,
                                { target: { value: formattedDate } },
                                'startDate',
                              )
                            }
                          }}
                        />
                      </div>
                      <div
                        className={`actions-activities-table-cell ${
                          kpi.errorFields && kpi.errorFields.targetDate ? 'input-error' : ''
                        }`}
                        style={{ wordWrap: 'break-word' }}
                      >
                        <input
                          name="targetDate"
                          className="kpi-wizard-input-box"
                          type="text"
                          value={kpi?.targetDate}
                          placeholder="yyyy-mm-dd"
                          onChange={(e) => {
                            const value = e.target.value
                            // Try to parse the typed date
                            const date = new Date(value)
                            if (!isNaN(date.getTime())) {
                              // If valid date, format it as YYYY-MM-DD
                              const formattedDate = date.toISOString().split('T')[0]
                              handleSuggestedKpiFieldChanged(
                                index,
                                { target: { value: formattedDate } },
                                'targetDate',
                              )
                            } else {
                              // If not a valid date, still update the field to allow typing
                              handleSuggestedKpiFieldChanged(index, e, 'targetDate')
                            }
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            const pastedText = e.clipboardData.getData('text')

                            // Try to parse the pasted date
                            const date = new Date(pastedText)
                            if (!isNaN(date.getTime())) {
                              // If valid date, format it as YYYY-MM-DD
                              const formattedDate = date.toISOString().split('T')[0]
                              handleSuggestedKpiFieldChanged(
                                index,
                                { target: { value: formattedDate } },
                                'targetDate',
                              )
                            }
                          }}
                          // Optional: Add onBlur to format the date when the user leaves the field
                          onBlur={(e) => {
                            const value = e.target.value
                            const date = new Date(value)
                            if (!isNaN(date.getTime())) {
                              const formattedDate = date.toISOString().split('T')[0]
                              handleSuggestedKpiFieldChanged(
                                index,
                                { target: { value: formattedDate } },
                                'targetDate',
                              )
                            }
                          }}
                        />
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        <input
                          name="dataAvailableToMeasure"
                          type="checkbox"
                          checked={kpi?.dataAvailableToMeasure}
                          onChange={(e) =>
                            handleSuggestedKpiFieldChanged(
                              index,
                              kpi?.dataAvailableToMeasure ? !kpi.dataAvailableToMeasure : true,
                              'dataAvailableToMeasure',
                            )
                          }
                          disabled={kpi?.kpiType === 3}
                          style={
                            kpi?.kpiType === 3
                              ? {
                                  backgroundColor: '#EDEDED',
                                }
                              : {}
                          }
                        />
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        <input
                          name="include"
                          type="checkbox"
                          checked={kpi?.include}
                          onChange={(e) =>
                            handleSuggestedKpiFieldChanged(
                              index,
                              kpi?.include ? !kpi.include : true,
                              'include',
                            )
                          }
                          disabled={
                            kpi?.kpiType !== 3 &&
                            (kpi?.dataAvailableToMeasure === false ||
                              !('dataAvailableToMeasure' in kpi))
                          }
                          style={
                            kpi?.kpiType !== 3 &&
                            (kpi?.dataAvailableToMeasure === false ||
                              !('dataAvailableToMeasure' in kpi))
                              ? {
                                  backgroundColor: '#EDEDED',
                                }
                              : {}
                          }
                        />
                      </div>
                    </div>
                  ))}

                  {/* Blank row for adding new KPI */}
                  <div className="kpi-wizard-kpis-row">
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <button
                        type="button"
                        className="btn-card tonedown-blue-btn"
                        style={{ width: '1.8rem' }}
                        onClick={addNewKpi}
                      >
                        <span>+</span>
                      </button>
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <input
                        name="name"
                        className="kpi-wizard-input-box"
                        value={newKpi.name}
                        onChange={(e) => handleNewKpiFieldChanged(e, 'name')}
                      />
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <select
                        as="kpiType"
                        name="kpiType"
                        className="kpi-wizard-input-box"
                        value={newKpi.kpiType}
                        onChange={(e) => handleNewKpiFieldChanged(e, 'kpiType')}
                      >
                        {kpiTypeList.map((type) => (
                          <option key={type.value} value={type.value} label={type.label} />
                        ))}
                      </select>
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <input
                        name="unit"
                        className="kpi-wizard-input-box"
                        value={newKpi.unit}
                        onChange={(e) => handleNewKpiFieldChanged(e, 'unit')}
                        disabled={newKpi.kpiType === 3}
                        style={{ visibility: newKpi.kpiType === 3 ? 'hidden' : 'visible' }}
                      />
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <select
                        as="frequency"
                        name="frequency"
                        className="kpi-wizard-input-box"
                        value={newKpi.frequency}
                        onChange={(e) => handleNewKpiFieldChanged(e, 'frequency')}
                      >
                        {kpiFrequencyList.map((type) => (
                          <option key={type.value} value={type.value} label={type.label} />
                        ))}
                      </select>
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <input
                        name="target"
                        className="kpi-wizard-input-box"
                        type="number"
                        value={newKpi.target}
                        onChange={(e) => handleNewKpiFieldChanged(e, 'target')}
                        disabled={newKpi.kpiType === 3}
                        style={{ visibility: newKpi.kpiType === 3 ? 'hidden' : 'visible' }}
                      />
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <input
                        name="startDate"
                        className="kpi-wizard-input-box"
                        type="date"
                        value={newKpi.startDate}
                        onChange={(e) => handleNewKpiFieldChanged(e, 'startDate')}
                      />
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <input
                        name="targetDate"
                        className="kpi-wizard-input-box"
                        type="date"
                        value={newKpi.targetDate}
                        onChange={(e) => handleNewKpiFieldChanged(e, 'targetDate')}
                      />
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <input
                        name="dataAvailableToMeasure"
                        type="checkbox"
                        checked={newKpi.dataAvailableToMeasure}
                        onChange={(e) =>
                          handleNewKpiFieldChanged(
                            newKpi.dataAvailableToMeasure ? !newKpi.dataAvailableToMeasure : true,
                            'dataAvailableToMeasure',
                          )
                        }
                        disabled={newKpi.kpiType === 3}
                      />
                    </div>
                    <div
                      className="actions-activities-table-cell"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <input
                        name="include"
                        type="checkbox"
                        checked={newKpi.include}
                        onChange={(e) =>
                          handleNewKpiFieldChanged(
                            newKpi.include ? !newKpi.include : true,
                            'include',
                          )
                        }
                        disabled={
                          newKpi.kpiType !== 3 &&
                          (newKpi.dataAvailableToMeasure === false ||
                            !('dataAvailableToMeasure' in newKpi))
                        }
                      />
                    </div>
                    {/* <div className="actions-activities-table-cell" style={{ wordWrap: 'break-word' }}>
                  <button onClick={addNewKpi}>{t('Common:add')}</button>
                </div> */}
                  </div>
                </div>
              </>
            )}

            {existingKpis?.length > 0 && (
              <>
                <h3
                  className="wizard-step__title"
                  style={{ margin: '1rem 0 1rem 0', textAlign: 'left' }}
                >
                  {t('existingKpis')}
                </h3>

                <div className="kpi-wizard-kpis-existing-table" style={{ textAlign: 'left' }}>
                  <div className="kpi-wizard-kpis-row header-row">
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.name')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.type')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.unit')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.updateFrequency')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.target')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.startDate')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('Common:kpi.reachBy')}
                    </div>
                    <div></div>
                  </div>

                  {existingKpis?.map((kpi, index) => (
                    <div key={index} className="kpi-wizard-kpis-row">
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {kpi?.name}
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {convertTypeEnumToText(kpi?.kpiType)}
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {kpi?.unit}
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {convertFrequencyEnumToText(kpi?.frequency)}
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {kpi?.target}
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {kpi?.startDate}
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {kpi?.targetDate}
                      </div>
                      <div
                        className="actions-activities-table-cell"
                        style={{ wordWrap: 'break-word' }}
                      >
                        <button
                          type="button"
                          className="btn-card tonedown-blue-btn"
                          style={{ width: '1.8rem' }}
                          onClick={() => {
                            setSelectedKpi(kpi)
                            setIsOpenAddEditKPIModal(true)
                          }}
                        >
                          <span className="material-symbols-outlined icon-size-sm">edit</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const KPISuggestedKPIs = () => {
  const { state, dispatch } = useKPIContext()
  const [suggestedKpis, setSuggestedKpis] = React.useState(null)

  React.useEffect(() => {
    if (suggestedKpis) {
      let kpisToSave = []
      for (let kpi of suggestedKpis) {
        if (kpi?.include === true) {
          kpisToSave.push(kpi)
        }
      }
      dispatch({ type: 'SET_SUGGESTED_KPIS_TO_SAVE', value: kpisToSave })
    }
  }, [suggestedKpis])

  React.useEffect(() => {
    if (
      state.suggestedKpisToSaveWithError &&
      state.suggestedKpisToSaveWithError.length > 0 &&
      suggestedKpis
    ) {
      // Create a copy and set the 'error' property to false for all suggestedKpis
      let tempSuggestedKpis = suggestedKpis.map((kpi) => ({
        ...kpi,
        error: false,
        errorFields: {},
      }))

      for (let i = 0; i < state.suggestedKpisToSaveWithError.length; i++) {
        for (let j = 0; j < tempSuggestedKpis.length; j++) {
          if (state.suggestedKpisToSaveWithError[i].name === tempSuggestedKpis[j].name) {
            tempSuggestedKpis[j].error = true
            tempSuggestedKpis[j].errorFields = {
              ...tempSuggestedKpis[j].errorFields,
              ...state.suggestedKpisToSaveWithError[i].errorFields,
            }
          }
        }
      }
      setSuggestedKpis(tempSuggestedKpis)
    }
  }, [state.suggestedKpisToSaveWithError])

  const updateSuggestedKpis = (newSuggestedKpis) => {
    setSuggestedKpis(newSuggestedKpis)
  }

  if (!state?.obj?.id) {
    return null
  }

  return (
    <KPISuggestedKPIsBase
      objId={state.obj.id}
      objStatement={state?.obj?.statement}
      suggestedKpis={suggestedKpis}
      setSuggestedKpis={updateSuggestedKpis}
    />
  )
}
export default KPISuggestedKPIs

export const KPISuggestedKPIsModalComponent = ({
  objId,
  objStatement,
  setSuggestedKpisfromBRP = () => {},
  suggestedKpisToSaveWithError = [],
  isLoading,
  exsitingSuggestedKpis = [],
}) => {
  console.log('isLoading:', isLoading)
  const [suggestedKpis, setSuggestedKpis] = React.useState(null)

  React.useEffect(() => {
    if (suggestedKpis) {
      let kpisToSave = []
      for (let kpi of suggestedKpis) {
        if (kpi?.include === true) {
          kpisToSave.push(kpi)
        }
      }
      setSuggestedKpisfromBRP(suggestedKpis)
    }
  }, [suggestedKpis])

  React.useEffect(() => {
    if (suggestedKpisToSaveWithError && suggestedKpisToSaveWithError.length > 0 && suggestedKpis) {
      console.log('suggestedKpis: ', suggestedKpis)
      // Create a copy and set the 'error' property to false for all suggestedKpis
      let tempSuggestedKpis = suggestedKpis.map((kpi) => ({
        ...kpi,
        error: false,
        errorFields: {},
      }))

      for (let i = 0; i < suggestedKpisToSaveWithError.length; i++) {
        for (let j = 0; j < tempSuggestedKpis.length; j++) {
          if (suggestedKpisToSaveWithError[i].name === tempSuggestedKpis[j].name) {
            tempSuggestedKpis[j].error = true
            tempSuggestedKpis[j].errorFields = {
              ...tempSuggestedKpis[j].errorFields,
              ...suggestedKpisToSaveWithError[i].errorFields,
            }
          }
        }
      }
      setSuggestedKpis(tempSuggestedKpis)
    }
  }, [suggestedKpisToSaveWithError])

  const updateSuggestedKpis = (newSuggestedKpis) => {
    setSuggestedKpis(newSuggestedKpis)
  }

  return (
    <KPISuggestedKPIsBase
      objId={objId}
      suggestedKpis={suggestedKpis}
      setSuggestedKpis={updateSuggestedKpis}
      objStatement={objStatement}
      isFromBRP={true}
      exsitingSuggestedKpis={exsitingSuggestedKpis}
    />
  )
}
