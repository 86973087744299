import { Position, MarkerType } from '@xyflow/react'
import { color, transition } from 'd3'

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const { width: intersectionNodeWidth, height: intersectionNodeHeight } = intersectionNode.measured
  const intersectionNodePosition = intersectionNode.internals.positionAbsolute
  const targetPosition = targetNode.internals.positionAbsolute

  const w = intersectionNodeWidth / 2
  const h = intersectionNodeHeight / 2

  const x2 = intersectionNodePosition.x + w
  const y2 = intersectionNodePosition.y + h
  const x1 = targetPosition.x + targetNode.measured.width / 2
  const y1 = targetPosition.y + targetNode.measured.height / 2

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h)
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h)
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1))
  const xx3 = a * xx1
  const yy3 = a * yy1
  const x = w * (xx3 + yy3) + x2
  const y = h * (-xx3 + yy3) + y2

  return { x, y }
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.internals.positionAbsolute, ...node }
  const nx = Math.round(n.x)
  const ny = Math.round(n.y)
  const px = Math.round(intersectionPoint.x)
  const py = Math.round(intersectionPoint.y)

  if (px <= nx + 1) {
    return Position.Left
  }
  if (px >= nx + n.measured.width - 1) {
    return Position.Right
  }
  if (py <= ny + 1) {
    return Position.Top
  }
  if (py >= n.y + n.measured.height - 1) {
    return Position.Bottom
  }

  return Position.Top
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target)
  const targetIntersectionPoint = getNodeIntersection(target, source)

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint)
  const targetPos = getEdgePosition(target, targetIntersectionPoint)

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  }
}

function handleItem(
  item,
  index,
  {
    x,
    y,
    topicColor,
    onRemoveItem,
    lockMindMap,
    updateMindMapText,
    isReadOnly,
    forwardOpenModal,
    switchActionDecision,
    topicText,
    isConverted,
    nodes,
    topicIndex,
    overallIndex,
    fromExternalLink,
  },
  isUpdatingMindMap,
) {
  const itemY = y + (overallIndex + 1) * 175 + 60

  const itemId = `item-${Math.random().toString(18).substr(2)}`

  nodes.push({
    id: itemId,
    type: 'mindmapItemNode',
    data: {
      label: item.statement,
      type: item.type,
      borderColor: topicColor,
      removeItem: onRemoveItem,
      isRecommended: item.isRecommended === true,
      lockMindMap: lockMindMap,
      updateMindMap: updateMindMapText,
      index: index,
      topicIndex: topicIndex,
      switchActionDecision: switchActionDecision,
      topicText: topicText,
      readOnly: isReadOnly,
      forwardOpenModal: forwardOpenModal,
      isConverted: isConverted,
      // id: item.id,
      isAddedInLastRound: item.isAddedInLastRound ?? false,
      isUpdatingMindMap: isUpdatingMindMap,
      fromExternalLink: fromExternalLink,
    },
    position: { x: x, y: itemY },
  })
}

function processItems(items, config, startIndex = 0, isUpdatingMindMap = false) {
  items.forEach((item, ind) =>
    handleItem(
      item,
      ind,
      {
        ...config,
        overallIndex: ind + startIndex,
      },
      isUpdatingMindMap,
    ),
  )
}

export function createNodesAndEdges(
  data,
  onRemoveItem,
  updateMindMapText,
  lockMindMap,
  isReadOnly,
  addNewItem,
  switchActionDecision,
  forwardOpenModal,
  addNewTopic,
  allowBulkConvertToMH = false,
  isUpdatingMindMap = false,
  fromZisiReadOnlyExternalLink = false,
) {
  const nodes = []
  const edges = []
  // const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  const xOffset = fromZisiReadOnlyExternalLink ? 50 : 350
  const center = { x: window.innerWidth / 2 - xOffset, y: 0 }

  const colors = [
    'rgb(233,227,211)',
    'rgb(212,227,237)',
    'rgb(226,246,211)',
    'rgb(255,248,184)',
    'rgb(246,226,221)',
  ]

  let colorIndex = 0

  const getNextColor = () => {
    const color = colors[colorIndex]
    colorIndex = (colorIndex + 1) % colors.length
    return color
  }

  const estimateNodeSize = (label) => {
    const avgCharWidth = 8
    const lineHeight = 20
    const maxWidth = 150
    const words = label.split(' ')
    let lines = 1
    let currentLineLength = 0

    words.forEach((word) => {
      if ((currentLineLength + word.length) * avgCharWidth > maxWidth) {
        lines++
        currentLineLength = word.length
      } else {
        currentLineLength += word.length + 1
      }
    })

    return {
      width: Math.min(label.length * avgCharWidth, maxWidth),
      height: lines * lineHeight,
    }
  }

  // Add problem statement as the center node with a default border color
  // nodes.push({
  //   id: 'problem',
  //   type: 'mindmapProblemNode',
  //   data: {
  //     label: data.problem,
  //     borderColor: '#000000', // Black border for the central node
  //     addNewTopic: addNewTopic,
  //   },
  //   position: {
  //     x: center.x - 150,
  //     y: center.y,
  //   },
  // })

  const hasTopicContent = (topic) => {
    return (
      topic.decisions?.filter((item) => item.text)?.length > 0 ||
      topic.actions?.filter((item) => item.text)?.length > 0 ||
      topic.comments?.filter((item) => item.text)?.length > 0 ||
      topic?.recommended_actions?.filter((item) => item.text)?.length > 0
    )
  }

  const areAllTopicsEmpty = data.action_plans.every((topic) => !hasTopicContent(topic))

  const filteredTopics = data.action_plans.filter((topic) => {
    const isNewlyAddedByUser = topic?.user_added ?? false
    return isNewlyAddedByUser || hasTopicContent(topic) || areAllTopicsEmpty
  })
  const topicCount = filteredTopics.length
  console.log('data.action_plans', data.action_plans)
  console.log('filteredTopics', filteredTopics)
  filteredTopics.forEach((topic, index) => {
    const x = center.x + (index - (topicCount - 1) / 2) * 240
    const y = center.y + 10

    const topicColor = getNextColor()
    // force generating a unique id for each topic node otherwise react-flow will not update the node
    const topicId = `topic-${index}-source-${Math.random().toString(18)}`
    const hasChildren =
      topic.decisions.length +
        topic.actions.length +
        (topic?.comments?.length ?? 0) +
        (topic?.recommended_actions?.length ?? 0) >
      0
    // const isNewlyAddedByUser = topic?.user_added ?? false
    // if (!areAllTopicsEmpty && !isNewlyAddedByUser && !hasChildren) {
    //   console.log('returning')
    //   return
    // }

    // Add topic node with colored border
    nodes.push({
      id: topicId,
      type: 'mindmapTopicNode',
      data: {
        label: topic.topic,
        borderColor: topicColor, // Set border color for topic node
        updateMindMap: updateMindMapText,
        lockMindMap: lockMindMap,
        index: index,
        // readOnly: isReadOnly,
        hasChildren: hasChildren,
        removeItem: onRemoveItem,
        addNewItem: addNewItem,
        type: topic.type,
        allowBulkConvertToMH: allowBulkConvertToMH,
        isParkingLot: topic?.is_parking_lot ?? false,
        isUpdatingMindMap: isUpdatingMindMap,
        summary: topic?.summary ?? '',
        fromExternalLink: fromZisiReadOnlyExternalLink,
        areAllTopicsEmpty: areAllTopicsEmpty,
        topicCount: topicCount,
      },
      position: { x, y },
    })

    edges.push({
      id: `edge-problem-${topicId}`,
      source: 'problem',
      target: topicId,
      type: 'default',
      style: { stroke: topicColor, strokeWidth: 4 },
    })

    const decisions = topic.decisions
      .filter((item) => item.text)
      .map((item) => ({
        statement: item.text,
        type: 'decision',
        isConverted: item?.is_converted ?? false,
        // id: item.id,
        isAddedInLastRound: item.isAddedInLastRound,
      }))

    const comments =
      topic?.comments
        ?.filter((item) => item.text)
        ?.map((item) => ({
          statement: item.text,
          type: 'comment',
          isConverted: item?.is_converted ?? false,
          // id: item.id,
          isAddedInLastRound: item.isAddedInLastRound,
        })) ?? []

    const actions = topic.actions
      .filter((item) => item.text)
      .map((item) => ({
        statement: item.text,
        type: 'action',
        isConverted: item?.is_converted ?? false,
        // id: item.id,
        isAddedInLastRound: item.isAddedInLastRound,
      }))

    const commonConfig = {
      x,
      y,
      topicColor,
      onRemoveItem,
      lockMindMap,
      updateMindMapText,
      isReadOnly,
      forwardOpenModal,
      switchActionDecision,
      topicText: topic.topic,
      nodes,
      topicIndex: index,
      fromExternalLink: fromZisiReadOnlyExternalLink,
    }

    // Process items in order: decisions, comments, actions
    processItems(actions, commonConfig, 0, isUpdatingMindMap)
    processItems(decisions, commonConfig, actions.length, isUpdatingMindMap)
    processItems(comments, commonConfig, actions.length + decisions.length, isUpdatingMindMap)

    // Handle recommended actions if they exist
    if (topic?.recommended_actions?.length > 0) {
      const recommendedActions = topic.recommended_actions.map((item) => ({
        statement: item.text,
        type: 'action',
        isRecommended: true,
        isConverted: item?.is_converted ?? false,
        // id: item.id,
      }))

      processItems(
        recommendedActions,
        commonConfig,
        decisions.length + comments.length + actions.length,
        isUpdatingMindMap,
      )
    }
  })

  return { nodes, edges }
}
