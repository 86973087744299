import { useBrainstorm } from '../Recorder/BrainstormContext'
import InPageLoader from '../../../phoenix/components/Loader'

const SubQuestions = ({
  subQuestionState,
  refreshQuestion = () => Promise.resolve(),
  refreshButtonLoading,
  collapseStates,
  isExploringProblems = false,
}) => {
  const { state } = useBrainstorm()

  return (
    <>
      {isExploringProblems && (
        <div className="flex justify-center">
          <InPageLoader inModal={true} />
        </div>
      )}
      {!isExploringProblems &&
        subQuestionState.map((question, ind) => {
          return (
            <div key={ind}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  gap: '0.5rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
                  {!state.readOnly && (
                    <div
                      className="sleek-button sleek-blue"
                      onClick={async () => {
                        await refreshQuestion(question, ind)
                      }}
                      style={{
                        marginTop: 'auto',
                        height: '1rem',
                        color: '#4b83f0',
                      }}
                    >
                      <span
                        className="material-symbols-outlined icon-size"
                        style={{
                          display: 'inline-block',
                          transform: refreshButtonLoading?.[ind]
                            ? 'rotate(360deg)'
                            : 'rotate(0deg)',
                          transition: 'transform 1s linear',
                          transformOrigin: 'center',
                          ...(refreshButtonLoading?.[ind] === true && {
                            transform: 'rotate(360deg)',
                            transition: 'none',
                            animation: 'infinite-rotation 0.5s linear infinite',
                          }),
                          fontSize: '15px',
                          color: '#4b83f0',
                        }}
                      >
                        refresh
                      </span>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div
                          className="review-generated-topics-list-item"
                          style={{ width: '100%' }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                            }}
                          >
                            {question}
                          </span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {state.questionIdeasStorage?.[ind]?.length > 0 && (
                          <div
                            className="box_header_wrapper NOT_STARTED_box"
                            style={{ display: 'flex', marginLeft: '0' }}
                          >
                            <span class="title_count">
                              {state.questionIdeasStorage?.[ind]?.length}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {collapseStates?.[ind] !== undefined && !collapseStates?.[ind] && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    marginLeft: '1rem',
                  }}
                >
                  <ul>
                    {state.questionIdeasStorage?.[ind]
                      ?.filter((i) => i !== undefined)
                      ?.map((idea, ideaIndex) => {
                        return (
                          <li key={ideaIndex}>
                            <span>{idea}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              )}
            </div>
          )
        })}
    </>
  )
}

export default SubQuestions
