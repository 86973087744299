import React from 'react'
import Modal from '../Modal'
import InPageLoader from '../../phoenix/components/Loader'
// import InPageLoader from '../InPageLoader'

const LoadingModal = ({ message, cleanupMsg }) => {
  return (
    <Modal
      includeCloseIcon={false}
      title={message}
      width={'30rem'}
      height={'20rem'}
      modalContainerStyle={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>{cleanupMsg}</div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <InPageLoader inComponent={true} inModal={true} />
      </div>
    </Modal>
  )
}

export default LoadingModal
