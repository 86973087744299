import { togglePauseRecording } from '../../../api/services/brainstorm.services'
import { useBrainstorm } from '../Recorder/BrainstormContext'

const PauseButton = (source) => {
  const { state, dispatch } = useBrainstorm()
  console.log('source: ', source)

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          className={`flex flex-row justify-center items-center ${
            state.isPaused
              ? 'bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700'
              : 'bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600'
          } text-white px-4 py-2 rounded-lg transform transition-transform duration-200 h-9 w-16 cursor-pointer tooltip`}
          onClick={() => {
            let newPauseState = !state.isPaused
            dispatch({
              type: 'SET_IS_PAUSED',
              isPaused: newPauseState,
            })
            togglePauseRecording(newPauseState, state.meetingInstanceId)
            console.log('newPauseState: ', newPauseState)
            const iframe = document.createElement('iframe')
            iframe.style.display = 'none'
            iframe.src = `${process.env.REACT_APP_ZISI_APP_NAME || 'zisiapp'}://togglePauseRecording?state=${newPauseState}`
            document.body.appendChild(iframe)
            setTimeout(() => document.body.removeChild(iframe), 500)
          }}
        >
          <span className="text-base font-medium"></span>
          <span className="material-symbols-outlined">
            {state.isPaused ? 'play_circle' : 'pause_circle'}
          </span>

          <span
            className="tooltiptext"
            style={{
              fontSize: '16px',
              width: '8rem',
              alignSelf: 'center',
              textAlign: 'center',
              left: '50%',
              marginLeft: '-4rem', // Half of width to center
              position: 'absolute',
              bottom: '125%',
              // Add peak centering styles
              '::after': {
                content: '""',
                position: 'absolute',
                top: '100%',
                left: '50%',
                marginLeft: '-5px',
                borderWidth: '5px',
                borderStyle: 'solid',
                borderColor: '#555 transparent transparent transparent',
              },
            }}
          >
            {state.isPaused
              ? state.language === 'en'
                ? 'Continue'
                : 'Continuar'
              : state.language === 'en'
                ? 'Pause'
                : 'Pausar'}
          </span>
        </div>
      </div>
    </>
  )
}

export default PauseButton
