import React, { useState } from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import { BrainstormProvider } from './Recorder/BrainstormContext'
import BrainstormComponent from './Component/BrainstormComponent'
import BrainstormSessionSetupWizard from './Component/BrainstormSessionSetupWizard'
import { ReactFlowProvider } from '@xyflow/react'
import '../../tailwind.css'

const Brainstorm = () => {
  const [setupComplete, setSetupComplete] = useState(false)
  const [setupData, setSetupData] = useState(null)
  const hideNavigation = localStorage.getItem('tfex') === 'true'
  const [isFullVersion, setIsFullVersion] = useState(true)

  const handleSetupComplete = (formData) => {
    setSetupData(formData)
    setSetupComplete(true)
  }

  return (
    <ApplicationLayout fromSetUpAgent={hideNavigation}>
      <ReactFlowProvider>
        <BrainstormProvider source={'dplus'}>
          <div className="tailwind-scoped h-full">
            {!setupComplete ? (
              <BrainstormSessionSetupWizard
                onComplete={handleSetupComplete}
                isFullVersion={isFullVersion}
                source={'dplus'}
              />
            ) : (
              <BrainstormComponent initialSetup={setupData} isFullVersion={isFullVersion} />
            )}
          </div>
        </BrainstormProvider>
      </ReactFlowProvider>
    </ApplicationLayout>
  )
}

export default Brainstorm
