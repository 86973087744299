import React, { useCallback, useEffect } from 'react'
import {
  ReactFlow,
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  MiniMap,
  Controls,
  ControlButton,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'

import FloatingEdge from './FloatingEdge'
import FloatingConnectionLine from './FloatingConnectionLine'
import { createNodesAndEdges } from './utils'
import './style.scss'
import { useBrainstorm } from '../../pages/Brainstorm/Recorder/BrainstormContext'
import MindMapItemNode from './MindMapItemNode'
import MindMapTopicNode from './MindMapTopicNode'
import MindMapProblemNode from './MindMapProblemNode'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import { similarity } from '../../utils/general'
import SelectionModal from './Component/SelectionModal'
import AddEditMeetingTopicModal from '../AddEditMeetingTopicModal'
import { getCompanyConfigSettings } from '../../utils/auth'
import BrainstormAddActionDecisionModal from './Component/BrainstormAddActionDecisionModal'
import AddEditActivityModal from '../../pages/MyNotebook/AddEditActivityModal'
import AddEditObjectiveModal from '../AddEditObjectiveModal'
import { v4 as uuidv4 } from 'uuid'
import MindMapLoader from '../../pages/Brainstorm/Component/MindMapLoader'
import { useTranslation } from 'react-i18next'

const sortUserChanges = (a, b) => {
  // topic comes first
  if (a.id === undefined && b.id !== undefined) {
    // a comes first
    return -1
  }
  if (b.id === undefined && a.id !== undefined) {
    // b comes first
    return 1
  }

  return 0
}

const edgeTypes = {
  // floating: FloatingEdge,
}
const nodeTypes = {
  mindmapItemNode: MindMapItemNode,
  mindmapTopicNode: MindMapTopicNode,
  mindmapProblemNode: MindMapProblemNode,
}

const ENUMSELECTEDTYPE = Object.freeze({
  OBJECTIVE: 0,
  TOPIC: 1,
  ACTION: 2,
  DECISION: 3,
})

const MindMap = ({
  jsonData,
  readOnly,
  brainstormId = null,
  allowBulkConvertToMH = false,
  fromZisiReadOnlyExternalLink = false,
  source = null,
}) => {
  const [itemIndices, setItemIndex] = React.useState(0)
  const [initialNodes, setInitialNodes] = React.useState([])
  const [initialEdges, setInitialEdges] = React.useState([])
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const { state, dispatch } = useBrainstorm()
  const { t } = useTranslation(['Phoenix'])

  const [mindmap, setMindmap] = React.useState(jsonData)
  const [triggerApiCall, setTriggerApiCall] = React.useState(false)
  const newTopicTemplate = {
    topic: 'New: click to edit',
    user_added: true,
    decisions: [],
    comments: [],
    actions: [],
    newTopic: true,
    type: 'Guidance',
    what_it_is_about: '',
  }

  // const [userJustAddedNewTopic, setUserJustAddedNewTopic] = React.useState(false)
  const [userChanges, setUserChanges] = React.useState([])
  const [isSelectionModalOpen, setIsSelectionModalOpen] = React.useState(false)
  const [selectedNode, setSelectedNode] = React.useState({})
  const [selectedType, setSelectedType] = React.useState(null)
  const [isAddMeetingTopicOpen, setIsAddMeetingTopicOpen] = React.useState(false)
  const [isAddCommentModalOpen, setIsAddCommentModalOpen] = React.useState(false)
  const [isAddActionModalOpen, setIsAddActionModalOpen] = React.useState(false)

  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')
  const [isAddEditObjModalOpen, setIsAddEditObjModalOpen] = React.useState(false)

  const userId = localStorage.getItem('tfei')

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            // type: 'floating',
            markerEnd: { type: MarkerType.Arrow },
          },
          eds,
        ),
      ),
    [setEdges, itemIndices],
  )

  const onRemoveItem = (description, topicIndex, itemIndex, itemId) => {
    dispatch({ type: 'ADD_TO_DISCARDED_LIST', description: description })
    // add to discarded list
    // and remove corresponding item from userChanges
    if (!readOnly) {
      if (itemIndex === undefined) {
        // if we are removing a topic, we need to remove all actions and decisions from user changes too
      } else {
        // otherwise, we should remove the specific action or decision whose topicIndex and id matches
      }
    }
  }

  React.useEffect(() => {
    setMindmap(jsonData)
  }, [jsonData])

  const updateMindMapText = async (topicText, previousText, text, actionDecisionType) => {
    if (text.trim() === '') {
      return
    }
    // NEW CHANGES FROM THIS POINT ONWARDS
    // {
    //   "decisions": [],
    //   "comments": [
    //     {
    //       "id": "a31a4061-d819-489f-b4a1-0a88a9600e5c",
    //       "is_converted": false,
    //       "text": "The current messaging and positioning needs to be rethought as part of the golden pitch certification."
    //     }
    //   ]
    // }
    console.log('mindmap ooo', mindmap)

    let foundTopic = null

    setMindmap((prevMindmap) => {
      console.log('prevMindmap', prevMindmap)
      console.log('updateText, index', text, actionDecisionType)
      const data = structuredClone(prevMindmap)
      console.log('old mm:', JSON.parse(JSON.stringify(data)))

      if (actionDecisionType === undefined) {
        // We're updating a topic
        const topicIndex = data.action_plans.findIndex((topic) => topic.topic === previousText)
        if (topicIndex !== -1) {
          data.action_plans[topicIndex].topic = text
          foundTopic = data.action_plans[topicIndex]
        }
      } else {
        // We're updating an action/decision/comment
        foundTopic = data.action_plans.find((topic) => topic.topic === topicText)

        if (foundTopic) {
          // Determine which array to search based on type
          const itemArrays = {
            action: 'actions',
            decision: 'decisions',
            comment: 'comments',
          }

          const itemType = itemArrays[actionDecisionType]
          if (itemType) {
            const itemIndex = foundTopic[itemType].findIndex((item) => item.text === previousText)
            if (itemIndex !== -1) {
              foundTopic[itemType][itemIndex].text = text
            }
          }
        }
      }

      return data
    })
    setTriggerApiCall(true)
  }

  React.useEffect(() => {
    if (triggerApiCall && mindmap) {
      ;(async () => {
        // update brainstorm map in context with latest mindmap
        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: {
            // 'problem' is probably not needed here because mindmap is already with problem
            problem: state.primaryProblem,
            action_plans: mindmap.action_plans,
          },
        })
        // temporary
        // await postHttpRequest(`/brainstorm/update_brainstorm_instance/${state.meetingInstanceId}`, {
        //   brainstormMap: {
        //     action_plans: mindmap.action_plans.filter(
        //       (topic) =>
        //         topic.topic && topic.topic !== '' && !state.discardedList.includes(topic.topic),
        //     ),
        //   },
        // })
        // if (readOnly && !fromZisiReadOnlyExternalLink) {
        //   try {
        //     await postHttpRequest(`/brainstorm/save_brainstorm_session`, {
        //       meetingInstanceId: state.meetingInstanceId,
        //       discardedList: state.discardedList,
        //       highlightedSubProblems: state.highlightedQuestions,
        //     })
        //   } catch (error) {
        //     console.log('error saving brainstorm session', error)
        //   }
        // }

        releaseMindMap()
      })()
    }
    setTriggerApiCall(false)
  }, [mindmap, triggerApiCall])

  const lockMindMap = () => {
    dispatch({ type: 'SET_MINDMAP_LOCKED', isMindMapLocked: true })
  }

  const releaseMindMap = () => {
    dispatch({ type: 'SET_MINDMAP_LOCKED', isMindMapLocked: false })
  }

  const addNewItem = (topic) => {
    setMindmap((prevMindmap) => {
      const data = structuredClone(prevMindmap)
      const topicNode = data.action_plans.find((t) => t.topic === topic.label)
      console.log('topicNode', topicNode)
      if (!topicNode) {
        console.log('Topic not found')
        return data
      }
      topicNode.actions.push({
        text: 'New Action: click to edit',
        is_converted: false,
        // id: uuidv4(),
        context: '',
      })

      return data
    })
  }

  const addNewTopic = () => {
    setMindmap((prevMindmap) => {
      const data = structuredClone(prevMindmap)
      data.action_plans.push(newTopicTemplate)
      return data
    })
  }

  const switchActionDecision = (topicText, dataType, text) => {
    // be careful with null value from item[0], especially when the item is recommended.,,
    // action -> decision -> comment -> action
    let nextValue
    if (dataType === 'action') {
      nextValue = 'decisions'
    } else if (dataType === 'decision') {
      nextValue = 'comments'
    } else if (dataType === 'comment') {
      nextValue = 'actions'
    }

    setMindmap((prevMindmap) => {
      const data = structuredClone(prevMindmap)
      // Find the topic by text
      const topicNode = data.action_plans.find((topic) => topic.topic === topicText)
      if (!topicNode) {
        console.log('topic not found')
        return data
      }
      console.log('topicNode switchActionDecision', topicNode)
      // let correctIndexInActionsOrDecisions = calculateIndexInDecisionsOrActionsFromOverallIndex(topicNode, actionDecisionIndex)

      const itemArrays = {
        action: 'actions',
        decision: 'decisions',
        comment: 'comments',
      }

      const sourceArray = itemArrays[dataType]
      if (!sourceArray) {
        return data
      }

      let itemIndex = topicNode[sourceArray].findIndex((item) => item.text === text)
      console.log('itemIndex', itemIndex)
      if (itemIndex === -1) {
        return data
      }

      console.log('itemIndex !== -1')
      let item = topicNode[sourceArray].splice(itemIndex, 1)
      console.log('item switchActionDecision', item)
      topicNode[nextValue].push(item[0])

      return data
    })
    setTriggerApiCall(true)
  }

  const removeDiscardedItems = React.useCallback(
    (topic, index) => {
      // Filter decisions
      topic.decisions =
        topic?.decisions?.filter((decision) => !state.discardedList.includes(decision.text)) ?? []

      // Filter actions
      topic.actions =
        topic?.actions?.filter((action) => !state.discardedList.includes(action.text)) ?? []
      // Filter comments
      topic.comments =
        topic?.comments?.filter((comment) => !state.discardedList.includes(comment.text)) ?? []

      // Filter recommended actions
      if (topic.recommended_actions) {
        topic.recommended_actions = topic.recommended_actions.filter(
          (recommendedAction) => !state.discardedList.includes(recommendedAction.text),
        )
      }
    },
    [state.discardedList],
  )

  const forwardOpenModal = (topicText, actionDecisionText, dataType, isRecommended) => {
    setSelectedNode({ topicText, actionDecisionText, dataType, isRecommended })
    setIsSelectionModalOpen(true)
  }

  React.useEffect(() => {
    if (mindmap) {
      let data = structuredClone(mindmap)
      if (data?.action_plans) {
        data.action_plans = data.action_plans.filter(
          (topic) =>
            topic.topic && topic.topic !== '' && !state.discardedList.includes(topic.topic),
        )

        data.action_plans.forEach(removeDiscardedItems)
        const { nodes: newNodes, edges: newEdges } = createNodesAndEdges(
          data,
          onRemoveItem,
          updateMindMapText,
          lockMindMap,
          state.readOnly,
          addNewItem,
          switchActionDecision,
          forwardOpenModal,
          addNewTopic,
          allowBulkConvertToMH,
          state.isUpdatingMM,
          fromZisiReadOnlyExternalLink,
        )

        // Adjust y positions of nodes to start higher up
        // newNodes.forEach((node) => {
        //   if (node.position) {
        //     node.position.y -= 50 // Adjust this value as needed
        //   }
        // })
        console.log('newNodes', newNodes)

        setNodes(newNodes)
        setEdges(newEdges)
      }
    }
  }, [mindmap, state.isUpdatingMM])

  const updateDiscardedList = async () => {
    // try {
    //   await postHttpRequest(`/brainstorm/update_discarded_list/${state.meetingInstanceId}`, {
    //     discardedList: state.discardedList,
    //   })
    // } catch (error) {
    //   console.log('error update_discarded_list: ', error)
    // }
  }

  React.useEffect(() => {
    if (mindmap) {
      setMindmap((prevMindmap) => {
        let data = structuredClone(prevMindmap)

        data.action_plans = data.action_plans.filter(
          (topic) =>
            topic.topic && topic.topic !== '' && !state.discardedList.includes(topic.topic),
        )

        data.action_plans.forEach(removeDiscardedItems)

        return data
      })
    }
    if (readOnly && !fromZisiReadOnlyExternalLink) {
      updateDiscardedList()
    }
  }, [state.discardedList])

  React.useEffect(() => {
    let data = structuredClone(jsonData)
    setMindmap((prevMindmap) => {
      let updatedMindmap = structuredClone(data)
      updatedMindmap.action_plans.forEach((topic) => {
        if (topic.newTopic) {
          topic.newTopic = false
        }
      })
      return updatedMindmap
    })
    setTriggerApiCall(true)
  }, [state.isUpdatingMMCount])

  // React.useEffect(() => {
  //   if (state.isUpdatingMM) {
  //     console.log('reset all new topic to false')
  //     setMindmap((prevMindmap) => {
  //       let updatedMindmap = structuredClone(prevMindmap)
  //       updatedMindmap.action_plans.forEach((topic) => {
  //         if (topic.newTopic) {
  //           topic.newTopic = false
  //         }
  //       })
  //       return updatedMindmap
  //     })
  //   }
  // }, [state.isUpdatingMM])

  const setTypeSelection = (selection) => {
    setSelectedType(selection)
  }

  const retrieveItem = (topicNode) => {
    let itemType =
      selectedNode.dataType === 'action'
        ? 'actions'
        : selectedNode.dataType === 'decision'
          ? 'decisions'
          : 'comments'
    let itemIndex = topicNode[itemType].findIndex(
      (item) => item.text == selectedNode.actionDecisionText,
    )

    if (itemIndex === -1) {
      return null
    }

    let item
    if (selectedNode.isRecommended) {
      item = topicNode['recommended_actions'][itemIndex]
    } else {
      let value
      if (selectedNode.dataType === 'action') {
        value = 'actions'
      } else if (selectedNode.dataType === 'decision') {
        value = 'decisions'
      } else if (selectedNode.dataType === 'comment') {
        value = 'comments'
      }

      item = topicNode[value][itemIndex]
    }

    return item
  }

  React.useEffect(() => {
    if (selectedType !== null) {
      switch (selectedType) {
        case ENUMSELECTEDTYPE.OBJECTIVE:
          setIsAddEditObjModalOpen(true)
          break
        case ENUMSELECTEDTYPE.TOPIC:
          setIsAddMeetingTopicOpen(true)
          break
        case ENUMSELECTEDTYPE.ACTION:
          setIsAddActionModalOpen(true)
          break
        case ENUMSELECTEDTYPE.DECISION:
          setIsAddCommentModalOpen(true)
          break
        default:
          break
      }
    }
  }, [selectedType])

  const loadAgain = () => {
    // add a new field to the item
    setMindmap((prevMindmap) => {
      const data = structuredClone(prevMindmap)
      let topicNode = data.action_plans.find((plan) => plan.topic === selectedNode.topicText)
      console.log('eeee topicNode loadAgain', topicNode)
      if (!topicNode) {
        return data
      }

      let item = retrieveItem(topicNode)
      if (!item) {
        return data
      }

      item.is_converted = true

      return data
    })
    setSelectedNode({})
    setTriggerApiCall(true)
  }

  const calculateTextInputIn = React.useMemo(() => {
    if (!(Object.keys(selectedNode).length > 0 && mindmap)) return ''

    let topicNode = mindmap.action_plans.find((plan) => plan.topic === selectedNode.topicText)

    if (!topicNode) {
      return ''
    }

    let item = retrieveItem(topicNode)
    if (!item) {
      return ''
    }

    return item.text
  }, [selectedNode, mindmap])

  const shouldShowLoader = !mindmap || !mindmap.action_plans || mindmap.action_plans.length === 0

  return (
    <>
      {isSelectionModalOpen && Object.keys(selectedNode).length > 0 && (
        <SelectionModal
          closeModal={() => {
            // setSelectedNode({})
            setIsSelectionModalOpen(false)
          }}
          setTypeSelection={setTypeSelection}
        />
      )}
      {isAddMeetingTopicOpen && (
        <AddEditMeetingTopicModal
          isModalOpen={isAddMeetingTopicOpen}
          closeModal={() => {
            setIsAddMeetingTopicOpen(false)
            setSelectedType(null)
          }}
          loadAgain={loadAgain}
          isProductSettingsEnabled={isProductSettingsEnabled}
          refreshBGUScorecardFn={() => {}}
          showMineButton={false}
          textInputIn={calculateTextInputIn}
          brainstormSessionId={brainstormId}
        />
      )}
      {isAddActionModalOpen && (
        <AddEditActivityModal
          isModalOpen={isAddActionModalOpen}
          closeModal={() => {
            setIsAddActionModalOpen(false)
            setSelectedType(null)
            // loadAgain()
          }}
          actionText={calculateTextInputIn}
          loadAgain={loadAgain}
          brainstormSessionId={brainstormId}
        />
      )}
      {isAddCommentModalOpen && (
        <BrainstormAddActionDecisionModal
          closeModal={() => {
            setIsAddCommentModalOpen(false)
            setSelectedType(null)
          }}
          text={calculateTextInputIn}
          loadAgain={loadAgain}
        />
      )}
      {isAddEditObjModalOpen && (
        <AddEditObjectiveModal
          closeModal={() => {
            setIsAddEditObjModalOpen(false)
            setSelectedType(null)
          }}
          isAddEditObjectiveModalOpen={isAddEditObjModalOpen}
          mode={'add'}
          setEditedObj={() => {}}
          objData={{
            statement: calculateTextInputIn,
            ownerId: userId,
          }}
          setObjData={() => {}}
          loadAgain={loadAgain}
          isFromObjectiveMap={true}
        />
      )}

      <div
        className={`${fromZisiReadOnlyExternalLink ? 'floatingedgesReadOnly' : 'floatingedges'}`}
        style={{
          border: '0px solid #000000',
          borderRadius: '0.75rem',
          position: 'relative',
        }}
      >
        <ReactFlow
          nodes={nodes.map((node) => ({
            ...node,
            data: {
              ...node.data,
              source,
            },
          }))}
          edges={[]} //instead of edges, we add empty array because we don't want to show the edges coming from the problem statement node
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
        >
          {shouldShowLoader && (
            <>
              {state.primaryProblem !== '' ? (
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    marginTop: '4rem',
                    textAlign: 'center',
                  }}
                >
                  {t('loaderStatement.statementWithPrimaryProblem')}
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    marginTop: '4rem',
                    textAlign: 'center',
                  }}
                >
                  {t('loaderStatement.statementWithoutProblem')}
                </div>
              )}
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 10,
                }}
              >
                <MindMapLoader />
              </div>
            </>
          )}
          <Background
            variant="dots"
            gap={20}
            size={4}
            color="#ffffff"
            style={{ backgroundColor: '#f5f5f5' }}
          />
          <Controls orientation={'horizontal'} showInteractive={false}></Controls>
        </ReactFlow>
      </div>
    </>
  )
}

export default MindMap
