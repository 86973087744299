import React from 'react'
import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import chromeShareScreenGif from '../../assets/chrome_share_screen2.gif'
import chromeShareMicGif from '../../assets/chrome_share_mic2.gif'
import edgeShareScreenGif from '../../assets/edge_share_screen.gif'
import edgeShareMicGif from '../../assets/edge_share_microphone.gif'
import chromeMac from '../../assets/chrome_mac2.gif'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import './style.css'
import { detectBrowser, detectOS } from '../../utils/general'

const ActivateSettingsTutorialModal = ({
  isModalOpen,
  closeModal,
  startRecording,
  setMeetings,
  setMeetingInstanceId,
  setIsSelectMeetingTagModalOpen,
  fromAiMeeting = false,
}) => {
  const { t } = useTranslation(['Common'])
  const [dontShowAgain, setDontShowAgain] = React.useState(false)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const slides = [
    {
      title: 'modalMessages.teamsMeetingInTab',
      image: chromeMac,
      step: 1,
      maxWidth: '450px',
    },
    {
      title: 'modalMessages.shareScreenAndAudio',
      image: chromeShareScreenGif,
      step: 2,
    },
    {
      title: 'modalMessages.allowMicrophoneUse',
      image: chromeShareMicGif,
      step: 3,
    },
  ]

  const updateUserConfig = async () => {
    try {
      await postHttpRequest('/edit_employees_user_preferences', {
        brainstormingTutorialOff: dontShowAgain,
      })
      return true
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
      return false
    }
  }

  const updateLocalStorage = () => {
    let cv = localStorage.getItem('cfg')
    let config = JSON.parse(cv)
    config.brainstorming_tutorial_off = true
    localStorage.setItem('cfg', JSON.stringify(config))
  }

  return (
    <Modal isModalOpen={isModalOpen} includeCloseIcon={false} width={'65%'} height="36rem">
      {detectOS() !== 'MacOS' && ( //Windows version the default
        <div className="gif-container">
          <div className="gif-column">
            <div className="gif-title">
              <div className="number-badge">1</div>

              <h3>{t('modalMessages.shareScreenAndAudio')}</h3>
            </div>
            <div className="gif-row">
              <img src={chromeShareScreenGif} alt="step 1: share screen" className="gif-image" />
            </div>
          </div>
          <div className="gif-column">
            <div className="gif-title">
              <div className="number-badge">2</div>

              <h3>{t('modalMessages.allowMicrophoneUse')}</h3>
            </div>
            <div className="gif-row">
              <img src={chromeShareMicGif} alt="step 2: share microphone" className="gif-image" />
            </div>
          </div>
        </div>
      )}

      {detectOS() === 'MacOS' && (
        <>
          <div className="gif-container" style={{ position: 'relative' }}>
            <div className="gif-column">
              <h3 style={{ fontSize: '1.125rem', fontWeight: 600, marginBottom: '0.5rem' }}>
                <div className="gif-title">
                  <div className="number-badge">{slides[currentSlide].step}</div>
                  {t(slides[currentSlide].title)}
                </div>
              </h3>
              <div className="gif-row">
                <div className="number-badge-container"></div>
                <img
                  src={slides[currentSlide].image}
                  alt={`step ${slides[currentSlide].step}`}
                  className="gif-image"
                  style={
                    slides[currentSlide].maxWidth ? { maxWidth: slides[currentSlide].maxWidth } : {}
                  }
                />
              </div>
            </div>

            <button
              onClick={() => setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1))}
              style={{
                position: 'absolute',
                left: '-5rem',
                top: '50%',
                transform: 'translateY(-50%)',
                background: '#f3f4f6',
                borderRadius: '50%',
                padding: '0.5rem',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <span className="material-symbols-outlined">chevron_left</span>
            </button>

            <button
              onClick={() => setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1))}
              style={{
                position: 'absolute',
                right: '-5rem',
                top: '50%',
                transform: 'translateY(-50%)',
                background: '#f3f4f6',
                borderRadius: '50%',
                padding: '0.5rem',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <span className="material-symbols-outlined">chevron_right</span>
            </button>
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '0.5rem', marginTop: '1rem' }}
          >
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  background: currentSlide === index ? '#3b82f6' : '#d1d5db',
                  border: 'none',
                  padding: 0,
                  cursor: 'pointer',
                }}
              />
            ))}
          </div>
        </>
      )}

      <div className="modal-footer">
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="dontShowAgain"
            name="dontShowAgain"
            value={dontShowAgain}
            onChange={() => setDontShowAgain(!dontShowAgain)}
          />
          <label htmlFor="dontShowAgain">{t('dontShowAgain')}</label>
        </div>

        {(currentSlide === slides.length - 1 || detectOS() !== 'MacOS') && (
          <div
            className="sleek-button sleek-full-blue"
            style={{ width: '3rem' }}
            onClick={async () => {
              if (dontShowAgain) {
                updateUserConfig()
                updateLocalStorage()
              }
              closeModal()
              // if (fromAiMeeting) {
              //   let { response, previousMeetings } = await startRecording()
              //   setMeetings(previousMeetings?.meetings)
              //   setMeetingInstanceId(response?.meetingInstanceId)
              //   if (previousMeetings?.meetings?.length > 0) {
              //     setIsSelectMeetingTagModalOpen(true)
              //   }
              // } else {
              //   startRecording()
              // }
            }}
          >
            <span>OK</span>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ActivateSettingsTutorialModal
