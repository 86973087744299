import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import {
  GET_SUMMARIZED_ACTION_PLANS,
  API_ERROR_MESSAGE,
  TOGGLE_PAUSE_RECORDING,
} from '../../api/constant/route'
import Toast from '../../common/toast'

export async function getSummarizedActionPlans(postData) {
  try {
    const data = await postHttpRequest(`/brainstorm${GET_SUMMARIZED_ACTION_PLANS}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function togglePauseRecording(pauseState, meetingInstanceId) {
  try {
    const postData = {
      pause_state: pauseState,
      meetingInstanceId: meetingInstanceId,
    }
    const data = await postHttpRequest(`/brainstorm${TOGGLE_PAUSE_RECORDING}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function setSkipTutorialFlag(skipTutorial) {
  try {
    const data = await postHttpRequest(`/brainstorm/set_skip_tutorial`, { skipTutorial })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getCurrentUserEmail() {
  try {
    const data = await getHttpRequest('/brainstorm/get_current_user_email')
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getRemainingTimeAndSubscriptionStatus() {
  try {
    const data = await getHttpRequest('/brainstorm/get_remaining_time_and_subscription_status')
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function generateOverallSummary(postData) {
  try {
    const data = await postHttpRequest('/brainstorm/generate_overall_summary', postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function deleteAccount() {
  try {
    const data = await postHttpRequest('/brainstorm/delete_account')
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getOwnerOfMeeting(meetingInstanceId) {
  try {
    const data = await getHttpRequest(
      `/brainstorm/get_owner_of_meeting?meetingInstanceId=${meetingInstanceId}`,
    )
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getEmailSentRecord() {
  try {
    const data = await getHttpRequest('/brainstorm/get_email_sent_record')
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function updateDesktopAppDownloadedFlag(postData) {
  try {
    const data = await postHttpRequest('/user/update_desktop_app_downloaded_flag', postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getDesktopAppDownloadedFlag() {
  try {
    const data = await getHttpRequest('/user/get_desktop_app_downloaded_flag')
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}
