import React from 'react'
import AddEditForm from './addEditForm'
import useEditHigherObjective from '../../api/mutation/editHigherObjective'
import ErrorModal from '../ErrorModal'
import Modal from '../Modal'
import useGetObjectiveById from '../../api/query/getObjectiveById'
import useHigherObjectives from '../../api/query/useHigherObjectives'
import { useTranslation } from 'react-i18next'
import useAddEditHigherObjective from '../../api/mutation/addEditHigherObjective'
import { getHttpRequest } from '../../api/query/dynamicAPI'
const L1L2ObjectiveModal = ({
  isOpen,
  mode,
  handleClose,
  objId,
  objectiveType,
  leaders,
  isAreaDashboard = false,
  isCeo = false,
  setEditedObj,
  loadAgain,
  objData,
  setObjData,
  isFromObjectiveMap = false, //This prop helps with specific behavior ad in edit mode, if available we use the objData ownerId(Set with the objMap dropdowns) to set the leader
}) => {
  const mutationAddObjective = useAddEditHigherObjective()
  const mutationEditObjective = useEditHigherObjective()
  const [objectiveWidgetErrorMessage, setObjectiveWidgetErrorMessage] = React.useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)
  const { t } = useTranslation(['Common'])
  const [leaderData, setLeaderData] = React.useState([])

  const {
    data: objectiveData,
    isLoading: isObjLoading,
    error: objError,
  } = useGetObjectiveById(objId)

  React.useEffect(() => {
    if (objectiveData && Object.keys(objectiveData).length > 0 && mode === 'edit') {
      setObjData({ ...objectiveData })
    }
  }, [objectiveData])

  const {
    data: higherObjData,
    isLoading: isHigherObjLoading,
    error: higherObjError,
  } = useHigherObjectives()

  React.useEffect(() => {
    if (objectiveType == 'cxo' || objectiveType == 'enterprise') {
      getHttpRequest('/all_csuite_leaders').then((response) => {
        setLeaderData(response)
      })
    } else {
      getHttpRequest('/all_area_leaders').then((response) => {
        setLeaderData(response)
      })
    }
  }, [])

  const handleSubmit = async (values, resetform) => {
    if (mode === 'edit') {
      let obj = {
        statement: `${values.statement}`,
        ownerId: values.leader,
        objectiveType: objectiveType,
        objId: parseInt(objId),
        changedColor: values.changedColor,
        color: values.color,
        greyOut: values.greyOut,
        detailedDescription: values.detailedDescription,
        companyFields: values.companyFields,
      }
      obj = {
        ...obj,
        parentId: values.parentId !== '' ? values.parentId : null,
        rights: [...values.rights],
        tags: [...values.tags],
      }

      if (isAreaDashboard) {
        obj = {
          ...obj,
          isAreaDashboard: true,
        }
      }

      await mutationEditObjective.mutateAsync(obj, {
        onSuccess: (response) => {
          if (!response.ok) {
            setObjectiveWidgetErrorMessage('There was an error, please try again.')
            openErrorModal()

            handleClose()
            resetform()
          } else {
            setEditedObj({
              objId: parseInt(objId),
              statement: `${values.statement}`,
            })
            handleClose()
            resetform()
          }
        },
        onError: () => {
          openErrorModal()
          handleClose()
          resetform()
        },
      })
      loadAgain()
    } else if (mode === 'add') {
      let obj = {
        isAreaDashboard: false,
        objId: null,
        objectiveType: objectiveType,
        ownerId: values.leader,
        parentId: values.parentId,
        rights: values.rights,
        statement: values.statement,
        tags: [...values.tags],
        color: values.color,
        greyOut: values.greyOut,
        detailedDescription: values.detailedDescription,
        companyFields: values.companyFields,
      }

      await mutationAddObjective.mutateAsync(obj, {
        onSuccess: (response) => {
          if (!response.ok) {
            setObjectiveWidgetErrorMessage('There was an error, please try again.')
            openErrorModal()

            handleClose()
            resetform()
          } else {
            loadAgain()
            handleClose()
            resetform()
          }
        },
        onError: () => {
          openErrorModal()
          handleClose()
          resetform()
        },
      })
    }
  }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setObjectiveWidgetErrorMessage(null)
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={objectiveWidgetErrorMessage}
      />
      {isOpen === true && (
        <Modal
          title={mode === 'edit' ? t('areaObjective.editObj') : t('areaObjective.newObj')}
          closeModal={() => {
            handleClose()
            //resetForm()
          }}
          titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
          width={'40rem'}
          style={{ overflowX: 'hidden' }}
          modalContainerStyle={{ width: '80%' }}
        >
          <AddEditForm
            handleSubmitForm={handleSubmit}
            objective={objData}
            handleClose={handleClose}
            leaders={leaderData}
            objectiveType={objectiveType}
            enterpriseObjectives={higherObjData?.enterpriseObjectives}
            isEdit={mode === 'edit' ? true : false}
            isLoading={mutationEditObjective.isLoading || mutationEditObjective.isFetching}
            isAreaDashboard={isAreaDashboard}
            isCeo={isCeo}
            loadAgain={loadAgain}
            isFromObjectiveMap={isFromObjectiveMap}
            objId={objId}
          ></AddEditForm>
        </Modal>
      )}
    </>
  )
}

export default L1L2ObjectiveModal
