import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import { getConsolidatedConfigSettings } from '../../../utils/auth'

function KpiDropdownMenu(props) {
  const { t } = useTranslation(['Common'])
  const restrictKpiAccess = getConsolidatedConfigSettings('restrict_kpi_access')
  const showGovernanceMgmntFeatures = getConsolidatedConfigSettings('show_governance_mgmt_features')

  return (
    <div style={{ textAlign: 'left' }}>
      <Menu
        menuClassName="kpi-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: props.backgroundColor ?? 'white',
              border: 'none',
              padding: '0',
            }}
          >
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
              more_vert
            </span>
          </MenuButton>
        }
      >
        {!props.fromKpiReport && (
          <>
            {!props.isCompleted && (!restrictKpiAccess || showGovernanceMgmntFeatures) && (
              <MenuItem
                onClick={() => {
                  props.onClickEdit()
                }}
              >
                {t('kpi.editKpiDetails')}
              </MenuItem>
            )}
            {props.canDelete && (
              <MenuItem
                onClick={() => {
                  props.onClickDelete()
                }}
              >
                {t('kpi.deleteKpi')}
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                props.onClickUpdateComplete()
              }}
            >
              {props.isCompleted ? t('kpi.openKpi') : t('kpi.completeKpi')}
            </MenuItem>
          </>
        )}
        {props.fromKpiReport && (
          <>
            {!props.isCompleted && (!restrictKpiAccess || showGovernanceMgmntFeatures) && (
              <MenuItem
                onClick={() => {
                  props.onClickEdit()
                }}
              >
                {t('kpi.editKpiDetails')}
              </MenuItem>
            )}
            {!props.isCompleted &&
              (!props.kpiDashBoardSelected ||
                props.isOwner ||
                showGovernanceMgmntFeatures ||
                !restrictKpiAccess) && (
                <MenuItem
                  onClick={() => {
                    props.onClickAddUpdates()
                  }}
                >
                  {t('kpi.addUpdate')}
                </MenuItem>
              )}
            {(!props.kpiDashBoardSelected ||
              props.isOwner ||
              showGovernanceMgmntFeatures ||
              !restrictKpiAccess) && (
              <MenuItem
                onClick={() => {
                  props.onClickSeeUpdates()
                }}
              >
                {t('kpi.kpiUpdates')}
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </div>
  )
}

export default KpiDropdownMenu
