import React, { useState, useEffect, useRef } from 'react'
import { getTeamObjectives } from '../../../api/services/objectives.services.jsx'
import CoOwnedObjectiveAllocModal from '../../../components/CoOwnedObjectiveAllocModal'
import CommentsModal2 from '../../../components/CommentsModal2/index.jsx'
import CompletedObjectivesModal from '../../../components/CompletedObjectivesModal'
import useEditObjectiveComplete_v2 from '../../../api/mutation/editObjectiveCompleteHome_v2'
import useDeleteObjective_v2 from '../../../api/mutation/deleteObjectiveHome_v2'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/routes'
import InPageLoader from '../../../components/InPageLoader'
import { useTranslation } from 'react-i18next'
import useDeleteObjectiveCheck from '../../../api/mutation/useDeleteObjectiveCheck.js'
import { getCompanyConfigSettings, hasRole } from '../../../utils/auth.js'
import { getConsolidatedConfigSettings } from '../../../utils/auth.js'
import ObjectiveDetailsModal from '../../MeetingMultiView/components/MeetingTopics/common/component/OjbectiveDetailsModal.jsx'
import AddEditObjectiveModal from '../../../components/AddEditObjectiveModal/index.jsx'

const TeamsObjective = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['Dashboard', 'Common'])

  const mutationEditObjectiveComplete_v2 = useEditObjectiveComplete_v2()
  const deleteObjective_v2 = useDeleteObjective_v2()

  const [objective, setObjective] = React.useState('')
  const [isObjDetailsOpen, setIsObjDetailsOpen] = React.useState(false)
  const [commentsModalIsOpen, setCommentsModalIsOpen] = React.useState(false)

  const [isCoOwnedObjectiveAllocModalOpen, setIsCoOwnedObjectiveAllocModalOpen] =
    React.useState(false)

  const [teamsObjective, setTeamsObjective] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isCompletedObjectivesModalOpen, setIsCompletedObjectivesModalOpen] = React.useState(false)
  const checkForDeleteObjective = useDeleteObjectiveCheck()
  const isAreaLeader = hasRole('area') ? true : false
  const canAddEditObjs = getConsolidatedConfigSettings('can_add_edit_objs') || isAreaLeader
  const productSettings = getCompanyConfigSettings('productSettings')
  const [isAddEditObjModalOpen, setIsAddEditObjModalOpen] = React.useState(false)
  const [mode, setMode] = React.useState('add')
  const [objData, setObjData] = React.useState({})
  const [objId, setObjId] = useState(null)

  useEffect(() => {
    getObjectives()
  }, [])

  async function getObjectives() {
    setIsLoading(true)
    const result = await getTeamObjectives()
    setIsLoading(false)
    if (result && result.objectives.length > 0) {
      setTeamsObjective(result.objectives)
    } else {
      setTeamsObjective([])
    }
  }

  const CoOwnedObjectiveAllocModalOpen = (data) => {
    setObjective(data)
    setIsCoOwnedObjectiveAllocModalOpen(true)
  }

  const openCommentsModal = (data) => {
    setObjective(data)
    setCommentsModalIsOpen(true)
  }
  const closeCommentsModal = () => {
    setCommentsModalIsOpen(false)
  }

  const completeObjective = async (objId, originalId) => {
    setIsLoading(true)
    let postObj = { objId: objId, originalId: originalId }
    await mutationEditObjectiveComplete_v2.mutateAsync(postObj, {
      onSuccess: (response) => {
        setIsLoading(false)
        getObjectives()
      },
      onError: (response) => {},
    })
  }

  const markAsComplete = async (obj) => {
    await checkForDeleteObjective.mutateAsync(obj.id, {
      onSuccess: (response) => {
        if (response.ok) {
          if (window.confirm(t('objectivesCard.completeObjectiveCheck'))) {
            completeObjective(obj.id, obj.originalId)
          }
        } else {
          if (window.confirm(t('objectivesCard.confirmCompleteMsg'))) {
            completeObjective(obj.id, obj.originalId)
          }
        }
      },
      onError: (response) => {},
    })
  }

  const handleDeleteObj = async (obj) => {
    await checkForDeleteObjective.mutateAsync(obj.id, {
      onSuccess: (response) => {
        if (obj.isOriginal || (obj.isOriginal === false && obj.coOwners.length === 0)) {
          if (response.ok) {
            if (window.confirm(t('objectivesCard.deleteObjectiveCheck'))) {
              deleteObj(obj.id, obj.originalId)
            }
          } else {
            if (window.confirm(t('objectivesCard.confirmDeleteMsg'))) {
              deleteObj(obj.id, obj.originalId)
            }
          }
        } else if (obj.isCopy) {
          if (response.ok) {
            if (window.confirm(t('objectivesCard.deleteCopyObjectiveCheck'))) {
              let lastCopy = obj.coOwners.length === 1 ? true : false
              deleteObj(obj.id, obj.originalId, lastCopy)
            }
          } else {
            if (window.confirm(t('objectivesCard.confirmCopyDeleteMsg'))) {
              let lastCopy = obj.coOwners.length === 1 ? true : false
              deleteObj(obj.id, obj.originalId, lastCopy)
            }
          }
        }
      },
      onError: (response) => {},
    })
  }

  const deleteObj = async (objId, originalId, lastCopy = false) => {
    let postObj = { objId: objId, lastCopy: lastCopy, originalId: originalId }
    setIsLoading(true)
    await deleteObjective_v2.mutateAsync(postObj, {
      onSuccess: (response) => {
        setIsLoading(false)
        getObjectives()
      },
      onError: (response) => {},
    })
  }

  const handleEdit = (obj) => {
    // navigate(`/${routes.demandintake}`, {
    //   state: { from: 'objectives', editObj: true, objective: objective },
    // })

    setMode('edit')
    setObjData(obj)
    setObjId(obj.id)
    setIsAddEditObjModalOpen(true)
  }

  const handleCreate = () => {
    // navigate(`/${routes.demandintake}`, { state: { createNewObj: true } })
    setMode('add')
    setIsAddEditObjModalOpen(true)
  }

  const onClickHistory = (obj) => {
    setObjective(obj)
    setIsObjDetailsOpen(true)
  }

  const GeneratedObjective = ({ data, index }) => {
    return (
      <div className="card_item" key={data.id}>
        <div className="card_item_heading" style={{ display: 'flex' }}>
          {productSettings && data?.initiativeId !== 0 && !data?.isGeneralObjective && (
            <b>
              <div style={{ display: 'flex', margin: '0.3rem' }}>
                <div>{data?.initiativeId.toString()?.padStart(5, '0')}</div>
                <span style={{ paddingLeft: '0.1rem' }}> -</span>
              </div>
            </b>
          )}

          <b style={{ display: 'flex', alignItems: 'center', wordBreak: 'break-word' }}>
            <div>{data.statement}</div>
          </b>
        </div>
        <div className="card_item_body">
          <span className="owner_name">
            {' '}
            {data.is_copy && data.is_original ? `Owner : ${data.ownerName}` : ''}{' '}
          </span>
          <span className="btn_box">
            {!data.isGeneralObjective && canAddEditObjs && data.isShared === false && (
              <button
                type="button"
                className="fitted-button blue"
                onClick={(e) => {
                  handleEdit(data)
                }}
              >
                <span className="material-symbols-outlined icon-size">edit </span>
              </button>
            )}
            <button
              type="button"
              className="fitted-button blue"
              onClick={(e) => {
                CoOwnedObjectiveAllocModalOpen(data)
              }}
            >
              <span className="material-symbols-outlined icon-size">Groups </span>
            </button>

            <button
              type="button"
              className="fitted-button blue"
              onClick={() => {
                onClickHistory(data)
              }}
            >
              <span className="material-symbols-outlined icon-size">History </span>
            </button>

            {/* <button type="button" className="fitted-button blue" onClick={(e) => { openCommentsModal(data) }} ><span className="material-symbols-outlined icon-size">comment</span></button> */}
            {!data.isGeneralObjective && data.isShared === false && (
              <button
                type="button"
                className="fitted-button blue"
                onClick={(e) => {
                  markAsComplete(data)
                }}
              >
                <span className="material-symbols-outlined icon-size">Check </span>
              </button>
            )}
            {!data.isGeneralObjective && data.isShared === false && (
              <button
                type="button"
                className="fitted-button blue"
                onClick={(e) => {
                  handleDeleteObj(data)
                }}
              >
                <span className="material-symbols-outlined icon-size">Close </span>
              </button>
            )}
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      {/* {isLoading && (<InPageLoader />)} */}
      <div className="card">
        <div className="card_header">
          <h2 className="card_title">
            {isAreaLeader
              ? t('objectivesCard.initativesTitle')
              : t('objectivesCard.teamsObjectivesTitle')}
          </h2>
          {!isLoading && canAddEditObjs && (
            <button
              type="button"
              className="fitted-button blue"
              style={{ background: 'white', padding: '0.4rem', fontWeight: 'bold' }}
              onClick={() => handleCreate()}
            >
              <span className="material-symbols-outlined icon-size">
                <span class="material-symbols-outlined" style={{ fontWeight: 'bold' }}>
                  add
                </span>
              </span>
            </button>
          )}
        </div>
        {isLoading ? (
          <InPageLoader />
        ) : (
          <div className="Team_card_body">
            {teamsObjective
              .filter((obj) => obj.isShared === false)
              .map((data, index) => (
                <GeneratedObjective data={data} index={index} />
              ))}
            {teamsObjective?.filter((obj) => obj.isShared === true).length > 0 && (
              <>
                <span
                  style={{
                    margin: '0',
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: '24px',
                    display: 'flex',
                    alignSelf: 'center',
                    marginTop: '2rem',
                    color: '#9a9a9a',
                    marginBottom: '1rem',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('Common:sharedObjective.objsSharedWithMe')}
                </span>
                {teamsObjective
                  .filter((obj) => obj.isShared === true)
                  .map((data, index) => (
                    <GeneratedObjective data={data} index={index} />
                  ))}
              </>
            )}

            <span
              className="view_all"
              onClick={() => {
                setIsCompletedObjectivesModalOpen(true)
              }}
            >
              {t('Common:commonButtons.completedObjectives')}
            </span>
          </div>
        )}
      </div>

      {commentsModalIsOpen === true && objective && (
        <CommentsModal2
          objective={{ id: objective.id }}
          handleCancel={closeCommentsModal}
          displayAllComments={true}
        />
      )}

      {isCoOwnedObjectiveAllocModalOpen === true && objective && (
        <CoOwnedObjectiveAllocModal
          objective={objective}
          isModalOpen={isCoOwnedObjectiveAllocModalOpen}
          closeModal={() => {
            setIsCoOwnedObjectiveAllocModalOpen(false)
          }}
          productSettings={productSettings}
        />
      )}

      {isCompletedObjectivesModalOpen && (
        <CompletedObjectivesModal
          isModalOpen={isCompletedObjectivesModalOpen}
          closeModal={() => {
            setIsCompletedObjectivesModalOpen(false)
            getObjectives()
          }}
        />
      )}

      {isObjDetailsOpen && (
        <ObjectiveDetailsModal
          isModalOpen={isObjDetailsOpen}
          closeModal={() => {
            setIsObjDetailsOpen(false)
          }}
          objIdIn={parseInt(objective.id)}
          showInitiativesId={productSettings}
        />
      )}

      {isAddEditObjModalOpen && (
        <AddEditObjectiveModal
          objId={objId}
          closeModal={() => {
            setIsAddEditObjModalOpen(false)
          }}
          isAddEditObjectiveModalOpen={isAddEditObjModalOpen}
          mode={mode}
          objData={objData}
          setObjData={setObjData}
          loadAgain={getObjectives}
          isFromObjectiveMap={false}
        />
      )}
    </>
  )
}
export default TeamsObjective
