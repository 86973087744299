import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import { getObjectivUserList } from '../../api/services/employee.services'
import Toast from '../../common/toast'
import InPageLoader from '../InPageLoader'
import { useTranslation } from 'react-i18next'
import SelectField from '../CustomMultipleSelect/singleOption'
import { ModalSaveButtonFitted } from '../ModalSubmitButton'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import TextArea from 'rc-textarea'

const NewMeetingDetailsModal = ({
  objectiveId,
  view,
  closeModal = () => {},
  loadAgain = () => {},
  refreshBGUScorecardFn = () => {},
  meetingId,
  meeting,
}) => {
  console.log('meeting', meeting)
  const { t } = useTranslation(['Common'])
  const initialValues = {
    meetingName: meeting?.meetingName ?? '',
    facilatator: meeting?.facilitatorId || null,
    assigns: meeting?.meetingManagers || [],
    frequency: meeting?.frequency ?? null,
    meetingDescription: meeting?.meetingDescription ?? '',
  }

  console.log('initialValues', initialValues)

  const [formValues, setFormValues] = useState(initialValues)

  const [userList, setUserList] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const cleanedError = {
    meetingName: '',
    facilatator: '',
    frequency: '',
    assigns: '',
    meetingDescription: '',
  }
  const [error, setError] = useState(cleanedError)
  const userId = localStorage.getItem('tfei')

  const frequencyOptions = [
    { value: 'weekly', label: t('meetingReport.weekly'), id: 1 },
    { value: 'bi-weekly', label: t('meetingReport.biWeekly'), id: 2 },
    { value: 'monthly', label: t('meetingReport.monthly'), id: 3 },
    { value: 'bi-monthly', label: t('meetingReport.bimonthly'), id: 5 },
    { value: 'quarterly', label: t('meetingReport.quarterly'), id: 4 },
  ]

  useEffect(() => {
    if (objectiveId) {
      getUserList()
    }
  }, [objectiveId])

  useEffect(() => {
    if (userList.length > 0) {
      if (!meeting) {
        let defaultFacilatator = userList.find((user) => user.eId == userId)
        // let defaultOwner = userList.find((user) => user.eId == userId)
        setFormValues((prevState) => {
          return {
            ...prevState,
            // facilatator: defaultFacilatator ? defaultFacilatator : userList[0],
            // owner: defaultOwner ? defaultOwner : userList[0],
            // frequency: frequencyOptions[0],
          }
        })
      } else {
        let defaultFacilatator = userList.find((user) => user.eId === meeting.facilitatorId)
        // let defaultOwner = userList.find((user) => user.eId === meeting.ownerId)
        setFormValues((prevState) => {
          return {
            ...prevState,
            facilatator: defaultFacilatator,
            // owner: defaultOwner,
            frequency: frequencyOptions.find((option) => option.id === meeting.frequency),
            meetingName: meeting.meetingName,
            meetingDescription: meeting.meetingDescription,
          }
        })
      }
    }
  }, [userList])

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  async function getUserList() {
    setIsLoading(true)
    const result = await getObjectivUserList(objectiveId)
    setIsLoading(false)
    if (result?.employees) setUserList(result.employees)
  }

  const textInputChangedHandler = (e) => {
    setError(cleanedError)
    console.log('e', e)
    setFormValues((prevState) => {
      return {
        ...prevState,
        meetingName: e.target.value,
      }
    })
  }

  const descriptionInputChangedHandler = (e) => {
    setFormValues((prevState) => {
      return {
        ...prevState,
        meetingDescription: e.target.value,
      }
    })
  }

  function inputChangedHandler(e, data) {
    setError(cleanedError)
    // Get name of changed input, and its corresponding value
    let name
    let value
    if (data) {
      if (data.name == 'assigns') {
        name = data.name
        value = e
      } else if (data.name == 'frequency') {
        name = data.name
        value = e
      } else if (data.name == 'facilatator') {
        name = data.name
        value = e
      }
    }
    updateFormHandler(name, value)
  }

  function updateFormHandler(name, value) {
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  async function onCustomSubmit(data, type) {
    let cleanedMeetingName = formValues.meetingName.trim()
    if (!cleanedMeetingName || formValues.assigns.length === 0) {
      setError({
        meetingName: !cleanedMeetingName ? 'Required !' : '',
        assigns: formValues.assigns.length === 0 ? 'Required !' : '',
      })
      return
    }

    setIsLoading(true)
    let response
    try {
      if (meetingId) {
        response = await postHttpRequest(`/update_meeting_detail/${meetingId}`, {
          facilatator: formValues.facilatator?.eId || null,
          frequency: formValues.frequency?.value || null,
          meetingName: cleanedMeetingName,
          meetingManagers: formValues.assigns.map((assign) => assign.eId),
          meetingDescription: formValues.meetingDescription ?? '',
        })
      } else {
        response = await postHttpRequest(`/add_new_meeting`, {
          meetingName: cleanedMeetingName,
          facilitator: formValues.facilatator?.eId || null,
          frequency: formValues.frequency?.value || null,
          meetingManagers: formValues.assigns.map((assign) => assign.eId),
          meetingDescription: formValues.meetingDescription ?? '',
        })
      }

      setIsLoading(false)
      if (response) {
        closeModal()
        setUserList([])
        setFormValues(initialValues)
        loadAgain()
        refreshBGUScorecardFn()
        Toast.fire({
          icon: 'success',
          title: 'Updated successfully',
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Something went wrong try again later!',
        })
      }
    } catch (error) {
      console.log('error', error)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong try again later!',
      })
    }
  }

  return (
    <div className="kanban-modal">
      <Modal
        title={
          meeting
            ? t('editMeetingDetailModal.editMeetingModal')
            : t('editMeetingDetailModal.addMeetingModal')
        }
        isModalOpen={view}
        data-testid="Assigne"
        closeModal={closeModal}
        width={'35rem'}
      >
        {isLoading ? <InPageLoader /> : ''}

        {!isLoading > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              className="column-input-objective"
              style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>
                    {t('editMeetingDetailModal.meetingName')}{' '}
                    <span className="text-red-500">*</span>
                  </label>
                  <div style={{ width: '100%' }}>
                    <TextArea
                      name="meetingName"
                      value={formValues.meetingName}
                      onChange={textInputChangedHandler}
                      style={{ width: '100%', resize: 'none', height: '30px' }}
                    />
                    {error.meetingName ? (
                      <span className="error error-add-commnet">{error.meetingName}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>{t('editMeetingDetailModal.meetingDescription')}</label>
                  <div style={{ width: '100%' }}>
                    <TextArea
                      name="meetingDescription"
                      value={formValues.meetingDescription}
                      onChange={descriptionInputChangedHandler}
                      style={{ width: '100%', resize: 'none' }}
                    />
                  </div>
                </div>
              </div>
              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>{t('editMeetingDetailModal.owner')}</label>
                  <div style={{ width: '100%' }}>
                    <SelectField
                      placeholder={t('formPlaceHolder.select')}
                      name="facilatator"
                      value={formValues.facilatator}
                      options={userList}
                      onChange={inputChangedHandler}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.eId}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                      isClearable={true}
                    />
                    {error.facilatator ? (
                      <span className="error error-add-commnet">{error.facilatator}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>{t('editMeetingDetailModal.frequency')}</label>
                  <div style={{ width: '100%' }}>
                    <SelectField
                      placeholder={t('formPlaceHolder.select')}
                      name="frequency"
                      value={formValues.frequency}
                      options={frequencyOptions}
                      onChange={inputChangedHandler}
                      isClearable={true}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                    />
                    {error.frequency ? (
                      <span className="error error-add-commnet">{error.frequency}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row-kanban-inputs">
                <div className="form-field action-field form-field action-field--kanban">
                  <label>
                    {t('assigneeActivityModal.assignTo')}
                    <span className="text-red-500">*</span>
                  </label>
                  <div style={{ width: '100%' }}>
                    <SelectField
                      isMulti={true}
                      placeholder={t('formPlaceHolder.select')}
                      name="assigns"
                      value={formValues.assigns}
                      options={userList}
                      onChange={inputChangedHandler}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.eId}
                      isClearable={true}
                      menuShouldScrollIntoView={false}
                      menuPosition="fixed"
                    />
                    {error.assigns ? (
                      <span className="error error-add-commnet">{error.assigns}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="modal-dual-btn">
                <ModalSaveButtonFitted
                  isLoading={isLoading}
                  tag={meeting ? t('Common:commonButtons.updateUpperCase') : null}
                  onClick={onCustomSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}
export default NewMeetingDetailsModal
