import React from 'react'
import { CardContainer, CardHeader } from './styles'
import styled from 'styled-components'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import Toast from '../../../../common/toast'
import InPageLoader from '../../../../components/InPageLoader'

const Grid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 0.8fr 0.3fr;
  row-gap: 0.5em;
  column-gap: 0.5rem;
  padding-top: 1rem;
  width: 100%;
`

const GridHeader = styled.div`
  font-weight: bold;
  justify-content: center;
  padding: 0.2rem 0.5rem;
`

const GridContent = styled.div`
  justify-content: center;
  padding: 0.2rem 0.5rem;
  word-break: break-word;
`

const EnterpriseObjectiveCard = ({}) => {
  const [companyFields, setCompanyFields] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    getCompanyFields()
  }, [])

  const getCompanyFields = async () => {
    setIsLoading(true)
    const response = await getHttpRequest('/get_company_fields')
    if (response?.companyFields) {
      const sortedFields = response.companyFields.sort((a, b) => {
        if (!a.fieldName) return 1 // Move empty fieldName to the end
        if (!b.fieldName) return -1 // Move empty fieldName to the end
        return a.fieldName.localeCompare(b.fieldName)
      })
      setCompanyFields(sortedFields)
    }
    setIsLoading(false)
  }

  const handleAddField = async () => {
    const newField = {
      fieldName: '',
      fieldType: 'string',
      isMandatory: false,
    }
    const response = await postHttpRequest('/add_company_field', newField)
    if (response.success) {
      getCompanyFields()
    }
    Toast.fire({
      icon: response.success === true ? 'success' : 'error',
      title: response.success === true ? 'Field added successfully' : 'Failed to add field',
    })
  }
  const handleMandatoryCheckboxChange = (fieldId, isChecked) => {
    const updatedFields = companyFields.map((field) => {
      if (field.fieldId === fieldId) {
        return { ...field, isMandatory: isChecked }
      }
      return field
    })
    setCompanyFields(updatedFields)
  }

  const handleFieldNameInputChange = (fieldId, value) => {
    const updatedFields = companyFields.map((field) => {
      if (field.fieldId === fieldId) {
        field.fieldName = value
      }
      return field
    })
    setCompanyFields(updatedFields)
  }

  const handleEditOnBlur = async (fieldId) => {
    const field = companyFields.find((field) => field.fieldId === fieldId)
    if (!field) {
      Toast.fire({
        icon: 'error',
        title: 'Field not found',
      })
      return
    }
    if (field.fieldName === '') {
      Toast.fire({
        icon: 'error',
        title: 'Field name cannot be empty',
      })
      return
    }

    if (field.fieldName !== '') {
      const response = await postHttpRequest('/edit_company_field', {
        fieldId: fieldId,
        fieldName: field.fieldName,
        isMandatory: companyFields.find((field) => field.fieldId === fieldId).isMandatory,
      })
      if (response.success) {
        getCompanyFields()
      }
      Toast.fire({
        icon: response.success === true ? 'success' : 'error',
        title: response.success === true ? 'Field updated successfully' : 'Failed to update field',
      })
    }
  }

  console.log('companyFields', companyFields)

  return (
    <>
      <CardContainer basis="100%">
        {isLoading && <InPageLoader inComponent={true} />}
        {!isLoading && (
          <>
            <CardHeader>Custom Objective Fields</CardHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
                width: '100%',
                position: 'relative',
              }}
            >
              <div className="underline-admin"></div>
            </div>
            <Grid>
              {companyFields.length > 0 && (
                <>
                  <GridHeader>Field Name</GridHeader>
                  <GridHeader>Field Type</GridHeader>
                  <GridHeader>Is Mandatory?</GridHeader>
                </>
              )}
              {companyFields.length === 0 && (
                <GridContent>There are no custom objective fields yet.</GridContent>
              )}
              {companyFields.length > 0 &&
                companyFields.map((field, index) => (
                  <React.Fragment key={`objectives-${index}`}>
                    <GridContent>
                      <input
                        type="text"
                        value={field.fieldName}
                        onChange={(e) => {
                          handleFieldNameInputChange(field.fieldId, e.target.value)
                        }}
                        onBlur={() => {
                          handleEditOnBlur(field.fieldId)
                        }}
                      />
                    </GridContent>
                    <GridContent>{field.fieldType === 'string' ? 'Text' : ''}</GridContent>
                    <GridContent>
                      <input
                        type="checkbox"
                        checked={field.isMandatory}
                        onChange={(e) => {
                          handleMandatoryCheckboxChange(field.fieldId, e.target.checked)
                        }}
                        onBlur={() => {
                          handleEditOnBlur(field.fieldId)
                        }}
                      />
                    </GridContent>
                  </React.Fragment>
                ))}
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
              <button
                type="button"
                className="fitted-button blue"
                style={{
                  background: 'white',
                  padding: '0.4rem',
                  height: '35px',
                  fontWeight: 'bold',
                  opacity: companyFields.length >= 10 ? '0.5' : '1',
                  cursor: companyFields.length >= 10 ? 'not-allowed' : 'pointer',
                }}
                onClick={() => {
                  handleAddField()
                }}
                disabled={companyFields.length >= 10}
              >
                ADD NEW FIELD
              </button>
            </div>
          </>
        )}
      </CardContainer>
    </>
  )
}

export default EnterpriseObjectiveCard
