import { useRef, useState, useEffect } from 'react'

const InsightsViewer = ({ insightsList = [], language }) => {
  const scrollContainerRef = useRef(null)
  const [dots, setDots] = useState('')

  // Update scroll position when insights list changes
  useEffect(() => {
    if (scrollContainerRef.current && insightsList.length > 0) {
      const lastMessageElement = scrollContainerRef.current.lastElementChild?.lastElementChild
      if (lastMessageElement) {
        lastMessageElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [insightsList])

  // Existing dots animation effect
  useEffect(() => {
    if (insightsList.length === 0) {
      const interval = setInterval(() => {
        setDots((prev) => {
          if (prev === '...') return ''
          return prev + '.'
        })
      }, 500)

      return () => clearInterval(interval)
    }
  }, [insightsList.length])

  return (
    <div
      ref={scrollContainerRef}
      className="insights-viewer-inner-container h-64 overflow-y-auto bg-gray-50 p-2 m-2"
      style={{ scrollBehavior: 'smooth', height: '-webkit-fill-available' }}
    >
      <div className="flex flex-col space-y-4">
        {insightsList.length === 0 && (
          <div className="max-w-xs self-start bg-gray-200 rounded-tr-xl rounded-tl-xl rounded-br-xl rounded-bl-none p-3 shadow-sm">
            <p className="text-base text-gray-800">
              {language === 'es' ? 'Generando insights' : 'Generating insights'}
              <span className="loading-dots" style={{ display: 'inline-block', minWidth: '24px' }}>
                {dots}
              </span>
            </p>
          </div>
        )}
        {insightsList.length > 0 &&
          insightsList.map((insight, index) => (
            <div
              key={index}
              className={`w-full ${
                index % 2 === 0
                  ? 'self-start bg-gray-200 rounded-tr-xl rounded-tl-xl rounded-br-xl rounded-bl-none'
                  : 'self-end bg-gray-300 rounded-tr-xl rounded-tl-xl rounded-br-xl rounded-bl-none'
              } p-3 shadow-sm 
                
                `}
            >
              {insight?.title && (
                <h3 className="text-sm font-semibold text-gray-900 mb-2">{insight.title}</h3>
              )}
              <p className="text-sm text-gray-800">{insight?.text}</p>
              <span className="text-xs text-gray-500 mt-1 block text-right">{insight?.date}</span>
            </div>
          ))}
      </div>
    </div>
  )
}

export default InsightsViewer
