import React, { useState } from 'react'

import { Handle, Position } from 'reactflow'
import './customNode.scss'
import ActivityIcon from '../../../assets/activity.svg'
import MessageIcon from '../../../assets/message.svg'
import ZoomIcon from '../../../assets/zoom.svg'
import ZoomOutIcon from '../../../assets/zoomOut.svg'
import ObjMapDropdownMenu from './objMapDropdownMenu'
import { shortenName } from '../../../utils/general'
import magic_wand from '../../../assets/magic.svg'
import { getConsolidatedConfigSettings } from '../../../utils/auth'
import { objectiveColors } from '../../../utils/colors'
import { useTranslation } from 'react-i18next'

const handleStyle = { left: 10 }

function CustomNodeObjectiveMode({ data, isConnectable }) {
  const { t } = useTranslation(['Common'])

  const SelectFirstCharacter = (name) => {
    return name
  }

  const renderListOfUserNames = (data) => {
    return (
      <>
        {data.slice(0, 4).map((item, key) => (
          <div>
            <span key={item.eId + key}>{SelectFirstCharacter(item.name)}</span>
            {key !== data.length - 1 && <span>,</span>}
          </div>
        ))}
        {data.length > 4 ? (
          <span className="d-flex align-items-center">{data.length - 4} more</span>
        ) : null}
      </>
    )
  }

  // Get the base color from the color mapping
  const baseColor = objectiveColors[data?.color] || '#737373'

  // Calculate opacity based on main level
  let opacity = 1
  switch (data.main) {
    case 1:
      opacity = 1
      break
    case 2:
      opacity = 0.7
      break
    case 3:
      opacity = 0.5
      break
    default:
      opacity = 1
  }

  // Convert hex to rgba
  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  const borderColor = data.greyOut ? hexToRgba(baseColor, 0.2) : hexToRgba(baseColor, opacity)

  const styles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    border: `3px solid ${borderColor}`,
    borderTop: `0.7rem solid ${borderColor}`,
    animation: 'fadeIn 2s',
  }

  function findCardIndexWithId(list, id) {
    if (!list) return -1

    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        return i
      }
    }
    return -1
  }

  let zoomIn = findCardIndexWithId(data?.zoomIndexLevel, data.id) === -1

  return (
    <div className="nodrag text-updater-node">
      {data.main === 1 || data.main === 2 || data.main === 3 ? (
        <>
          {' '}
          <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
      <div
        className={`custom-box-node box-level-${data.main} custom-box-node-assignee ${
          data.isSearchedObjective ? 'searched-border' : ''
        }`}
        style={styles}
      >
        <ul className="node-text-list">
          <li>
            <div style={{ display: 'flex', gap: '1.5rem', marginBottom: '0.2rem' }}>
              <div className="owner">{shortenName(data.owner)}</div>
            </div>
          </li>
          <li className="single-node">
            {/* <h2>Objective:</h2> */}
            <p className="goal-desc">{data.goal}</p>
          </li>
        </ul>
        <div className="icons-list-node-box">
          <div
            style={{
              width: '100%',
              alignSelf: 'flex-end',
              gap: '0.313rem',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 .313rem .313rem 0',
              padding: '0',
            }}
          >
            <div>
              {data.cardPermissions.canSeeObjStatistics === true &&
                data.hasOverdueActivities === true && (
                  <div class="fitted-button yellow-notification">
                    <span
                      class="material-symbols-outlined icon-size-sm"
                      style={{ backgroundColor: '#fff' }}
                      onClick={() => {
                        data.ShowOverdueModal(data?.id)
                      }}
                    >
                      warning
                    </span>
                  </div>
                )}
            </div>
            {data.cardPermissions.canAdd && (
              <div class="fitted-button blue tooltip">
                <span class="tooltiptext" style={{ fontSize: '16px' }}>
                  {' '}
                  {t('Common:tooltip.addButton')}
                </span>
                <span
                  class="material-symbols-outlined icon-size-sm"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    data.ShowAddModal(data, data.main)
                  }}
                >
                  add
                </span>
              </div>
            )}

            {!data.isReviewMode && (
              <>
                {data.cardPermissions.canSeeObjStatistics === true &&
                  data.hasAnyProgressLastTwoWeeks === true && (
                    <div class="fitted-button green-notification">
                      <span
                        class="material-symbols-outlined icon-size-sm"
                        style={{ backgroundColor: '#fff' }}
                        onClick={() => {
                          data.ShowNotificationsModal(data?.id)
                        }}
                      >
                        notifications
                      </span>
                    </div>
                  )}
                {data.cardPermissions.canSeeObjStatistics === true &&
                  data.hasAnyProgressLastTwoWeeks === false && (
                    <div class="fitted-button red-notification" style={{ cursor: 'grab' }}>
                      <span
                        class="material-symbols-outlined icon-size-sm"
                        style={{ backgroundColor: '#fff' }}
                      >
                        hourglass_disabled
                      </span>
                    </div>
                  )}
                {data.rag &&
                  data.main == 3 &&
                  data.rag !== 'UNKNOWN_RAG' &&
                  !data.fromSetUpAgent && (
                    <div
                      className={`rag-letter-box-sm ${
                        data.rag === 'RED'
                          ? 'red-rag-box'
                          : data.rag === 'YELLOW'
                            ? 'amber-rag-box'
                            : data.rag === 'GREEN'
                              ? 'green-rag-box'
                              : ''
                      }`}
                      style={{ cursor: 'grab' }}
                      onClick={() => {
                        data.ShowKPIModalSpecificKpi(data)
                      }}
                    >
                      <span class="icon-size-sm">
                        {data.rag === 'RED'
                          ? 'R'
                          : data.rag === 'YELLOW'
                            ? 'A'
                            : data.rag === 'GREEN'
                              ? 'G'
                              : ''}
                      </span>
                    </div>
                  )}

                {data.cardPermissions.canSeeActivities && (
                  <li className="fitted-button blue" style={{ backgroundColor: '#fff' }}>
                    <span
                      className="material-symbols-outlined icon-size-sm"
                      style={{ transform: 'rotate(270deg) scaleX(-1)', marginLeft: '0.1rem' }}
                      onClick={() => data.ShowActivitesModal(data?.id)}
                    >
                      account_tree
                    </span>
                  </li>
                )}

                {data.cardPermissions.canSeeHistory && (
                  <li>
                    <div
                      class="fitted-button blue"
                      style={{ backgroundColor: '#fff' }}
                      onClick={() => {
                        data.ShowHistoryModal(data?.id)
                      }}
                    >
                      <span class="material-symbols-outlined icon-size-sm">history</span>
                    </div>
                  </li>
                )}
              </>
            )}

            {data.cardPermissions.canSeeRecommendations &&
              (data.main === 1 || data.main === 2 || data.main === 3) && (
                <li
                  className="fitted-button blue tooltip"
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => data.ShowRecommendedObjStatementModalOpen(data)}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span class="tooltiptext" style={{ fontSize: '16px' }}>
                      {t('Common:tooltip.aiRecButton')}
                    </span>
                    <img
                      src={magic_wand}
                      alt="magic wand"
                      style={{
                        width: '15px',
                        height: 'auto',
                      }}
                    />
                  </div>
                </li>
              )}

            {data.main !== 3 && data.hasChildren === true && (
              <>
                {data.cardPermissions.canZoom &&
                  data.main !== 3 &&
                  zoomIn &&
                  !data?.expandedMode && (
                    // zoomIn && (
                    <li
                      className="fitted-button blue tooltip"
                      style={{ backgroundColor: '#fff' }}
                      onClick={() => data.setZoomIndex(data, data.main, zoomIn)}
                    >
                      <span class="tooltiptext" style={{ fontSize: '16px' }}>
                        {' '}
                        {t('Common:tooltip.expandViewButton')}
                      </span>
                      <span class="material-symbols-outlined icon-size-sm">zoom_in</span>
                    </li>
                  )}

                {data.cardPermissions.canZoom &&
                  !zoomIn &&
                  data.main !== 3 &&
                  findCardIndexWithId(data?.zoomIndexLevel, data.id) >= 0 && (
                    <li
                      className="fitted-button blue"
                      style={{ backgroundColor: '#e4e7fd' }}
                      onClick={() => data.setZoomIndex(data, data.main, zoomIn)}
                    >
                      <span class="material-symbols-outlined icon-size-sm">zoom_out</span>
                    </li>
                  )}
              </>
            )}
            {data.cardPermissions.canKpi && data.isReviewMode && (
              <li className="fitted-button blue tooltip" onClick={() => data.ShowKPIModal(data)}>
                <span class="tooltiptext" style={{ fontSize: '16px' }}>
                  {' '}
                  {t('Common:tooltip.viewKrButton')}
                </span>
                <span class="material-symbols-outlined icon-size-sm">query_stats</span>
              </li>
            )}

            {data.cardPermissions.canEdit && (
              <ObjMapDropdownMenu
                level={data.main}
                canEdit={data.cardPermissions.canEdit}
                canDeleteComplete={data.cardPermissions.canDeleteComplete}
                canSeeFiles={data.cardPermissions.canSeeFiles}
                isDeactivated={data.isDeactivated}
                canKpi={data.cardPermissions.canKpi}
                canAddTopic={data.cardPermissions.canAddTopic}
                onClickEdit={() => {
                  data.ShowEditModal(data?.id, data.main)
                }}
                onClickRequestUpdate={() => {
                  data.ShowRequestUpdateModal(data?.id)
                }}
                onClickViewFiles={() => {
                  data.ShowFilesModal(data?.id)
                }}
                onClickKPIs={() => {
                  data.ShowKPIModal(data)
                }}
                onClickChangelog={() => {
                  data.ShowChangelogPage(data?.id)
                }}
                onClickCompleteObj={() => {
                  data.ShowCompleteObjectiveModal(data?.id, data.main, data?.numOfChildrenActive)
                }}
                onClickDeleteObj={() => {
                  data.ShowDeleteObjectiveModal(data?.id, data.main, data?.numOfChildrenNotDeleted)
                }}
                onClickShowGanttChart={() => {
                  data.ShowGanttChartModal(data?.id)
                }}
                isReviewMode={data.isReviewMode}
                onClickAddMeetingTopic={() => {
                  data.ShowAddEditMeetingTopicModal(data)
                }}
              />
            )}
          </div>
        </div>
      </div>

      {data.main != 3 ? (
        <>
          <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default CustomNodeObjectiveMode
