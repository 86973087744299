import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import './styles.scss'
import { useTranslation } from 'react-i18next'

function DecisionLogAddCommentMilestone(props) {
  const { t } = useTranslation(['MeetingHub'])

  return (
    <div>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: !props.aiGenPendingApproval ? 'white' : '#f2f2f2',
              border: 'none',
              padding: '0',
            }}
          >
            <div className="fitted-button blue">
              <span className="material-symbols-outlined">add</span>
            </div>
          </MenuButton>
        }
      >
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.createItem(
              'action',
              props.goalId,
              props.mentions,
              props.umtId,
              props.meetingInstanceId,
              props.logId,
            )
          }}
        >
          {t('decisionLog.action')}
        </MenuItem>
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.createItem(
              'comment',
              props.goalId,
              props.mentions,
              props.umtId,
              props.meetingInstanceId,
              props.logId,
            )
          }}
        >
          {t('decisionLog.comment')}
        </MenuItem>
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.createItem(
              'decision',
              props.goalId,
              props.mentions,
              props.umtId,
              props.meetingInstanceId,
              props.logId,
            )
          }}
        >
          {t('decisionLog.decision')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default DecisionLogAddCommentMilestone
