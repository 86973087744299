import React from 'react'
import { defaultState, UpdateProvider } from './context/updateContext'
import UpdateWizard from './components/UpdateWizard'
import useUpdateData from './hooks/useUpdateData'
import Loading from '../../components/Loading'
import ApplicationLayout from '../../components/ApplicationLayout'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createTranslatedSteps } from './components/Joyride/steps'
import { isAreaLeader } from '../../utils/auth'

const UpdateWizardPage = () => {
  const location = useLocation()
  const showBGUonly = location?.state?.showBGUonly ?? null
  const showOverdueKPIsPageOnly = location?.state?.showOverdueKPIsPageOnly ?? null
  const existingStep = showBGUonly ? 2 : showOverdueKPIsPageOnly ? 1 : 0

  const { data, isLoading, error } = useUpdateData()
  const { t } = useTranslation(['UpdateWizard'])
  const steps = createTranslatedSteps(t)
  //delete KPI tutorial for area leader
  // if (isAreaLeader()) {
  //   steps.meetingTopics.splice(2, 1)
  // }

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return 'Error'
  }

  const initialState = {
    ...defaultState,
    data,
    locationState: location.state,
  }

  return (
    <ApplicationLayout>
      <UpdateProvider initialState={initialState}>
        <div
          className="wizard-container tailwind-scoped"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <UpdateWizard existingStep={existingStep} steps={steps} />
        </div>
      </UpdateProvider>
    </ApplicationLayout>
  )
}

export default UpdateWizardPage
