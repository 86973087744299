import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { getCheckoutSession } from '../../../../api/services/payment.services'

const BillingModal = ({ closeModal = () => {} }) => {
  const { t } = useTranslation(['Phoenix'])

  const onClickSubscribe = async (price) => {
    console.log('Processing payment...')
    const response = await getCheckoutSession(price)
    console.log(response)
    const paymentUrl = response?.url
    if (paymentUrl) {
      window.location.href = paymentUrl
    }
  }

  return (
    <Modal closeModal={closeModal}>
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-8 mt-6">
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-gray-900">{t('billing.availablePlans')}</h3>
        </div>

        <div className="mb-6 text-left">
          <p className="text-lg font-medium text-gray-900 mb-2 text-left">
            {t('billing.limitedTimeOffer')}
          </p>
          <ul className="list-disc text-gray-600 space-y-1 ml-6">
            <li className="text-left">{t('billing.unlimitedSessions')}</li>
            <li className="text-left">{t('billing.100HoursOfUsageTime')}</li>
          </ul>
          <p className="text-sm text-gray-500 mt-3 text-left">{t('billing.offerEndsOn')}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Monthly Plan */}
          <div
            className="border border-gray-200 rounded-xl p-6 hover:border-blue-200 transition-all cursor-pointer relative overflow-hidden w-[320px]"
            onClick={() => onClickSubscribe(20)}
          >
            <div className="absolute -right-8 top-4 rotate-45 bg-blue-600 text-white px-8 py-1 text-sm">
              Save 25%
            </div>
            <div className="flex flex-col items-start mb-4">
              <div>
                <h4 className="text-xl font-semibold text-gray-900">{t('billing.20DollarPlan')}</h4>
                <div className="mt-2 text-left">
                  <span className="text-3xl font-bold text-gray-900">$15</span>
                  <span className="text-gray-500">/month</span>
                </div>
              </div>
            </div>
          </div>

          {/* 6-Month Plan */}
          <div
            className="border border-gray-200 rounded-xl p-6 hover:border-blue-200 transition-all relative overflow-hidden cursor-pointer hover:bg-gray-50 w-[320px]"
            onClick={() => onClickSubscribe(60)}
          >
            <div className="absolute -right-8 top-4 rotate-45 bg-blue-600 text-white px-8 py-1 text-sm">
              Save 50%
            </div>
            <div className="flex flex-col items-start mb-4">
              <div>
                <h4 className="text-xl font-semibold text-gray-900">{t('billing.60DollarPlan')}</h4>
                <div className="mt-2 text-left">
                  <span className="text-3xl font-bold text-gray-900">$10</span>
                  <span className="text-gray-500">/month</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BillingModal
