import React from 'react'
import { useUpdateContext } from '../../../../context/updateContext'
import { useNavigate } from 'react-router-dom'
import WizardButton from '../../../../../../components/WizardButton'
import Joyride from 'react-joyride'
import { useTranslation } from 'react-i18next'

const SubmitUpdateWizard = ({ submitIsLoading, currentStep, setErrorMessage, handleSubmit }) => {
  const { t } = useTranslation(['Common'])
  const { state } = useUpdateContext()
  const navigate = useNavigate()
  const [isDisabled, setIsDisabled] = React.useState(false)

  let isBlockerError = false
  for (let objId in state.blockerErrors) {
    isBlockerError = isBlockerError || state.blockerErrors[objId]
  }
  return (
    <>
      <div className="submit-update-wizard-button">
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-md transition duration-200 flex items-center justify-center h-10 w-32 shadow-sm"
          type="submit"
          disabled={isDisabled || isBlockerError}
          onClick={() => {
            handleSubmit()
            setIsDisabled(true)
            setTimeout(function () {
              setIsDisabled(false)
            }, 10000)
          }}
        >
          {t('commonButtons.complete')}
          {submitIsLoading === true ? (
            <div
              style={{ width: '0.5rem', height: '0.5rem', margin: '0' }}
              className="loading-spinner"
            ></div>
          ) : null}
        </button>
        {/* <WizardButton
          isLoading={submitIsLoading}
          onClick={() => {
            handleSubmit()
            setIsDisabled(true)
            setTimeout(function () {
              setIsDisabled(false)
            }, 10000)
          }}
          disabled={isDisabled || isBlockerError}
        /> */}
      </div>
    </>
  )
}

export default SubmitUpdateWizard
