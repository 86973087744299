import React, { useState, useEffect, useMemo } from 'react'
import useGetAreaEmployeesMinusSelfAndCopyOwners from '../../api/query/useGetAreaEmployeesMinusSelfAndCopyOwners'
import { useFeatureFlagsContext } from '../../utils/featureFlagsContext'
import {
  getCompanyConfigSettings,
  getConsolidatedConfigSettings,
  isAreaLeader,
} from '../../utils/auth'
import useEmployeeData_2_3 from '../../api/query/useEmployeeDataLevel2&3'
import useLinkageData from '../../api/query/useLinkageData'
import useGetObjectiveById from '../../api/query/getObjectiveById'
import useAddObjective from '../../api/mutation/addObjectiveHome'
import useEditObjective from '../../api/mutation/editObjectiveHome'
import { useTranslation } from 'react-i18next'
import TagInput from '../TagInput'
import '../../tailwind.css'
import Select from 'react-select'
import {
  selectDropdownStyles,
  DateInput,
  TextInput,
  ValidationMessage,
  TextAreaInput,
  CheckboxInput,
} from '../../utils/styling'
import { postHttpRequest, getHttpRequest } from '../../api/query/dynamicAPI'
import {
  getFilesFilingCabinetByObjId,
  uploadFileFilingCabinet,
} from '../../api/services/filingCabinet.services'
import Toast from '../../common/toast'
import MeetingDeck from '../../pages/MeetingMultiView/components/PopupPage'
import Swal from 'sweetalert2'

const SLIDESHOW_MIME_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
]

// Helper function to check if file should show slideshow icon
const shouldShowSlideshow = (file, mimetype) => {
  let val = mimetype && SLIDESHOW_MIME_TYPES.includes(mimetype) && file.id
  return val
}

const ObjectiveForm = ({
  action,
  objId,
  isAddEditObjectiveModalOpen,
  closeModal,
  setEditedObj,
  objData,
  setObjData,
  loadAgain,
  existingTags,
  isFromObjectiveMap,
}) => {
  const { t } = useTranslation(['Dashboard', 'Common'])
  const statusOptions = [
    { value: 'NEW', label: 'Pre-PD0' },
    { value: 'NOT_STARTED', label: 'PD0' },
    { value: 'IN_PROGRESS', label: 'PD1' },
    { value: 'COMPLETE', label: 'PDf' },
    { value: 'DEPRIORITIZED', label: 'PDx' },
  ]

  const [companyFields, setCompanyFields] = React.useState([])
  const [mainFileAttachment, setMainFileAttachment] = React.useState(null)
  const [secondaryFileAttachments, setSecondaryFileAttachments] = React.useState([])
  const [isMeetingDeckOpen, setIsMeetingDeckOpen] = useState(false)
  const [selectedFileId, setSelectedFileId] = useState(null)
  const [objectiveModalTabs, setObjectiveModalTabs] = React.useState([
    { label: t('Common:areaObjective.basics'), value: 'basics' },
  ])

  const productSettings = getCompanyConfigSettings('productSettings')

  useEffect(() => {
    ;(async () => {
      await getCompanyFields()
      getObjectiveDocuments()

      if (objId) {
        setObjectiveModalTabs((prevTabs) => [
          ...prevTabs,
          { label: t('Common:areaObjective.attachments'), value: 'attachments' },
        ])
      }
    })()
  }, [])

  const getCompanyFields = async () => {
    const response = await getHttpRequest('/get_company_fields')
    if (response.companyFields) {
      let compFields = response.companyFields
      setCompanyFields(compFields)
      setFormData((prev) => ({
        ...prev,
        companyFields: compFields.reduce((acc, field) => {
          acc[field.fieldId] = ''
          return acc
        }, {}),
      }))
      if (compFields.length > 0) {
        setObjectiveModalTabs((prevTabs) => [
          ...prevTabs,
          { label: t('Common:areaObjective.custom'), value: 'custom' },
        ])
      }
    }
  }

  const getObjectiveDocuments = async () => {
    if (objId === null || objId === undefined) {
      return
    }
    let formData = new FormData()
    formData.append('objId', objId)
    formData.append('objFilesOnly', true)
    const response = await getFilesFilingCabinetByObjId(formData)

    if (response && response.fileList) {
      const mainAttachment = response.fileList.find((file) => file.isObjMainAttachment)
      const secondaryAttachments = response.fileList.filter((file) => !file.isObjMainAttachment)

      setMainFileAttachment(
        mainAttachment
          ? {
              name: mainAttachment.fileName,
              id: mainAttachment.fileId,
              mimetype: mainAttachment.mimetype,
            }
          : null,
      )

      setSecondaryFileAttachments(
        secondaryAttachments.map((file) => ({
          name: file.fileName,
          id: file.fileId,
          mimetype: file.mimetype,
        })),
      )
    }
  }

  const [formData, setFormData] = useState({
    statement: objData?.statement ?? '',
    isBAU: objData?.typeId === 3 ? true : false,
    topLevelObjectiveId: objData?.topLevelObjectiveId ? parseInt(objData?.topLevelObjectiveId) : '',
    parentId: objData?.parentId ? parseInt(objData?.parentId) : '',
    impactMeasureId: objData?.impactMeasureId ? parseInt(objData?.impactMeasureId) : '',
    durationWeeks: objData?.durationWeeks ?? 1,
    coOwners: objData?.coOwners ?? [],
    rights: objData?.rights ?? [],
    transferOwnership: '',
    status: objData?.status ?? statusOptions[0].value,
    dueDateFilter: objData?.dueDateStr ?? '',
    startDateFilter: objData?.startDateStr ?? '',
    productSettings: productSettings ?? '',
    ownerId: objData?.ownerId ?? '',
    bizRationale: productSettings ? objData?.bizRationale : null,
    greyOut: objData?.greyOut ?? false,
    detailedDescription: objData?.detailedDescription ?? '',

    companyFields: companyFields?.map((field) => ({
      [field.fieldId]:
        objData?.companyFields?.find((f) => f.fieldId === field.fieldId)?.fieldValue ?? '',
    })),
    mainAttachment: mainFileAttachment,
    secondaryAttachments: secondaryFileAttachments,
  })

  useEffect(() => {
    if (formData.parentId && formData.parentId !== '0') {
      setFormData((prev) => ({
        ...prev,
        topLevelObjectiveId: goals?.filter((goal) =>
          goal.levelTwoGoals.some((level2Goal) => level2Goal.id === formData.parentId),
        )[0]?.levelOneGoal.id,
      }))
    }
  }, [formData.parentId])

  React.useEffect(() => {
    setFormData({
      statement: objData?.statement ?? '',
      isBAU: objData?.typeId === 3 ? true : false,
      topLevelObjectiveId: objData?.topLevelObjectiveId
        ? parseInt(objData?.topLevelObjectiveId)
        : '',
      parentId: objData?.parentId ? parseInt(objData?.parentId) : '',
      impactMeasureId: objData?.impactMeasureId ? parseInt(objData?.impactMeasureId) : '',
      durationWeeks: objData?.durationWeeks ?? 1,
      coOwners: objData?.coOwners ?? [],
      rights: objData?.rights ?? [],
      transferOwnership: '',
      status: objData?.status ?? statusOptions[0].value,
      dueDateFilter: objData?.dueDateStr ?? '',
      startDateFilter: objData?.startDateStr ?? '',
      productSettings: productSettings ?? '',
      ownerId: objData?.ownerId ?? localStorage.getItem('tfei'),
      bizRationale: productSettings ? objData?.bizRationale : null,
      greyOut: objData?.greyOut ?? false,
      detailedDescription: objData?.detailedDescription ?? '',
      companyFields: companyFields?.map((field) => ({
        [field.fieldId]:
          objData?.companyFields?.find((f) => f.fieldId === field.fieldId)?.fieldValue ?? '',
      })),
      mainAttachment: mainFileAttachment,
      secondaryAttachments: secondaryFileAttachments,
    })
  }, [objData, companyFields, action, mainFileAttachment, secondaryFileAttachments])

  const [activeTab, setActiveTab] = useState('basics')
  const [showExitDialog, setShowExitDialog] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState({})
  const [errors, setErrors] = useState({})
  const { flags } = useFeatureFlagsContext()

  const allowCxoUsersSeeL3Objs =
    getConsolidatedConfigSettings('allow_cxo_users_see_l3_objs') ||
    getConsolidatedConfigSettings('allow_l2_users_see_all_objs_under_cxo')
  const canChangeObjStatus =
    getConsolidatedConfigSettings('can_change_obj_status') || isAreaLeader()
  const { data: linkageData } = useLinkageData()
  const { data: dataEmpLevel23 } = useEmployeeData_2_3()
  const { data: objectiveData } = useGetObjectiveById(objId)
  const mutationAddObjective = useAddObjective()
  const mutationEditObjective = useEditObjective(objectiveData?.ownerId)

  const [reportingLeaders, setReportingLeaders] = React.useState([])
  const [tags, setTags] = React.useState(existingTags)
  const [teamLeaders, setTeamLeaders] = React.useState([])
  const [employeesLevel2, setEmployeesLevel2] = React.useState([])

  const [isUploadingMainAttachment, setIsUploadingMainAttachment] = useState(false)
  const [isUploadingSecondaryAttachment, setIsUploadingSecondaryAttachment] = useState(false)

  useEffect(() => {
    if (objectiveData && Object.keys(objectiveData).length > 0) {
      setObjData({ ...objectiveData })
    }
  }, [objectiveData])

  React.useEffect(() => {
    if (dataEmpLevel23 && Object.keys(dataEmpLevel23).length > 0) {
      let filteredList = []
      if (!isFromObjectiveMap) {
        filteredList =
          dataEmpLevel23.employees.filter(
            (emp) => emp.level === 3 && emp.isDeactivated === false,
          ) ?? []
      } else {
        filteredList = [...dataEmpLevel23.employees]
      }
      setEmployeesLevel2(filteredList)
    }

    setTeamLeaders(
      dataEmpLevel23?.employees.filter(
        (emp) => emp.eId !== parseInt(objData?.ownerId) && emp.isDeactivated === false,
      ),
    )
  }, [dataEmpLevel23])

  const handleChange = (field, value) => {
    {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }))
    }

    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: '' }))
    }
  }

  const deleteFile = async (fileId) => {
    if (!fileId) return
    try {
      await postHttpRequest('/update_file_from_filing_cabinet', {
        fileId: fileId,
        isDeleted: true,
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const goals = React.useMemo(() => {
    const returnGoals = []

    if (linkageData?.linkageData?.higherObjectives) {
      linkageData.linkageData?.higherObjectives.forEach((goal) => {
        if (goal.level === 1) {
          returnGoals.push({
            levelOneGoal: goal,
            levelTwoGoals:
              linkageData.linkageData?.higherObjectives.filter(
                (test) => test.parentId === goal.id && test.level === 2,
              ) ?? [],
          })
        }
      })

      // This handles orphan level 2 objectives. We create a dummy level 1 objecitve with the ID 0,
      // which can be used to represent the dropdown option for "Not linked to an enterprise objective".
      returnGoals.push({
        levelOneGoal: {
          id: '0', // This should be a string because others are strings too (why??)
          statement: t('Common:areaObjective.notLinked'),
        },
        levelTwoGoals:
          linkageData?.linkageData?.higherObjectives.filter(
            (test) =>
              (test.parentId === undefined || parseInt(test.parentId) === 0) && test.level === 2,
          ) ?? [],
      })
    }

    return returnGoals
  }, [linkageData?.linkageData?.higherObjectives])

  // Flatten all level two goals into a single array as we inverted goal selection from enterprise to area to area to enterprise
  const allLevelTwoGoals = goals?.flatMap((goal) => goal.levelTwoGoals || [])

  // Find the selected goal from the flattened array
  const selectedAreaGoal = useMemo(() => {
    return allLevelTwoGoals.find((goal) => parseInt(goal.id) === parseInt(formData.parentId))
  }, [allLevelTwoGoals, formData.parentId])

  const validateForm = () => {
    const requiredMessage = t('Common:required')
    const newErrors = {
      ownerId: !formData.ownerId ? requiredMessage : '',
      statement: !formData.statement ? requiredMessage : '',

      topLevelObjectiveId:
        formData.isBAU === false && !formData.topLevelObjectiveId ? requiredMessage : '',
      parentId: formData.isBAU === false && !formData.parentId ? requiredMessage : '',
      dueDateFilter: productSettings && !formData.dueDateFilter ? requiredMessage : '',
    }
    for (const companyField of formData.companyFields) {
      const fieldId = Object.keys(companyField)[0]
      const fieldValue = companyField[fieldId]

      if (
        (!fieldValue || fieldValue === '') &&
        companyFields.find((f) => f.fieldId === fieldId).isMandatory
      ) {
        newErrors[fieldId] = requiredMessage
      }
    }

    setErrors(newErrors)
    return !Object.values(newErrors).some((error) => error !== '')
  }

  const prepareObj = async (values, resetForm, selectDefaultLeader = false) => {
    let objectiveToSubmit = {
      id: objData?.id ?? 0,
      typeId: values.isBAU
        ? 3
        : parseInt(values.topLevelObjectiveId) === 0 ||
            parseInt(values.topLevelObjectiveId) === null
          ? 2
          : 1,
      durationWeeks: values.durationWeeks,
      isBlocked: objData?.isBlocked ?? false,
      blockedReason: objData?.blockedReason ?? null,
      statement: values.statement,
      detailedDescription: values.detailedDescription,
      topLevelObjectiveId: values.topLevelObjectiveId,
      parentId: values.parentId,
      impactMeasureId: values.impactMeasureId,
      lackingCapacity: objData?.lackingCapacity ?? false,
      tags: [...tags],
      coOwners: [...values.coOwners],
      originalId: action === 'edit' ? (objData?.originalId ?? 0) : objData?.originalId,
      rights: [...values.rights],
      status: values?.status,
      dueDate: values?.dueDateFilter,
      startDate: values?.startDateFilter,
      objectiveType: 'General',
      bizRationale: productSettings ? values?.bizRationale : null,
      greyOut: values?.greyOut ?? false,
      companyFields: values?.companyFields,
    }

    if (action === 'add') {
      objectiveToSubmit.objectiveOwner = values?.ownerId
      objectiveToSubmit.selectDefaultLeader = selectDefaultLeader
    }

    if (action === 'edit') {
      objectiveToSubmit.objectiveOwner = values?.ownerId
    }

    if (values.isBAU) {
      objectiveToSubmit = {
        ...objectiveToSubmit,
        topLevelObjectiveId: null,
        parentId: null,
        impactMeasureId: null,
      }
    } else {
      objectiveToSubmit = {
        ...objectiveToSubmit,
        topLevelObjectiveId: values.topLevelObjectiveId.toString(),
        parentId: values.parentId.toString(),
        impactMeasureId: parseInt(values.impactMeasureId),
      }
    }

    let mutationApi = action === 'edit' ? mutationEditObjective : mutationAddObjective
    let responseObj = {
      newObjectiveData: objectiveToSubmit,
      suggestionId: null,
    }

    await mutationApi.mutateAsync(responseObj, {
      onSuccess: () => {
        closeModal()
        resetForm()
        loadAgain()
      },
      onError: () => {
        alert('There was an error submitting your changes.')
      },
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let isValid = validateForm()

    if (isValid) {
      await prepareObj(formData, () => setFormData({}), false)
    }
  }

  const handleExit = () => {
    const hasChanges = Object.values(formData).some((value) => {
      if (Array.isArray(value)) return value.length > 0
      if (typeof value === 'object' && value !== null) return Object.keys(value).length > 0
      return value !== '' && value !== false && value !== null
    })

    if (hasChanges) {
      setShowExitDialog(true)
    } else {
      window.history.back()
    }
  }

  // Tooltip for help icon
  const BAUHelpIcon = () => (
    <div className="tooltip">
      <span className="material-symbols-outlined text-base cursor-pointer relative group">
        help
      </span>
      <div className="tooltiptext ">{t('Common:areaObjective.bauHelp')}</div>
    </div>
  )

  const selectedOwner = useMemo(() => {
    return dataEmpLevel23?.employees?.find(
      (emp) => parseInt(emp.eId) === parseInt(formData.ownerId),
    )
  }, [dataEmpLevel23, formData.ownerId])

  const handleUploadError = (errorResult) => {
    if (errorResult === 'timeout') {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.fileUploadRequestTimeout'),
      })
    } else if (errorResult === 'malicious') {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.fileScanningFailed'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const handleFileUpload = async (file, isMainAttachment) => {
    let formData = new FormData()
    formData.append('objId', objId)
    formData.append('file', file, file.name)
    formData.append('isMainAttachment', isMainAttachment)
    formData.append('shouldDownloadAsPdf', 'true')
    if (isMainAttachment) {
      setIsUploadingMainAttachment(true)
    } else {
      setIsUploadingSecondaryAttachment(true)
    }

    try {
      const response = await uploadFileFilingCabinet(formData)
      if (response.upload_status === 'success') {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.fileUploadSuccess'),
        })

        const fileData = {
          name: file.name,
          id: response.fileId,
          mimetype: file.type, // Include the mimetype from the uploaded file
        }

        if (isMainAttachment) {
          handleChange('mainAttachment', fileData)
        } else {
          setFormData((prev) => ({
            ...prev,
            secondaryAttachments: [...prev.secondaryAttachments, fileData],
          }))
        }
        return true
      } else {
        handleUploadError(response.data.upload_status)
        return false
      }
    } catch (error) {
      handleUploadError()
      return false
    } finally {
      setIsUploadingMainAttachment(false)
      setIsUploadingSecondaryAttachment(false)
    }
  }

  const moveSecondaryToMain = async (file) => {
    const originalSecondaryAttachments = [...formData.secondaryAttachments]
    const originalMainAttachment = formData.mainAttachment

    try {
      const newSecondaryAttachments = formData.secondaryAttachments.filter((f) => f.id !== file.id)

      if (formData.mainAttachment) {
        newSecondaryAttachments.push(formData.mainAttachment)
        await postHttpRequest('/update_file_from_filing_cabinet', {
          fileId: formData.mainAttachment.id,
          isMainAttachment: false,
        })
      }

      handleChange('secondaryAttachments', newSecondaryAttachments)
      handleChange('mainAttachment', file)

      await postHttpRequest('/update_file_from_filing_cabinet', {
        fileId: file.id,
        isMainAttachment: true,
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
      // revert the changes
      handleChange('secondaryAttachments', originalSecondaryAttachments)
      handleChange('mainAttachment', originalMainAttachment)
    }
  }

  return (
    <>
      {isMeetingDeckOpen && selectedFileId && (
        <MeetingDeck
          closeModal={() => {
            setIsMeetingDeckOpen(false)
            setSelectedFileId(null)
          }}
          readOnly={true}
          fromKanban={true}
          fileId={selectedFileId}
        />
      )}
      <div className="tailwind-scoped w-full max-w-2xl mx-auto p-6 ">
        <div className="space-y-6">
          {/* Header */}

          <form onSubmit={handleSubmit}>
            {/* Tabs */}
            <div className="border-b border-gray-200 mb-6">
              <nav
                className={`flex flex-row -mb-px ${objectiveModalTabs.length > 2 ? 'justify-between' : 'gap-12'}`}
              >
                {objectiveModalTabs.map((tab) => (
                  <button
                    key={tab.value}
                    type="button"
                    onClick={() => setActiveTab(tab.value)}
                    className={`mr-2 py-2 px-4 font-medium ${
                      activeTab === tab.value
                        ? 'border-b-2 border-blue-500 text-blue-600'
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </nav>
            </div>

            {/* Basics Tab */}
            {activeTab === 'basics' && (
              <div className="space-y-6">
                {/* Objective Owner */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('Common:areaObjective.ownerName')}
                    &nbsp; <span className="text-red-500">*</span>
                  </label>
                  <Select
                    id="ownerId"
                    name="ownerId"
                    options={
                      dataEmpLevel23?.employees?.map((emp) => ({
                        value: emp.eId,
                        label: emp.name,
                      })) || []
                    }
                    value={
                      selectedOwner
                        ? {
                            value: selectedOwner.eId,
                            label: selectedOwner.name,
                          }
                        : null
                    }
                    placeholder={t('Common:areaObjective.selectOwner')}
                    styles={selectDropdownStyles}
                    onChange={(selectedOption) => {
                      setFormData((prev) => ({
                        ...prev,
                        ownerId: selectedOption ? selectedOption.value : null, // Changed from eId to value
                      }))
                    }}
                  />
                  <ValidationMessage show={errors.ownerId} message={errors.ownerId} />
                </div>

                {/* Objective Name */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('Common:areaObjective.statement')}
                    &nbsp; <span className="text-red-500">*</span>
                  </label>
                  <TextInput
                    name="statement"
                    value={formData.statement}
                    onChange={(e) => handleChange('statement', e.target.value)}
                    placeholder={t('Common:areaObjective.enterObjectiveStatement')}
                  />
                  <ValidationMessage show={errors.statement} message={errors.statement} />
                </div>

                {/* Objective Description */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('Common:areaObjective.detailedDescription')}
                  </label>
                  <TextAreaInput
                    name="detailedDescription"
                    value={formData.detailedDescription}
                    onChange={(e) => handleChange('detailedDescription', e.target.value)}
                    placeholder={t('Common:areaObjective.enterObjectiveDetailedDescription')}
                  />
                </div>

                {productSettings && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('addObjectivesModal.businessRationaeKeyCustomers')}
                    </label>
                    <TextAreaInput
                      name="bizRationale"
                      value={formData.bizRationale}
                      onChange={(e) => handleChange('bizRationale', e.target.value)}
                      placeholder={t('Common:areaObjective.enterObjectiveBizRationale')}
                    />
                  </div>
                )}

                {/* BAU Checkbox */}
                <div className="flex flex-row items-center space-x-2">
                  <CheckboxInput
                    name="isBAU"
                    checked={formData.isBAU}
                    onChange={(checked) => {
                      handleChange('isBAU', checked)
                      handleChange('topLevelObjectiveId', '')
                      handleChange('parentId', '')
                      handleChange('impactMeasureId', '')
                      handleChange('durationWeeks', 999999)
                      handleChange('objectiveDuration', 'full_cycle')
                    }}
                  />
                  <label className="text-sm text-gray-700">
                    {t('addObjectivesModal.doesNotMapToStrategy')}
                  </label>
                  <BAUHelpIcon />
                </div>
                {/* Area Objective */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left ">
                    {t('addObjectivesModal.areaObjective')} &nbsp;
                    {!formData.isBAU && <span className="text-red-500">*</span>}
                  </label>
                  <Select
                    id="parentId"
                    name="parentId"
                    placeholder={t('Common:areaObjective.selectAreaObjective')}
                    styles={selectDropdownStyles}
                    isDisabled={formData.isBAU}
                    value={
                      selectedAreaGoal
                        ? {
                            value: selectedAreaGoal.id,
                            label: selectedAreaGoal.statement,
                          }
                        : null
                    }
                    options={
                      allLevelTwoGoals?.map((goal) => ({
                        value: goal.id,
                        label: goal.statement,
                      })) || []
                    }
                    onChange={(selectedOption) => {
                      setFormData((prev) => ({
                        ...prev,
                        parentId: selectedOption ? selectedOption.value : null,
                      }))
                    }}
                  />

                  <ValidationMessage show={errors.parentId} message={errors.parentId} />
                </div>

                {/* Enterprise Objective */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('addObjectivesModal.enterpriseObjective')} &nbsp;
                    {!formData.isBAU && <span className="text-red-500">*</span>}
                  </label>
                  <Select
                    id="topLevelObjectiveId"
                    name="topLevelObjectiveId"
                    placeholder={t('Common:areaObjective.selectEnterprise')}
                    styles={selectDropdownStyles}
                    isDisabled={true}
                    value={
                      goals?.find(
                        (goal) =>
                          parseInt(goal.levelOneGoal.id) === parseInt(formData.topLevelObjectiveId),
                      )
                        ? {
                            value: formData.topLevelObjectiveId,
                            label: goals.find(
                              (goal) =>
                                parseInt(goal.levelOneGoal.id) ===
                                parseInt(formData.topLevelObjectiveId),
                            ).levelOneGoal.statement,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleChange('topLevelObjectiveId', selectedOption?.value)
                    }
                    options={
                      goals
                        ?.filter((goal) =>
                          goal.levelTwoGoals.some(
                            (level2Goal) => level2Goal.id === formData.parentId,
                          ),
                        )
                        .map((goal) => ({
                          value: goal.levelOneGoal.id,
                          label: goal.levelOneGoal.statement,
                        })) || []
                    }
                  />
                  <ValidationMessage
                    show={errors.topLevelObjectiveId}
                    message={errors.topLevelObjectiveId}
                  />
                </div>

                {productSettings && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('addObjectivesModal.status')}
                    </label>
                    <Select
                      name="status"
                      className="custom-select"
                      isDisabled={!canChangeObjStatus}
                      options={statusOptions?.map((data, index) => ({
                        value: data.value,
                        label: data.label,
                      }))}
                      value={formData.status}
                      onChange={(value) => handleChange('status', value)}
                      styles={selectDropdownStyles}
                    />
                  </div>
                )}

                <div className="mt-2 flex flex-row justify-between">
                  <div className="flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('addObjectivesModal.startDate')}
                    </label>
                    <DateInput
                      date={formData.startDateFilter}
                      onChange={(value) => handleChange('startDateFilter', value)}
                      name="startDateFilter"
                    />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('addObjectivesModal.dueDate')}
                      &nbsp; {productSettings && <span className="text-red-500">*</span>}
                    </label>
                    <DateInput
                      date={formData.dueDateFilter}
                      onChange={(value) => handleChange('dueDateFilter', value)}
                      name="dueDateFilter"
                    />
                    <ValidationMessage
                      show={errors.dueDateFilter && productSettings}
                      message={errors.dueDateFilter}
                    />
                  </div>
                </div>

                {/* { (
  <div className="space-y-2">
    <label className="block text-sm font-medium text-gray-700 text-left">
      {t('addObjectivesModal.dueDate')}
    </label>
    <input name="dueDateFilter" type="date" />
    {errors.dueDateFilter && (
      <p className="text-sm text-red-500">{errors.dueDateFilter}</p>
    )}
  </div>
)} */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('addObjectivesModal.tags')}
                  </label>
                  <TagInput
                    existingTags={tags}
                    setTagsExternally={setTags}
                    objective={true}
                    isFromObjectiveMap={isFromObjectiveMap}
                    objectiveId={objId}
                    newStyle={true}
                    addEditObjectiveModal={true}
                  />
                </div>
                <div
                  className="space-y-2 action-field action-field--kanban"
                  style={{ textAlign: 'left' }}
                >
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('addObjectivesModal.shared')}
                    <div style={{ fontWeight: 'normal' }}>
                      {t('addObjectivesModal.sharedDescription')}
                    </div>
                  </label>
                  <div data-testid="multi-options-2">
                    <Select
                      id="rights"
                      isMulti
                      options={teamLeaders?.map((leader) => ({
                        value: leader.eId,
                        label: leader.name,
                      }))}
                      value={
                        formData.rights?.map((right) => ({
                          value: right.eId,
                          label: right.name,
                        })) || []
                      }
                      onChange={(selectedOptions) => {
                        handleChange(
                          'rights',
                          selectedOptions?.map((option) => ({
                            eId: option.value,
                            name: option.label,
                          })) || [],
                        )
                      }}
                      placeholder={t('Common:formPlaceHolder.select')}
                      error={errors.rights}
                      styles={selectDropdownStyles}
                    />
                  </div>
                  {errors.rights && <p className="text-sm text-red-500">{errors.rights}</p>}
                </div>
                {(objData?.level == 2 || objData?.level == 3 || action === 'add') && (
                  <div className="space-y-2 flex flex-col">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('Common:areaObjective.differentiate')}
                    </label>
                    <div className="flex flex-row items-center space-x-2">
                      <input type="checkbox" name="greyOut" />
                      <span className="text-sm text-gray-700">
                        {t('Common:areaObjective.greyOut')}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Custom Fields Tab */}
            {activeTab === 'custom' && (
              <div className="space-y-6">
                <div className="flex">
                  {/* <span className="text-sm font-medium text-gray-700 text-left">
                  {t('Common:areaObjective.customFields')}
                </span> */}
                </div>

                {companyFields?.map((field, index) => (
                  <div key={field.fieldId} className="flex gap-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {field.fieldName}
                      &nbsp;
                      {field.isMandatory && <span className="text-red-500">*</span>}
                    </label>
                    <input
                      type="text"
                      value={formData?.companyFields?.[index]?.[field.fieldId]}
                      onChange={(e) => {
                        const updatedCompanyFields = formData?.companyFields?.map(
                          (companyField, i) => {
                            if (i === index) {
                              return { [field.fieldId]: e.target.value }
                            }
                            return companyField
                          },
                        )
                        handleChange('companyFields', updatedCompanyFields)
                      }}
                      className="flex-1 px-3 py-2 border border-gray-300 rounded-md hover:border-black"
                      placeholder={field.fieldName}
                    />
                    <ValidationMessage
                      show={errors[field.fieldId]}
                      message={errors[field.fieldId]}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* Attachments Tab */}
            {activeTab === 'attachments' && (
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('Common:areaObjective.mainAttachment')}
                  </label>

                  {!formData.mainAttachment ? (
                    // Show upload UI only when no main attachment exists
                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
                      <input
                        type="file"
                        className="hidden"
                        id="main-attachment"
                        onChange={async (e) => {
                          if (isUploadingMainAttachment !== true) {
                            const file = e.target.files[0]
                            if (file) {
                              await handleFileUpload(file, true)
                              document.getElementById('main-attachment').value = ''
                            }
                          }
                        }}
                      />
                      <div className="flex flex-col items-center">
                        <span className="material-symbols-outlined w-8 h-8 text-gray-400">
                          upload
                        </span>
                        <span className="mt-2 text-sm text-gray-500">
                          {t('Common:areaObjective.dropFilesHereOrClickToUpload')}
                        </span>
                        <button
                          type="button"
                          onClick={() => document.getElementById('main-attachment').click()}
                          className="mt-2 px-3 py-1 text-sm border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                          {isUploadingMainAttachment === true ? (
                            <div
                              style={{
                                width: '0.5rem',
                                height: '0.5rem',
                                margin: '0',
                                borderTopColor: 'blue',
                                borderWidth: '5px',
                              }}
                              className="loading-spinner"
                            ></div>
                          ) : null}
                          {t('Common:areaObjective.uploadMainAttachment')}
                        </button>
                      </div>
                    </div>
                  ) : (
                    // Show file info when main attachment exists
                    <div className="flex flex-row justify-between items-center bg-slate-50 p-2 rounded mt-2">
                      <span className="text-sm text-left truncate flex-1">
                        {formData.mainAttachment.name}
                      </span>
                      <div className="flex flex-row items-center gap-2 flex-shrink-0">
                        {shouldShowSlideshow(
                          formData.mainAttachment,
                          formData.mainAttachment.mimetype,
                        ) && (
                          <>
                            <div
                              className="tooltip text-gray-500"
                              onClick={() => {
                                setIsMeetingDeckOpen(true)
                                setSelectedFileId(formData.mainAttachment.id)
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <span className="material-symbols-outlined text-base text-gray-500">
                                slideshow
                              </span>
                              <span class="tooltiptext">
                                {' '}
                                {t('Common:tooltip.viewUploadedPresentation')}{' '}
                              </span>
                            </div>
                          </>
                        )}
                        <button
                          type="button"
                          onClick={async () => {
                            const result = await Swal.fire({
                              title: t('Common:modalMessages.areYouSureDeleteThisFile'),
                              icon: 'question',
                              showCancelButton: true,
                              confirmButtonText: t('Common:commonButtons.yes'),
                              cancelButtonText: t('Common:commonButtons.no'),
                            })
                            if (result.isConfirmed) {
                              const deletedFileId = formData.mainAttachment.id
                              handleChange('mainAttachment', null)
                              deleteFile(deletedFileId)
                            }
                          }}
                          className="p-1 hover:bg-gray-100 rounded-md tooltip"
                        >
                          <span class="tooltiptext"> {t('Common:tooltip.deleteFile')}</span>
                          <span className="material-symbols-outlined text-base">delete</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="space-y-2 pt-4 border-t">
                  <div className="flex flex-row items-center justify-between">
                    <label className="block text-sm font-medium text-gray-700">
                      {t('Common:areaObjective.secondaryAttachments')}
                    </label>
                    <button
                      type="button"
                      className="px-3 py-1 text-sm border border-gray-300 rounded-md hover:bg-gray-50 flex flex-row items-center gap-1"
                      onClick={() => {
                        if (isUploadingSecondaryAttachment !== true) {
                          document.getElementById('secondary-attachments').click()
                        }
                      }}
                    >
                      {isUploadingSecondaryAttachment === true ? (
                        <div
                          style={{
                            width: '0.5rem',
                            height: '0.5rem',
                            margin: '0',
                            borderTopColor: 'blue',
                            borderWidth: '5px',
                          }}
                          className="loading-spinner"
                        ></div>
                      ) : (
                        <span className="material-symbols-outlined w-4 h-4 text-sm">add</span>
                      )}
                      {t('Common:areaObjective.addFiles')}
                    </button>
                  </div>
                  <input
                    id="secondary-attachments"
                    type="file"
                    multiple
                    className="hidden"
                    onChange={async (e) => {
                      const files = Array.from(e.target.files || [])
                      for (const file of files) {
                        await handleFileUpload(file, false)
                      }
                      document.getElementById('secondary-attachments').value = ''
                    }}
                  />
                  <div className="space-y-2">
                    {formData.secondaryAttachments.map((file, index) => (
                      <div
                        key={index}
                        className="flex flex-row justify-between items-center bg-slate-50 p-2 rounded"
                      >
                        <span className="text-sm text-left truncate flex-1">{file.name}</span>
                        <div className="flex flex-row items-center gap-2 flex-shrink-0">
                          {shouldShowSlideshow(file, file.mimetype) && (
                            <>
                              <div
                                className="tooltip text-gray-500"
                                onClick={() => {
                                  setIsMeetingDeckOpen(true)
                                  setSelectedFileId(file.id)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <span className="material-symbols-outlined text-base text-gray-500">
                                  slideshow
                                </span>
                                <span class="tooltiptext">
                                  {' '}
                                  {t('Common:tooltip.viewUploadedPresentation')}{' '}
                                </span>
                              </div>
                            </>
                          )}
                          <button
                            type="button"
                            onClick={async () => {
                              const result = await Swal.fire({
                                title: t('Common:modalMessages.areYouSureDeleteThisFile'),
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: t('Common:commonButtons.yes'),
                                cancelButtonText: t('Common:commonButtons.no'),
                              })
                              if (result.isConfirmed) {
                                const newFiles = formData.secondaryAttachments.filter(
                                  (_, i) => i !== index,
                                )
                                const deletedFileId = file.id
                                handleChange('secondaryAttachments', newFiles)
                                deleteFile(deletedFileId)
                              }
                            }}
                            className="p-1 hover:bg-gray-100 rounded-md tooltip"
                          >
                            <span class="tooltiptext"> {t('Common:tooltip.deleteFile')}</span>
                            <span className="material-symbols-outlined text-base">delete</span>
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              moveSecondaryToMain(file)
                            }}
                            className="p-1 hover:bg-gray-100 rounded-md tooltip"
                          >
                            <span class="tooltiptext"> {t('Common:tooltip.markAsMainFile')} </span>
                            <span
                              class="material-symbols-outlined text-base"
                              style={{
                                transform: 'rotate(90deg)',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              first_page
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                    {formData.secondaryAttachments.length === 0 && (
                      <div className="text-sm text-slate-500 text-center py-4">
                        {t('Common:areaObjective.noSecondaryAttachments')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Form Actions */}
            <div className="flex flex-row justify-end gap-4 pt-6">
              <button
                type="button"
                onClick={handleExit}
                className="px-4 py-2 w-40 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                {t('Common:commonButtons.cancel')}
              </button>
              <button
                type="submit"
                className="px-4 py-2 w-40 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                {action === 'add'
                  ? t('Common:areaObjective.createObjective')
                  : t('Common:areaObjective.saveChanges')}
              </button>
            </div>
          </form>
        </div>

        {/* Exit Dialog */}
        {/* {showExitDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-2">
              {t('Common:areaObjective.leavePageQuestion')}
            </h2>
            <p className="text-gray-600 mb-4">
              {t('Common:areaObjective.youHaveUnsavedChanges')}
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowExitDialog(false)}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                {t('Common:areaObjective.continueEditing')}
              </button>
              <button
                onClick={() => window.history.back()}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                {t('Common:areaObjective.leavePage')}
              </button>
            </div>
          </div>
        </div>
      )} */}
      </div>
    </>
  )
}

export default ObjectiveForm
