// MeetingRecordingProvider.js
import React, { useReducer, useRef, useEffect, useState } from 'react'
import BrainstormReducer, { defaultState } from './BrainstormReducer'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import useLock from '../../../common/useLock'
import { useReactFlow, useStoreApi } from '@xyflow/react'
import { getConsolidatedConfigSettings } from '../../../utils/auth'
import ApiQueueManager from '../../../utils/apiQueue'
import BillingModal from '../Component/Modal/BillingModal'

const CHUNK_DURATION = 500
const SEND_INTERVAL = 10000
const numChunksIn2seconds = (15 * 1000) / (CHUNK_DURATION * 1.0)
const TOPIC_GENERATION_INTERVAL = 60000
const QUEUE_PEEK_INTERVAL = 30000
const DEVIATED_CONVERSATION_DETECTION_INTERVAL = 60000
const SHORT_TERM_SUMMARY_INTERVAL = 20000
const INITIAL_DETECT_DEVIATION_DELAY = 0
const GENERATE_RESEARCH_INTERVAL = 300000 // 5 minutes
const MIND_MAP_TOPIC_GENERATINO_INTERVAL = 300000 //1.5 mins for now for testibg purpose
const MIND_MAP_ITEM_GENERATION_INTERVAL = 30000
const TOPIC_REDISTRIBUTION_TIMEOUT = 180000
const TOPIC_REDISTRIBUTION_INTERVAL = 120000
const INITIAL_DO_YOU_WANT_TO_CONTINUE_TIMEOUT = 3600000 // 1 hour
const SUBSEQUENT_DO_YOU_WANT_TO_CONTINUE_TIMEOUT = 1800000 // 30 mins
// const ITEM_REPLACEMENT_INTERVAL = 60000 // 3 mins

const BrainstormContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
  startRecording: () => Promise.resolve(),
  stopRecording: () => Promise.resolve(),
  exploreProblems: () => Promise.resolve(),
  // revertBrainstormMap: () => Promise.resolve(),
  accumulateDataChunks: () => {},
  sendAudioData: () => Promise.resolve(),
  generateResearch: () => Promise.resolve(),
  source: null,
  removeDuplicateItems: () => Promise.resolve(),
  removeContradictoryItems: () => Promise.resolve(),
  initAddUserTopic: () => Promise.resolve(),
  reDistributeParkingLot: () => Promise.resolve(),
})

const BrainstormProvider = ({ children, source }) => {
  const { t } = useTranslation(['Common'])

  const [state, dispatch] = useReducer(BrainstormReducer, defaultState)

  const mediaRecorderRef = useRef(null)
  const audioContextRef = useRef(null)
  const chunksRef = useRef([])
  const headerChunkRef = useRef(null)
  const sendingIntervalRef = useRef(null)
  const dataIntervalRef = useRef(null)
  const problemGenerationIntervalRef = useRef(null)
  const displayStreamRef = useRef(null)
  const audioStreamRef = useRef(null)
  const timeoutRef = useRef(null)
  const topicTimeoutRef = useRef(null)
  const itemTimeoutRef = useRef(null)
  const stateRef = useRef(state)
  const isProcessingDataQueue = useRef(false)
  const dataQueue = useRef([])
  const deviatedConversationDetectionTimeoutRef = useRef(null)
  const mindMapRecommendationTimeoutRef = useRef(null)
  const shortTermSummaryIntervalRef = useRef(null)
  const topicsGenerationIntervalRef = useRef(null)
  const topicsRedistributionIntervalRef = useRef(null)
  const topicRedistributionTimeoutRef = useRef(null)
  const mindmapTopicGenerationIntervalRef = useRef(null)
  const mindmapItemGenerationIntervalRef = useRef(null)
  const itemReplacementIntervalRef = useRef(null)
  // const isReverting = useRef(false)
  const isGeneratingProblems = useRef(false)
  const numCallsToProblemGeneration = useRef(0)
  const lastAudioApiCall = useRef(0)
  const [counter, setCounter] = React.useState(0)
  const [selectedQuestionNumber, setSelectedQuestionNumber] = React.useState(-1)
  const sendAduioTimeoutRef = useRef(null)
  const initialDelayRef = useRef(null)
  const mindmapItemGenerationTimeoutRef = useRef(null)
  // const isUpdatingMMRef = useRef(false)
  const researchGenerationIntervalRef = useRef(null)
  // const heartbeatIntervalRef = useRef(null)
  // const HEARTBEAT_INTERVAL = 20000 // 20 seconds

  // Add new constant for desktop heartbeat interval
  const DESKTOP_HEARTBEAT_INTERVAL = 5000 // 5 seconds

  // Add new ref for desktop heartbeat interval
  const desktopHeartbeatIntervalRef = useRef(null)

  const doYouWantToContinueTimeoutRef = useRef(null)
  const isSwalOpenRef = useRef(false)
  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

  const { requestQueueAccess, lockAccess, releaseLock } = useLock()

  const sourceIdEventSource = useRef(null)

  const { setCenter, getNodes, getNodesBounds, fitBounds, fitView } = useReactFlow()
  const store = useStoreApi()

  // const MIND_MAP_INITIAL_INTERVALS = [
  //   { duration: 90000, count: 4 },  // 90 seconds, 4 times
  //   { duration: 120000, count: 2 }, // 120 seconds, 2 times
  //   { duration: 180000, count: 1 }, // 180 seconds, 1 time
  //   { duration: 300000, count: Infinity } // 5 minutes, indefinitely
  // ]
  const topicGenerationCountRef = useRef(0)
  const currentIntervalIndexRef = useRef(0)

  const enableBrainstormingResearch = getConsolidatedConfigSettings('enable_brainstorming_research')

  const apiQueueManager = useRef(new ApiQueueManager())

  const retryItemsTimeoutRef = useRef(null)

  const [showBillingModal, setShowBillingModal] = useState(false)

  function markNewItemsV2(actionPlans) {
    // Create deep copy of action plans to avoid mutating original
    const updatedActionPlans = JSON.parse(JSON.stringify(actionPlans))

    // Helper function to mark an item as new if its ID is in newUUIDs
    function markIfAddedInLastRound(item) {
      if (item?.is_new === true) {
        item.isAddedInLastRound = true
      }
      return item
    }

    // Process each action plan
    return updatedActionPlans.map((plan) => ({
      ...plan,
      comments: plan.comments?.map(markIfAddedInLastRound) || [],
      decisions: plan.decisions?.map(markIfAddedInLastRound) || [],
      actions: plan.actions?.map(markIfAddedInLastRound) || [],
    }))
  }

  const handleZoomToNewNode = () => {
    // Filter nodes to those whose data.id is in the provided UUID list
    const initialNodes = getNodes()
    const filteredNodes = initialNodes.filter((node) => node.data.isAddedInLastRound)

    if (filteredNodes.length === 0) {
      return
    }

    const newNode = filteredNodes[0]

    if (newNode) {
      const { x, y } = newNode.position

      setCenter(x, y, { zoom: 1.4, duration: 800 })

      // After 4 seconds, we need to reset the view to show everything.
      setTimeout(() => {
        fitViewToImportantNodes()
      }, 4000)
    }
  }

  const fitViewToImportantNodes = () => {
    const nodes = getNodes()
    // Filter nodes by provided IDs
    const filteredNodes = nodes
      .filter((node) => {
        return node.type == 'mindmapTopicNode'
      })
      .slice(-5)

    if (filteredNodes.length === 0) {
      fitView({ duration: 800 })
      return
    }

    // Get the bounds of the nodes but then fit the horitzontal bounds to be
    // from the top of the topic nodes to the bottom of the canvas.
    const bounds = getNodesBounds(filteredNodes)
    const { height } = store.getState()
    const adjustedBounds = {
      x: bounds.x,
      y: bounds.y - 20, // 20 is some arbtirary padding
      width: bounds.width,
      height: height,
    }

    fitBounds(adjustedBounds, { duration: 800, padding: 0 })
  }

  useEffect(() => {
    return () => {
      cleanup()
    }
  }, [])

  useEffect(() => {
    stateRef.current = state
    console.log('stateRef.current: ', stateRef.current)
  }, [state])

  useEffect(() => {
    if (state.progressState === 'exploration') {
      console.log('trigger intervals')

      // after a 30 seconds delay, start the counter to execute function evey 1 min
      if (!topicsGenerationIntervalRef.current && !state.readOnly) {
        // Schedule topics generation with 1 minute interval, starting at 30s
        apiQueueManager.current.scheduleApiCall(
          'generateTopics',
          () => generateTopicsV2(stateRef.current.meetingInstanceId),
          TOPIC_GENERATION_INTERVAL,
          30000,
          false,
        )
      }

      // Runs at 3 mins, and then every 5 mins after
      if (!topicsRedistributionIntervalRef.current && !state.readOnly) {
        // Schedule topic redistribution with 5 min interval, starting at 3 mins
        apiQueueManager.current.scheduleApiCall(
          'redistributeTopics',
          () => redistributeTopics(stateRef.current.meetingInstanceId),
          TOPIC_REDISTRIBUTION_INTERVAL,
          TOPIC_REDISTRIBUTION_TIMEOUT,
        )
      }

      // if (!itemReplacementIntervalRef.current && !state.readOnly) {
      //   apiQueueManager.current.scheduleApiCall(
      //     'removeDuplicateItems',
      //     () => removeDuplicateItems(stateRef.current.meetingInstanceId),
      //     ITEM_REPLACEMENT_INTERVAL,
      //     0,
      //     false,
      //   )
      // }

      // run 'generate first set topics' at 30 seconds
      // then follow by item generations (then every 30 seconds)
      if (!mindmapItemGenerationIntervalRef.current && !state.readOnly) {
        setTimeout(() => {
          // Initial topics generation
          apiQueueManager.current
            .addToQueue('initialTopics', () =>
              generateTopicsV2(stateRef.current.meetingInstanceId, true),
            )
            .then(() => {
              // Schedule item generation with 30s interval
              apiQueueManager.current.scheduleApiCall(
                'generateItems',
                () => generateItemsV2(stateRef.current.meetingInstanceId),
                MIND_MAP_ITEM_GENERATION_INTERVAL,
              )
            })
            .catch((error) => {
              console.error('Error in initial topics generation:', error)
            })
        }, 30000)
      }

      if (!researchGenerationIntervalRef.current && !state.readOnly) {
        researchGenerationIntervalRef.current = setInterval(() => {
          generateResearch()
        }, GENERATE_RESEARCH_INTERVAL)
      }
    }

    return () => {
      console.log('clear intervals')
      apiQueueManager.current.cleanup()

      if (topicTimeoutRef.current) {
        clearTimeout(topicTimeoutRef.current)
      }

      if (itemTimeoutRef.current) {
        clearTimeout(itemTimeoutRef.current)
      }

      currentIntervalIndexRef.current = 0
      topicGenerationCountRef.current = 0
    }
  }, [state.progressState, state.readOnly])

  // React.useEffect(() => {
  //   let shouldStopInterruptionTemporarilyTimer
  //   if (state.shouldStopInterruptionTemporarily) {
  //     shouldStopInterruptionTemporarilyTimer = setTimeout(() => {
  //       dispatch({ type: 'SET_SHOULD_STOP_INTERRUPTION', payload: false })
  //       // roughly 5 minutes
  //     }, 290000)
  //   }
  //   return () => {
  //     if (shouldStopInterruptionTemporarilyTimer) {
  //       clearTimeout(shouldStopInterruptionTemporarilyTimer)
  //     }
  //   }
  // }, [state.shouldStopInterruptionTemporarily])

  const findFirstLargerTimestamp = () => {
    let low = 0
    let high = chunksRef.current.length - 1
    let result = -1

    while (low <= high) {
      let mid = Math.floor((low + high) / 2)
      // take 3 weconds of previous audio
      if (chunksRef.current[mid].timestamp > lastAudioApiCall.current - 3) {
        result = mid // Store the current index
        high = mid - 1 // Search in the left half
      } else {
        low = mid + 1 // Search in the right half
      }
    }
    return result
  }

  const startRecordingRequest = async (hasDesktopApp) => {
    const response = await postHttpRequest('/brainstorm/start_stop_brainstorm', {
      isStopRecording: false,
      isBrainstorming: true,
      isDesktopRecording: hasDesktopApp,
    })
    const meetingInstanceId = response.meetingInstanceId
    const brainstormInstanceId = response.associatedInstanceId
    const brainstormLinkId = response.tokenId

    return { meetingInstanceId, brainstormInstanceId, brainstormLinkId }
  }

  // const sendHeartbeat = async (meetingInstanceId) => {
  //   try {
  //     let res = await postHttpRequest('/brainstorm/record_app_heartbeat', {
  //       meetingInstanceId: meetingInstanceId
  //     })
  //     console.log("Heartbeat response", res)
  //     if(!res.heartbeat_valid) {
  //       //generate a popup
  //       Swal.fire({
  //         title: t('Common:brainstorming.desktopAppDisconnected'),
  //         text: t('Common:brainstorming.desktopAppDisconnectedMessage'),
  //         icon: 'error',
  //         confirmButtonText: 'OK',
  //       })
  //     }
  //   } catch (error) {
  //     console.error('Error sending heartbeat:', error)
  //   }
  // }

  // Add new function to check desktop heartbeat
  const checkDesktopHeartbeat = async (meetingInstanceId) => {
    if (!stateRef.current.hasDesktopApp) {
      return
    }
    let randomInstanceId = Math.floor(Math.random() * 1000000)
    // console.log time
    console.log(
      randomInstanceId,
      'checkDesktopHeartbeat function called time (s): ',
      Math.floor(Date.now() / 1000),
    )
    console.log(
      randomInstanceId,
      'checkDesktopHeartbeat stateRef.current.readOnly',
      stateRef.current.readOnly,
    )
    if (stateRef.current.readOnly) {
      return
    }
    console.log(randomInstanceId, 'checkDesktopHeartbeat starting')
    try {
      console.log(
        randomInstanceId,
        'checkDesktopHeartbeat calling postHttpRequest at time (s): ',
        Math.floor(Date.now() / 1000),
      )
      let res = await postHttpRequest('/brainstorm/get_desktop_heartbeat', {
        meeting_id: meetingInstanceId,
      })
      console.log(randomInstanceId, 'Desktop heartbeat at time (s):', Math.floor(Date.now() / 1000))
      console.log(randomInstanceId, 'Desktop heartbeat response:', res)

      // Check if desktop_heartbeat exists and is not within 20 seconds of current time
      if (res.desktop_heartbeat) {
        const currentTime = Math.floor(Date.now() / 1000)
        const heartbeatTime = res.desktop_heartbeat
        console.log(randomInstanceId, 'currentTime', currentTime)
        console.log(randomInstanceId, 'heartbeatTime', heartbeatTime)

        if (currentTime - heartbeatTime > 30) {
          Swal.fire({
            title: t('Common:brainstorming.desktopAppDisconnected'),
            text: t('Common:brainstorming.desktopAppDisconnectedMessage'),
            icon: 'error',
            confirmButtonText: 'OK',
          })
          cleanup()
        }
      }
      console.log(
        randomInstanceId,
        'checkDesktopHeartbeat finished at time (s): ',
        Math.floor(Date.now() / 1000),
      )
    } catch (error) {
      console.error('Error checking desktop heartbeat:', error)
    }
  }

  const extendMeeting = async () => {
    if (!isSwalOpenRef.current) {
      isSwalOpenRef.current = true

      const result = await Swal.fire({
        title: 'Do you want to continue?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        // Auto close after 60 seconds
        timer: 60000,
        timerProgressBar: true,
      })

      isSwalOpenRef.current = false

      if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
        // Case 2 - user clicked cancel
        console.log('cleanup')
        dispatch({ type: 'AUTO_TERMINATION' })
      } else if (result.isConfirmed) {
        // Case 3 - user clicked yes
        // Schedule next popup in 30 minutes
        if (doYouWantToContinueTimeoutRef.current) {
          clearTimeout(doYouWantToContinueTimeoutRef.current)
        }
        doYouWantToContinueTimeoutRef.current = setTimeout(() => {
          extendMeeting()
        }, SUBSEQUENT_DO_YOU_WANT_TO_CONTINUE_TIMEOUT)
        //send request to server to extend meeting
        const response = await postHttpRequest('/brainstorm/extend_meeting', {
          meetingInstanceId: stateRef.current.meetingInstanceId,
        })
        console.log('extendMeeting response:', response)
      } else {
        // Timer ran out (Case 1)
        console.log('cleanup')
        cleanup()
      }
    }
  }

  const startRecording = async (zisiPersona = 0) => {
    let includeTabAudio = stateRef.current.useMicOnly === false
    let hasDesktopApp = stateRef.current.hasDesktopApp
    try {
      dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'idle' })
      dataQueue.current = []
      console.log('hasDesktopApp: ', hasDesktopApp)
      // dplus: desktop app + webapp
      // zisi: desktop app only
      if (source === 'zisi' && !hasDesktopApp) {
        return false
      }
      if (source === 'zisi' && hasDesktopApp) {
        // Desktop app only flow
        const { meetingInstanceId, brainstormInstanceId, brainstormLinkId } =
          await startRecordingRequest(true)
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.src = `${process.env.REACT_APP_ZISI_APP_NAME || 'zisiapp'}://startRecording?s1&meetingInstanceId=${meetingInstanceId}`
        document.body.appendChild(iframe)
        setTimeout(() => document.body.removeChild(iframe), 500)
        dispatch({
          type: 'START_RECORDING',
          meetingInstanceId,
          brainstormInstanceId,
          brainstormLinkId,
          source,
          zisiPersona,
        })

        // Start heartbeat
        // heartbeatIntervalRef.current = setInterval(() => {
        //   if (source === 'zisi' && hasDesktopApp) {
        //     sendHeartbeat(meetingInstanceId)
        //   }
        // }, HEARTBEAT_INTERVAL)

        // Start desktop heartbeat check
        desktopHeartbeatIntervalRef.current = setInterval(() => {
          console.log('desktopHeartbeatIntervalRef start time (s): ', Math.floor(Date.now() / 1000))
          if (source === 'zisi' && hasDesktopApp) {
            checkDesktopHeartbeat(meetingInstanceId)
          } else {
            console.log('not calling checkDesktopHeartbeat')
          }
        }, DESKTOP_HEARTBEAT_INTERVAL)

        // Start do you want to continue interval
        doYouWantToContinueTimeoutRef.current = setTimeout(() => {
          extendMeeting()
        }, INITIAL_DO_YOU_WANT_TO_CONTINUE_TIMEOUT)

        return true
      } else if (source === 'dplus') {
        // Could be desktop app or webapp
        // Comment for now
        if (hasDesktopApp) {
          // Use desktop app flow
          // Desktop app only flow
          const { meetingInstanceId, brainstormInstanceId, brainstormLinkId } =
            await startRecordingRequest(true)
          const iframe = document.createElement('iframe')
          iframe.style.display = 'none'
          console.log('process.env.REACT_APP_ZISI_APP_NAME', process.env.REACT_APP_ZISI_APP_NAME)
          iframe.src = `${process.env.REACT_APP_ZISI_APP_NAME || 'zisiapp'}://startRecording?s1&meetingInstanceId=${meetingInstanceId}`
          document.body.appendChild(iframe)
          setTimeout(() => document.body.removeChild(iframe), 500)
          dispatch({
            type: 'START_RECORDING',
            meetingInstanceId,
            brainstormInstanceId,
            brainstormLinkId,
            source,
            zisiPersona,
          })

          // Start desktop heartbeat check
          desktopHeartbeatIntervalRef.current = setInterval(() => {
            console.log(
              'desktopHeartbeatIntervalRef start time (s): ',
              Math.floor(Date.now() / 1000),
            )
            if (source === 'zisi' && hasDesktopApp) {
              checkDesktopHeartbeat(meetingInstanceId)
            } else {
              console.log('not calling checkDesktopHeartbeat')
            }
          }, DESKTOP_HEARTBEAT_INTERVAL)

          // Start do you want to continue interval
          doYouWantToContinueTimeoutRef.current = setTimeout(() => {
            extendMeeting()
          }, INITIAL_DO_YOU_WANT_TO_CONTINUE_TIMEOUT)

          return true
        }

        // Webapp flow
        let displayStream = null
        if (includeTabAudio) {
          displayStream = await navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: true,
          })
          displayStreamRef.current = displayStream
        }

        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        audioStreamRef.current = audioStream
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)()

        // Create and configure audio context nodes
        const destinationNode = audioContextRef.current.createMediaStreamDestination()
        const audioSourceNode = audioContextRef.current.createMediaStreamSource(audioStream)
        audioSourceNode.connect(destinationNode)

        // Only connect display stream if it exists
        if (displayStream) {
          const displaySourceNode = audioContextRef.current.createMediaStreamSource(displayStream)
          displaySourceNode.connect(destinationNode)
        }

        mediaRecorderRef.current = new MediaRecorder(destinationNode.stream)

        const { meetingInstanceId, brainstormInstanceId, brainstormLinkId } =
          await startRecordingRequest(false)
        dispatch({ type: 'SET_STREAM', stream: destinationNode.stream })
        dispatch({
          type: 'START_RECORDING',
          meetingInstanceId,
          brainstormInstanceId,
          brainstormLinkId,
          source,
          zisiPersona,
        })

        desktopHeartbeatIntervalRef.current = setInterval(() => {
          console.log('desktopHeartbeatIntervalRef start time (s): ', Math.floor(Date.now() / 1000))
          checkDesktopHeartbeat(meetingInstanceId)
        }, DESKTOP_HEARTBEAT_INTERVAL)

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            if (headerChunkRef.current === null) {
              headerChunkRef.current = event.data
            } else {
              // when bot audio is not playing (can't be muted), or bot audio is playing (but muted)
              if (
                (!stateRef.current.isBotAudioPlaying ||
                  (stateRef.current.isMuted && stateRef.current.isBotAudioPlaying)) &&
                !stateRef.current.isPaused
              ) {
                chunksRef.current.push({
                  timestamp: Math.floor(Date.now() / 1000),
                  data: event.data,
                })
                let ind = findFirstLargerTimestamp()
                if (ind !== -1) {
                  let newChunks = chunksRef.current.slice(ind)
                  chunksRef.current = newChunks
                }
              } else {
                console.log('Bot audio playing or paused')
              }
            }
          }
        }

        mediaRecorderRef.current.start(CHUNK_DURATION)
        sendingIntervalRef.current = setInterval(() => {
          accumulateDataChunks()
          sendAudioData(meetingInstanceId)
        }, QUEUE_PEEK_INTERVAL)
        sourceIdEventSource.current = null

        doYouWantToContinueTimeoutRef.current = setTimeout(() => {
          extendMeeting()
        }, INITIAL_DO_YOU_WANT_TO_CONTINUE_TIMEOUT)

        // // Start heartbeat
        // heartbeatIntervalRef.current = setInterval(() => {
        //   sendHeartbeat(meetingInstanceId)
        // }, HEARTBEAT_INTERVAL)
      }
      return true
    } catch (err) {
      console.log('err', err)
      const hasBillingError = err?.response?.data?.error === 'billing_error'
      console.log('hasBillingError', hasBillingError)

      if (hasBillingError) {
        const billingStatus = err?.response?.data?.billing_status
        let title = ''
        if (billingStatus === 'SUBSCRIPTION_CANCELLED_PAYMENT_OVER_30_DAYS') {
          title = "You don't have a subscription"
        } else if (billingStatus === 'SUBSCRIPTION_VALID_PAYMENT_OVER_30_DAYS') {
          title = "You don't have a subscription"
        } else if (billingStatus === 'FREE_TRIAL_LIMIT_EXCEEDED') {
          title = "You've used up your free trial time"
        } else if (billingStatus === 'PAID_SUBSCRIPTION_LIMIT_EXCEEDED') {
          title = "You've used up your subscription time"
          setShowBillingModal(true)
        } else {
          // NO_SUBSCRIPTION_STATUS
          // SUBSCRIPTION_CANCELLED_NO_PAYMENT
          title = 'Billing error'
        }
        const result = await Swal.fire({
          title: title,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } else {
        let title = ''
        if (source === 'dplus') {
          if (hasDesktopApp) {
            title = 'Something went wrong, please try again later'
          } else {
            title = t('Common:brainstorming.shareAudioErrorMessage')
          }
        } else {
          title = 'Something went wrong, please try again later'
        }

        const result = await Swal.fire({
          title: title,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
      return false
    }
  }

  const redistributeTopics = async (meetingInstanceId) => {
    if (
      stateRef.current.isPaused ||
      stateRef.current.isMindMapLocked ||
      stateRef.current.progressState !== 'exploration'
    ) {
      return
    }

    const divBeforeMindMap = document.querySelector('#mindmap-beginning')
    if (divBeforeMindMap) {
      const rect = divBeforeMindMap.getBoundingClientRect()
      const top = rect.top

      const mindmapParent = document.querySelector('.floatingedges')
      if (mindmapParent) {
        mindmapParent.style.height = `calc(100vh - ${top}px)`
      }
    }

    dispatch({ type: 'SET_IS_UPDATING_MM', payload: true })
    try {
      const response = await postHttpRequest(
        `/brainstorm/mind_map_topic_redistribution/${meetingInstanceId}`,
        {
          language_setting: stateRef.current.language,
        },
      )
      if (response.mindmap && !stateRef.current.isMindMapLocked) {
        response.mindmap.problem = stateRef.current.primaryProblem
        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: response.mindmap,
          auto: true,
        })

        setTimeout(() => {
          fitViewToImportantNodes()
        }, 1000)
      }
    } catch (error) {
      console.error('Error redistributing topics:', error)
    } finally {
      dispatch({ type: 'SET_IS_UPDATING_MM', payload: false })
    }
  }

  const generateTopicsV2 = async (meetingInstanceId, initialRequest = false) => {
    if (
      stateRef.current.isPaused ||
      stateRef.current.isMindMapLocked ||
      stateRef.current.progressState !== 'exploration'
    ) {
      return null
    }

    const divBeforeMindMap = document.querySelector('#mindmap-beginning')
    if (divBeforeMindMap) {
      const rect = divBeforeMindMap.getBoundingClientRect()
      const top = rect.top

      const mindmapParent = document.querySelector('.floatingedges')
      if (mindmapParent) {
        mindmapParent.style.height = `calc(100vh - ${top}px)`
      }
    }

    dispatch({ type: 'SET_IS_UPDATING_MM', payload: true })
    try {
      const response = await postHttpRequest(
        `/brainstorm/mind_map_topic_generation/${meetingInstanceId}`,
        {
          language_setting: stateRef.current.language,
          is_initial_request: initialRequest,
        },
      )
      if (
        response.mindmap &&
        response.mindmap.action_plans.length > 0 &&
        !stateRef.current.isMindMapLocked
      ) {
        response.mindmap.problem = stateRef.current.primaryProblem
        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: response.mindmap,
          auto: true,
        })
      }
      return response
    } catch (error) {
      console.error('Error generating topics:', error)
      return null
    } finally {
      dispatch({ type: 'SET_IS_UPDATING_MM', payload: false })
    }
  }

  const generateItemsV2 = async (meetingInstanceId) => {
    if (
      stateRef.current.isPaused ||
      stateRef.current.isMindMapLocked ||
      stateRef.current.progressState !== 'exploration'
    ) {
      return
    }

    const divBeforeMindMap = document.querySelector('#mindmap-beginning')
    if (divBeforeMindMap) {
      const rect = divBeforeMindMap.getBoundingClientRect()
      const top = rect.top

      const mindmapParent = document.querySelector('.floatingedges')
      if (mindmapParent) {
        mindmapParent.style.height = `calc(100vh - ${top}px)`
      }
    }

    dispatch({ type: 'SET_IS_UPDATING_MM', payload: true })
    try {
      const response = await postHttpRequest(
        `/brainstorm/mind_map_item_generation_v3/${meetingInstanceId}`,
        {
          language_setting: stateRef.current.language,
        },
      )

      if (response.mindmap && !stateRef.current.isMindMapLocked) {
        response.mindmap.action_plans = markNewItemsV2(response.mindmap.action_plans)
        response.mindmap.problem = stateRef.current.primaryProblem

        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: response.mindmap,
          auto: true,
        })

        // Doing this in a timeout for now because it needs to run after the above dispatch() has
        // been fully executed.
        setTimeout(() => {
          handleZoomToNewNode()
        }, 500)
      }
      if (!response.problems && response.status == 403 && response.error == 'refresh') {
        // Only clear the items schedule
        apiQueueManager.current.clearSchedule('generateItems')

        // Clear any existing timeout before setting a new one
        if (retryItemsTimeoutRef.current) {
          clearTimeout(retryItemsTimeoutRef.current)
        }

        retryItemsTimeoutRef.current = setTimeout(() => {
          apiQueueManager.current.addToQueue('retryItems', () =>
            generateItemsV2(stateRef.current.meetingInstanceId),
          )

          apiQueueManager.current.scheduleApiCall(
            'generateItems',
            () => generateItemsV2(stateRef.current.meetingInstanceId),
            MIND_MAP_ITEM_GENERATION_INTERVAL,
            0, // no initial delay needed here
            false,
          )
        }, 5000)

        return
      }

      try {
        if (stateRef.current.discardedList.length > 0) {
          await postHttpRequest(`/brainstorm/update_discarded_list/${meetingInstanceId}`, {
            discardedList: stateRef.current.discardedList,
          })
        }
      } catch (error) {
        console.log('error update_discarded_list: ', error)
      }
    } catch (error) {
      console.error('Error generating items v2:', error)
    } finally {
      dispatch({ type: 'SET_IS_UPDATING_MM', payload: false })
    }
  }

  const reDistributeParkingLot = async (meetingInstanceId) => {
    try {
      const response = await postHttpRequest(
        `/brainstorm/redistribute_parking_lot/${meetingInstanceId}`,
        {
          language_setting: stateRef.current.language,
        },
      )
      if (response.mindmap && !stateRef.current.isMindMapLocked) {
        response.mindmap.problem = stateRef.current.primaryProblem
        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: response.mindmap,
          auto: true,
        })
      }
    } catch (error) {
      console.error('Error re-distributing parking lot:', error)
    }
  }

  const removeContradictoryItems = async (meetingInstanceId) => {
    try {
      const response = await postHttpRequest(
        `/brainstorm/remove_contradictory_items/${meetingInstanceId}`,
        {
          language_setting: stateRef.current.language,
        },
      )
      // console.log('response removeContradictoryItems:', JSON.parse(response.debug))

      if (response.mindmap && !stateRef.current.isMindMapLocked) {
        console.log('11111111')
        response.mindmap.problem = stateRef.current.primaryProblem
        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: response.mindmap,
          auto: true,
        })
      }
    } catch (error) {
      console.error('Error removing contradictory items:', error)
    }
  }

  const addUserTopic = async (meetingInstanceId, topicText) => {
    try {
      const response = await postHttpRequest(`/brainstorm/add_user_topic/${meetingInstanceId}`, {
        language_setting: stateRef.current.language,
        topic_text: topicText,
      })
      if (response.mindmap && !stateRef.current.isMindMapLocked) {
        console.log('2222')
        response.mindmap.problem = stateRef.current.primaryProblem
        dispatch({
          type: 'SET_BRAINSTORM_MAP',
          brainstormMap: response.mindmap,
          auto: true,
        })
      }
    } catch (error) {
      console.log('error addUserTopic: ', error)
    }
  }

  const initAddUserTopic = async (topicText) => {
    console.log('apiQueueManager.current: ', apiQueueManager.current)
    apiQueueManager.current.addToFrontOfQueue('addUserTopic', () =>
      addUserTopic(stateRef.current.meetingInstanceId, topicText),
    )
  }

  // const removeDuplicateItems = async (meetingInstanceId, finalCall = false) => {
  //   if (
  //     (stateRef.current.isPaused && !finalCall) ||
  //     stateRef.current.isMindMapLocked ||
  //     stateRef.current.progressState !== 'exploration'
  //   ) {
  //     return
  //   }
  //   dispatch({ type: 'SET_IS_UPDATING_MM', payload: true })
  //   dispatch({ type: 'SET_IS_REMOVING_DUPLICATE_ITEMS', payload: true })
  //   try {
  //     const response = await postHttpRequest(`/brainstorm/mind_map_items_replacement/${meetingInstanceId}`, {
  //       language_setting: stateRef.current.language,
  //     })
  //     if (response.mindmap && !stateRef.current.isMindMapLocked){
  //       response.mindmap.problem = stateRef.current.primaryProblem
  //       dispatch({
  //         type: 'SET_BRAINSTORM_MAP',
  //         brainstormMap: response.mindmap,
  //         auto: true,
  //       })
  //     }
  //     console.log('response removeDuplicateItems:', JSON.parse(response.debug))
  //   } catch (error) {
  //     console.error('Error replacing items:', error)
  //   } finally {
  //     dispatch({ type: 'SET_IS_UPDATING_MM', payload: false })
  //     dispatch({ type: 'SET_IS_REMOVING_DUPLICATE_ITEMS', payload: false })
  //   }
  // }

  const cleanup = () => {
    console.log('queue cleanup??')
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()

      audioContextRef.current.close()
    }

    if (sendingIntervalRef.current) {
      clearInterval(sendingIntervalRef.current)
    }
    if (dataIntervalRef.current) {
      clearInterval(dataIntervalRef.current)
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (shortTermSummaryIntervalRef.current) {
      clearInterval(shortTermSummaryIntervalRef.current)
    }
    if (problemGenerationIntervalRef.current) {
      clearInterval(problemGenerationIntervalRef.current)
    }
    if (deviatedConversationDetectionTimeoutRef.current) {
      clearTimeout(deviatedConversationDetectionTimeoutRef.current)
    }
    if (topicsGenerationIntervalRef.current) {
      clearInterval(topicsGenerationIntervalRef.current)
    }
    if (mindMapRecommendationTimeoutRef.current) {
      clearTimeout(mindMapRecommendationTimeoutRef.current)
    }
    if (initialDelayRef.current) {
      clearTimeout(initialDelayRef.current)
    }
    if (researchGenerationIntervalRef.current) {
      clearInterval(researchGenerationIntervalRef.current)
    }

    if (topicTimeoutRef.current) {
      clearTimeout(topicTimeoutRef.current)
    }

    if (itemTimeoutRef.current) {
      clearTimeout(itemTimeoutRef.current)
    }

    if (mindmapItemGenerationTimeoutRef.current) {
      clearTimeout(mindmapItemGenerationTimeoutRef.current)
    }

    if (mindmapItemGenerationIntervalRef.current) {
      clearInterval(mindmapItemGenerationIntervalRef.current)
    }

    if (mindmapTopicGenerationIntervalRef.current) {
      clearInterval(mindmapTopicGenerationIntervalRef.current)
    }

    if (topicsRedistributionIntervalRef.current) {
      clearInterval(topicsRedistributionIntervalRef.current)
    }

    if (topicRedistributionTimeoutRef.current) {
      clearTimeout(topicRedistributionTimeoutRef.current)
    }

    if (displayStreamRef.current) {
      displayStreamRef.current.getTracks().forEach((track) => track.stop())
    }

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => track.stop())
    }

    // Clean up any remaining API queue schedules
    if (apiQueueManager.current) {
      apiQueueManager.current.cleanup()
    }

    // if (heartbeatIntervalRef.current) {
    //   clearInterval(heartbeatIntervalRef.current)
    // }

    if (desktopHeartbeatIntervalRef.current) {
      clearInterval(desktopHeartbeatIntervalRef.current)
    }

    if (doYouWantToContinueTimeoutRef.current) {
      clearTimeout(doYouWantToContinueTimeoutRef.current)
    }

    if (sendAduioTimeoutRef.current) {
      clearTimeout(sendAduioTimeoutRef.current)
    }

    if (retryItemsTimeoutRef.current) {
      clearTimeout(retryItemsTimeoutRef.current)
    }

    isSwalOpenRef.current = false
  }

  const stopRecording = async () => {
    cleanup()

    const response = await postHttpRequest('/brainstorm/start_stop_brainstorm', {
      isStopRecording: true,
      meetingInstanceId: stateRef.current.meetingInstanceId,
      isBrainstorming: true,
    })
    if (response.error) {
      console.error('Error stopping recording:', response.error)
    }

    // reset to false
    isProcessingDataQueue.current = false
    dispatch({
      type: 'STOP_RECORDING',
    })
    localStorage.removeItem('mets')

    if (stateRef.current.hasDesktopApp) {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      // iframe.src = 'zisiapp://stopRecording'
      iframe.src = `${process.env.REACT_APP_ZISI_APP_NAME || 'zisiapp'}://stopRecording`
      document.body.appendChild(iframe)
      setTimeout(() => document.body.removeChild(iframe), 500)
    }
  }

  const accumulateDataChunks = () => {
    if (chunksRef.current.length === 0 || headerChunkRef.current === null) return

    const formData = new FormData()
    const blobParts = [headerChunkRef.current].concat(chunksRef.current.map((chunk) => chunk.data))
    const combinedBlob = new Blob(blobParts, { type: 'audio/webm' })
    formData.append('audio', combinedBlob, 'combined_chunks.webm')
    formData.append('timestamp_start', chunksRef.current[0].timestamp.toString())
    formData.append(
      'timestamp_end',
      chunksRef.current[chunksRef.current.length - 1].timestamp.toString(),
    )
    formData.append('mode', 'stream')
    formData.append('language_setting', stateRef.current.language)
    dataQueue.current = []
    dataQueue.current.push({ data: formData })
    if (stateRef.current.isPaused) {
      dataQueue.current = []
    }
    console.log('dataQueue.current: ', dataQueue.current)
  }

  // this won;t be called in readOnly mode because vad stream is not set in this case..
  // or startRecording is not called in readOnly mode so no more inteval, mediaRecorder etc..
  const sendAudioData = async (
    meetingInstanceId,
    user_init = false,
    calledBeforeButtonClick = false,
  ) => {
    try {
      console.log('123')
      if (
        dataQueue.current.length === 0 ||
        (isProcessingDataQueue.current && !calledBeforeButtonClick) ||
        (stateRef.current.isBotAudioPlaying && !stateRef.current.isMuted) ||
        stateRef.current.isPaused
      )
        return

      if (isProcessingDataQueue.current && calledBeforeButtonClick) {
        console.log('wwwww into new timeout')
        if (sendAduioTimeoutRef.current) {
          clearTimeout(sendAduioTimeoutRef.current)
        }
        sendAduioTimeoutRef.current = setTimeout(() => {
          sendAudioData(meetingInstanceId, user_init, calledBeforeButtonClick)
        }, 300)
      }

      let formData = dataQueue.current.shift()
      console.log('321')
      isProcessingDataQueue.current = true

      // we don't need lock for deviation detetion endpoint because deviation detetion audio is added in batch
      // so it's impossible to have user-init audio added in the middle of the batch

      // some cases to justifying why not moving it to the if below:
      // 1. let's say immediately after user says the words (processing), deviation detection returns audios,
      // since it's not locked, it will first say 'may I jump in', then it could be locked because audio_v2/event_source now returns with quesiton_ideas
      // at this point, it will start adding user-init audio to the queue but what about the steer audio?
      // will the steer audio be added to the queue? before lock is released?
      // unless we update the logic of when to release steerAudio?

      // handle case where auto sendAudioData will overwrite the lock sourceid of user-init
      if (user_init) {
        sourceIdEventSource.current = requestQueueAccess(true)
        // always overwrite the lock if it's user-init.. so that sourceIdEventSource.current is always the latest
        lockAccess(sourceIdEventSource.current)
      }

      lastAudioApiCall.current = Math.floor(Date.now() / 1000)
      let response = await postHttpRequest(
        `/meeting/process_audio_chunks_v2/${meetingInstanceId}/${user_init}`,
        formData.data,
      )

      console.log('response.request: ', response.request)
      // explicitly check for all these conditions
      if (
        response.encoded_user_request_str &&
        response.request === 'question_ideas' &&
        (response.question_number !== null || response.question_number !== undefined)
      ) {
        // cancel user init
        // dispatch({ type: 'SET_ENCODED_REQUEST', encodedRequest: response.encoded_user_request_str })
        let num = parseInt(response.question_number) - 1
        setSelectedQuestionNumber(num >= stateRef.current.subQuestions.length ? 0 : num)
      } else {
        if (user_init) {
          // release lock if it's user-init and not question_ideas
          if (sourceIdEventSource.current) {
            releaseLock(sourceIdEventSource.current)
            sourceIdEventSource.current = null
          }
        } else {
          if (response.request === 'auto') {
            console.log('auto')
          } else {
            // this will cancel eventsource
            // not sure if it's needed here thoough
            dispatch({ type: 'SET_ENCODED_REQUEST', encodedRequest: '' })
          }
        }
      }
      isProcessingDataQueue.current = false
      console.log('Chunks sent successfully')

      // return response.recommendation
      // dispatch({ type: 'SET_RECOMMENDATION', recommendation: response?.recommendation ?? '' })
    } catch (error) {
      console.error('Error sending chunks to API:', error)
      console.log(error.response.status)
      isProcessingDataQueue.current = false
      dispatch({ type: 'SET_RECOMMENDATION', recommendation: '' })
      if (sourceIdEventSource.current) {
        releaseLock(sourceIdEventSource.current)
        sourceIdEventSource.current = null
      }
    }
  }

  const exploreProblems = async (badQuestion = null, isRefreshQuestions = false) => {
    console.log('questions: ', stateRef.current.problems)
    let ts = Math.floor(Date.now() / 1000) - 3 // hacky way....
    // accumulateDataChunks()
    // await sendAudioData(stateRef.current.meetingInstanceId)
    console.log('qqqqqqqqqqqqq')
    dispatch({ type: 'SET_IS_GENERATING_PROBLEMS', isGeneratingProblems: true })
    try {
      const response = await postHttpRequest(
        `/brainstorm/expand_questions/${stateRef.current.meetingInstanceId}`,
        {
          questions: stateRef.current.primaryProblem,
          timestamp: ts,
          questionToBeRefreshed: badQuestion,
          language_setting: stateRef.current.language,
        },
      )
      console.log('ppppppppppppppppp')
      // if(response.problems){

      // }

      if (response.sub_questions?.length > 0) {
        // console.log('debug_dict: ', response.debug_dict)
        dispatch({ type: 'SET_SUB_QUESTIONS', subQuestions: response.sub_questions })
        if (stateRef.current.progressState === 'exploration') {
          console.log('second time')
        } else {
          dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'exploration' })
        }
        if (!isRefreshQuestions) {
          let placeholder = {
            problem: stateRef.current.primaryProblem,
            action_plans: [],
          }
          dispatch({ type: 'SET_BRAINSTORM_MAP', brainstormMap: placeholder })
        }
      }
    } catch (error) {
      console.error('Error generating problems:', error)
    }
    dispatch({ type: 'SET_IS_GENERATING_PROBLEMS', isGeneratingProblems: false })
  }

  // sleep time expects milliseconds
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  const generateResearch = async () => {
    if (!stateRef.current.isPaused) {
      try {
        if (stateRef.current.brainstormMap?.action_plans?.length > 0) {
          const res = await postHttpRequest(`/brainstorm/generate_research`, {
            brainstormMap: JSON.stringify(stateRef.current.brainstormMap?.action_plans),
            language_setting: stateRef.current.language,
            meetingInstanceId: stateRef.current.meetingInstanceId,
            problem_statement: stateRef.current.primaryProblem,
            zisiPersona: stateRef.current.zisiPersona,
          })
          const response = res?.response
          if (res.status == 200 && response?.research_content) {
            dispatch({ type: 'SET_RESEARCH_CONTENT', research_content: response.research_content })
          } else {
            dispatch({ type: 'SET_RESEARCH_CONTENT', research_content: null })
          }
        }
      } catch (error) {
        console.log('error generate_research: ', error)
      }
    }
  }

  return (
    <BrainstormContext.Provider
      value={{
        state,
        dispatch,
        startRecording,
        stopRecording,
        exploreProblems,
        // revertBrainstormMap,
        accumulateDataChunks,
        sendAudioData,
        generateResearch,
        source,
        // removeDuplicateItems,
        removeContradictoryItems,
        initAddUserTopic,
        reDistributeParkingLot,
      }}
    >
      {children}
      {showBillingModal && <BillingModal closeModal={() => setShowBillingModal(false)} />}
    </BrainstormContext.Provider>
  )
}

const useBrainstorm = () => {
  const context = React.useContext(BrainstormContext)
  if (!context) {
    throw new Error('useBrainstorm must be used within a BrainstormProvider.')
  }
  return context
}

export { BrainstormProvider, useBrainstorm }
