import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import './styles.scss'
import { useTranslation } from 'react-i18next'

function DecisionLogSelectMenu(props) {
  const { t } = useTranslation(['MeetingHub'])

  return (
    <div>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: !props.aiGenPendingApproval ? 'white' : '#f2f2f2',
              border: 'none',
              padding: '0',
            }}
          >
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
              arrow_drop_down
            </span>
          </MenuButton>
        }
      >
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.onSelectAll()
          }}
        >
          {t('decisionLog.all')}
        </MenuItem>
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.onSelectNone()
          }}
        >
          {t('decisionLog.none')}
        </MenuItem>
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.onSelectOnlyAI()
          }}
        >
          {t('decisionLog.ai')}
        </MenuItem>
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.onSelectOnlyManuallyAdded()
          }}
        >
          {t('decisionLog.manuallyAdded')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default DecisionLogSelectMenu
