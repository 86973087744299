import React, { PureComponent } from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts'
import styles from './Charts.module.scss'
import { useTranslation } from 'react-i18next'

const COLORS = ['#F49782', '#FBDCD5']
// const COLORS = ['#A9D18E', '#F9BD8A '];

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y + 10}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const renderKPICustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.1
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)
  if (value === 0) return <></>
  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`} ({value})
    </text>
  )
}

const PieCharCustomizedLabel = ({ data, kpi = false }) => {
  const { t } = useTranslation(['Common'])
  if (kpi) {
    COLORS[0] = '#FF0000'
    COLORS[1] = '#FFD700'
    COLORS[2] = '#008000'
    COLORS.push('#808080')
  }

  const customLegendFormatter = (value, entry) => {
    const { payload } = entry
    return `${payload.name}: ${(payload.percent * 100).toFixed(0)}% (${payload.value})`
  }

  return (
    <ResponsiveContainer
      width={kpi ? '40%' : '100%'}
      height="100%"
      className={styles.customStyling}
    >
      <PieChart width={400} height={kpi ? 300 : 400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          // label={kpi ? renderKPICustomizedLabel : renderCustomizedLabel}
          outerRadius={kpi ? 100 : 80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {/* <Tooltip/> */}
        <Legend
          formatter={customLegendFormatter}
          verticalAlign="middle"
          align="right"
          layout="vertical"
          wrapperStyle={{
            fontSize: '12px',
            paddingLeft: '10px',
          }}
        />
        {kpi && (
          <text
            x="40%"
            y="8%"
            fill="black"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize="14"
            fontWeight="bold"
          >
            {t('kpi.kpiPieChartTitle')}
          </text>
        )}
        {kpi && (
          <text
            x="15%"
            y="85%"
            fill="black"
            alignmentBaseline="middle"
            fontSize="13"
            fontWeight="lighter"
          >
            {t('kpi.kpiGrey')}
          </text>
        )}
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PieCharCustomizedLabel
