import ReactGA from 'react-ga4'

let isInitialized = false
const DEFAULT_APP_CONTEXT = 'Phoenix'

// Initialize GA4 with your measurement ID
export const initGA = (measurementId) => {
  if (!isInitialized && measurementId) {
    try {
      ReactGA.initialize(measurementId, {
        gaOptions: {
          appVersion: process.env.REACT_APP_VERSION || '1.0.0',
        },
        testMode: process.env.NODE_ENV !== 'production',
        debug: process.env.NODE_ENV !== 'production', // Enable debug mode in non-production
      })
      isInitialized = true
      console.log('GA4 initialized successfully')
    } catch (error) {
      console.error('Failed to initialize GA4:', error)
    }
  }
}

// Track page views using GA4 format
export const trackPageView = (path) => {
  if (!isInitialized) {
    console.warn('GA4 not initialized. Page view not tracked:', path)
    return
  }

  try {
    // GA4 page_view event
    ReactGA.event('page_view', {
      page_location: window.location.href,
      page_path: path,
      page_title: document.title,
      app_context: DEFAULT_APP_CONTEXT,
      timestamp: new Date().toISOString(),
      environment: process.env.NODE_ENV,
      app_version: process.env.REACT_APP_VERSION || '1.0.0',
    })
  } catch (error) {
    console.error('Failed to track page view:', error)
  }
}

// Base event tracking function using GA4 format
export const trackEvent = (eventName, params = {}) => {
  if (!isInitialized) {
    console.warn('GA4 not initialized. Event not tracked:', eventName)
    return
  }

  try {
    // Send event to GA4 with standardized parameters
    ReactGA.event(eventName, {
      app_context: DEFAULT_APP_CONTEXT,
      timestamp: new Date().toISOString(),
      environment: process.env.NODE_ENV,
      app_version: process.env.REACT_APP_VERSION || '1.0.0',
      ...params,
    })
  } catch (error) {
    console.error('Failed to track event:', error)
  }
}

// Helper functions for common events
export const trackAuthEvent = (action, params = {}) => {
  trackEvent('auth_event', {
    auth_action: action,
    ...params,
  })
}

export const trackFeatureUsage = (featureName, action, params = {}) => {
  trackEvent('feature_interaction', {
    feature_name: featureName,
    interaction_type: action,
    ...params,
  })
}
