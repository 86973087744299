import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import { formatTimestampToMonthDayYear } from '../../../../utils/time'
import { useBrainstorm } from '../../Recorder/BrainstormContext'
import Select from 'react-select'
import TextArea from 'rc-textarea'
import CreatableSelect from 'react-select/creatable'
import { getCompanyConfigSettings, getConsolidatedConfigSettings } from '../../../../utils/auth'
import Swal from 'sweetalert2'
import BrainstormSessionDropdownMenu from '../BrainstormSessionDropdownMenu'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  getDesktopAppDownloadedFlag,
  updateDesktopAppDownloadedFlag,
} from '../../../../api/services/brainstorm.services'
import InPageLoader from '../../../../phoenix/components/Loader'

//import '../../../../tailwind.css'

const BrainstormSessionSetupWizard = ({
  onComplete,
  isFullVersion,
  appLanguage = null,
  user_brainstorm_count = 0,
  source,
}) => {
  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const location = useLocation()
  const userId = localStorage.getItem('tfei')

  // Determine initial tab value
  const getInitialTab = () => {
    if (location.pathname === '/brainstorming/previous_sessions') {
      return 'recent'
    }
    // Default to 'new' for both /brainstorming and /brainstorming/new_session
    return 'new'
  }

  const hideDesktopAppDownloadPopup = getConsolidatedConfigSettings(
    'hide_desktop_app_download_popup',
  )
  console.log('hideDesktopAppDownloadPopup', hideDesktopAppDownloadPopup)

  const [activeTab, setActiveTab] = useState(getInitialTab())
  const [showValidation, setShowValidation] = useState(false)
  const [selectedSession, setSelectedSession] = useState(null)

  const [brainstormSessions, setBrainstormSessions] = React.useState([])
  const [filteredBrainstormSessions, setFilteredBrainstormSessions] = React.useState([])
  const [meetingsOnSessions, setMeetingsOnSessions] = useState({})
  const [tagsOnSessions, setTagsOnSessions] = useState({})

  const [brainStormingTemplateOptions, setBrainStormingTemplateOtions] = React.useState(null)
  const [selectedTemplate, setSelectedTemplate] = React.useState(null)
  const [isReadyToRecord, setIsReadyToRecord] = useState(false)
  const [isRecentSessionsLoading, setIsRecentSessionsLoading] = useState(false)

  const language = isFullVersion
    ? getCompanyConfigSettings('languageSettings')
    : (appLanguage ?? 'en')

  const { state, dispatch, startRecording } = useBrainstorm()

  const [selectedTagsOnSessions, setSelectedTagsOnSessions] = React.useState(null)

  React.useEffect(() => {
    getBrainstormSessions()
    getBrainStormingTemplate()
    getDesktopAppDownloadedStatus()
  }, [])

  React.useEffect(() => {
    if (selectedTagsOnSessions) {
      const filteredSessions = brainstormSessions.filter((session) => {
        return session.tags.some((tag) => selectedTagsOnSessions.tagId === tag.tagId)
      })
      setFilteredBrainstormSessions(filteredSessions)
    } else {
      setFilteredBrainstormSessions(brainstormSessions)
    }
  }, [selectedTagsOnSessions])

  const getDesktopAppDownloadedStatus = async () => {
    if (source == 'zisi') {
      dispatch({ type: 'SET_DESKTOP_APP_AVAILABLE', hasDesktopApp: true })
      return
    }
    const data = await getDesktopAppDownloadedFlag()
    console.log('data', data)
    if (data.has_downloaded_desktop_app && data.desktop_app_logged_in) {
      dispatch({ type: 'SET_DESKTOP_APP_AVAILABLE', hasDesktopApp: true })
    } else if (data.has_downloaded_desktop_app && !data.desktop_app_logged_in) {
      dispatch({ type: 'SET_DESKTOP_APP_AVAILABLE', hasDesktopApp: false })
      //show a pop up to remind user set up the desktop app
      Swal.fire({
        title: t('Common:brainstormSessionSetupWizard.setupDesktopApp'),
        text: t('Common:brainstormSessionSetupWizard.setupDesktopAppDescription'),
        icon: 'info',
        confirmButtonText: t('Common:brainstormSessionSetupWizard.ok'),
      })
    } else if (!hideDesktopAppDownloadPopup) {
      dispatch({ type: 'SET_DESKTOP_APP_AVAILABLE', hasDesktopApp: false })

      // Check if user is on Windows platform
      const os = navigator.userAgentData.platform
      const isWindows = os === 'Windows'

      // Configure custom buttons for SweetAlert
      const sweetAlertOptions = {
        title: t('Common:brainstormSessionSetupWizard.downloadDesktopApp'),
        text: t('Common:brainstormSessionSetupWizard.downloadDesktopAppPopup'),
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('Common:brainstormSessionSetupWizard.no'),
        footer: `
          <div style="
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
          ">
            <input 
              type="checkbox" 
              id="dontShowAgain" 
              style="margin-right: 8px;"
            >
            <label for="dontShowAgain">${t('Common:brainstormSessionSetupWizard.dontShowAgain')}</label>
          </div>
        `,
        didOpen: () => {
          // Add click handler for the checkbox
          document.getElementById('dontShowAgain').addEventListener('click', (e) => {
            e.stopPropagation()
          })
        },
      }

      // Add different button options depending on platform
      if (isWindows) {
        // For Windows users, add a specific MS Store option
        sweetAlertOptions.showDenyButton = true
        sweetAlertOptions.confirmButtonText = t(
          'Common:brainstormSessionSetupWizard.downloadFromMSStore',
        )
        sweetAlertOptions.denyButtonText = t('Common:brainstormSessionSetupWizard.downloadDirectly')
        // Add custom class to make the deny button blue
        sweetAlertOptions.width = '50vw'
        sweetAlertOptions.customClass = {
          denyButton: 'swal2-deny swal2-styled swal2-blue',
          confirmButton: 'swal2-confirm swal2-styled swal2-blue',
        }
        // Add custom styling for the blue button
        const style = document.createElement('style')
        style.innerHTML = `
          .swal2-blue {
            background-color: #3085d6 !important;
            color: white !important;
          }
        `
        document.head.appendChild(style)
      } else {
        // For non-Windows users, just the standard Yes button
        sweetAlertOptions.confirmButtonText = t('Common:brainstormSessionSetupWizard.yes')
      }

      const result = await Swal.fire(sweetAlertOptions)

      // Get checkbox state
      const dontShowAgain = document.getElementById('dontShowAgain')?.checked

      if (result.isConfirmed || result.isDenied) {
        // User clicked either confirm or deny (both are download options)
        await updateDesktopAppDownloadedFlag({
          has_downloaded_desktop_app: true,
          hideDesktopAppDownloadPopup: dontShowAgain,
        })

        // Update the local storage
        localStorage.setItem(
          'cfg',
          JSON.stringify({
            ...JSON.parse(localStorage.getItem('cfg')),
            hide_desktop_app_download_popup: dontShowAgain,
          }),
        )

        if (os === 'macOS') {
          window.open(
            'https://dplus.transforml.co/downloads/dplus/macos/Decision+ZisiCompanion_v1.dmg',
            '_blank',
          )
        } else if (os === 'Windows') {
          if (result.isDenied) {
            //down load directly
            window.open(
              'https://dplus.transforml.co/downloads/dplus/windows/Decision+ZisiCompanion_v1.msi',
              '_blank',
            )
          } else {
            // Regular download option was clicked
            window.open('ms-windows-store://pdp/?productid=9PFV3QPZWM70', '_blank')
          }
        }
      } else if (dontShowAgain && !result.isConfirmed && !result.isDenied) {
        // User clicked Cancel but checked "Don't show again"
        await updateDesktopAppDownloadedFlag({
          has_downloaded_desktop_app: false,
          hideDesktopAppDownloadPopup: true,
        })
        // Update the local storage
        localStorage.setItem(
          'cfg',
          JSON.stringify({
            ...JSON.parse(localStorage.getItem('cfg')),
            hide_desktop_app_download_popup: true,
          }),
        )
      }
    }
  }

  // const checkDesktopAppAvailability = async () => {
  //   try {
  //     dispatch({ type: 'SET_DESKTOP_APP_AVAILABLE', hasDesktopApp: true })
  //     await sleep(300)
  //   } catch (error) {
  //     console.log('checkDesktopAppAvailability error', error)
  //   }
  // }

  const personaOptions = [
    { value: 2, label: t('Common:brainstormSessionSetupWizard.devilsAdvocate') },
    { value: 0, label: t('Common:brainstormSessionSetupWizard.facilitator') },
    { value: 1, label: t('Common:brainstormSessionSetupWizard.audienceMember') },
  ]

  const [formData, setFormData] = useState({
    problemStatement: '',
    language: language,
    meetingType: 'Online',
    consent: false,
    selectedSession: null,
    zisiPersona: 2,
  })

  const handleFormChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }))

    if (field === 'problemStatement') {
      dispatch({ type: 'UPDATE_PRIMARY_PROBLEM', problem: value })
    }

    if (field === 'language') {
      dispatch({ type: 'SET_LANGUAGE', language: value })
    }
    if (field === 'meetingType') {
      dispatch({ type: 'SET_USE_MIC_ONLY', payload: value !== 'Online' })
    }
  }

  const isFormValid = () => {
    return (
      //formData.aiSuggestions &&
      formData.language &&
      ((formData.meetingType === 'Online' && formData.consent) ||
        formData.meetingType === 'In Person')
    )
  }

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  React.useEffect(() => {
    ;(async () => {
      if (isReadyToRecord) {
        // if(source === 'zisi'){
        //   await checkDesktopAppAvailability()
        // }
        const record = async () => {
          let canContinue = await startRecording(formData.zisiPersona)
          formData.activeTab = activeTab
          if (canContinue !== false) {
            onComplete(formData)
          }
          setIsReadyToRecord(false)
        }
        await record()
      }
    })()
  }, [isReadyToRecord])

  //After we select a session, we want to view it right away, no middleman button
  // React.useEffect(() => {
  //   if (selectedSession) {
  //     handleViewSession()
  //   }
  // }, [selectedSession])

  const handleGetStarted = () => {
    setShowValidation(true)
    if (isFormValid()) {
      setIsReadyToRecord(true)
    }
  }

  const handleViewSession = (session) => {
    if (session) {
      formData.selectedSession = session
      formData.activeTab = activeTab
      onComplete(formData)
    }
  }

  const deleteSessionLocal = (session) => {
    let tempBrainstormSessions = JSON.parse(JSON.stringify(brainstormSessions))
    tempBrainstormSessions.forEach((brainstormSession) => {
      if (brainstormSession.brainstormId === session.brainstormId) {
        brainstormSession.isDeleted = true
      }
    })
    setBrainstormSessions(tempBrainstormSessions)
  }

  const ValidationMessage = ({ show, message }) =>
    show ? <p className="text-red-500 text-xs mt-1">{message}</p> : null

  const getBrainstormSessions = async (selectedBrainstormId = null) => {
    setIsRecentSessionsLoading(true)
    try {
      const response = await getHttpRequest(`/brainstorm/get_brainstorm_sessions`, {
        params: {
          brainstormId: selectedBrainstormId,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      if (selectedBrainstormId && response) {
        return response
      } else {
        setBrainstormSessions(response.topLevelProblems)
        setFilteredBrainstormSessions(response.topLevelProblems)

        const meetingsMap = new Map()
        const tagsMap = new Map()

        response.topLevelProblems.forEach((problem) => {
          if (problem.meetings) {
            problem.meetings.forEach((meeting) => {
              if (!meetingsMap.has(meeting.meetingId)) {
                meetingsMap.set(meeting.meetingId, meeting)
              }
            })
          }

          if (problem.tags) {
            problem.tags.forEach((tag) => {
              if (!tagsMap.has(tag.tagId)) {
                tagsMap.set(tag.tagId, tag)
              }
            })
          }
        })

        setMeetingsOnSessions(Array.from(meetingsMap.values()))
        setTagsOnSessions(Array.from(tagsMap.values()))
      }
    } catch (error) {
      console.log('getBrainstormSessions error', error)
    } finally {
      setIsRecentSessionsLoading(false)
    }
    return null
  }

  const getBrainStormingTemplate = async () => {
    try {
      const response = await getHttpRequest(`/brainstorm/get_brainstorm_template`)
      if (response) {
        setBrainStormingTemplateOtions(response.brainstormTemplates)
      }
    } catch (error) {
      console.log('getBrainStromingTemplate error', error)
    }
  }

  function isMacUser() {
    let isBrian = true
    if (!isBrian) return false

    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.includes('macintosh') || userAgent.includes('mac os')
  }

  // Sort sessions alphabetically by meetingName
  // const sortedSessions = [...brainstormSessions]?.sort((a, b) => a?.createdAt - b?.createdAt)

  const handleTabChange = (tab) => {
    setActiveTab(tab)
    if (tab === 'new') {
      navigate('/brainstorming/new_session')
    } else {
      navigate('/brainstorming/previous_sessions')
    }
  }

  return (
    <div
      className="w-full min-h-screen flex flex-col bg-gray-100"
      style={{ paddingTop: '2.75rem' }}
    >
      {isFullVersion && <div style={{ marginBottom: '1rem' }}></div>}

      <div
        className="flex flex-col justify-start items-center flex-1 h-full"
        style={{ paddingTop: isFullVersion === false ? '1rem' : '0' }}
      >
        {/* top buttons */}
        <div className="flex justify-center space-x-4 mb-6 flex-row w-1/2">
          <button
            className={`px-4 py-1.5  rounded-lg text-sm w-full ${
              activeTab === 'new' ? 'bg-orange-500 text-white' : 'bg-gray-300'
            }`}
            onClick={() => handleTabChange('new')}
          >
            {t('brainstormSessionSetupWizard.newProblemSolving')}
          </button>
          <button
            className={`px-4 py-1.5  rounded-lg text-sm w-full ${
              activeTab === 'recent' ? 'bg-orange-500 text-white' : 'bg-gray-300'
            }`}
            onClick={() => handleTabChange('recent')}
          >
            {t('brainstormSessionSetupWizard.viewRecentSessions')}
          </button>
        </div>

        {activeTab === 'new' && (
          <>
            <div className="flex flex-column justify-center w-2/3 bg-white rounded-xl shadow-sm border border-gray-200 p-6 mb-8 ">
              <h2
                className="text-2xl font-bold mb-4 w-full text-left"
                style={{ maxWidth: 'unset', margin: '0 0 1rem 0' }}
              >
                {t('brainstormSessionSetupWizard.startNewProblemSolvingSession')}
              </h2>

              <div className="flex flex-row gap-4 justify-between top">
                {/* Language input */}
                <div className="flex flex-column item">
                  <label
                    className="text-base font-semibold mb-3 w-full text-left"
                    style={{ maxWidth: 'unset', marginBottom: '1rem', display: 'block' }}
                  >
                    {t('brainstormSessionSetupWizard.language')}
                  </label>
                  <div className="mb-6">
                    <Select
                      name="language"
                      className={`${showValidation && !formData.language ? 'border-red-500' : ''}`}
                      value={{
                        value: formData.language,
                        label:
                          formData.language === 'en'
                            ? t('brainstormSessionSetupWizard.english')
                            : t('brainstormSessionSetupWizard.spanish'),
                      }}
                      onChange={(option) => handleFormChange('language', option.value)}
                      options={[
                        { value: 'en', label: t('brainstormSessionSetupWizard.english') },
                        { value: 'es', label: t('brainstormSessionSetupWizard.spanish') },
                      ]}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '38px',
                          height: '38px',
                          borderRadius: '0.375rem',
                          borderColor: '#e2e8f0',
                          width: '200px',
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          height: '38px',
                          padding: '0 8px',
                        }),
                        input: (base) => ({
                          ...base,
                          margin: '0px',
                        }),
                      }}
                    />
                    <ValidationMessage
                      show={showValidation && !formData.language}
                      message={t('brainstormSessionSetupWizard.languageError')}
                    />
                  </div>
                </div>
                {/* Zisi persona input */}
                <div className="flex flex-column item">
                  <label
                    className="text-base font-semibold mb-3 w-full text-left"
                    style={{ maxWidth: 'unset', marginBottom: '1rem', display: 'block' }}
                  >
                    {t('brainstormSessionSetupWizard.whichPersona')}
                  </label>
                  <div className="mb-6">
                    <Select
                      name="zisiPersona"
                      value={{
                        value: formData.zisiPersona,
                        label: personaOptions.find(
                          (option) => option.value === formData.zisiPersona,
                        )?.label,
                      }}
                      onChange={(option) => handleFormChange('zisiPersona', option.value)}
                      options={personaOptions}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '38px',
                          height: '38px',
                          borderRadius: '0.375rem',
                          borderColor: '#e2e8f0',
                          width: '200px',
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          height: '38px',
                          padding: '0 8px',
                        }),
                        input: (base) => ({
                          ...base,
                          margin: '0px',
                        }),
                      }}
                    />
                  </div>
                </div>
                {/* Meeting type input*/}
                <div className="flex flex-column item">
                  <label
                    className="text-base font-semibold mb-3 w-full text-left"
                    style={{ maxWidth: 'unset', marginBottom: '1rem', display: 'block' }}
                  >
                    {t('brainstormSessionSetupWizard.howMeetingIsBeingHeld')}
                  </label>
                  <div className="mb-6">
                    <Select
                      name="meetingType"
                      className={`${
                        showValidation && !formData.meetingType ? 'border-red-500' : ''
                      }`}
                      value={{
                        value: formData.meetingType,
                        label:
                          formData.meetingType === 'Online'
                            ? t('brainstormSessionSetupWizard.online')
                            : t('brainstormSessionSetupWizard.inPerson'),
                      }}
                      onChange={(option) => handleFormChange('meetingType', option.value)}
                      options={[
                        { value: 'Online', label: t('brainstormSessionSetupWizard.online') },
                        { value: 'In Person', label: t('brainstormSessionSetupWizard.inPerson') },
                      ]}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '38px',
                          height: '38px',
                          borderRadius: '0.375rem',
                          borderColor: '#e2e8f0',
                          width: '200px',
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          height: '38px',
                          padding: '0 8px',
                        }),
                        input: (base) => ({
                          ...base,
                          margin: '0px',
                        }),
                      }}
                    />
                    <ValidationMessage
                      show={showValidation && !formData.meetingType}
                      message={t('brainstormSessionSetupWizard.meetingTypeError')}
                    />
                  </div>
                </div>
              </div>

              {/* Problem statement input */}
              <div className="mb-6">
                <label
                  className="text-base font-semibold mb-3 w-full text-left"
                  style={{ maxWidth: 'unset', marginBottom: '1rem', display: 'block' }}
                >
                  {t('brainstormSessionSetupWizard.problemStatement')}
                </label>
                <textarea
                  className="w-full h-12 p-2 border border-[#e2e8f0] resize-none rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  //placeholder={t('brainstormSessionSetupWizard.problemStatementPlaceholder')}
                  value={formData.problemStatement}
                  onChange={(e) => handleFormChange('problemStatement', e.target.value)}
                  placeholder={t('brainstormSessionSetupWizard.problemStatementPlaceholder')}
                />

                {/* <ValidationMessage
                  show={showValidation && !formData.problemStatement}
                  message={t('brainstormSessionSetupWizard.problemStatementError')}
                /> */}
              </div>

              <div className="mb-6">
                <label
                  className="text-base font-semibold mb-3 w-full text-left"
                  style={{ maxWidth: 'unset', marginBottom: '1rem', display: 'block' }}
                >
                  {t('brainstormSessionSetupWizard.orSelectOne')}
                </label>
                <div className="grid grid-cols-3 gap-4">
                  <div className="flex flex-column gap-4">
                    <div
                      className="flex cursor-pointer text-sm  rounded-xl bg-blue-50 p-2 grow min-h-14"
                      onClick={() =>
                        handleFormChange(
                          'problemStatement',
                          formData.language === 'en'
                            ? 'What is the recent progress on our priorities?'
                            : '¿Cuál es el progreso reciente en nuestras prioridades?',
                        )
                      }
                    >
                      {formData.language === 'en'
                        ? 'What is the recent progress on our priorities?'
                        : '¿Cuál es el progreso reciente en nuestras prioridades?'}
                    </div>
                    <div
                      className="flex cursor-pointer text-sm  rounded-xl bg-blue-50 p-2 grow min-h-14"
                      onClick={() =>
                        handleFormChange(
                          'problemStatement',
                          formData.language === 'en'
                            ? 'What are the priorities for the next sprint or week?'
                            : '¿Cuáles son las prioridades para el próximo sprint o semana?',
                        )
                      }
                    >
                      {formData.language === 'en'
                        ? 'What are the priorities for the next sprint or week?'
                        : '¿Cuáles son las prioridades para el próximo sprint o semana?'}
                    </div>
                  </div>

                  <div className="flex flex-column gap-4">
                    <div
                      className="flex cursor-pointer text-sm  rounded-xl bg-blue-50 p-2 grow min-h-14"
                      onClick={() =>
                        handleFormChange(
                          'problemStatement',
                          formData.language === 'en'
                            ? 'What have we done well and where can we improve?'
                            : '¿Qué hemos hecho bien y en qué podemos mejorar?',
                        )
                      }
                    >
                      {formData.language === 'en'
                        ? 'What have we done well and where can we improve?'
                        : '¿Qué hemos hecho bien y en qué podemos mejorar?'}
                    </div>
                    <div
                      className="flex cursor-pointer text-sm  rounded-xl bg-blue-50 p-2 grow min-h-14"
                      onClick={() =>
                        handleFormChange(
                          'problemStatement',
                          formData.language === 'en'
                            ? 'How can we increase sales?'
                            : '¿Cómo podemos aumentar las ventas?',
                        )
                      }
                    >
                      {formData.language === 'en'
                        ? 'How can we increase sales?'
                        : '¿Cómo podemos aumentar las ventas?'}
                    </div>
                  </div>

                  <div className="flex flex-column gap-4">
                    <div
                      className="flex cursor-pointer text-sm  rounded-xl bg-blue-50 p-2 grow min-h-14"
                      onClick={() =>
                        handleFormChange(
                          'problemStatement',
                          formData.language === 'en'
                            ? 'How can we reduce costs?'
                            : '¿Cómo podemos reducir costos?',
                        )
                      }
                    >
                      {formData.language === 'en'
                        ? 'How can we reduce costs?'
                        : '¿Cómo podemos reducir costos?'}
                    </div>
                    <div
                      className="flex cursor-pointer text-sm  rounded-xl bg-blue-50 p-2 grow min-h-14"
                      onClick={() =>
                        handleFormChange(
                          'problemStatement',
                          formData.language === 'en'
                            ? 'How can we improve our operational processes?'
                            : '¿Cómo podemos mejorar nuestros procesos operativos?',
                        )
                      }
                    >
                      {formData.language === 'en'
                        ? 'How can we improve our operational processes?'
                        : '¿Cómo podemos mejorar nuestros procesos operativos?'}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${formData.meetingType === 'In Person' ? 'hidden' : '' + ' mb-6'}`}>
                <label
                  className="text-base font-semibold mb-3 w-full text-left"
                  style={{ maxWidth: 'unset', marginBottom: '1rem', display: 'block' }}
                >
                  {t('brainstormSessionSetupWizard.provideConsent')}
                </label>

                <label className="flex flex-row items-center inline-flex gap-2 ml-4 ">
                  <input
                    type="checkbox"
                    className={`h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 ${
                      showValidation && !formData.consent ? 'border-red-500' : ''
                    }`}
                    checked={formData.consent}
                    onChange={(e) => handleFormChange('consent', e.target.checked)}
                  />
                  <span className="ml-2 text-sm">
                    {t('brainstormSessionSetupWizard.consentStatement')}
                  </span>
                </label>
                <ValidationMessage
                  show={showValidation && !formData.consent}
                  message={t('brainstormSessionSetupWizard.consentError')}
                />
              </div>
              {/* Get Started Button */}
              <div className="flex justify-center mt-4 items-center">
                {isFullVersion && (
                  <button
                    onClick={handleGetStarted}
                    className="w-full max-w-[200px] px-5 py-1.5 rounded-lg bg-[#4472C4] text-white text-sm cursor-pointer hover:bg-[#3a62aa]"
                  >
                    {t('brainstormSessionSetupWizard.getStarted')}
                  </button>
                )}
                {!isFullVersion && (
                  <button
                    onClick={handleGetStarted}
                    className="bg-blue-600 text-sm  font-medium text-white px-6 py-2.5 rounded-lg  inline-flex items-center hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                  >
                    {t('brainstormSessionSetupWizard.getStarted')}
                  </button>
                )}
              </div>
            </div>
          </>
        )}

        {activeTab === 'recent' && (
          <>
            <div className="flex flex-column justify-center w-2/3 bg-white rounded-xl shadow-sm border border-gray-200 p-6 mb-8 h-full">
              <div
                className="flex justify-between items-center mb-4"
                style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
              >
                <h2
                  className="text-2xl font-bold w-full text-left"
                  style={{ maxWidth: 'unset', margin: '0' }}
                >
                  {t('brainstormSessionSetupWizard.recentSessions')}
                </h2>
                <div style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                  {t('brainstormSessionSetupWizard.filterByTag')}
                </div>
                <div style={{ width: '400px' }}>
                  <Select
                    menuPortalTarget={document.body}
                    placeholder={t('Common:filter.select') + '...'}
                    value={selectedTagsOnSessions}
                    onChange={(value) => setSelectedTagsOnSessions(value)}
                    getOptionLabel={(option) => option?.tagName}
                    getOptionValue={(option) => option?.tagId}
                    options={tagsOnSessions}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }),
                    }}
                    isClearable={true}
                  />
                </div>
              </div>
              {isRecentSessionsLoading && (
                <>
                  <InPageLoader inComponent={true} />
                </>
              )}
              {!isRecentSessionsLoading && (
                <div className="flex-1">
                  <div className="mb-6">
                    <h3 className="text-lg font-medium mb-3">
                      {t('brainstormSessionSetupWizard.completedSessions')}
                    </h3>
                    <div className="grid grid-cols-4 gap-4">
                      {filteredBrainstormSessions?.filter(
                        (session) => !session.isDeleted && session.isBsCompleted,
                      )?.length > 0 ? (
                        filteredBrainstormSessions
                          ?.filter((session) => !session.isDeleted && session.isBsCompleted)
                          ?.map((session) => (
                            <div
                              key={session.brainstormId}
                              onClick={() => {
                                setSelectedSession(session)
                              }}
                              className="flex flex-col cursor-pointer transition-colors overflow-hidden"
                            >
                              <div
                                className={`h-24 w-48 bg-gray-100 flex items-center justify-center mx-auto border rounded-lg ${
                                  selectedSession?.brainstormId === session?.brainstormId
                                    ? 'border-orange-500'
                                    : 'hover:border-gray-400'
                                }`}
                                onClick={() => {
                                  handleViewSession(session)
                                }}
                              >
                                {session.screenshotUrl ? (
                                  <img
                                    src={session.screenshotUrl}
                                    alt="Mind map Screenshot"
                                    className="w-full h-full object-contain"
                                    style={{ margin: '0 auto' }}
                                  />
                                ) : (
                                  <div className="text-gray-400">No preview</div>
                                )}
                              </div>
                              <div className="p-3">
                                <div
                                  className="text-sm font-medium line-clamp-2 mb-1"
                                  onClick={() => {
                                    handleViewSession(session)
                                  }}
                                >
                                  {session.problemStatement}
                                </div>
                                {console.log(session.createdAt)}
                                <div className="flex flex-row justify-between">
                                  <div className="text-xs text-gray-400">
                                    {formatTimestampToMonthDayYear(session.createdAt)}
                                  </div>
                                  <BrainstormSessionDropdownMenu
                                    brainstormId={session.brainstormId}
                                    onDelete={() => {
                                      deleteSessionLocal(session)
                                      setSelectedSession(null)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="col-span-4 text-center text-sm text-gray-500 py-4">
                          {t('brainstormSessionSetupWizard.noRecentSessions')}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-3">
                      {t('brainstormSessionSetupWizard.unSavedSessions')}
                    </h3>
                    <div className="grid grid-cols-4 gap-4">
                      {filteredBrainstormSessions?.filter(
                        (session) => !session.isDeleted && !session.isBsCompleted,
                      )?.length > 0 ? (
                        filteredBrainstormSessions
                          ?.filter((session) => !session.isDeleted && !session.isBsCompleted)
                          ?.map((session) => (
                            <div
                              key={session.brainstormId}
                              onClick={() => {
                                setSelectedSession(session)
                              }}
                              className="flex flex-col cursor-pointer transition-colors overflow-hidden"
                            >
                              <div
                                className={`h-24 w-48 bg-gray-100 flex items-center justify-center mx-auto border rounded-lg ${
                                  selectedSession?.brainstormId === session?.brainstormId
                                    ? 'border-orange-500'
                                    : 'hover:border-gray-400'
                                }`}
                                onClick={() => {
                                  handleViewSession(session)
                                }}
                              >
                                {session.screenshotUrl ? (
                                  <img
                                    src={session.screenshotUrl}
                                    alt="Mind map Screenshot"
                                    className="w-full h-full object-contain"
                                    style={{ margin: '0 auto' }}
                                  />
                                ) : (
                                  <div className="text-gray-400">No preview</div>
                                )}
                              </div>
                              <div className="p-3">
                                <div className="text-sm font-medium line-clamp-2 mb-1">
                                  {session.problemStatement}
                                </div>
                                {console.log(session.createdAt)}
                                <div className="flex flex-row justify-between">
                                  <div className="text-xs text-gray-400">
                                    {formatTimestampToMonthDayYear(session.createdAt)}
                                  </div>
                                  <BrainstormSessionDropdownMenu
                                    brainstormId={session.brainstormId}
                                    onDelete={() => {
                                      deleteSessionLocal(session)
                                      setSelectedSession(null)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="col-span-4 text-center text-sm text-gray-500 py-4">
                          {t('brainstormSessionSetupWizard.noRecentSessions')}
                        </div>
                      )}
                    </div>
                  </div>

                  {brainstormSessions?.length === 0 && (
                    <div className="w-full text-center text-sm text-gray-500 py-8">
                      {t('brainstormSessionSetupWizard.noRecentSessions')}
                    </div>
                  )}
                </div>
              )}
              {/* <div className="flex justify-center mt-6 items-center">
            <button
              onClick={handleViewSession}
              disabled={!selectedSession}
              className={`w-48 px-5 py-1.5 rounded-lg text-sm ${
                selectedSession
                  ? 'bg-gray-500 text-white cursor-pointer hover:bg-gray-600'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {t('brainstormSessionSetupWizard.viewSession')}
            </button>
          </div> */}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default BrainstormSessionSetupWizard
