import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.css'

const InPageLoader = ({ inComponent = false, displayErrorText = false, inModal = false }) => {
  const { t } = useTranslation(['Phoenix'])

  return (
    <div className={`phoenix ${!inModal && 'h-screen'} flex justify-center items-center`}>
      {displayErrorText === true ? (
        <div className="loading-container-with-error">
          <h2 style={{ maxWidth: '100%' }}>{t('inPageLoader.title')}</h2>
          <div className="loading-spinner"></div>
        </div>
      ) : inComponent === false ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  )
}

export default InPageLoader
