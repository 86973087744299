import { useTranslation } from 'react-i18next'
import './style.css'
import { useEffect, useState } from 'react'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import { convertTimeStamToMMDDYYYY } from '../../utils/time'
import DecisionLogModal from '../DecisionLogModal'
import { useNavigate } from 'react-router-dom'
import { meetingMultiViewSubRoutes, routes } from '../../utils/routes'
import MeetingReportDropdownMenu from './MeetingReportDropdown'
import Swal from 'sweetalert2'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import NewMeetingDetailsModal from './NewMeetingDetailsModal'
import { getConsolidatedConfigSettings } from '../../utils/auth'

const MeetingReportComponent = () => {
  const { t } = useTranslation(['Common'])
  const [meetings, setMeetings] = useState([])
  const [isDecisionLogModalOpen, setIsDecisionLogModalOpen] = useState(false)
  const [meetingInstanceId, setMeetingInstanceId] = useState(null)
  const [isFinalized, setIsFinalized] = useState(false)
  const [meetingName, setMeetingName] = useState('')
  const [decisionLogId, setDecisionLogId] = useState(null)
  const [isDecisionLogFinalized, setIsDecisionLogFinalized] = useState(false)
  const navigate = useNavigate()

  const [selectedMeeting, setSelectedMeeting] = useState(null)
  const [isEditMeetingModalOpen, setIsEditMeetingModalOpen] = useState(false)
  const showGovernanceMgmntFeatures =
    getConsolidatedConfigSettings('show_governance_mgmt_features') === true

  useEffect(() => {
    getMeetings()
  }, [])

  const getMeetings = async () => {
    try {
      let result = await getHttpRequest('/get_meetings_under_cxo')
      console.log(result)
      setMeetings(result.meetings)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const getFrequency = (frequency) => {
    switch (frequency) {
      case 1:
        return t('meetingReport.weekly')
      case 2:
        return t('meetingReport.biWeekly')
      case 3:
        return t('meetingReport.monthly')
      case 4:
        return t('meetingReport.quarterly')
      case 5:
        return t('meetingReport.bimonthly')
      case 6:
        return t('meetingReport.semiannually')
      case 7:
        return t('meetingReport.annually')
      default:
        return ''
    }
  }

  const navigateToMeetingNotes = (meetingInstanceId) => {
    let url = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingSummaries}`
    console.log(url)
    navigate(url, {
      state: {
        meetingInstanceId: meetingInstanceId,
      },
    })
    navigate(0)
  }

  const deleteCurrentMeeting = async (meetingId) => {
    try {
      const result = await Swal.fire({
        title: t('Common:modalMessages.areYouSureDeleteThis'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      })

      if (result.isConfirmed) {
        await postHttpRequest(`/edit_meeting/${meetingId}`, {
          isDelete: true,
        })
        await getMeetings()
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  return (
    <div
      style={{ marginLeft: '0.5rem', marginTop: '5rem', display: 'flex', flexDirection: 'column' }}
    >
      {isDecisionLogModalOpen && meetingInstanceId && decisionLogId && (
        <DecisionLogModal
          isViewOpen={isDecisionLogModalOpen}
          closeView={() => {
            setMeetingInstanceId(null)
            setIsDecisionLogModalOpen(false)
          }}
          meetingInstanceId={meetingInstanceId}
          isFinalized={isFinalized}
          reload={getMeetings}
          meetingName={meetingName}
          decisionLogId={decisionLogId}
          isDecisionLogFinalized={isDecisionLogFinalized}
        />
      )}
      {isEditMeetingModalOpen && (
        <NewMeetingDetailsModal
          objectiveId={-1}
          meetingId={selectedMeeting?.meetingId}
          setModelView={setIsEditMeetingModalOpen}
          view={isEditMeetingModalOpen}
          meeting={selectedMeeting}
          closeModal={() => {
            setIsEditMeetingModalOpen(false)
            setSelectedMeeting(null)
          }}
          loadAgain={getMeetings}
        />
      )}
      <div style={{ fontSize: '1.6rem', color: '#376dc3' }}>{t('meetingReport.myTeamCadence')}</div>
      <div style={{ marginTop: '3rem', width: '90%' }}>
        {showGovernanceMgmntFeatures && (
          <div
            class="fitted-button blue"
            style={{
              marginTop: 'auto',
              marginBottom: 'auto',
              padding: '0rem',
              marginLeft: 'auto',
            }}
            onClick={() => {
              setIsEditMeetingModalOpen(true)
            }}
          >
            <span class="material-symbols-outlined">add</span>
          </div>
        )}
        <div className="shadow-meeting-report" style={{ marginTop: '1rem' }}>
          <div className="meeting_report-inner-grid">
            <div className="meeting_report-header" style={{ textAlign: 'left' }}>
              {t('meetingReport.meeting')}
            </div>
            <div className="meeting_report-header" style={{ textAlign: 'left' }}>
              {t('meetingReport.owner')}
            </div>
            <div className="meeting_report-header" style={{ textAlign: 'left' }}>
              {t('meetingReport.frequency')}
            </div>
            <div className="meeting_report-header" style={{ textAlign: 'left' }}>
              {t('meetingReport.status')}
            </div>
            <div className="meeting_report-header" style={{ textAlign: 'left' }}>
              {t('meetingReport.lastHeldOn')}
            </div>
            <div className="meeting_report-header" style={{ textAlign: 'left' }}>
              {t('meetingReport.lastHolder')}
            </div>
            <div className="meeting_report-header" style={{ textAlign: 'left' }}>
              {t('meetingReport.lastMeetingLog')}
            </div>
            <div className="meeting_report-header" style={{ textAlign: 'left' }}>
              {/* {t('meetingReport.linkToMeetingNotes')} */}
            </div>
          </div>
          {meetings.length > 0 &&
            meetings.map((meeting, index) => {
              console.log(meeting)
              return (
                <div>
                  <div className="divider" style={{ marginBottom: '0.1rem' }}></div>
                  <div
                    className="meeting_report-inner-grid"
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
                    }}
                  >
                    <div>{meeting.meetingName}</div>
                    <div>{meeting.facilitatorName}</div>
                    <div>{getFrequency(meeting.frequency)}</div>
                    <div>
                      {
                        <div
                          style={{
                            backgroundColor:
                              meeting.status === 0
                                ? 'green'
                                : meeting.status === 1
                                  ? 'red'
                                  : 'grey',
                            width: '2rem',
                            height: '2rem',
                          }}
                        ></div>
                      }
                    </div>
                    {meeting.lastMeetingTs == 0 ? (
                      <div> -- </div>
                    ) : (
                      <div>{convertTimeStamToMMDDYYYY(meeting.lastMeetingTs)}</div>
                    )}
                    {meeting.lastMeetingCreatedBy == '' ? (
                      <div>--</div>
                    ) : (
                      <div>{meeting.lastMeetingCreatedBy}</div>
                    )}
                    {meeting.latestDecisionLogId === 0 || meeting.lastMeetingInstanceName === '' ? (
                      <div>--</div>
                    ) : (
                      <div
                        onClick={() => {
                          setMeetingInstanceId(meeting.lastMeetingInstanceId)
                          setIsFinalized(meeting.lastInstanceFinalized)
                          setMeetingName(meeting.meetingName)
                          setIsDecisionLogModalOpen(true)
                          setDecisionLogId(meeting.latestDecisionLogId)
                          setIsDecisionLogFinalized(meeting.lastInstanceFinalized)
                        }}
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        {meeting.lastMeetingInstanceName}
                      </div>
                    )}
                    {showGovernanceMgmntFeatures && (
                      <MeetingReportDropdownMenu
                        onClickOpenMeetingModal={() => {
                          console.log('open meeting modal')
                          setSelectedMeeting(meeting)
                          setIsEditMeetingModalOpen(true)
                        }}
                        color={index % 2 === 0 ? '#f5f5f5' : 'white'}
                        deleteMeeting={() => {
                          deleteCurrentMeeting(meeting.meetingId)
                        }}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          <div className="divider" style={{ marginBottom: '0.1rem' }}></div>
        </div>
      </div>
    </div>
  )
}

export default MeetingReportComponent
