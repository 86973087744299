import React from 'react'
import { useLocation } from 'react-router-dom'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import ApplicationLayout from '../../components/ApplicationLayout'
import InPageLoader from '../../components/InPageLoader'
import './style.css'
import { useTranslation } from 'react-i18next'
import { dateFilter } from '../../utils/time'
import moment from 'moment'
import Select from 'react-select'
import SelectSingleField from '../../components/CustomMultipleSelect/singleOption'
import { getObjectivUserList } from '../../api/services/employee.services'
import { shortenName } from '../../utils/general'
import Toast from '../../common/toast'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'

const Changelog = () => {
  const { state } = useLocation()
  const { objId } = state

  const [isLoading, setIsLoading] = React.useState(false)
  const [changeLogList, setChangeLogList] = React.useState([])
  const [changeLogListFiltered, setChangeLogListFiltered] = React.useState([])
  const [objDescription, setObjDescription] = React.useState('')

  const [selectedPerson, setSelectedPerson] = React.useState([])
  const [peopleList, setPeopleList] = React.useState([])
  const [selectedType, setSelectedType] = React.useState([])
  const [typesList, setTypesList] = React.useState([])

  const [dateFilterValue, setDateFilterValue] = React.useState(['', ''])
  const [showValidationMsg, setShowValidationMsg] = React.useState(false)

  const [userList, setUserList] = React.useState([])

  const { t } = useTranslation(['Common'])

  const textTypeMapping = {
    1: {
      description: 'Objective Name Edited',
      is_deleted: 'Deleted',
      status: 'PD Status',
      rag: 'RAG Colour',
      due_date: 'Due Date',
      biz_rationale_encrypted: 'Business Rationale',
      start_date: 'Start Date',
      complete: 'Objective Completed',
    },
    2: {
      description: 'Topic Name',
      is_open: 'Topic Resolved',
      obj_id: 'Topic associated with Objective',
      owner_id: 'Topic Owner Changed',
      topic_type: 'Topic Category',
    },
    3: {
      description: 'Action Name',
      progress: 'Action Progress',
      is_deleted: 'Action Deleted',
      is_priority: 'Action Flagged',
      due_date: 'Action Due Date Updated',
      umt_id: 'Action associated with Topic',
      start_date: 'Action Start Date Updated',
    },
    4: {
      description: 'Comment',
      is_deleted: 'Comment Deleted',
      is_decision: 'Decision',
    },
    5: {
      amount: 'KPI Update',
    },
    6: {
      due_date: 'KPI Due Date',
      frequency: 'KPI Frequency',
      kpi_target: 'KPI Target',
      owner_e_id: 'KPI Owner',
    },
    9: {
      priority: 'Priority',
      initiative_id: 'Initiative Id',
    },
  }
  const typeNameMapping = {
    1: 'Objective',
    2: 'Topic',
    3: 'Action',
    4: 'Comment',
    9: 'Priority',
    5: 'KPI Update',
  }
  const actionProgressMapping = [
    {
      label: 'Completed',
      value: '1',
    },
    {
      label: 'On Track',
      value: '2',
    },
    {
      label: 'At Risk',
      value: '3',
    },
    {
      label: 'Deprioritized',
      value: '4',
    },
    {
      label: 'Not Started',
      value: '5',
    },
  ]
  const statusOptions = [
    { value: '1', label: 'Pre-PD0' },
    { value: '2', label: 'PD0' },
    { value: '3', label: 'PD1' },
    { value: '4', label: 'PDf' },
    { value: '5', label: 'PDx' },
  ]
  const ragOptions = [
    { value: '0', label: 'Green' },
    { value: '1', label: 'Yellow' },
    { value: '2', label: 'Red' },
  ]
  const topicTypeOptions = [
    { value: '1', label: 'Blocker' },
    { value: '2', label: 'Guidance' },
    { value: '3', label: 'Update' },
    { value: '4', label: 'Admin' },
  ]

  const kpiFrequencyOptions = [
    { value: '1', label: 'Weekly' },
    { value: '2', label: 'Biweekly' },
    { value: '3', label: 'Monthly' },
    { value: '4', label: 'Quarterly' },
    { value: '5', label: 'Bi-Monthly' },
    { value: '6', label: 'Semi-Annually' },
    { value: '7', label: 'Annually' },
  ]

  const kpiTypeOptions = [
    { value: '1', label: 'Not Started' },
    { value: '2', label: 'In Progress' },
    { value: '3', label: 'Completed' },
  ]

  React.useEffect(() => {
    if (objId) {
      getChangeLogData(objId)
      getUserList(objId)
    }
  }, [])

  const getUserList = async (objId) => {
    const result = await getObjectivUserList(objId)
    if (result?.employees) setUserList(result.employees)
  }

  const getChangeLogData = async (objId) => {
    try {
      setIsLoading(true)

      const response = await getHttpRequest(`/get_change_log/${objId}`)
      let changeLogListRaw = response.changeLogList
      changeLogListRaw.sort((a, b) => b.timestamp - a.timestamp)
      setChangeLogList(changeLogListRaw)
      setChangeLogListFiltered(changeLogListRaw)
      setObjDescription(response.objDescription)

      // get all unique people apprear in the change log, also types
      let userListRaw = []
      let typeListRaw = []
      changeLogListRaw.forEach((changeLog) => {
        changeLog.changeLog.forEach((changeLogItem) => {
          userListRaw.push({
            eId: changeLogItem.changedByEid,
            name: changeLogItem.changedByName,
          })
          typeListRaw.push({
            type: changeLogItem.field,
            name: generateChangeItemDescription(changeLogItem),
          })
        })
      })
      userListRaw = userListRaw.filter(
        (value, index, self) => index === self.findIndex((t) => t.eId === value.eId),
      )

      typeListRaw = typeListRaw.filter(
        (value, index, self) => index === self.findIndex((t) => t.name === value.name),
      )

      setPeopleList(userListRaw.filter((u) => u.eId !== -999))
      setTypesList(typeListRaw)

      setIsLoading(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const generateChangeItemDescription = (changeLogItem) => {
    if (
      changeLogItem.field === 'description' ||
      changeLogItem.field === 'kpi_description_encrypted'
    ) {
      switch (changeLogItem.type) {
        case '2':
          return changeLogItem.isEdited ? 'Topic Edited' : 'Topic Added'
        case '3':
          return changeLogItem.isEdited ? 'Action Edited' : 'Action Added'
        case '4':
          if (changeLogItem.isDecision) {
            return changeLogItem.isEdited ? 'Decision Edited' : 'Decision Added'
          }
          return changeLogItem.isEdited ? 'Comment Edited' : 'Comment Added'
        case '5':
          return 'KPI Update Added'
        case '6':
          return changeLogItem.isEdited ? 'KPI Edited' : 'KPI Added'
        default:
          break
      }
    }

    return textTypeMapping[changeLogItem.type][changeLogItem.field]
  }

  const formatDueDate = (dueDate) =>
    ((dates) => `${dates[1]}/${dates[2]}/${dates[0].slice(-2)}`)(dueDate?.split('-'))

  const parseDescription = (value, changeLogItem, isOldValue = false) => {
    if (changeLogItem.field === 'is_deleted') {
      return isOldValue ? changeLogItem.currentDescription : '--'
    }
    if (changeLogItem.field === 'is_open' || changeLogItem.field === 'is_priority') {
      return isOldValue ? changeLogItem.currentDescription : value
    }
    if (changeLogItem.field === 'progress') {
      return isOldValue
        ? changeLogItem.currentDescription
        : (actionProgressMapping.find((actionProgress) => actionProgress.value === value)?.label ??
            '--')
    }
    if (changeLogItem.field === 'rag') {
      return isOldValue
        ? changeLogItem.currentDescription
        : (ragOptions.find((rag) => rag.value === value)?.label ?? '--')
    }
    if (changeLogItem.field === 'status') {
      return isOldValue
        ? changeLogItem.currentDescription
        : (statusOptions.find((status) => status.value === value)?.label ?? '--')
    }
    if (changeLogItem.field === 'owner_id' || changeLogItem.field === 'owner_e_id') {
      return isOldValue
        ? changeLogItem.currentDescription
        : (shortenName(userList.find((user) => user.eId == value)?.name) ?? '--')
    }
    if (changeLogItem.field === 'topic_type') {
      return isOldValue
        ? changeLogItem.currentDescription
        : (topicTypeOptions.find((topicType) => topicType.value == value)?.label ?? '--')
    }
    if (changeLogItem.changeOn === '--' && isOldValue) {
      return changeLogItem.changeOn
    }
    if (changeLogItem.field === 'obj_id') {
      return isOldValue ? changeLogItem.currentDescription : value
    }
    if (changeLogItem.field === 'kpi_target') {
      return isOldValue ? changeLogItem.currentDescription : value
    }
    if (changeLogItem.field === 'frequency') {
      return isOldValue
        ? changeLogItem.currentDescription
        : (kpiFrequencyOptions.find((frequency) => frequency.value == value)?.label ?? '--')
    }

    if (changeLogItem.field === 'due_date' || changeLogItem.field === 'start_date') {
      if (isOldValue) return changeLogItem.currentDescription
      console.log(value)
      // due_date for kpi = 'MM/DD/YY
      if (changeLogItem.type === '6') return value
      // due_date for obj = '01-04-2021', for others = '1617235200'
      if (value === '0') return '--'
      return !isNaN(parseInt(value)) &&
        parseInt(value) > 999999999 &&
        moment.unix(parseInt(value)).isValid()
        ? dateFilter(value)
        : value === '--'
          ? '--'
          : formatDueDate(value)
    }

    // it's KPI update milestone
    if (changeLogItem.type === '5' && changeLogItem.kpiType === '3') {
      return isOldValue
        ? changeLogItem.currentDescription
        : (kpiTypeOptions.find((kpiType) => kpiType.value == value)?.label ?? '--')
    }

    return value
  }

  const peopleChangeHandler = (e) => {
    setSelectedPerson(e)
  }

  const typeChangeHandler = (e) => {
    setSelectedType(e)
  }

  const handleDateFilter = (event, type) => {
    let tempArrayDate = [...dateFilterValue]
    if (type === 'FROM') {
      tempArrayDate[0] = event.target.value
    } else if (type === 'TO') {
      tempArrayDate[1] = event.target.value
    }
    setDateFilterValue(tempArrayDate)
  }

  const convertDateStringToTimestamp = (dateString, isEndDate = false) => {
    if (dateString === '') {
      return null
    }
    let dateArray = dateString.split('-')
    const data = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])

    if (isEndDate) {
      data.setHours(23, 59, 59, 999)
    } else {
      data.setHours(0, 0, 0, 0)
    }
    const timestamp = data.getTime() / 1000
    return timestamp
  }

  const applyTimestampFilter = (timestamp, startTimestamp, endTimestamp) => {
    if (startTimestamp == null && endTimestamp == null) {
      return true
    }
    if (startTimestamp == null) {
      return timestamp <= endTimestamp
    }
    if (endTimestamp == null) {
      return timestamp >= startTimestamp
    }
    return timestamp >= startTimestamp && timestamp <= endTimestamp
  }

  const applyFilters = () => {
    if (dateFilterValue[1] !== '' && dateFilterValue[0] > dateFilterValue[1]) {
      setShowValidationMsg(true)
      return
    } else {
      setShowValidationMsg(false)
    }

    let startTimestamp = convertDateStringToTimestamp(dateFilterValue[0], false)
    let endTimestamp = convertDateStringToTimestamp(dateFilterValue[1], true)

    let selectedPersonId = selectedPerson.map((person) => person.eId)
    let selectedTypeOnly = selectedType.map((type) => type.name)
    let filteredChangeLog = changeLogList
      .filter((changeLog) =>
        applyTimestampFilter(changeLog.timestamp, startTimestamp, endTimestamp),
      )
      .map((changeLog) => ({
        ...changeLog,
        changeLog: changeLog.changeLog.filter(
          (changeLogItem) =>
            (selectedPersonId.length === 0 ||
              selectedPersonId.includes(changeLogItem.changedByEid)) &&
            (selectedTypeOnly.length === 0 ||
              selectedTypeOnly.includes(generateChangeItemDescription(changeLogItem))),
        ),
      }))

    filteredChangeLog.sort((a, b) => b.timestamp - a.timestamp)
    setChangeLogListFiltered(filteredChangeLog)
  }

  if (isLoading) {
    return (
      <ApplicationLayout>
        <InPageLoader />
      </ApplicationLayout>
    )
  }
  return (
    <ApplicationLayout>
      <div className="dashboard_wrapper">
        <div style={{ fontSize: '22px', textAlign: 'left', wordBreak: 'break-word' }}>
          <b>
            {t('changeLog.title')}: {objDescription}
          </b>
        </div>
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '0.5rem', marginTop: '3rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-end' }}>
            <label className="label-custom" style={{ fontSize: '16px', marginBottom: '0.2rem' }}>
              <b>{t('Common:filter.changeType')}</b>
            </label>
            <div style={{ width: '15rem' }}>
              <Select
                placeholder={t('formPlaceHolder.select')}
                name="selectType"
                value={selectedType}
                isMulti
                onChange={(e) => {
                  typeChangeHandler(e)
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                options={typesList}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-end' }}>
            <label className="label-custom" style={{ fontSize: '16px', marginBottom: '0.2rem' }}>
              <b>{t('Common:filter.date')}</b>
            </label>
            {showValidationMsg && (
              <span className="validation-message">
                {t('Common:filter.fromToDateErrorMessage')}
              </span>
            )}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{ display: 'flex', flexDirection: 'row', rowGap: '0.5rem', width: '15rem' }}
              >
                <label className="label-custom" style={{ fontSize: '16px', margin: 'auto 0.5rem' }}>
                  <b>{t('Common:filter.from')}</b>
                </label>
                <input
                  type="date"
                  id="dateFilter"
                  name="dateFilter"
                  autoComplete="off"
                  onChange={(e) => {
                    handleDateFilter(e, 'FROM')
                  }}
                  style={{ height: '28px', width: '12rem' }}
                  defaultValue={dateFilterValue[0]}
                />
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', rowGap: '0.5rem', width: '15rem' }}
              >
                <label className="label-custom" style={{ fontSize: '16px', margin: 'auto 0.5rem' }}>
                  <b>{t('Common:filter.to')}</b>
                </label>
                <input
                  type="date"
                  id="dateFilter"
                  name="dateFilter"
                  autoComplete="off"
                  onChange={(e) => {
                    handleDateFilter(e, 'TO')
                  }}
                  style={{ height: '28px', width: '12rem' }}
                  defaultValue={dateFilterValue[1]}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-end' }}>
            <label className="label-custom" style={{ fontSize: '16px', marginBottom: '0.2rem' }}>
              <b>{t('Common:filter.changedBy')}</b>
            </label>
            <div style={{ width: '15rem' }}>
              <Select
                placeholder={t('formPlaceHolder.select')}
                name="selectPerson"
                value={selectedPerson}
                isMulti
                onChange={(e) => {
                  peopleChangeHandler(e)
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.eId}
                options={peopleList}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div
            className="fitted-button blue"
            onClick={() => {
              applyFilters()
            }}
            style={{ alignSelf: 'flex-end' }}
          >
            <span style={{ margin: '0px 0.5rem' }}>{t('Common:filter.applyFilter')}</span>
          </div>
        </div>
        <div
          className="change-log-table"
          style={{ paddingBottom: '2rem', wordBreak: 'break-word' }}
        >
          <div className="chart-log-meeting-header title-border">{t('changeLog.change')}</div>
          <div className="chart-log-meeting-header title-border">{t('changeLog.changeOn')}</div>
          <div className="chart-log-meeting-header title-border">{t('changeLog.details')}</div>
          <div className="chart-log-meeting-header title-border">{t('changeLog.changeDate')}</div>
          <div className="chart-log-meeting-header title-border">{t('changeLog.changeBy')}</div>
          {changeLogListFiltered.length > 0 &&
            changeLogListFiltered.map((changeLog, index) =>
              changeLog.changeLog.map((changeLogItem, index) => {
                return (
                  <React.Fragment key={`${changeLog.timestamp}` + changeLogItem.newValue}>
                    <div>{generateChangeItemDescription(changeLogItem)}</div>
                    <div>
                      {removeMentionMarkupFromText(
                        parseDescription(changeLogItem.changeOn, changeLogItem, true),
                      )}
                    </div>
                    <div>
                      {/*  changeLogItem.newValue is of type string based on the proto definition*/}
                      {removeMentionMarkupFromText(
                        parseDescription(changeLogItem.newValue, changeLogItem),
                      )}
                    </div>
                    <div>{changeLog.timestamp === 0 ? '--' : dateFilter(changeLog.timestamp)}</div>
                    <div>{shortenName(changeLogItem.changedByName)}</div>
                  </React.Fragment>
                )
              }),
            )}
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default Changelog
