const selectDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? 'rgba(200,200,200,0.5)' : 'white',
    borderColor: state.isDisabled ? '#a0a0a0' : state.isFocused ? 'blue' : '#d1d5db',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    borderRadius: '0.375rem',
    textAlign: 'left',
    opacity: state.isDisabled ? 0.7 : 1,
    '&:hover': {
      borderColor: state.isDisabled ? '#a0a0a0' : state.isFocused ? 'blue' : 'gray',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'lightgray' : 'white',
    textAlign: 'left',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#a0a0a0' : '#425066',
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#9ca3af',
    textAlign: 'left',
    opacity: state.isDisabled ? 0.7 : 1,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(238, 238, 238, 0.2)' : 'white',
  }),
}

const ValidationMessage = ({ show, message }) => {
  return show ? <div className="text-red-500 text-xs mt-1 text-left">{message}</div> : null
}

const DateInput = ({ date, onChange = function () {}, name, error = false }) => {
  return (
    <input
      name={name}
      id={name}
      type="date"
      className={`w-full px-3 py-2 border rounded-md border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 hover:border-black ${
        date ? 'text-black' : 'text-[#9ca3af]'
      } ${error ? 'border-red-500' : ''}`}
      value={date}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

const TextInput = ({
  value,
  onChange,
  name,
  error = false,
  placeholder = '',
  disabled = false,
}) => {
  return (
    <input
      name={name}
      id={name}
      type="text"
      className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none hover:border-black focus:border-blue-500 focus:border-[0.5px] bg-white
        ${error ? 'border-red-500' : ''}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

const TextAreaInput = ({
  value,
  onChange = function () {},
  name,
  error = false,
  placeholder = '',
  disabled = false,
}) => {
  return (
    <textarea
      name={name}
      id={name}
      className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24 resize-none hover:border-black focus:border-[0.5px] focus:border-blue-500  
        ${error ? 'border-red-500' : ''}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

const CheckboxInput = ({ name, checked, onChange = function () {} }) => {
  return (
    <input
      name={name}
      id={name}
      type="checkbox"
      checked={checked}
      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
      onChange={(e) => onChange(e.target.checked)}
    />
  )
}

export {
  selectDropdownStyles,
  DateInput,
  ValidationMessage,
  TextInput,
  TextAreaInput,
  CheckboxInput,
}
