import React from 'react'
import './style.css'

const ChatInput = ({ sendText = () => {}, setMessageBubbles }) => {
  const [inputValue, setInputValue] = React.useState('')

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (inputValue.trim() !== '') {
      setMessageBubbles((prevMessages) => [
        ...prevMessages,
        { message: inputValue, isUser: true },
        { message: '........', isUser: false, isFetched: false },
      ])
      sendText(inputValue)
      setInputValue('')
    }
  }

  return (
    <div className="input">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="Type your message here"
        />
        <button class="send-button">
          <span class="material-symbols-outlined">arrow_upward</span>
        </button>
      </form>
    </div>
  )
}

export default ChatInput
