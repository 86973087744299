import React from 'react'
import Modal from '../../../../components/Modal'
import TextArea from 'rc-textarea'
import { useTranslation } from 'react-i18next'

const TextInputModal = ({ isModalOpen, closeModal, onSave, language = 'en' }) => {
  const [textInputValue, setTextInputValue] = React.useState('')
  const { t } = useTranslation(['Common'])

  const handleSave = () => {
    if (textInputValue.trim()) {
      onSave(textInputValue)
      setTextInputValue('')
    }
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width={'40rem'}
      title={language === 'es' ? 'Agregar texto' : 'Add text'}
      modalContainerStyle={{ width: '80%', padding: '0px' }}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '99%' }}>
        <TextArea
          rows="5"
          value={textInputValue}
          onChange={(e) => setTextInputValue(e.target.value)}
          style={{
            resize: 'none',
            borderRadius: '10px',
            // backgroundColor: 'white',
            fontSize: '14px',
            width: '100%',
            padding: '0.5rem',
            border: `1px solid lightgray`,
            backgroundColor: '#f1f0f0',
          }}
          placeholder={language === 'es' ? 'Escribe aquí...' : 'Type here...'}
        />
        <div
          className="sleek-button sleek-full-blue"
          style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
          onClick={handleSave}
        >
          {language === 'es' ? 'GUARDAR' : 'SAVE'}
        </div>
      </div>
    </Modal>
  )
}

export default TextInputModal
