import React, { useEffect } from 'react'
import styled from 'styled-components'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'
import { useTranslation } from 'react-i18next'
import UpdateMeetingTopicModal from '../../pages/MeetingMultiView/components/MeetingSummary/sub-components/UpdateMeetingTopicModal'
import Toast from '../../common/toast'
import Swal from 'sweetalert2'
import TextArea from 'rc-textarea'
import AssigneeActivity from '../../../../react_client/src/pages/MeetingMultiView/components/MeetingTopics/common/assigneeActivity'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import {
  isAreaLeader,
  isTeamLeader,
  getCompanyConfigSettings,
  isDelegate,
} from '../../../../react_client/src/utils/auth'
import { getDecisionLogByMeetingInstanceId } from '../../api/services/meetingTopic.services'
import Modal from '../../components/Modal'
import logoWithoutText from '../../../../react_client/src/assets/transforml_image_no_padding.png'
import DecisionLog from '../../pages/MeetingMultiView/components/DecisionLog'
import InPageLoader from '../InPageLoader'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-word;
`

const DecisionLogModal = ({
  // meetingGeneratedItems = [],
  reload = () => {},
  isFinalized = false,
  meetingNoteName = '',
  isViewOpen,
  closeView,
  meetingInstanceId,
  meetingName,
  decisionLogId,
  isDecisionLogFinalized = false,
}) => {
  const { t } = useTranslation(['Common'])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [selectedTopic, setSelectedTopic] = React.useState(null)
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = React.useState(false)
  const [selectedItemId, setSelectedItemId] = React.useState(null)
  const [selectedTopicId, setSelectedTopicId] = React.useState(null)
  const [meetingGeneratedItems, setMeetingGeneratedItems] = React.useState([])
  const [availableTopics, setAvailableTopics] = React.useState([])

  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')

  const enableDecisionLog = getCompanyConfigSettings('enableDecisionLog') === true
  const [decisionLogData, setDecisionLogData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const getDecisionLogData = async (logId, isFinalized) => {
    if (logId) {
      try {
        setIsLoading(true)
        const response = await getHttpRequest(`/get_decision_log/${logId}?finalized=${isFinalized}`)
        if (response) {
          setDecisionLogData(response)
        }
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      await getDecisionLogData(decisionLogId, isDecisionLogFinalized)
    })()
  }, [])

  return (
    <Modal
      title={meetingName}
      isModalOpen={isViewOpen}
      data-testid="Assigne"
      closeModal={closeView}
      width={'90%'}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
      showCard={true}
    >
      <>
        {isLoading ? (
          <InPageLoader inComponent={true} />
        ) : (
          <div
            style={{ width: '100%', paddingTop: '1rem', display: 'flex', justifyContent: 'center' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '2rem',
                  width: '100%',
                  zIndex: '5',
                  textAlign: 'left',
                }}
              >
                <DecisionLog
                  reload={reload}
                  selectedLog={() => {}}
                  setSelectedLog={() => {}}
                  decisionLogList={[]}
                  setDecisionLogList={() => {}}
                  isLogEditable={false}
                  setIsLogEditable={() => {}}
                  decisionLogData={decisionLogData}
                  setDecisionLogData={setDecisionLogData}
                  logTopicList={[]}
                  getDecisionLogData={getDecisionLogData}
                  checkboxComments={() => {}}
                  checkboxMiles={() => {}}
                  setCheckboxComments={() => {}}
                  setCheckboxMiles={() => {}}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        )}
      </>
    </Modal>
  )
}

export default DecisionLogModal
