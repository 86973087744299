// ApiQueueManager handles sequential API calls with different intervals
class ApiQueueManager {
  constructor() {
    this.isProcessing = false
    this.queue = []
    this.intervals = {}
    this.timeouts = {}
    console.log('[Queue] ApiQueueManager initialized')
  }

  // Schedule an API call with a specific interval
  scheduleApiCall(apiKey, apiFunction, interval, initialDelay = 0, run_immediately = true) {
    const timestamp = Math.floor(Date.now() / 1000)
    console.log(`[Queue] Scheduling ${apiKey}:
    - Interval: ${interval}ms
    - Initial Delay: ${initialDelay}ms
    - Current Queue Length: ${this.queue.length}
    - Is Processing: ${this.isProcessing}
    - Timestamp: ${timestamp}
    - Active Intervals: ${Object.keys(this.intervals).join(', ') || 'none'}
    - Active Timeouts: ${Object.keys(this.timeouts).join(', ') || 'none'}`)

    // Clear any existing interval/timeout for this key
    this.clearSchedule(apiKey)

    // Set initial timeout
    this.timeouts[apiKey] = setTimeout(() => {
      console.log(`[Queue] Initial timeout triggered for ${apiKey}:
      - Queue Length: ${this.queue.length}
      - Is Processing: ${this.isProcessing}
      - Time since schedule: ${Math.floor(Date.now() / 1000) - timestamp}s
      - Expected delay: ${initialDelay}ms`)

      // Make first call
      if (run_immediately) {
        this.addToQueue(apiKey, apiFunction)
      }

      // Set up recurring interval
      this.intervals[apiKey] = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000)
        console.log(`[Queue] Interval triggered for ${apiKey}:
        - Time since last trigger: ${currentTime - timestamp}s
        - Queue Length: ${this.queue.length}
        - Is Processing: ${this.isProcessing}
        - Expected interval: ${interval}ms`)

        this.addToQueue(apiKey, apiFunction)
      }, interval)
    }, initialDelay)
  }

  // Add an API call to the queue
  async addToQueue(apiKey, apiFunction) {
    const timestamp = Math.floor(Date.now() / 1000)
    console.log(`[Queue] Adding ${apiKey} to queue:
    - Current Queue Length: ${this.queue.length}
    - Is Processing: ${this.isProcessing}
    - Queue Contents: ${this.queue.map((item) => item.key).join(', ') || 'empty'}
    - Timestamp: ${timestamp}
    - Function: ${apiFunction.name || 'anonymous'}`)

    this.queue.push({
      key: apiKey,
      func: apiFunction,
      enqueuedAt: timestamp,
    })
    return this.processQueue()
  }

  // Process the queue sequentially
  async processQueue() {
    const timestamp = Math.floor(Date.now() / 1000)
    if (this.isProcessing || this.queue.length === 0) {
      console.log(`[Queue] Process queue skipped:
      - Is Processing: ${this.isProcessing}
      - Queue Length: ${this.queue.length}
      - Queue Contents: ${this.queue.map((item) => item.key).join(', ') || 'empty'}
      - Timestamp: ${timestamp}`)
      return null
    }

    this.isProcessing = true
    const { key, func, enqueuedAt } = this.queue.shift()
    console.log(`[Queue] Starting to process ${key}:
    - Time in queue: ${timestamp - enqueuedAt}s
    - Remaining queue: ${this.queue.map((item) => item.key).join(', ') || 'empty'}
    - Queue Length: ${this.queue.length}
    - Function: ${func.name || 'anonymous'}`)

    try {
      const startTime = Date.now()
      console.log(`[Queue] Executing ${key} at ${Math.floor(startTime / 1000)}`)
      const result = await func()
      const executionTime = Date.now() - startTime
      console.log(`[Queue] Completed ${key}:
      - Execution time: ${executionTime}ms
      - Queue Length: ${this.queue.length}
      - Next in queue: ${this.queue[0]?.key || 'none'}
      - Timestamp: ${Math.floor(Date.now() / 1000)}`)

      this.isProcessing = false
      this.processQueue()
      return result
    } catch (error) {
      console.error(`[Queue] Error in ${key}:
      - Error: ${error.message}
      - Stack: ${error.stack}
      - Queue Length: ${this.queue.length}
      - Timestamp: ${Math.floor(Date.now() / 1000)}`)

      this.isProcessing = false
      this.processQueue()
      throw error
    }
  }

  // Clear schedule for a specific key
  clearSchedule(apiKey) {
    const timestamp = Math.floor(Date.now() / 1000)
    if (this.intervals[apiKey] || this.timeouts[apiKey]) {
      console.log(`[Queue] Clearing schedules for ${apiKey}:
      - Had interval: ${!!this.intervals[apiKey]}
      - Had timeout: ${!!this.timeouts[apiKey]}
      - Remaining intervals: ${Object.keys(this.intervals).length - (this.intervals[apiKey] ? 1 : 0)}
      - Remaining timeouts: ${Object.keys(this.timeouts).length - (this.timeouts[apiKey] ? 1 : 0)}
      - Timestamp: ${timestamp}`)
    }

    if (this.intervals[apiKey]) {
      clearInterval(this.intervals[apiKey])
      delete this.intervals[apiKey]
    }
    if (this.timeouts[apiKey]) {
      clearTimeout(this.timeouts[apiKey])
      delete this.timeouts[apiKey]
    }
  }

  // Clean up all intervals and timeouts
  cleanup() {
    const timestamp = Math.floor(Date.now() / 1000)
    console.log(`[Queue] Cleaning up all schedules:
    - Intervals to clear: ${Object.keys(this.intervals).join(', ') || 'none'}
    - Timeouts to clear: ${Object.keys(this.timeouts).join(', ') || 'none'}
    - Queue items to clear: ${this.queue.map((item) => item.key).join(', ') || 'none'}
    - Queue Length: ${this.queue.length}
    - Is Processing: ${this.isProcessing}
    - Timestamp: ${timestamp}`)

    Object.values(this.intervals).forEach((interval) => clearInterval(interval))
    Object.values(this.timeouts).forEach((timeout) => clearTimeout(timeout))
    this.intervals = {}
    this.timeouts = {}
    this.queue = []
  }

  // Add an API call to the front of the queue
  async addToFrontOfQueue(apiKey, apiFunction) {
    const timestamp = Math.floor(Date.now() / 1000)
    console.log(`[Queue] Adding ${apiKey} to front of queue:
    - Current Queue Length: ${this.queue.length}
    - Is Processing: ${this.isProcessing}
    - Queue Contents: ${this.queue.map((item) => item.key).join(', ') || 'empty'}
    - Timestamp: ${timestamp}
    - Function: ${apiFunction.name || 'anonymous'}`)

    this.queue.unshift({
      key: apiKey,
      func: apiFunction,
      enqueuedAt: timestamp,
    })

    return this.processQueue()
  }
}

export default ApiQueueManager
