import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import {
  CREATE_CHECKOUT_SESSION,
  API_ERROR_MESSAGE,
  GET_USER_PAYMENT_INFO,
  CANCEL_SUBSCRIPTION,
} from '../../api/constant/route'
import Toast from '../../common/toast'
import axios from 'axios'

const BASE_URL = 'http://localhost:8000/api'

let abortController = new AbortController()

export async function getCheckoutSession(price) {
  try {
    let requestConfig = {
      signal: abortController.signal,
    }
    const data = await getHttpRequest(`${CREATE_CHECKOUT_SESSION}?price=${price}`, requestConfig)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getUserPaymentInfo() {
  let params = {}
  let requestConfig = {
    signal: abortController.signal,
  }
  const data = getHttpRequest(`${GET_USER_PAYMENT_INFO}`, params, requestConfig)
  return data
}

export async function cancelSubscription(subscription_id, cancelAfterPeriodEnd = true) {
  let params = {
    subscription_id: subscription_id,
    cancel_after_period_end: cancelAfterPeriodEnd,
  }
  let requestConfig = {
    signal: abortController.signal,
  }
  const data = getHttpRequest(`${CANCEL_SUBSCRIPTION}`, { params: params }, requestConfig)
  return data
}
