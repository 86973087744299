export const defaultState = {
  preventDefaultWhileNavigating: false,
}

const ApplicationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PREVENT_DEFAULT_WHILE_NAVIGATING':
      return {
        ...state,
        preventDefaultWhileNavigating: action.payload,
      }

    default:
      throw new Error(`Action: ${action.type} not implemented in ApplicationReducer.`)
  }
}

export default ApplicationReducer
