import React, { useState } from 'react'
import Modal from '../Modal'
import './styles.css'
import TextArea from 'rc-textarea'
import { useTranslation } from 'react-i18next'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
import logoWithoutText from '../../assets/transforml_image_no_padding.png'

const KpiReviewModal = ({ isOpen, onClose = () => {}, kpiData = [] }) => {
  const { t } = useTranslation(['Common', 'MeetingHub'])
  const [kpiDataState, setKpiDataState] = useState(kpiData)

  const [validationError, setValidationError] = useState({
    isValid: true,
    errorCode: 999,
    id: '',
  })

  const [isUpdating, setIsUpdating] = useState(false)

  const kpiMilestoneTypeValueList = [
    { label: t('kpi.notStarted'), value: 1 },
    { label: t('kpi.inProgress'), value: 2 },
    { label: t('kpi.completed'), value: 3 },
  ]

  // RAG status mapping
  const getStatusLabel = (rag) => {
    switch (rag) {
      case 0:
        return 'G'
      case 1:
        return 'Y'
      case 2:
        return 'R'
      default:
        return 'GREY' // Default to Grey for -1
    }
  }

  // Get RAG value from label
  const getRagValue = (label) => {
    switch (label) {
      case 'G':
        return 0
      case 'Y':
        return 1
      case 'R':
        return 2
      default:
        return -1
    }
  }

  const handleEditDefaultTopic = (kpiItem, newRag) => {
    if (newRag === 2 || newRag === 1) {
      kpiItem.kpiUpdates[0].umtId = -1
      const isBlocker = newRag === 2
      const description = isBlocker ? t('Common:kpi.addNewBlocker') : t('Common:kpi.addNewGuidance')
      const topicType = isBlocker ? 1 : 2

      const existingTopic = kpiItem.kpi.asscoiatedMeetingTopics.find((topic) => topic.umtId === -1)

      if (existingTopic) {
        existingTopic.description = description
        existingTopic.topicType = topicType
      } else {
        kpiItem.kpi.asscoiatedMeetingTopics.push({
          umtId: -1,
          description,
          topicType,
        })
      }
    } else {
      kpiItem.kpiUpdates[0].umtId = 0
    }
  }

  // Handle status changes
  const handleStatusChange = (kpiId, status) => {
    const updatedData = [...kpiDataState]
    const kpiItem = updatedData.find((item) => item.kpiId === kpiId)
    if (kpiItem) {
      const newRag = getRagValue(status)
      kpiItem.kpiUpdates[0].rag = newRag
      handleEditDefaultTopic(kpiItem, newRag)
    }
    setKpiDataState(updatedData)
  }

  // Handle value changes
  const handleValueChange = (kpiId, value) => {
    const updatedData = [...kpiDataState]
    const kpiItem = updatedData.find((item) => item.kpiId === kpiId)
    if (kpiItem) {
      kpiItem.kpiUpdates[0].amount = value === '' ? '' : parseFloat(value)
    }
    setKpiDataState(updatedData)
  }

  const handleCommentChange = (kpiId, comment) => {
    console.log(kpiId, comment)
    const updatedData = [...kpiDataState]
    const kpiUpdate = updatedData.find((item) => item.kpiId === kpiId)
    console.log(kpiUpdate)
    if (kpiUpdate) {
      kpiUpdate.kpiUpdates[0].comment = comment
    }
    setKpiDataState(updatedData)
  }

  const validateKpiData = (kpiData) => {
    for (let item of kpiData) {
      console.log(item.kpiUpdates[0].rag)
      if (item.kpiUpdates[0].rag !== 0 && item.kpiUpdates[0].comment.trim() === '') {
        return { isValid: false, errorCode: 0, id: item.kpiId }
      }
      if (item.kpiUpdates[0].amount === '') {
        return { isValid: false, errorCode: 1, id: item.kpiId }
      }
    }
    return { isValid: true, errorCode: 999, id: '' }
  }

  const onUpdateKpiData = async (kpiData) => {
    setIsUpdating(true)
    console.log(kpiData)
    try {
      const response = await postHttpRequest('/bulk_create_kpi_updates', kpiData)
      console.log(response)
      onClose(true)
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
      })
    } finally {
      setIsUpdating(false)
    }
  }

  const handleUpdate = () => {
    const validationResult = validateKpiData(kpiDataState)
    setValidationError(validationResult)

    if (validationResult.isValid) {
      console.log(kpiDataState)
      onUpdateKpiData(kpiDataState)
    }
  }

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 0:
        return t('Common:kpi.commentRequired')
      case 1:
        return t('Common:kpi.valueRequired')
      default:
        return ''
    }
  }

  React.useEffect(() => {
    console.log(kpiDataState)
  }, [kpiDataState])

  const handleUmtChange = (kpiId, umtId) => {
    console.log(kpiId, umtId)
    const updatedData = [...kpiDataState]
    const kpiItem = updatedData.find((item) => item.kpiId === kpiId)
    if (kpiItem) {
      // handleEditDefaultTopic(kpiItem, umtId)
      kpiItem.kpiUpdates[0].umtId = parseInt(umtId)
    }
    setKpiDataState(updatedData)
  }

  // Status options for dropdown
  const statusOptions = ['G', 'Y', 'R']

  return (
    <Modal
      title={t('Common:kpiReviewModal.title')}
      isModalOpen={isOpen}
      closeModal={onClose}
      showCard={true}
      width={'88%'}
      titleStyle={{ marginRight: 'auto', color: '#5780ca' }}
      modalContainerStyle={{ width: '95%' }}
    >
      <div className="kpi-review-container">
        {!validationError.isValid && (
          <div className="validation-error-message">
            {getErrorMessage(validationError.errorCode)}
          </div>
        )}

        <div className="kpi-review-table">
          <div className="kpi-review-header-row">
            <div className="kpi-review-header">{t('Common:kpiReviewModal.KPI')}</div>
            <div className="kpi-review-header">{t('Common:kpiReviewModal.status')}</div>
            <div className="kpi-review-header">{t('Common:kpiReviewModal.value')}</div>
            <div className="kpi-review-header">{t('Common:kpiReviewModal.note')}</div>
            <div className="kpi-review-header">{t('Common:kpiReviewModal.addTopic')}</div>
            {/* <div className="kpi-review-header"></div> */}
          </div>

          {kpiDataState.map((item, index) => {
            const kpiUpdate = item.kpiUpdates[0] || {}
            const rag = kpiUpdate.rag
            const statusLabel = getStatusLabel(rag)
            const statusClass =
              rag === 2
                ? 'status-red'
                : rag === 1
                  ? 'status-gold'
                  : rag === 0
                    ? 'status-green'
                    : 'status-grey'

            // Check if this row has a validation error
            const hasError = !validationError.isValid && validationError.id === item.kpiId

            return (
              <div
                className={`kpi-review-row`}
                key={item.kpiId}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
                }}
              >
                <div className="kpi-review-cell">
                  <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.5rem' }}>
                    {kpiUpdate.explanationOfTheUpdate && (
                      <div
                        className="tooltip"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingBottom: '0.5rem',
                        }}
                        onMouseOver={() => {
                          console.log('mouse over')
                        }}
                      >
                        <img
                          className="logo-new-folded"
                          style={{ width: '1rem', height: 'auto', paddingRight: '0.3rem' }}
                          src={logoWithoutText}
                          alt="TransforML Logo"
                        />
                        <span
                          style={{
                            fontStyle: 'italic',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            color: 'rgb(91, 132, 203)',
                          }}
                        >
                          {t('MeetingHub:meetingTopics.ai')}
                          <span
                            className="tooltiptext"
                            style={{
                              width: '20rem',
                              fontSize: '16px',
                              // zIndex: 1000,
                              left: '80%',
                            }}
                          >
                            {kpiUpdate.explanationOfTheUpdate}
                          </span>
                        </span>
                      </div>
                    )}
                    {!kpiUpdate.explanationOfTheUpdate && (
                      <div style={{ width: '43px', minWidth: '43px' }}></div>
                    )}
                    <div style={{ wordWrap: 'break-word', maxHeight: '3.5rem', overflowY: 'auto' }}>
                      {item.kpi.name}
                    </div>
                  </div>
                </div>
                <div className="kpi-review-cell">
                  <select
                    className={`status-select ${statusClass}`}
                    value={statusLabel}
                    onChange={(e) => handleStatusChange(item.kpiId, e.target.value)}
                    style={{
                      border:
                        hasError && validationError.errorCode === 0
                          ? '2px solid #ff0000'
                          : '1px solid #ddd',
                    }}
                  >
                    {statusOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="kpi-review-cell">
                  {item.kpi.kpiType === 3 ? (
                    <select
                      style={{
                        width: '90%',
                        padding: '5px',
                        border:
                          hasError && validationError.errorCode === 1
                            ? '2px solid #ff0000'
                            : '1px solid #ddd',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      value={kpiUpdate.amount}
                      onChange={(e) => handleValueChange(item.kpiId, e.target.value)}
                    >
                      {kpiMilestoneTypeValueList.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="number"
                      className="value-input"
                      value={kpiUpdate.amount === 0 ? '0' : kpiUpdate.amount}
                      onChange={(e) => handleValueChange(item.kpiId, e.target.value)}
                      step="0.1"
                      style={{
                        border:
                          hasError && validationError.errorCode === 1
                            ? '2px solid #ff0000'
                            : '1px solid #ddd',
                      }}
                    />
                  )}
                </div>
                <div className="kpi-review-cell">
                  <div className="review-generated-topics-list-item">
                    <TextArea
                      // rows={3}
                      type="text"
                      style={{
                        height: '2rem',
                        backgroundColor: 'white',
                        width: '90%',
                        border:
                          hasError && validationError.errorCode === 0
                            ? '2px solid #ff0000'
                            : '1px solid lightgray',
                      }}
                      spellCheck={false}
                      onChange={(e) => handleCommentChange(item.kpiId, e.target.value)}
                      value={kpiUpdate.comment}
                    />
                  </div>
                </div>
                <div
                  className="kpi-review-cell "
                  style={{ maxHeight: '3.5rem', overflowY: 'auto' }}
                >
                  {(rag == 2 || rag == 1) && (
                    <>
                      <select
                        style={{
                          width: '100%',
                          padding: '5px',
                          border: '1px solid #ddd',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                        value={item.kpiUpdates[0].umtId}
                        onChange={(e) => handleUmtChange(item.kpiId, e.target.value)}
                      >
                        {item.kpi.asscoiatedMeetingTopics.map((option, index) => (
                          <option key={index} value={option.umtId}>
                            {option.description}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
                {/* <div className="kpi-review-cell">
                  icon
                </div> */}
              </div>
            )
          })}
        </div>
        <div className="kpi-review-button">
          <div
            className="fitted-button blue"
            onClick={handleUpdate}
            style={{
              pointerEvents: 'auto',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isUpdating ? (
              <div
                style={{
                  width: '0.5rem',
                  height: '0.5rem',
                  margin: '0 auto',
                }}
                className="loading-spinner"
              ></div>
            ) : null}
            <span
              style={{
                display: 'flex',
                fontSize: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {' '}
              {t('Common:kpiReviewModal.updateKpis')}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default KpiReviewModal
