import React from 'react'
import { Link, useMatch, useResolvedPath, useNavigate } from 'react-router-dom'
import { useNavigationContext } from '../../pages/Brainstorm/Context/NavigationContext'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'

const activeStyle = {
  color: 'rgb(9, 38, 213)',
}

const ActiveLink = ({ children, to, end = false, disableActive = false, ...props }) => {
  const [isHovering, setIsHovering] = React.useState(false)
  const resolved = useResolvedPath(to)
  const doesMatch = useMatch({ path: resolved.pathname, end })
  const { state, dispatch } = useNavigationContext()
  const navigate = useNavigate()
  const { t } = useTranslation('Phoenix')
  console.log('state11', state)
  console.log('dispatch11', dispatch)

  const handleClick = async (e) => {
    if (state.preventDefaultWhileNavigating) {
      e.preventDefault()
      const result = await Swal.fire({
        title: t('navigation.areYouSure'),
        text: t('navigation.youWillLoseCurrentProgress'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('navigation.yesNavigate'),
        cancelButtonText: t('navigation.noStayHere'),
      })

      if (result.isConfirmed) {
        dispatch({ type: 'SET_PREVENT_DEFAULT_WHILE_NAVIGATING', payload: false })
        navigate(to)
      }
    } else {
      if (props.onClick) {
        props.onClick(e)
      }
    }
  }

  return (
    <Link
      style={disableActive ? null : doesMatch || isHovering ? activeStyle : null}
      to={to}
      {...props}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={handleClick}
    >
      {children}
    </Link>
  )
}

export default ActiveLink
