import React, { useState, useEffect } from 'react'
import { useUpdateContext } from '../../context/updateContext'
import ObjTeamMappingTable from '../../../../components/AllocSubWizard/ObjTeamMappingTable'
import AddTeamMemberModal from '../../../../components/AllocSubWizard/AddTeamMemberModal'
import { useTranslation } from 'react-i18next'
import CapacityAddObjectiveModal from '../../../../components/AllocSubWizard/AddObjectiveModal'

const UpdateObjectivesTeamMapping = () => {
  const { state, dispatch } = useUpdateContext()
  const { t } = useTranslation(['UpdateWizard'])

  return (
    <>
      {state.isAddObjectiveModalOpen === true ? (
        <CapacityAddObjectiveModal contextHandler={useUpdateContext} />
      ) : null}
      {state.isTeamMemberFormOpen === true ? (
        <AddTeamMemberModal contextHandler={useUpdateContext} />
      ) : null}

      <div className="wizard-step">
        <h2 className="wizard-step__title" style={{ marginBottom: '1rem' }}>
          {t('objectivesTeamMappingStep.header')}
        </h2>
        {/* <div className="wizard-step__overview">
          <div className="wizard-step__overview__paragraph">
            
          </div>
        </div> */}

        <div className="wizard-step__main-content-container">
          <div className="objectivesTeamMapping">
            <ObjTeamMappingTable contextHandler={useUpdateContext} />
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateObjectivesTeamMapping
