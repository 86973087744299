import React from 'react'
import { Formik, Field, Form } from 'formik'
import VerbDataList from '../../utils/VerbDataList'
import * as Yup from 'yup'
import useValidateGoal from '../../api/mutation/useValidateGoal'
import ErrorModal from '../ErrorModal'
import ObjectiveExampleText from '../ObjectiveExampleText'
import ModalSaveButton from '../ModalSubmitButton'
import { useTranslation } from 'react-i18next'
import SelectField from '../CustomMultipleSelect/customSelect'
import useTeamLeadersList from '../../api/query/useTeamLeadersList'
import TagInput from '../TagInput'
import { enumToObjColor, objectiveColorsArray } from '../../utils/colors'
import useEmployeeData_2_3 from '../../api/query/useEmployeeDataLevel2&3'
import Select from 'react-select'
import {
  selectDropdownStyles,
  DateInput,
  TextInput,
  ValidationMessage,
  TextAreaInput,
  CheckboxInput,
} from '../../utils/styling'
import { postHttpRequest, getHttpRequest } from '../../api/query/dynamicAPI'
import {
  getFilesFilingCabinetByObjId,
  uploadFileFilingCabinet,
} from '../../api/services/filingCabinet.services'
import Toast from '../../common/toast'
import MeetingDeck from '../../pages/MeetingMultiView/components/PopupPage'
import Swal from 'sweetalert2'

const SLIDESHOW_MIME_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
]

// Helper function to check if file should show slideshow icon
const shouldShowSlideshow = (file, mimetype) => {
  let val = mimetype && SLIDESHOW_MIME_TYPES.includes(mimetype) && file.id
  return val
}

const AddEditForm = ({
  handleSubmitForm,
  objective,
  handleClose,
  leaders,
  objectiveType,
  enterpriseObjectives,
  isEdit,
  isLoading,
  isAreaDashboard = false,
  isCeo = false,
  isFromObjectiveMap = false,
  objId,
}) => {
  const { t } = useTranslation(['Dashboard', 'Common'])
  const [activeTab, setActiveTab] = React.useState('basics')
  const [showExitDialog, setShowExitDialog] = React.useState(false)
  const [errors, setErrors] = React.useState({})
  const [verbOptions, setVerbOptions] = React.useState([])
  const [errorMessage, setErrorMessage] = React.useState(null)

  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)

  const [teamLeaders, setTeamLeaders] = React.useState([])

  const [companyFields, setCompanyFields] = React.useState([])
  const [objectiveModalTabs, setObjectiveModalTabs] = React.useState([
    { label: t('Common:areaObjective.basics'), value: 'basics' },
  ])
  const [mainFileAttachment, setMainFileAttachment] = React.useState(null)
  const [secondaryFileAttachments, setSecondaryFileAttachments] = React.useState([])
  const [isMeetingDeckOpen, setIsMeetingDeckOpen] = React.useState(false)
  const [selectedFileId, setSelectedFileId] = React.useState(null)

  React.useEffect(() => {
    ;(async () => {
      await getCompanyFields()
      getObjectiveDocuments()
      if (isEdit) {
        setObjectiveModalTabs((prevTabs) => [
          ...prevTabs,
          { label: t('Common:areaObjective.attachments'), value: 'attachments' },
        ])
      }

      setFormData((prev) => ({
        ...prev,
        companyFields: companyFields.reduce((acc, field) => {
          acc[field.fieldId] = ''
          return acc
        }, {}),
      }))
    })()
  }, [])

  const getCompanyFields = async () => {
    const response = await getHttpRequest('/get_company_fields')
    if (response.companyFields) {
      setCompanyFields(response.companyFields)
      if (response.companyFields.length > 0) {
        setObjectiveModalTabs((prevTabs) => [
          ...prevTabs,
          { label: t('Common:areaObjective.custom'), value: 'custom' },
        ])
      }
    }
  }

  const getObjectiveDocuments = async () => {
    if (objId === null || objId === undefined) {
      return
    }
    let formData = new FormData()
    formData.append('objId', objId)
    formData.append('objFilesOnly', true)
    const response = await getFilesFilingCabinetByObjId(formData)

    if (response && response.fileList) {
      const mainAttachment = response.fileList.find((file) => file.isObjMainAttachment)
      const secondaryAttachments = response.fileList.filter((file) => !file.isObjMainAttachment)

      setMainFileAttachment(
        mainAttachment
          ? {
              name: mainAttachment.fileName,
              id: mainAttachment.fileId,
              mimetype: mainAttachment.mimetype,
            }
          : null,
      )

      setSecondaryFileAttachments(
        secondaryAttachments.map((file) => ({
          name: file.fileName,
          id: file.fileId,
          mimetype: file.mimetype,
        })),
      )
    }
  }

  const loggedInUserEmail = localStorage.getItem('tfmu')
  const [tags, setTags] = React.useState([])
  const [isUploadingMainAttachment, setIsUploadingMainAttachment] = React.useState(false)
  const [isUploadingSecondaryAttachment, setIsUploadingSecondaryAttachment] = React.useState(false)

  const { data: teamLeaderData } = useEmployeeData_2_3()

  const [formData, setFormData] = React.useState({
    statement: objective?.statement ?? '',
    leader:
      isEdit === true || isFromObjectiveMap
        ? objective?.ownerId
        : (leaders?.employees?.find((employee) => employee.email === loggedInUserEmail)?.eId ?? ''),
    objectiveType: objectiveType,
    parentId: objective?.parentId ?? '',
    rights: objective?.rights ?? [],
    color: objective?.color ?? null,
    changedColor: false,
    greyOut: objective?.greyOut ?? false,
    detailedDescription: objective?.detailedDescription ?? '',

    companyFields: companyFields?.map((field) => ({
      [field.fieldId]:
        objective?.companyFields?.find((f) => f.fieldId === field.fieldId)?.fieldValue ?? '',
    })),
    mainAttachment: mainFileAttachment,
    secondaryAttachments: secondaryFileAttachments,
  })

  React.useEffect(() => {
    setFormData({
      statement: objective?.statement ?? '',
      leader:
        isEdit === true || isFromObjectiveMap
          ? objective?.ownerId
          : (leaders?.employees?.find((employee) => employee.email === loggedInUserEmail)?.eId ??
            ''),
      objectiveType: objectiveType,
      parentId: objective?.parentId ?? '',
      rights: objective?.rights ?? [],
      color: objective?.color ?? null,
      changedColor: false,
      greyOut: objective?.greyOut ?? false,
      detailedDescription: objective?.detailedDescription ?? '',

      companyFields: companyFields?.map((field) => ({
        [field.fieldId]:
          objective?.companyFields?.find((f) => f.fieldId === field.fieldId)?.fieldValue ?? '',
      })),
      mainAttachment: mainFileAttachment,
      secondaryAttachments: secondaryFileAttachments,
    })
  }, [objective, companyFields, isEdit, mainFileAttachment, secondaryFileAttachments])

  React.useEffect(() => {
    setTeamLeaders(teamLeaderData?.employees)
  }, [teamLeaderData])

  const handleVerbSuggestions = ({ target }) => {
    if (target.value) {
      const filteredOptions = VerbDataList.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase()),
      )
      setVerbOptions(filteredOptions)
    } else {
      setVerbOptions([])
    }
  }

  // const errorCheck = async (values, resetForm) => {
  //   // if(allowCxoUsersSeeL3Objs){
  //   //   values.leader = leaders?.employees[0].eId
  //   // }
  //   values.statement = values.statement.charAt(0).toUpperCase() + values.statement.slice(1)
  //   values.tags = tags
  //   handleSubmit(values, resetForm)
  // }

  const openErrorModal = () => {
    setIsErrorModalOpen(true)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
  }

  const buttonStyle = (color, index, enumValue) => ({
    width: '38px',
    height: '38px',
    borderRadius: '50%',
    backgroundColor: `${color}`,
    border: color === 'FFFFFF' ? '1px solid #CCCCCC' : 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    outline: 'none',
    boxShadow: index + 1 === enumValue ? '0 0 0 3px #A9A9A9' : 'none',
  })

  const validateForm = () => {
    const requiredMessage = t('Common:required')
    const newErrors = {
      statement: !formData.statement ? requiredMessage : '',
      leader: !formData.leader ? requiredMessage : '',
    }

    // Add validation logic for parentId
    if (!isCeo && (formData.objectiveType === 'area' || formData.objectiveType === 'cxo')) {
      newErrors.parentId = !formData.parentId ? requiredMessage : ''
    }

    // Add validation logic for color
    if (objectiveType === 'cxo' || objectiveType === 'enterprise') {
      newErrors.color = !formData.color ? requiredMessage : ''
    }

    // Add validation logic for leader
    if (isEdit === false) {
      newErrors.leader = !formData.leader ? requiredMessage : ''
    }

    for (const companyField of formData.companyFields) {
      const fieldId = Object.keys(companyField)[0]
      const fieldValue = companyField[fieldId]

      if (
        (!fieldValue || fieldValue === '') &&
        companyFields.find((f) => f.fieldId === fieldId).isMandatory
      ) {
        newErrors[fieldId] = requiredMessage
      }
    }

    setErrors(newErrors)
    return !Object.values(newErrors).some((error) => error !== '')
  }

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: '' }))
    }
  }

  const handleExit = () => {
    const hasChanges = Object.values(formData).some((value) => {
      if (Array.isArray(value)) return value.length > 0
      if (typeof value === 'object' && value !== null) return Object.keys(value).length > 0
      return value !== '' && value !== false && value !== null
    })

    if (hasChanges) {
      setShowExitDialog(true)
    } else {
      window.history.back()
    }
  }

  const selectedLeader = React.useMemo(() => {
    return leaders?.employees?.find((emp) => parseInt(emp.eId) === parseInt(formData.leader))
  }, [leaders?.employees, formData.leader])

  const selectedEnterpriseObjective = React.useMemo(() => {
    return enterpriseObjectives?.find((obj) => obj.objId === parseInt(formData.parentId))
  }, [enterpriseObjectives, formData.parentId])

  const resetForm = () => {
    setFormData({
      statement: '',
      leader: '',
      color: '',
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let isValid = validateForm()

    if (isValid) {
      formData.tags = tags
      handleSubmitForm(formData, resetForm)
      handleClose()
    }
  }

  const deleteFile = async (fileId) => {
    if (!fileId) return
    try {
      await postHttpRequest('/update_file_from_filing_cabinet', {
        fileId: fileId,
        isDeleted: true,
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const handleUploadError = (errorResult) => {
    if (errorResult === 'timeout') {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.fileUploadRequestTimeout'),
      })
    } else if (errorResult === 'malicious') {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.fileScanningFailed'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const handleFileUpload = async (file, isMainAttachment) => {
    let formData = new FormData()
    formData.append('objId', objId)
    formData.append('file', file, file.name)
    formData.append('isMainAttachment', isMainAttachment)
    formData.append('shouldDownloadAsPdf', 'true')
    if (isMainAttachment) {
      setIsUploadingMainAttachment(true)
    } else {
      setIsUploadingSecondaryAttachment(true)
    }

    try {
      const response = await uploadFileFilingCabinet(formData)
      if (response.upload_status === 'success') {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.fileUploadSuccess'),
        })

        const fileData = {
          name: file.name,
          id: response.fileId,
          mimetype: file.type, // Include the mimetype from the uploaded file
        }

        if (isMainAttachment) {
          handleChange('mainAttachment', fileData)
        } else {
          setFormData((prev) => ({
            ...prev,
            secondaryAttachments: [...prev.secondaryAttachments, fileData],
          }))
        }
        return true
      } else {
        handleUploadError(response.data.upload_status)
        return false
      }
    } catch (error) {
      handleUploadError()
      return false
    } finally {
      setIsUploadingMainAttachment(false)
      setIsUploadingSecondaryAttachment(false)
    }
  }

  const moveSecondaryToMain = async (file) => {
    const originalSecondaryAttachments = [...formData.secondaryAttachments]
    const originalMainAttachment = formData.mainAttachment

    try {
      const newSecondaryAttachments = formData.secondaryAttachments.filter((f) => f.id !== file.id)

      if (formData.mainAttachment) {
        newSecondaryAttachments.push(formData.mainAttachment)
        await postHttpRequest('/update_file_from_filing_cabinet', {
          fileId: formData.mainAttachment.id,
          isMainAttachment: false,
        })
      }

      handleChange('secondaryAttachments', newSecondaryAttachments)
      handleChange('mainAttachment', file)

      await postHttpRequest('/update_file_from_filing_cabinet', {
        fileId: file.id,
        isMainAttachment: true,
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
      // revert the changes
      handleChange('secondaryAttachments', originalSecondaryAttachments)
      handleChange('mainAttachment', originalMainAttachment)
    }
  }

  return (
    <>
      {isMeetingDeckOpen && selectedFileId && (
        <MeetingDeck
          closeModal={() => {
            setIsMeetingDeckOpen(false)
            setSelectedFileId(null)
          }}
          readOnly={true}
          fromKanban={true}
          fileId={selectedFileId}
        />
      )}
      <div className="tailwind-scoped w-full max-w-2xl mx-auto p-6 ">
        <div className="space-y-6">
          {/* Header */}
          <form onSubmit={handleSubmit}>
            {/* Tabs */}
            <div className="border-b border-gray-200 mb-6">
              <nav
                className={`flex flex-row -mb-px ${objectiveModalTabs.length > 2 ? 'justify-between' : 'gap-12'}`}
              >
                {objectiveModalTabs.map((tab) => (
                  <button
                    key={tab.value}
                    type="button"
                    onClick={() => setActiveTab(tab.value)}
                    className={`mr-2 py-2 px-4 font-medium ${
                      activeTab === tab.value
                        ? 'border-b-2 border-blue-500 text-blue-600'
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </nav>
            </div>

            {/* Basics Tab */}
            {activeTab === 'basics' && (
              <div className="space-y-6">
                {/* Leader */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('Common:areaObjective.ownerName')}
                    &nbsp; {isEdit === false && <span className="text-red-500">*</span>}
                  </label>

                  <Select
                    id="leader"
                    name="leader"
                    options={
                      leaders?.employees?.map((leader) => ({
                        value: leader.eId,
                        label: leader.name,
                      })) || []
                    }
                    value={
                      selectedLeader
                        ? {
                            value: selectedLeader.eId,
                            label: selectedLeader.name,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      setFormData((prev) => ({
                        ...prev,
                        leader: selectedOption?.value,
                      }))
                    }}
                    styles={selectDropdownStyles}
                    placeholder={t('Common:areaObjective.selectOwner')}
                  />
                  <ValidationMessage show={errors.leader} message={errors.leader} />
                </div>

                {/* Enterprise Link */}
                {objectiveType === 'area' && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('Common:areaObjective.enterpriseLink')}
                      &nbsp;{' '}
                      {!isCeo && (objectiveType === 'area' || objectiveType === 'cxo') && (
                        <span className="text-red-500">*</span>
                      )}
                    </label>
                    <Select
                      id="parentId"
                      name="parentId"
                      options={
                        enterpriseObjectives?.map((obj) => ({
                          value: obj.objId,
                          label: obj.statement,
                        })) || []
                      }
                      value={
                        selectedEnterpriseObjective
                          ? {
                              value: selectedEnterpriseObjective.objId,
                              label: selectedEnterpriseObjective.statement,
                            }
                          : null
                      }
                      onChange={(selectedOption) => {
                        setFormData((prev) => ({
                          ...prev,
                          parentId: selectedOption?.value,
                        }))
                      }}
                      styles={selectDropdownStyles}
                      placeholder={t('Common:areaObjective.selectEnterprise')}
                    />
                    <ValidationMessage show={errors.parentId} message={errors.parentId} />
                  </div>
                )}

                {/* Statement */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('Common:areaObjective.statement')}
                    &nbsp; <span className="text-red-500">*</span>
                  </label>
                  <TextInput
                    id="statement"
                    name="statement"
                    value={formData.statement}
                    onChange={(e) => setFormData({ ...formData, statement: e.target.value })}
                    placeholder={t('Common:areaObjective.enterObjectiveStatement')}
                  />
                  <ValidationMessage show={errors.statement} message={errors.statement} />
                </div>

                {/* Detailed Description */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('Common:areaObjective.detailedDescription')}
                  </label>
                  <TextAreaInput
                    id="detailedDescription"
                    name="detailedDescription"
                    value={formData.detailedDescription}
                    onChange={(e) =>
                      setFormData({ ...formData, detailedDescription: e.target.value })
                    }
                    placeholder={t('Common:areaObjective.enterObjectiveDetailedDescription')}
                  />
                  <ValidationMessage
                    show={errors.detailedDescription}
                    message={errors.detailedDescription}
                  />
                </div>

                {/* Tags */}
                {objectiveType === 'area' && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('addObjectivesModal.tags')}
                    </label>
                    <TagInput
                      existingTags={tags}
                      setTagsExternally={setTags}
                      objective={true}
                      objectiveId={isEdit ? (objId ?? objective?.id) : null}
                      addEditObjectiveModal={true}
                      isFromObjectiveMap={isFromObjectiveMap}
                      newStyle={true}
                      placeholder={t('addObjectivesModal.enterTags')}
                    />
                  </div>
                )}

                {/* Shared */}

                {objectiveType === 'area' && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('addObjectivesModal.shared')}
                    </label>
                    <div data-testid="multi-options-2">
                      <Select
                        id="rights"
                        isMulti
                        options={teamLeaders?.map((leader) => ({
                          value: leader.eId,
                          label: leader.name,
                        }))}
                        value={
                          formData.rights?.map((right) => ({
                            value: right.eId,
                            label: right.name,
                          })) || []
                        }
                        onChange={(selectedOptions) => {
                          handleChange(
                            'rights',
                            selectedOptions?.map((option) => ({
                              eId: option.value,
                              name: option.label,
                            })) || [],
                          )
                        }}
                        placeholder={t('Common:formPlaceHolder.select')}
                        error={errors.rights}
                        styles={selectDropdownStyles}
                      />
                    </div>
                    {errors.rights && <p className="text-sm text-red-500">{errors.rights}</p>}
                  </div>
                )}

                {/* Color Theme */}
                {(objectiveType === 'cxo' || objectiveType === 'enterprise') && (
                  <div className="space-y-2">
                    <div className="form-field action-field form-field action-field--kanban">
                      <label className="block text-sm font-medium text-gray-700 text-left">
                        {t('Common:enterpriseObjective.colorTheme') +
                          '\n' +
                          '(' +
                          t('Common:enterpriseObjective.appliesToAllAssociatedObjectives') +
                          ')'}
                        &nbsp;{' '}
                        {(objectiveType === 'cxo' || objectiveType === 'enterprise') && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '16px',
                          padding: '16px',
                        }}
                      >
                        {objectiveColorsArray.map((color, index) => (
                          <button
                            key={index}
                            type="button"
                            style={buttonStyle(color, index, formData.color)}
                            onClick={() => {
                              setFormData((prev) => ({
                                ...prev,
                                color: index + 1,
                                changedColor: index + 1 !== objective?.color,
                              }))
                            }}
                            onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
                            onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                          />
                        ))}
                      </div>
                      <ValidationMessage show={errors.color} message={errors.color} />
                    </div>
                  </div>
                )}

                {(objective?.level == 2 || objective?.level == 3 || isEdit == false) && (
                  <div className="space-y-2 flex flex-col">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {t('Common:areaObjective.differentiate')}
                    </label>
                    <div className="flex flex-row items-center space-x-2">
                      <input type="checkbox" name="greyOut" />
                      <span className="text-sm text-gray-700">
                        {t('Common:areaObjective.greyOut')}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Custom Fields Tab */}
            {activeTab === 'custom' && (
              <div className="space-y-6">
                <div className="flex">
                  {/* <span className="text-sm font-medium text-gray-700 text-left">
                  {t('Common:areaObjective.customFields')}
                </span> */}
                </div>

                {companyFields?.map((field, index) => (
                  <div key={field.fieldId} className="flex gap-2">
                    <label className="block text-sm font-medium text-gray-700 text-left">
                      {field.fieldName}
                      &nbsp;
                      {field.isMandatory && <span className="text-red-500">*</span>}
                    </label>
                    <input
                      type="text"
                      value={formData?.companyFields?.[index]?.[field.fieldId]}
                      onChange={(e) => {
                        const updatedCompanyFields = formData?.companyFields?.map(
                          (companyField, i) => {
                            if (i === index) {
                              return { [field.fieldId]: e.target.value }
                            }
                            return companyField
                          },
                        )
                        handleChange('companyFields', updatedCompanyFields)
                      }}
                      className="flex-1 px-3 py-2 border border-gray-300 rounded-md hover:border-black"
                      placeholder={field.fieldName}
                    />
                    <ValidationMessage
                      show={errors[field.fieldId]}
                      message={errors[field.fieldId]}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* Attachments Tab */}
            {activeTab === 'attachments' && (
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 text-left">
                    {t('Common:areaObjective.mainAttachment')}
                  </label>

                  {!formData.mainAttachment ? (
                    // Show upload UI only when no main attachment exists
                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
                      <input
                        type="file"
                        className="hidden"
                        id="main-attachment"
                        onChange={async (e) => {
                          if (isUploadingMainAttachment !== true) {
                            const file = e.target.files[0]
                            if (file) {
                              await handleFileUpload(file, true)
                              document.getElementById('main-attachment').value = ''
                            }
                          }
                        }}
                      />
                      <div className="flex flex-col items-center">
                        <span className="material-symbols-outlined w-8 h-8 text-gray-400">
                          upload
                        </span>
                        <span className="mt-2 text-sm text-gray-500">
                          {t('Common:areaObjective.dropFilesHereOrClickToUpload')}
                        </span>
                        <button
                          type="button"
                          onClick={() => document.getElementById('main-attachment').click()}
                          className="mt-2 px-3 py-1 text-sm border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                          {isUploadingMainAttachment === true ? (
                            <div
                              style={{
                                width: '0.5rem',
                                height: '0.5rem',
                                margin: '0',
                                borderTopColor: 'blue',
                                borderWidth: '5px',
                              }}
                              className="loading-spinner"
                            ></div>
                          ) : null}
                          {t('Common:areaObjective.uploadMainAttachment')}
                        </button>
                      </div>
                    </div>
                  ) : (
                    // Show file info when main attachment exists
                    <div className="flex flex-row justify-between items-center bg-slate-50 p-2 rounded mt-2">
                      <span className="text-sm text-left truncate flex-1">
                        {formData.mainAttachment.name}
                      </span>
                      <div className="flex flex-row items-center gap-2 flex-shrink-0">
                        {shouldShowSlideshow(
                          formData.mainAttachment,
                          formData.mainAttachment.mimetype,
                        ) && (
                          <>
                            <div
                              className="tooltip text-gray-500"
                              onClick={() => {
                                setIsMeetingDeckOpen(true)
                                setSelectedFileId(formData.mainAttachment.id)
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <span className="material-symbols-outlined text-base text-gray-500">
                                slideshow
                              </span>
                              <span class="tooltiptext">
                                {' '}
                                {t('Common:tooltip.viewUploadedPresentation')}{' '}
                              </span>
                            </div>
                          </>
                        )}
                        <button
                          type="button"
                          onClick={async () => {
                            const result = await Swal.fire({
                              title: t('Common:modalMessages.areYouSureDeleteThisFile'),
                              icon: 'question',
                              showCancelButton: true,
                              confirmButtonText: t('Common:commonButtons.yes'),
                              cancelButtonText: t('Common:commonButtons.no'),
                            })
                            if (result.isConfirmed) {
                              const deletedFileId = formData.mainAttachment.id
                              handleChange('mainAttachment', null)
                              deleteFile(deletedFileId)
                            }
                          }}
                          className="p-1 hover:bg-gray-100 rounded-md tooltip"
                        >
                          <span class="tooltiptext"> {t('Common:tooltip.deleteFile')}</span>
                          <span className="material-symbols-outlined text-base">delete</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="space-y-2 pt-4 border-t">
                  <div className="flex flex-row items-center justify-between">
                    <label className="block text-sm font-medium text-gray-700">
                      {t('Common:areaObjective.secondaryAttachments')}
                    </label>
                    <button
                      type="button"
                      className="px-3 py-1 text-sm border border-gray-300 rounded-md hover:bg-gray-50 flex flex-row items-center gap-1"
                      onClick={() => {
                        if (isUploadingSecondaryAttachment !== true) {
                          document.getElementById('secondary-attachments').click()
                        }
                      }}
                    >
                      {isUploadingSecondaryAttachment === true ? (
                        <div
                          style={{
                            width: '0.5rem',
                            height: '0.5rem',
                            margin: '0',
                            borderTopColor: 'blue',
                            borderWidth: '5px',
                          }}
                          className="loading-spinner"
                        ></div>
                      ) : (
                        <span className="material-symbols-outlined w-4 h-4 text-sm">add</span>
                      )}
                      {t('Common:areaObjective.addFiles')}
                    </button>
                  </div>
                  <input
                    id="secondary-attachments"
                    type="file"
                    multiple
                    className="hidden"
                    onChange={async (e) => {
                      const files = Array.from(e.target.files || [])
                      for (const file of files) {
                        await handleFileUpload(file, false)
                      }
                      document.getElementById('secondary-attachments').value = ''
                    }}
                  />
                  <div className="space-y-2">
                    {formData.secondaryAttachments.map((file, index) => (
                      <div
                        key={index}
                        className="flex flex-row justify-between items-center bg-slate-50 p-2 rounded"
                      >
                        <span className="text-sm text-left truncate flex-1">{file.name}</span>
                        <div className="flex flex-row items-center gap-2 flex-shrink-0">
                          {shouldShowSlideshow(file, file.mimetype) && (
                            <>
                              <div
                                className="tooltip text-gray-500"
                                onClick={() => {
                                  setIsMeetingDeckOpen(true)
                                  setSelectedFileId(file.id)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <span className="material-symbols-outlined text-base text-gray-500">
                                  slideshow
                                </span>
                                <span class="tooltiptext">
                                  {' '}
                                  {t('Common:tooltip.viewUploadedPresentation')}{' '}
                                </span>
                              </div>
                            </>
                          )}
                          <button
                            type="button"
                            onClick={async () => {
                              const result = await Swal.fire({
                                title: t('Common:modalMessages.areYouSureDeleteThisFile'),
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: t('Common:commonButtons.yes'),
                                cancelButtonText: t('Common:commonButtons.no'),
                              })
                              if (result.isConfirmed) {
                                const newFiles = formData.secondaryAttachments.filter(
                                  (_, i) => i !== index,
                                )
                                const deletedFileId = file.id
                                handleChange('secondaryAttachments', newFiles)
                                deleteFile(deletedFileId)
                              }
                            }}
                            className="p-1 hover:bg-gray-100 rounded-md tooltip"
                          >
                            <span class="tooltiptext"> {t('Common:tooltip.deleteFile')}</span>
                            <span className="material-symbols-outlined text-base">delete</span>
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              moveSecondaryToMain(file)
                            }}
                            className="p-1 hover:bg-gray-100 rounded-md tooltip"
                          >
                            <span class="tooltiptext"> {t('Common:tooltip.markAsMainFile')} </span>
                            <span
                              class="material-symbols-outlined text-base"
                              style={{
                                transform: 'rotate(90deg)',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              first_page
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                    {formData.secondaryAttachments.length === 0 && (
                      <div className="text-sm text-slate-500 text-center py-4">
                        {t('Common:areaObjective.noSecondaryAttachments')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Form Actions */}
            <div className="flex flex-row justify-end gap-4 pt-6">
              <button
                type="button"
                onClick={handleExit}
                className="px-4 py-2 w-40 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                {t('Common:commonButtons.cancel')}
              </button>
              <button
                type="submit"
                className="px-4 py-2 w-40 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                {isEdit === false
                  ? t('Common:areaObjective.createObjective')
                  : t('Common:areaObjective.saveChanges')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddEditForm
