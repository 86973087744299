import React, { createContext, useContext, useState, useEffect } from 'react'
import { getHttpRequest } from '../api/query/dynamicAPI'
import { trackAuthEvent } from './utils/analyticsEvents'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState({
    // `authenticated` can be 'none', 'firstLogin', 'fully'.
    authenticated: 'none',
    loading: true,
    userData: null,
  })

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await getHttpRequest('/f/userlocal')
        if (response.noauth) {
          setAuthStatus({ authenticated: 'none', loading: false, userData: null })
          trackAuthEvent('session_expired')
        } else if (response.first_time_user) {
          setAuthStatus({ authenticated: 'firstLogin', loading: false, userData: null })
          trackAuthEvent('first_time_login')
        } else {
          setAuthStatus({ authenticated: 'fully', loading: false, userData: response })
          trackAuthEvent('login_success', { user_type: response.user_type })
        }
      } catch (error) {
        setAuthStatus({ authenticated: 'none', loading: false, userData: null })
        trackAuthEvent('auth_error', { error_message: error.message })
      }
    }

    checkAuthStatus()
  }, [])

  // const enhancedSetAuthStatus = (newStatus) => {
  //   // Track logout events
  //   if (authStatus.authenticated !== 'none' && newStatus.authenticated === 'none') {
  //     trackAuthEvent('logout')
  //   }
  //   // Track user status changes
  //   if (newStatus.authenticated !== authStatus.authenticated) {
  //     trackAuthEvent('status_change', {
  //       from: authStatus.authenticated,
  //       to: newStatus.authenticated
  //     })
  //   }
  //   setAuthStatus(newStatus)
  // }

  return (
    <AuthContext.Provider value={{ authStatus, setAuthStatus }}>{children}</AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
