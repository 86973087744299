import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { kpiSubRoutes, routes, updateSubRoutes } from '../../utils/routes'
import {
  getCompanySetupWizardStatus,
  getConsolidatedConfigSettings,
  hasRole,
  isTeamLeader,
} from '../../utils/auth'
import ShowUwStatusesModal from '../ShowUwStatusesModal'
import Swal from 'sweetalert2'
import { useNavigationContext } from '../../pages/Brainstorm/Context/NavigationContext'

import { Link } from 'react-router-dom'
import { myAction } from '../../api/services/dashboard.services'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'
import { Tooltip } from 'react-tooltip'
import useEditKanbanBoardChangeActivityStatus from '../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import { getUpdateWizStatus, getHasSetupWizardAction } from '../../utils/auth'
import MeiReportsModal from '../MeiReportsModal'
import TeamPerformanceMessage from '../ChatBot/component/TeamPerformanceMessage'
import ManageMeetingModal from '../ManageMeetingModal'
import ManageTagsModal from '../ManageTagsModal'
import EditReminderModal from '../EditReminderModal'
import ViewFollowUpModal from '../ViewFollowupModal'

function NavigationDropdownMenu(props) {
  const { t } = useTranslation(['Common', 'Phoenix'])
  const navigate = useNavigate()
  const { state, dispatch } = useNavigationContext()
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const currentUser = localStorage.getItem('tfmu')
  const showGovernanceMgmntFeatures =
    getConsolidatedConfigSettings('show_governance_mgmt_features') === true

  const enableKpiWizard = getConsolidatedConfigSettings('enable_kpi_wizard') === true

  const enableOdinInbox = getConsolidatedConfigSettings('enable_odin_inbox') === true
  const canManageMeeting = getConsolidatedConfigSettings('can_manage_meeting') === true
  const canManageTags = getConsolidatedConfigSettings('can_manage_tags') === true

  const [showUwStatusesModal, setShowUwStatusesModal] = React.useState(false)
  const [showMore, setShowMore] = React.useState(false)
  const [action, setAction] = React.useState(null)
  const [showTooltips, setShowTooltips] = React.useState({})
  const [reload, setReload] = React.useState(0)
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()
  const [meiMdoalOn, setMeiModalOn] = React.useState(false)
  const cfg = JSON.parse(localStorage.getItem('cfg'))
  const ifShowMasterMeiReport = cfg.show_master_mei_report ?? false

  const [showTeamPerformanceModal, setShowTeamPerformanceModal] = React.useState(false)
  const [showMeetingManagementModal, setShowMeetingManagementModal] = React.useState(false)
  const [showManageTagModal, setShowManageTagModal] = React.useState(false)
  const [vieReminderModalOpen, setViewReminderModalOpen] = React.useState(false)
  const [viewFollowUpModalOpen, setViewFollowUpModalOpen] = React.useState(false)

  let hasPendingUW = getUpdateWizStatus()
  const enableSetupAgent = getConsolidatedConfigSettings('enable_setup_agent') === true
  const enableTeamCadence = getConsolidatedConfigSettings('enable_team_cadence_report') === true

  const isCompanyTransforML = localStorage.getItem('tfci') === '43'

  const handleNavigation = async (path, navigateState = {}) => {
    if (state?.preventDefaultWhileNavigating === true) {
      const result = await Swal.fire({
        title: t('Phoenix:navigation.areYouSure'),
        text: t('Phoenix:navigation.youWillLoseCurrentProgress'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('Phoenix:navigation.yesNavigate'),
        cancelButtonText: t('Phoenix:navigation.noStayHere'),
      })

      if (result.isConfirmed) {
        dispatch({ type: 'SET_PREVENT_DEFAULT_WHILE_NAVIGATING', payload: false })
        navigate(path, { state: navigateState })
      }
    } else {
      navigate(path, { state: navigateState })
    }
  }

  const closeMeiReportModal = () => {
    setMeiModalOn(false)
  }

  return (
    <div>
      {viewFollowUpModalOpen && (
        <ViewFollowUpModal
          isModalOpen={viewFollowUpModalOpen}
          closeModal={() => {
            setViewFollowUpModalOpen(false)
          }}
        />
      )}
      {vieReminderModalOpen && (
        <EditReminderModal
          isModalOpen={vieReminderModalOpen}
          closeModal={() => {
            setViewReminderModalOpen(false)
          }}
        />
      )}
      {showUwStatusesModal === true && (
        <ShowUwStatusesModal
          isModalOpen={showUwStatusesModal}
          closeModal={() => setShowUwStatusesModal(false)}
        />
      )}
      {showTeamPerformanceModal === true && (
        <TeamPerformanceMessage
          onlyShowModal={true}
          isTeamPerformanceModalOpen={showTeamPerformanceModal}
          closeTeamformanceModal={() => {
            setShowTeamPerformanceModal(false)
          }}
        />
      )}
      {/* {showMeetingManagementModal === true && (
        <ManageMeetingModal
          closeModal={() => {
            setShowMeetingManagementModal(false)
          }}
        />
      )} */}
      {showManageTagModal === true && (
        <ManageTagsModal
          closeModal={() => {
            setShowManageTagModal(false)
          }}
        />
      )}
      <Menu
        menuClassName="navigation-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: 'white',
              border: '1px solid rgb(176, 173, 173)',
              borderRadius: '10px',
              padding: '0.5rem 1rem',
              color: 'black',
              cursor: 'pointer',
              fontFamily: '"Open Sans", "Arial", "Helvetica", "system-ui", sans-serif',
              width: '10rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '2rem',
            }}
          >
            <div>{t('navigation.iWantTo')}</div>

            <span class="navigation-toggle material-symbols-outlined">expand_more</span>
          </MenuButton>
        }
      >
        <>
          {/* <MenuItem
            style={{ textAlign: 'left' }}
            onClick={() => {
              navigate(`/${routes.kanban}`)
            }}
          >
            {t('navigation.manageMyObjectivesAndActivities')}
          </MenuItem> */}
          {(hasRole('team') || hasRole('area')) && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                handleNavigation(`/${routes.update}`, {
                  state: { showBGUonly: true, isFromDashBoard: true },
                })
              }}
            >
              {t('navigation.prepareForAMeeting')}
            </MenuItem>
          )}
          {hasRole('team') && !isDelegateTl && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                handleNavigation(`/${routes.meetingMultiView}`)
              }}
            >
              {t('navigation.holdAMeeting')}
            </MenuItem>
          )}
          {(hasRole('area') || hasRole('cxo') || (hasRole('team') && isDelegateTl)) && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                handleNavigation(`/${routes.meetingMultiView}`)
              }}
            >
              {t('navigation.holdAMeeting')}
            </MenuItem>
          )}

          {(hasRole('team') || hasRole('area') || hasRole('ic') || hasRole('cxo')) && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                handleNavigation(`/${routes.emailReminder}`)
              }}
            >
              {t('navigation.seeMyToDoList')}
            </MenuItem>
          )}
          {showGovernanceMgmntFeatures && (
            <>
              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  setShowUwStatusesModal(true)
                }}
              >
                {t('navigation.updateWizStatus')}
              </MenuItem>

              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  handleNavigation(`/${routes.cxomgmtscore}`, { state: { isFromQuickLinks: true } })
                }}
              >
                {t('navigation.mei')}
              </MenuItem>
            </>
          )}
          {ifShowMasterMeiReport && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                setMeiModalOn(true)
              }}
            >
              {t('navigation.mei')}
            </MenuItem>
          )}

          {(currentUser === 'david.benello@v-nova.com' ||
            currentUser === 'guido.meardi@v-nova.com' ||
            currentUser === 'pierdavide.marcolongo@v-nova.com' ||
            currentUser === 'fab@v-nova.com' ||
            currentUser === 'andrew.jordan@v-nova.com' ||
            currentUser === 'simone.ferrara@v-nova.com' ||
            currentUser === 'guendalina.cobianchi@v-nova.com') && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                handleNavigation(`/${routes.sankey}`)
              }}
            >
              {t('navigation.companyExecutionStats')}
            </MenuItem>
          )}

          {(hasPendingUW == 0 || hasPendingUW == 2) && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                handleNavigation(`/${routes.update}`)
              }}
            >
              {t('navigation.youHaveAWizardToComplete')}
            </MenuItem>
          )}

          {!enableSetupAgent && getHasSetupWizardAction() && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                if (hasRole('team')) {
                  handleNavigation(`/${routes.teamleader}`)
                } else if (hasRole('area')) {
                  handleNavigation(`/${routes.areaLeader}`)
                }
              }}
            >
              {t('navigation.youHaveAWizardToComplete')}
            </MenuItem>
          )}

          {enableOdinInbox && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                handleNavigation(`/${routes.odinInbox}`)
              }}
            >
              {t('navigation.viewMyOdinInbox')}
            </MenuItem>
          )}

          {(hasRole('area') || hasRole('cxo')) && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                setShowTeamPerformanceModal(true)
              }}
            >
              {t('navigation.viewTeamPerformance')}
            </MenuItem>
          )}

          {(hasRole('team') || hasRole('area')) && enableKpiWizard && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                handleNavigation(`/${routes.kpi}/${kpiSubRoutes.intro}`)
              }}
            >
              {t('navigation.completeMyKpiWizard')}
            </MenuItem>
          )}
          {/* {canManageMeeting && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                setShowMeetingManagementModal(true)
              }}
            >
              {t('navigation.manageMeeting')}
            </MenuItem>
          )} */}
          {canManageTags && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                setShowManageTagModal(true)
              }}
            >
              {t('navigation.manageTags')}
            </MenuItem>
          )}
          {isCompanyTransforML && (
            <>
              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  setViewReminderModalOpen(true)
                }}
              >
                {t('navigation.manageReminder')}
              </MenuItem>

              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  setViewFollowUpModalOpen(true)
                }}
              >
                {t('navigation.manageFollowup')}
              </MenuItem>
            </>
          )}
          {/* {hasPnedingSetup ==0 && hasRole('team') && <MenuItem
            style={{ textAlign: 'left' }}
            onClick={() => {
              navigate(`/${routes.setupIntroScreen}`, {
                state: {
                  teamLeaderIntro: true,
                },
              })
            }}
          >
            Company Setup Agent
          </MenuItem>} */}
        </>
        {(enableTeamCadence || hasRole('cxo')) && (
          <MenuItem
            style={{ textAlign: 'left' }}
            onClick={() => {
              handleNavigation(`/${routes.meetingReport}`)
            }}
          >
            {t('navigation.cadenceReport')}
          </MenuItem>
        )}
      </Menu>
      {meiMdoalOn && <MeiReportsModal closeModal={() => closeMeiReportModal()} />}
    </div>
  )
}

export default NavigationDropdownMenu
