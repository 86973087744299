import React from 'react'
import next from '../../../../assets/next-white.svg'
import SubmitUpdateWizard from './components/SubmitUpdateWizard'
import { stepsLabelsGenerator } from '../../hooks/useUpdateWizardSteps'
import usePendingActionData from '../../../../api/query/getPendingActionData'
import Modal from '../../../../components/Modal'
import WizardButton from '../../../../components/WizardButton'
import { useTranslation } from 'react-i18next'
import { postHttpRequest } from '../../../../api/query/dynamicAPI'
import { getUpdateWizStatus } from '../../../../utils/auth'

const NextPreviousActions = ({
  hideBackButton,
  submitIsLoading,
  isLastStep,
  currentStep,
  handleNext,
  handlePrevious,
  errorMessage,
  setErrorMessage,
  handleSubmit,
  showMeetingTopicsInUW = false,
  screenPlacement = 'bottom',
}) => {
  const pendingActionData = usePendingActionData()
  const isPendingAction = pendingActionData?.data?.pending_action_count > 0 ? true : false
  const [isPendingActionModalOpen, setIsPendingActionModalOpen] = React.useState(false)
  const [pendingActionModalMsg, setPendingActionModalMsg] = React.useState(null)
  // const [isSaveButtonDisabled, setIsSaveButtonDisabled] = React.useState(false)
  const { t } = useTranslation(['Common'])

  const stepsLabels = stepsLabelsGenerator(showMeetingTopicsInUW)

  const resetErrorMessage = () => {
    setErrorMessage(null)
  }

  const closePendingActionModal = () => {
    setIsPendingActionModalOpen(false)
  }

  const showPendingActionErrorModal = () => {
    setPendingActionModalMsg(
      'You have a pending reallocation on your Dashboard. Complete this before going further. ',
    )
    setIsPendingActionModalOpen(true)
  }

  function updateUwStatus() {
    const tlWizStatus = getUpdateWizStatus()
    if (tlWizStatus != null && tlWizStatus != 2 && tlWizStatus != 3) {
      // Update UW status to 2
      postHttpRequest('/change_uw_completion_stage', { stage: 2 })
      localStorage.setItem('tfuwtl', '2')
    }
  }

  return (
    <>
      <div
        className={`buttons-area-container ${
          screenPlacement === 'top' ? 'top-top-top' : 'bottom-bottom-bottom'
        } w-full `}
        style={screenPlacement === 'top' ? { margin: '0' } : {}}
      >
        <div className="error-container">
          {errorMessage ? <div className="validation-error">{errorMessage}</div> : null}
        </div>

        <div
          className={`dual-button-container flex flex-row items-center gap-4 w-10/12 mx-auto ${
            currentStep === stepsLabels.INTRO && screenPlacement === 'bottom'
              ? 'justify-center'
              : 'justify-between'
          }`}
          style={screenPlacement === 'top' ? { margin: '0' } : {}}
        >
          {isPendingActionModalOpen === true ? (
            <Modal
              title={t('holdOn')}
              children={pendingActionModalMsg}
              isModalOpen={isPendingActionModalOpen}
              closeModal={closePendingActionModal}
            ></Modal>
          ) : null}
          {currentStep === stepsLabels.INTRO && screenPlacement === 'bottom' && (
            <button
              onClick={() => {
                if (isPendingAction) {
                  showPendingActionErrorModal()
                } else {
                  handleNext()
                  updateUwStatus()
                }
              }}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-md transition duration-200 flex items-center justify-center h-10 w-32 shadow-sm"
              id="uw-start-btn"
            >
              {t('commonButtons.clickToBegin')}
            </button>
          )}
          {currentStep !== stepsLabels.INTRO && (
            <>
              {!hideBackButton && (
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-md transition duration-200 flex items-center justify-center h-8 w-10 shadow-sm"
                  onClick={() => {
                    handlePrevious()
                    resetErrorMessage()
                  }}
                  id="uw-back-btn"
                >
                  <span class="material-symbols-outlined">chevron_left</span>
                </button>
              )}
              {isLastStep ? (
                <div>
                  <SubmitUpdateWizard
                    submitIsLoading={submitIsLoading}
                    currentStep={currentStep}
                    setErrorMessage={setErrorMessage}
                    handleSubmit={handleSubmit}
                  />
                </div>
              ) : (
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-md transition duration-200 flex items-center justify-center h-8 w-10 shadow-sm"
                  onClick={() => {
                    handleNext()
                  }}
                  id="uw-next-btn"
                >
                  <span class="material-symbols-outlined">chevron_right</span>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default NextPreviousActions
