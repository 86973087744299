import React, { useReducer, useEffect } from 'react'
import ApplicationReducer, { defaultState } from './ApplicationReducer'

const NavigationContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
})

const NavigationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ApplicationReducer, defaultState)
  console.log('state', state)
  console.log('dispatch', dispatch)

  return (
    <NavigationContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

const useNavigationContext = () => {
  const context = React.useContext(NavigationContext)
  if (!context) {
    throw new Error('useNavigationContext must be used within an NavigationProvider')
  }
  return context
}

export { NavigationProvider, useNavigationContext }
