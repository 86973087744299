import React from 'react'
import { useTranslation } from 'react-i18next'
import OverdueKPIs from '../../../../components/OverdueKPIs'

const KPIUpdatePage = ({}) => {
  const { t } = useTranslation(['Common'])
  return (
    <>
      <div className="wizard-step" style={{ width: '85%' }}>
        <h2 className="wizard-step__title" style={{ marginBottom: '1rem' }}>
          {' '}
          {t('kpi.almostOverdueKPITitle')}{' '}
        </h2>

        <div className="wizard-step__main-content-container">
          <div className="objectivesTeamMapping">
            <>
              <div style={{ paddingBottom: '10px' }}>
                <OverdueKPIs />
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export default KPIUpdatePage
