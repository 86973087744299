import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { routes, updateSubRoutes } from '../../utils/routes'
import { getCompanyConfigSettings, getConsolidatedConfigSettings, hasRole } from '../../utils/auth'
import ShowUwStatusesModal from '../ShowUwStatusesModal'

function UserConfigurationDropdown(props) {
  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const currentUser = localStorage.getItem('tfmu')
  const showGovernanceMgmntFeatures =
    getConsolidatedConfigSettings('show_governance_mgmt_features') === true

  const [showUwStatusesModal, setShowUwStatusesModal] = React.useState(false)

  const productSettings = getCompanyConfigSettings('productSettings')

  const isVnovaIc = hasRole('ic') && productSettings

  return (
    <div>
      {showUwStatusesModal === true && (
        <ShowUwStatusesModal
          isModalOpen={showUwStatusesModal}
          closeModal={() => setShowUwStatusesModal(false)}
        />
      )}
      <Menu
        menuClassName="navigation-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: 'white',
              border: 'black',
              padding: '0',
              textDecoration: 'none',
              color: '#425066',
              cursor: 'pointer',
              fontWeight: '600',
              fontSize: '16px',
              fontFamily: '"Open Sans", "Arial", "Helvetica", "system-ui", sans-serif',
              paddingTop: '.8rem',
            }}
          >
            <span class="navigation-toggle material-symbols-outlined">expand_more</span>
          </MenuButton>
        }
      >
        <>
          {props.loginProtected && (
            <MenuItem style={{ textAlign: 'left' }} onClick={() => props.logout()}>
              <div>
                <div className="nav-link">{t('navigation.logout')}</div>
              </div>
            </MenuItem>
          )}
          <MenuItem style={{ textAlign: 'left' }} onClick={() => props.tutorialSettings()}>
            <div>
              <div className="nav-link">{t('navigation.tutorialSettings')}</div>
            </div>
          </MenuItem>
          {!isVnovaIc && (
            <MenuItem style={{ textAlign: 'left' }} onClick={() => props.userSettings()}>
              <div>
                <div className="nav-link">{t('navigation.userSettings')}</div>
              </div>
            </MenuItem>
          )}
          <MenuItem style={{ textAlign: 'left' }} onClick={() => props.downloadDesktopApp()}>
            <div>
              <div className="nav-link">{t('navigation.downloadDesktopApp')}</div>
            </div>
          </MenuItem>
        </>
      </Menu>
    </div>
  )
}

export default UserConfigurationDropdown
