import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { useTranslation } from 'react-i18next'

function MeetingReportDropdownMenu(props) {
  const { t } = useTranslation(['Common'])

  return (
    <div>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton style={{ backgroundColor: props.color, border: 'none', padding: '0' }}>
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
              more_vert
            </span>
          </MenuButton>
        }
      >
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.onClickOpenMeetingModal()
          }}
        >
          {t('meetingReport.editMeeting')}
        </MenuItem>
        <MenuItem
          style={{ textAlign: 'left' }}
          onClick={() => {
            props.deleteMeeting()
          }}
        >
          {t('meetingReport.deleteMeeting')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default MeetingReportDropdownMenu
