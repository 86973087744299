import React from 'react'
import './style.css'
import ChatInput from './component/ChatInput'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import { useTranslation } from 'react-i18next'
import { useBrainstorm } from '../../../pages/Brainstorm/Recorder/BrainstormContext.jsx'
import hand_logo from '../../assets/hand_logo.png'

const BrainstormingChatBot = () => {
  const { t } = useTranslation(['Phoenix'])
  const [isCollapsed, setIsCollapsed] = React.useState(
    JSON.parse(localStorage.getItem('isCollapsed') || 'true'),
  )

  const initialMessageStrEn = `
  <div>
      <span>Hello! I'm Zisi, your AI meeting consultant. I'm here to help answer any questions you have during your meeting. I can:</span>
      <ul class="list-disc">
          <li style="margin-left: 20px;">Answer questions about meeting topics and objectives</li>
          <li style="margin-left: 20px;">Provide clarification on discussed points</li>
          <li style="margin-left: 20px;">Help summarize key information</li>
          <li style="margin-left: 20px;">Assist with understanding context and background</li>
          <li style="margin-left: 20px;">Support decision-making with relevant information</li>
      </ul>
      <span>Feel free to ask me anything about the meeting - I'm here to help!</span>
  </div>
  `
  const initialMessageStrEs = `
  <div>
      <span>¡Hola! Soy Zisi, tu consultor de IA para reuniones. Estoy aquí para ayudarte a responder cualquier pregunta que tengas durante tu reunión. Puedo:</span>
      <ul class="list-disc">
          <li style="margin-left: 20px;">Responder preguntas sobre temas y objetivos de la reunión</li>
          <li style="margin-left: 20px;">Proporcionar aclaraciones sobre los puntos discutidos.</li>
          <li style="margin-left: 20px;">Ayudar a resumir la información clave</li>
          <li style="margin-left: 20px;">Ayudar a comprender el contexto y los antecedentes.</li>
          <li style="margin-left: 20px;">Apoyar la toma de decisiones con información relevante</li>
      </ul>
      <span>No dudes en preguntarme cualquier cosa sobre la reunión - ¡Estoy aquí para ayudar!</span>
  </div>
  `
  const [initialMessageStr, setInitialMessageStr] = React.useState(initialMessageStrEn)

  const { state } = useBrainstorm()
  const [messageBubbles, setMessageBubbles] = React.useState(
    JSON.parse(
      JSON.stringify([
        {
          message: initialMessageStr,
          isUser: false,
          isFetched: false,
        },
      ]),
    ),
  )
  const [isLoading, setIsLoading] = React.useState(false)
  const [primaryDateMode, setPrimaryDateMode] = React.useState(null)

  const messageContainerRef = React.useRef(null)

  // Add useEffect for scrolling
  React.useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight
    }
  }, [messageBubbles]) // Scroll whenever messages update

  React.useEffect(() => {
    // load data from local storage
    const localDataMessageBuggles = JSON.parse(
      localStorage.getItem('messageBubbles') ||
        JSON.stringify([
          {
            message: initialMessageStr,
            isUser: false,
            isFetched: false,
          },
        ]),
    )
    setMessageBubbles(localDataMessageBuggles)

    const localDataIsCollapsed = JSON.parse(localStorage.getItem('isCollapsed') || 'true')
    setIsCollapsed(localDataIsCollapsed)
  }, [])

  React.useEffect(() => {
    if (messageBubbles.length > 0) {
      localStorage.setItem('messageBubbles', JSON.stringify(messageBubbles))
    }
  }, [messageBubbles])

  React.useEffect(() => {
    if (isCollapsed !== undefined) {
      localStorage.setItem('isCollapsed', JSON.stringify(isCollapsed))
    }
  }, [isCollapsed])

  // Add new useEffect to reset chat when meetingInstanceId changes
  React.useEffect(() => {
    if (state.meetingInstanceId) {
      const defaultMessage = {
        message: initialMessageStr,
        isUser: false,
        isFetched: false,
      }
      setMessageBubbles([defaultMessage])
      localStorage.setItem('messageBubbles', JSON.stringify([defaultMessage]))
    }
  }, [state.meetingInstanceId])

  React.useEffect(() => {
    if (state.language === 'es') {
      setInitialMessageStr(initialMessageStrEs)
    } else {
      setInitialMessageStr(initialMessageStrEn)
    }
  }, [state.language])

  const handleErrorCondition = (empty_response = false) => {
    setMessageBubbles((prev) => {
      // remove the last message which is ... from bot
      const updatedMessages = prev.slice(0, -1)

      let error_text = empty_response
        ? 'I am sorry, I did not find any relevant search results for your input.'
        : 'I am sorry, I am an early version of the D+ assistant. At this point, I can only help with search requests'
      updatedMessages.push({ message: error_text, isUser: false, isFetched: false })
      return updatedMessages
    })
  }

  const sendText = async (text) => {
    try {
      // useLLM = false for now
      let result = await postHttpRequest(`/brainstorm/chat`, {
        text,
        meetingInstanceId: state.meetingInstanceId,
        languageSetting: state.language,
      })
      console.log('result', result)
      if (result?.response?.question_response) {
        setMessageBubbles((prev) => {
          let updatedMessages = prev.slice(0, -1)
          updatedMessages.push({
            message: [result.response.question_response],
            isUser: false,
            isFetched: true,
          })
          return updatedMessages
        })
      }
    } catch (err) {
      console.log('err')
      console.log(err)
      handleErrorCondition()
    }
  }

  return (
    <div className="zisi-chatbot-container w-[30vw] min-w-[340px]">
      <div
        className="zisi-chatbot-header flex flex-row items-center gap-2 p-2"
        onClick={() => setIsCollapsed((prev) => !prev)}
      >
        <img src={hand_logo} alt="ZISI Logo" style={{ height: '24px' }} />
        <div className={'whitespace-nowrap'}>{t('chatbot.askZisi')}</div>
      </div>
      {true && (
        <div className={`zisi-chatbot ${!isCollapsed ? 'zisi-expanded-chatbot w-full' : ''}`}>
          <div
            className="zisi-message-container h-[50vh] overflow-y-auto px-2"
            ref={messageContainerRef}
          >
            {messageBubbles.length > 0 && (
              <>
                {/* Filtered and processed messages */}
                {messageBubbles.map((messageBubble, index) => {
                  // Skip rendering if it's a user message or unfetched message
                  if (messageBubble.isUser || messageBubble.isFetched === false) {
                    return (
                      <div
                        key={index}
                        className={`zisi-message-bubble ${
                          messageBubble.isUser ? 'zisi-user-bubble' : 'zisi-bot-bubble'
                        }`}
                      >
                        <div dangerouslySetInnerHTML={{ __html: messageBubble.message }} />
                      </div>
                    )
                  }
                  // Render team performance messages

                  // Render non-consolidated messages
                  return (
                    <div
                      key={index}
                      className={`zisi-message-bubble ${
                        messageBubble.isUser ? 'zisi-user-bubble' : 'zisi-bot-bubble'
                      }`}
                    >
                      <div>
                        {/* once we found something, display template message first, followed by the results */}
                        <span>{messageBubble.message[0]}</span>
                        {console.log('messageBubble.message', messageBubble.message)}
                        <ul></ul>
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>
          <ChatInput
            sendText={sendText}
            messageBubbles={messageBubbles}
            setMessageBubbles={setMessageBubbles}
          />
        </div>
      )}
    </div>
  )
}

export default BrainstormingChatBot
