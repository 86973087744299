import { trackEvent } from '../../utils/analytics'

// Authentication Events
export const trackAuthEvent = (action, additionalParams = {}) => {
  trackEvent('auth_event', {
    auth_action: action,
    ...additionalParams,
  })
}

// Registration Events
export const trackRegisterEvent = (eventName, additionalParams = {}) => {
  trackEvent(eventName, {
    registration_action: eventName,
    ...additionalParams,
  })
}

// Navigation Events
export const trackNavigationEvent = (destination, source, additionalParams = {}) => {
  trackEvent('navigation_event', {
    destination_path: destination,
    source_path: source,
    ...additionalParams,
  })
}

// Brainstorming Events
export const trackBrainstormEvent = (action, sessionId, additionalParams = {}) => {
  trackEvent('brainstorm_event', {
    brainstorm_action: action,
    session_id: sessionId,
    ...additionalParams,
  })
}

// User Settings Events
export const trackSettingsEvent = (action, category, additionalParams = {}) => {
  trackEvent('settings_event', {
    settings_action: action,
    settings_category: category,
    ...additionalParams,
  })
}

// Meeting Minutes Events
export const trackMeetingEvent = (action, meetingId, additionalParams = {}) => {
  trackEvent('meeting_event', {
    meeting_action: action,
    meeting_id: meetingId,
    ...additionalParams,
  })
}

// Download Events
export const trackDownloadEvent = (fileType, platform, additionalParams = {}) => {
  trackEvent('download_event', {
    file_type: fileType,
    platform: platform,
    ...additionalParams,
  })
}

// Feature Usage Events
export const trackFeatureUsage = (featureName, action, additionalParams = {}) => {
  trackEvent('feature_interaction', {
    feature_name: featureName,
    interaction_type: action,
    ...additionalParams,
  })
}

// Error Events
export const trackErrorEvent = (errorType, errorMessage, additionalParams = {}) => {
  trackEvent('error_event', {
    error_type: errorType,
    error_message: errorMessage,
    ...additionalParams,
  })
}
