import React from 'react'
import Modal from '../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../../../components/CustomMultipleSelect/singleOption'

const UpdateMeetingTopicModal = ({
  closeModal,
  // Accept either single item or array of items
  selectedItem,
  currentTopic,
  availableTopics = [],
  // Modified to handle both single and multiple items
  transferItemToTopic = () => {},
}) => {
  const { t } = useTranslation(['Common'])
  const [newSelectedTopic, setNewSelectedTopic] = React.useState(currentTopic)

  // Convert selectedItem to array if it's a single item
  const selectedItems = Array.isArray(selectedItem) ? selectedItem : [selectedItem]

  const updateTopic = (newSelectedTopic) => {
    setNewSelectedTopic(newSelectedTopic)
  }

  const handleSave = async () => {
    if (newSelectedTopic?.umtId !== currentTopic?.umtId) {
      // Handle multiple items
      await transferItemToTopic(selectedItems, newSelectedTopic, currentTopic)
    }
    closeModal(true)
  }

  return (
    <Modal
      closeModal={closeModal}
      includeCloseIcon={true}
      width="60%"
      modalContainerStyle={{ width: '90%' }}
      showCard={true}
      title={t('topicSelectionModal.title')}
      titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      modalTitleContainerStyle={{ justifyContent: 'flex-start' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.5rem',
          width: '100%',
        }}
      >
        <div style={{ marginBottom: '1rem' }}>
          {/* {selectedItems.length > 1 ? (
            <div style={{ color: '#666', fontSize: '0.9rem' }}>
              {t('topicSelectionModal.multipleItems', { count: selectedItems.length })}
            </div>
          ) : null} */}
        </div>
        <SelectField
          name="selectTopic"
          value={newSelectedTopic}
          options={availableTopics}
          onChange={updateTopic}
          getOptionLabel={(option) => option.umtDescription ?? option.description}
          getOptionValue={(option) => option.umtId}
          width={'45%'}
          menuPosition="fixed"
        />
        <div
          className="sleek-button sleek-blue"
          onClick={handleSave}
          style={{ marginLeft: 'auto' }}
        >
          <span>{t('Common:commonButtons.saveSmall')}</span>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateMeetingTopicModal
