import React, { useState } from 'react'

import { Handle, Position } from '@xyflow/react'

function MindMapProblemNode({ data, isConnectable }) {
  return (
    <div className="nodrag text-updater-node">
      {/* Code we had when we were displaying the problem node */}
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          visibility: 'hidden',// cheat colution to hidding the node
        }}
      >
        <div
          className="problem-box"
          style={{
            display: 'flex',
            flexDirection: 'row',
            border: '2px solid #aaaaaa',
            textAlign: 'left',
            borderRadius: '10px',
            width: '500px',
            backgroundColor: '#E5E7EB',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.05),0 2px 4px -1px rgba(0, 0, 0, 0.03)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '90%',
              textAlign: 'left',
              borderRadius: '10px',
              padding: '10px',
              color: 'black',
              height: '90px',
              fontSize: '15px',
              overflowY: 'auto',
            }}
          >
            {data.label}
          </div>
        </div>
        <div
          class="fitted-button"
          style={{
            width: '20px',
            color: 'black',
            padding: '2px',
            fontSize: '10px',
            height: '20px',
            border: '3px solid #aaaaaa',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '10px',
          }}
          onClick={() => {
            if (data.isUpdatingMindMap) return
            data.addNewTopic()
          }}
        >
          <span class="material-symbols-outlined" style={{ fontSize: '16px' }}>
            add
          </span>
        </div>
      </div> */}

      <>
        <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
      </>
    </div>
  )
}

export default MindMapProblemNode
