import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import MindMap from '../../components/MindMap'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import MindMapLoader from './Component/MindMapLoader'
import NotFound from '../NotFound'
import ApplicationLayout from '../../components/ApplicationLayout'
import { useNavbarContext } from '../../utils/navBarContext'

const IS_MOBILE = window.innerWidth <= 768
const language = navigator.language

const BrainstormReadOnly = () => {
  const [mindmapData, setMindmapData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const { sessionId } = useParams()
  const [problemStatement, setProblemStatement] = useState('')
  console.log(sessionId)
  const navBarContext = useNavbarContext()
  const { isNavbarCollapsed, handleNavbarStateChange } = navBarContext
  useEffect(() => {
    const fetchMindmap = async () => {
      if (!sessionId) {
        setError('No session ID provided')
        setIsLoading(false)
        return
      }

      try {
        setIsLoading(true)
        const response = await getHttpRequest(
          `/brainstorm/retrieve_brainstorm_from_link/${sessionId}`,
        )
        response.mindmap.problem = response.primary_problem_statement
        setMindmapData(response.mindmap)
        if (response?.primary_problem_statement) {
          setProblemStatement(response.primary_problem_statement)
        }
      } catch (err) {
        console.error('Error fetching mindmap:', err)
        setError('Failed to load mindmap')
      } finally {
        setIsLoading(false)
      }
    }

    fetchMindmap()
  }, [sessionId])

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <MindMapLoader />
      </div>
    )
  }

  if (error || !sessionId) {
    return <NotFound />
  }

  return (
    <>
      <ApplicationLayout />
      <div className="tailwind-scoped">
        <div className="flex flex-col h-screen">
          <div
            className="w-full bg-white px-8 py-6 mt-12"
            style={{ marginLeft: isNavbarCollapsed ? '4.2rem' : '14rem' }}
          >
            <h1 className="text-2xl font-bold text-gray-800">{problemStatement}</h1>
          </div>

          {IS_MOBILE && (
            <div>
              <p className="text-sm text-gray-600 px-2 ml-4">
                {language === 'es'
                  ? 'Por favor, arrastra entre los elementos en la pizarra para moverla'
                  : 'Please drag between the items on the whiteboard to move it'}
              </p>
            </div>
          )}

          <div
            className="flex-1 w-full p-4"
            style={{ marginLeft: isNavbarCollapsed ? '4.2rem' : '14rem' }}
          >
            {mindmapData && (
              <MindMap
                jsonData={mindmapData}
                readOnly={true}
                brainstormId={null}
                allowBulkConvertToMH={false}
                fromZisiReadOnlyExternalLink={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BrainstormReadOnly
