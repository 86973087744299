// MeetingRecordingProvider.js
import React, { useReducer, useRef, useEffect } from 'react'
import meetingRecordingReducer, { defaultState } from './meetingRecordingReducer'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import {
  getMeetingTagsListForUser,
  getSingleMeetingTopicDetails,
} from '../../api/services/meetingTopic.services'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

const CHUNK_DURATION = 500
const SEND_INTERVAL = 10000
const numChunksIn15seconds = (15 * 1000) / (CHUNK_DURATION * 1.0)
const SHORT_TERM_SUMMARY_INTERVAL = 20000
const QUEUE_PEEK_INTERVAL = 2000
const EXPIRED_CHECK_INTERVAL = 5000
const GET_MEETING_PRESENTER_INTERVAL = 10000

const MeetingRecordingContext = React.createContext({
  state: defaultState,
  dispatch: () => {},
  startRecording: () => Promise.resolve(),
  stopRecording: () => Promise.resolve(),
})

const MeetingRecordingProvider = ({ children }) => {
  const { t } = useTranslation(['Common'])

  const [state, dispatch] = useReducer(meetingRecordingReducer, defaultState)

  const mediaRecorderRef = useRef(null)
  const audioContextRef = useRef(null)
  const chunksRef = useRef([])
  const headerChunkRef = useRef(null)
  const sendingIntervalRef = useRef(null)
  const dataIntervalRef = useRef(null)
  const expirationCheckIntervalRef = useRef(null)
  const shortTermSummaryIntervalRef = useRef(null)
  const displayStreamRef = useRef(null)
  const audioStreamRef = useRef(null)
  const timeoutRef = useRef(null)
  const stateRef = useRef(state)
  const isSwalOpenRef = useRef(false)
  const isProcessingDataQueue = useRef(false)
  const dataQueue = useRef([])
  const presenterCheckIntervalRef = useRef(null)

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (stateRef.current.hasSummarizationStarted && !stateRef.current.isNotAiMeeting) {
        // Show browser default "Changes you made may not be saved" dialog
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    if (window.location.pathname.endsWith('/MeetingMultiView')) {
      get_in_progress_meeting_instances_and_stop_orphaned_meetings()
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      if (sendingIntervalRef.current) {
        clearInterval(sendingIntervalRef.current)
      }
      if (dataIntervalRef.current) {
        clearInterval(dataIntervalRef.current)
      }
      if (dataIntervalRef.current) {
        clearInterval(dataIntervalRef.current)
      }
      if (shortTermSummaryIntervalRef.current) {
        clearInterval(shortTermSummaryIntervalRef.current)
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      if (expirationCheckIntervalRef.current) {
        clearInterval(expirationCheckIntervalRef.current)
      }
      if (presenterCheckIntervalRef.current) {
        clearInterval(presenterCheckIntervalRef.current)
      }
    }
  }, [])

  useEffect(() => {
    stateRef.current = state
  }, [state])

  // useEffect(() => {
  // }, [])

  // Check for orphaned meetings when component mounts
  const get_in_progress_meeting_instances_and_stop_orphaned_meetings = async () => {
    try {
      const response = await postHttpRequest(
        '/meeting/get_in_progress_meeting_instances_and_stop_orphaned_meetings',
      )
      if (response.success) {
        dispatch({ type: 'PREPARE_RECORDING', isPreProcessingCompleted: true })
        if (response.meetingInstanceId) {
          dispatch({
            type: 'START_RECORDING',
            meetingInstanceId: response.meetingInstanceId,
            isNotAiMeeting: true,
          })
        }
      }
    } catch (error) {
      console.error('Error checking orphaned meetings:', error)
    }
  }

  const startRecordingRequest = async (isNotAiMeeting) => {
    const response = await postHttpRequest('/meeting/start_stop_meeting_recording', {
      isStopRecording: false,
      isNotAiMeeting: isNotAiMeeting,
    })

    return response
  }

  const updateMeetingPresenter = async (meetingInstanceId) => {
    const response = await postHttpRequest('/meeting/update_meeting_presenter', {
      meetingInstanceId: meetingInstanceId,
    })
    return response
  }

  const getMeetingPresenter = async (meetingInstanceId) => {
    const response = await postHttpRequest('/meeting/get_meeting_presenter', {
      meetingInstanceId: meetingInstanceId,
    })
    console.log('response: ', response)
    if (!response.current_presenter) {
      await stopRecording(true)
    }
  }

  const startRecording = async (isNotAiMeeting, lastMeetingInstanceId = null) => {
    let response
    try {
      isSwalOpenRef.current = false
      dispatch({ type: 'SET_TRANSCRIPTION', transcription: [] })
      dataQueue.current = []
      localStorage.removeItem('mets')

      const previousMeetings = await getMeetingTagsListForUser()

      if (!isNotAiMeeting) {
        const displayStream = await navigator.mediaDevices.getDisplayMedia({
          audio: true,
          video: true,
        })
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

        displayStreamRef.current = displayStream
        audioStreamRef.current = audioStream

        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)()

        const displaySourceNode = audioContextRef.current.createMediaStreamSource(displayStream)
        const audioSourceNode = audioContextRef.current.createMediaStreamSource(audioStream)

        const destinationNode = audioContextRef.current.createMediaStreamDestination()
        displaySourceNode.connect(destinationNode)
        audioSourceNode.connect(destinationNode)

        mediaRecorderRef.current = new MediaRecorder(destinationNode.stream)
        let meetingInstanceId
        if (!lastMeetingInstanceId) {
          response = await startRecordingRequest(isNotAiMeeting)
          meetingInstanceId = response.meetingInstanceId
          localStorage.setItem('mets', JSON.stringify(response.expiredTimestamp))
        } else {
          let res = await updateMeetingPresenter(lastMeetingInstanceId)
          console.log('res: ', res)
          meetingInstanceId = lastMeetingInstanceId
          let expiredTimestamp = parseInt((Date.now() + 75 * 60 * 1000) / 1000)
          response = {
            meetingInstanceId: meetingInstanceId,
            expiredTimestamp: expiredTimestamp,
          }
          localStorage.setItem('mets', JSON.stringify(expiredTimestamp))
          console.log('expiredTimestamp: ', expiredTimestamp)
        }

        dispatch({
          type: 'START_RECORDING',
          meetingInstanceId: meetingInstanceId,
          isNotAiMeeting: false,
        })

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            if (headerChunkRef.current === null) {
              headerChunkRef.current = event.data
            } else {
              chunksRef.current.push({
                timestamp: Math.floor(Date.now() / 1000),
                data: event.data,
              })
              if (chunksRef.current.length > numChunksIn15seconds) {
                chunksRef.current.shift()
              }
            }
          }
        }

        mediaRecorderRef.current.start(CHUNK_DURATION)

        dataIntervalRef.current = setInterval(() => {
          accumulateDataChunks()
        }, SEND_INTERVAL)

        sendingIntervalRef.current = setInterval(() => {
          sendAudioData(meetingInstanceId)
        }, QUEUE_PEEK_INTERVAL)

        timeoutRef.current = setTimeout(() => {
          shortTermSummaryIntervalRef.current = setInterval(() => {
            generateShortTermSummary(meetingInstanceId)
          }, SHORT_TERM_SUMMARY_INTERVAL)
        }, 5000)

        expirationCheckIntervalRef.current = setInterval(() => {
          checkExpiration(isNotAiMeeting)
        }, EXPIRED_CHECK_INTERVAL)

        // Start periodic presenter check
        presenterCheckIntervalRef.current = setInterval(async () => {
          let res = await getMeetingPresenter(meetingInstanceId)
          console.log('res: ', res)
        }, GET_MEETING_PRESENTER_INTERVAL)
      } else {
        expirationCheckIntervalRef.current = setInterval(() => {
          checkExpiration(isNotAiMeeting)
        }, EXPIRED_CHECK_INTERVAL)
        let meetingInstanceId
        if (!lastMeetingInstanceId) {
          response = await startRecordingRequest(isNotAiMeeting)
          meetingInstanceId = response.meetingInstanceId
          localStorage.setItem('mets', JSON.stringify(response.expiredTimestamp))
        } else {
          let res = await updateMeetingPresenter(lastMeetingInstanceId)
          console.log('res: ', res)
          meetingInstanceId = lastMeetingInstanceId
          // If it's not an AI meeting, set the expiration timestamp to 8hrs from now
          let expiredTimestamp = parseInt((Date.now() + 8 * 60 * 60 * 1000) / 1000)
          localStorage.setItem('mets', JSON.stringify(expiredTimestamp))
          console.log('expiredTimestamp: ', expiredTimestamp)
          response = {
            meetingInstanceId: meetingInstanceId,
            expiredTimestamp: expiredTimestamp,
          }
        }

        dispatch({ type: 'START_RECORDING', meetingInstanceId, isNotAiMeeting: true })
        // Start periodic presenter check
        presenterCheckIntervalRef.current = setInterval(async () => {
          let res = await getMeetingPresenter(meetingInstanceId)
          console.log('res: ', res)
        }, GET_MEETING_PRESENTER_INTERVAL)
      }
      return { response, previousMeetings }
    } catch (err) {
      const result = await Swal.fire({
        title: t('Common:brainstorming.shareAudioErrorMessage'),
        icon: 'error',
        confirmButtonText: 'OK',
      })
      return { response, previousMeetings: null }
    }
  }

  const stopRecording = async (forceQuit = false) => {
    dispatch({
      type: 'SET_IS_STOPPING_RECORDING',
      isStoppingRecording: true,
    })

    console.log('stopRecording')
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()
      clearInterval(expirationCheckIntervalRef.current)
      clearInterval(sendingIntervalRef.current)
      clearInterval(dataIntervalRef.current)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      clearInterval(shortTermSummaryIntervalRef.current)
      audioContextRef.current.close()
    }

    // if expired, sendChunks api will return 410, no more stopRecoding call.
    accumulateDataChunks()
    // wait until dispatch is done
    // await sleep(500)
    // console.log('new Date().toLocaleTimeString() with milliseconds a: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
    await sendAudioData(stateRef.current.meetingInstanceId)
    // console.log('new Date().toLocaleTimeString() with milliseconds b: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())

    if (stateRef.current.topicFocusEventTracker.length > 0) {
      // always call actions/comments generation when stop recording..
      let lastEvent =
        stateRef.current.topicFocusEventTracker[stateRef.current.topicFocusEventTracker.length - 1]
      let startTimestamp = lastEvent.timestamp
      let endTimestamp = Math.floor(new Date().getTime() / 1000)
      if (lastEvent.eventType === 'click') {
        let newFocusTracker = [...stateRef.current.topicFocusEventTracker]

        dispatch({
          type: 'SET_CARD_RECORDING_STARTED',
          umtId: lastEvent.umtId,
          newValue: false,
        })
        newFocusTracker.push({
          timestamp: endTimestamp,
          umtId: lastEvent.umtId,
          objId: lastEvent.objId,
          eventType: 'stop',
        })
        dispatch({
          type: 'SET_TOPIC_FOCUS_EVENT_TRACKER',
          topicFocusEventTracker: newFocusTracker,
        })
        // tell meeting hub if we need to refres the single card
        dispatch({
          type: 'SHOULD_REFRESH',
          shouldRefresh: true,
        })
        // console.log('new Date().toLocaleTimeString() with milliseconds c: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
        // we need await here because white space calcualtion depends on this
        await generateActionsCommentsForTopicTimespan(
          startTimestamp,
          endTimestamp,
          lastEvent.umtId,
          lastEvent.objId,
        )
        // console.log('new Date().toLocaleTimeString() with milliseconds d: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
      }
    }

    if (displayStreamRef.current) {
      displayStreamRef.current.getTracks().forEach((track) => track.stop())
    }
    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => track.stop())
    }

    try {
      await postHttpRequest('/meeting/generate_items_from_non_topic_transcript', {
        meetingInstanceId: stateRef.current.meetingInstanceId,
        meetingId: stateRef.current.meetingId,
      })
    } catch (error) {
      console.error('Error generating white gap items:', error)
    }

    const response = await postHttpRequest('/meeting/start_stop_meeting_recording', {
      isStopRecording: true,
      meetingInstanceId: stateRef.current.meetingInstanceId,
    })
    if (response.error) {
      console.error('Error stopping recording:', response.error)
    }

    if (response.log_id) {
      dispatch({
        type: 'SET_DECISION_LOG',
        decisionLog: {
          logId: response.log_id,
          isFinalized: response.log_finalized,
        },
      })
    }

    if (stateRef.current.isNotAiMeeting) {
      dispatch({
        type: 'UPDATE_MEETING_TERMINATED',
        meetingTerminated: response.meetting_is_terminated,
      })
    }

    // reset to false

    dispatch({
      type: 'SET_MEETING_ID',
      meetingId: null,
    })

    isProcessingDataQueue.current = false
    dispatch({
      type: 'SET_IS_STOPPING_RECORDING',
      isStoppingRecording: false,
    })
    dispatch({ type: 'STOP_RECORDING', showPopupAfterStopRecording: !forceQuit })
    localStorage.removeItem('mets')

    // Clear presenter check interval
    if (presenterCheckIntervalRef.current) {
      clearInterval(presenterCheckIntervalRef.current)
    }
  }

  const accumulateDataChunks = () => {
    if (chunksRef.current.length < numChunksIn15seconds || headerChunkRef.current === null) return

    const formData = new FormData()
    const blobParts = [headerChunkRef.current].concat(chunksRef.current.map((chunk) => chunk.data))
    const combinedBlob = new Blob(blobParts, { type: 'audio/webm' })
    formData.append('audio', combinedBlob, 'combined_chunks.webm')
    formData.append('timestamp_start', chunksRef.current[0].timestamp.toString())
    formData.append(
      'timestamp_end',
      chunksRef.current[chunksRef.current.length - 1].timestamp.toString(),
    )

    dataQueue.current.push({ data: formData })
  }

  const checkExpiration = async (isNotAiMeeting) => {
    let expiredTsStr = localStorage.getItem('mets')
    if (expiredTsStr === null) return

    let expiredTs = JSON.parse(expiredTsStr)
    let currentTimestamp = Math.floor(Date.now() / 1000)

    // 5 mins before expiration.. show something....
    if (!isNotAiMeeting && currentTimestamp >= expiredTs - 5 * 60 && currentTimestamp < expiredTs) {
      if (!stateRef.current.isAboutExpired && isSwalOpenRef.current === false) {
        isSwalOpenRef.current = true
        dispatch({
          type: 'SET_IS_ABOUT_EXPIRED',
          isAboutExpired: true,
        })
        const result = await Swal.fire({
          title: 'Your meeting is about to end in 5 minutes, do you want to extend it?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })
        if (result.isConfirmed) {
          isSwalOpenRef.current = false
          dispatch({
            type: 'SET_IS_ABOUT_EXPIRED',
            isAboutExpired: false,
          })
          localStorage.setItem('mets', JSON.stringify(expiredTs + 15 * 60))
          try {
            await postHttpRequest('/meeting/extend_meeting_recording', {
              meetingInstanceId: stateRef.current.meetingInstanceId,
              // additional 15 minutes
              newExpiredTimestamp: expiredTs + 15 * 60,
            })
          } catch (error) {
            console.log('Error extending meeting:', error)
          }
        } else {
          isSwalOpenRef.current = false
          dispatch({
            type: 'SET_IS_ABOUT_EXPIRED',
            isAboutExpired: true,
          })
        }
      }
    }

    if (currentTimestamp > expiredTs && !stateRef.current.isExpired) {
      dispatch({
        type: 'SET_IS_EXPIRED',
        isExpired: true,
      })
      if (isSwalOpenRef.current) {
        Swal.close()
        isSwalOpenRef.current = false
      }

      await stopRecording()
    }
  }

  const sendAudioData = async (meetingInstanceId, shouldShowPopup = true) => {
    try {
      // let newDataQueue = [...stateRef.current.dataQueue]
      // console.log('new Date().toLocaleTimeString() with milliseconds 11xx: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
      if (dataQueue.current.length === 0 || isProcessingDataQueue.current) return
      // console.log('new Date().toLocaleTimeString() with milliseconds 11yy: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
      let formData = dataQueue.current.shift()

      isProcessingDataQueue.current = true
      // console.log('isProcessingDataQueue.current 2: ', isProcessingDataQueue.current)
      let response = await postHttpRequest(
        `/meeting/process_audio_chunks/${meetingInstanceId}`,
        formData.data,
      )
      // console.log('new Date().toLocaleTimeString() with milliseconds 11: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())

      isProcessingDataQueue.current = false
      console.log('Chunks sent successfully')
      localStorage.setItem('mets', JSON.stringify(response.expired_ts))
    } catch (error) {
      console.error('Error sending chunks to API:', error)
      console.log(error.response.status)
      isProcessingDataQueue.current = false
    }
  }

  const generateShortTermSummary = async (meetingInstanceId) => {
    try {
      const response = await postHttpRequest(
        `/meeting/generate_short_term_summary/${meetingInstanceId}`,
      )
      dispatch({ type: 'SET_TRANSCRIPTION', transcription: response.shortTermSummaries })
    } catch (error) {
      console.error('Error generating short term summary:', error)
    }
  }

  // sleep time expects milliseconds
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  const generateActionsCommentsForTopicTimespan = async (
    startTimestamp,
    endTimestamp,
    umtId,
    objId,
    cardSelections,
    updateSingleTopicCard,
  ) => {
    //TODO handle stop recording from other pages..
    // console.log(
    //   'startTimestamp: ',
    //   startTimestamp,
    //   'endTimestamp: ',
    //   endTimestamp,
    //   'umtId: ',
    //   umtId,
    //   'objId: ',
    //   objId,
    // )
    try {
      dispatch({
        type: 'SET_IS_GENERATING_ACTIONS_COMMENTS_FROM_RECORDING',
        umtId: umtId,
        newValue: true,
      })
      // console.log('new Date().toLocaleTimeString() with milliseconds 3: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
      // await sleep(5000)
      const data = await postHttpRequest('/meeting/generate_actions_comments_for_topic_timespan', {
        startTimestamp,
        endTimestamp,
        umtId,
        meetingInstanceId: stateRef.current.meetingInstanceId,
        objId,
      })

      if (data.topic_id_comment_decision_action_count) {
        dispatch({
          type: 'SET_TOPIC_ID_COMMENT_DECISION_ACTION_COUNT',
          topicIdCommentDecisionActionCount: data.topic_id_comment_decision_action_count,
        })
      }
      // console.log('new Date().toLocaleTimeString() with milliseconds 4: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
      dispatch({
        type: 'SET_IS_GENERATING_ACTIONS_COMMENTS_FROM_RECORDING',
        umtId: umtId,
        newValue: false,
      })
      if (cardSelections) {
        let topicNeededUpdate = cardSelections.find((topic) => topic.umtId === umtId)
        if (topicNeededUpdate) {
          let postObj = { umtId: topicNeededUpdate.umtId, topicType: topicNeededUpdate.topicType }
          const singleTopic = await getSingleMeetingTopicDetails(postObj)
          updateSingleTopicCard(singleTopic)
        }
      }
    } catch (error) {
      console.log(error)
      // Toast.fire({
      //   icon: 'error',
      //   title: t('Common:modalMessages.somethingWentWrongTryAgainLater')
      // })
      // setIsGeneratingActionsCommentsFromRecording(prev => ({
      //   ...prev,
      //   [umtId]: false
      // }))
      dispatch({
        type: 'SET_IS_GENERATING_ACTIONS_COMMENTS_FROM_RECORDING',
        umtId: umtId,
        newValue: false,
      })
    }
  }

  // this function is not captured in setInterval, so it's ok to not use stateRef.current
  const updateTopicFocusFn = async (
    umtId,
    objId,
    eventType,
    cardSelections,
    updateSingleTopicCard,
  ) => {
    try {
      accumulateDataChunks()

      console.log('adding focus event', umtId, objId, eventType)
      let newFocusTracker = [...stateRef.current.topicFocusEventTracker]
      let timestamp = Math.floor(new Date().getTime() / 1000)
      if (newFocusTracker.length > 0) {
        // user has not stopped the previous recording explicitly.
        let lastEvent = newFocusTracker[newFocusTracker.length - 1]
        if (lastEvent.umtId !== umtId && lastEvent.eventType !== 'stop') {
          // setIsCardRecordingStarted(prev => ({
          //   ...prev,
          //   [lastEvent.umtId]: false
          // }))
          dispatch({
            type: 'SET_CARD_RECORDING_STARTED',
            umtId: lastEvent.umtId,
            newValue: false,
          })
          newFocusTracker.push({
            timestamp: timestamp,
            umtId: lastEvent.umtId,
            objId: lastEvent.objId,
            eventType: 'stop',
            isForced: true,
          })
        }
      }
      newFocusTracker.push({
        timestamp: timestamp,
        umtId: umtId,
        objId: objId,
        eventType: eventType,
        isForced: false,
      })
      // setTopicFocusEventTracker(newFocusTracker)
      dispatch({
        type: 'SET_TOPIC_FOCUS_EVENT_TRACKER',
        topicFocusEventTracker: newFocusTracker,
      })

      // wait until dispatch is done
      // await sleep(500)
      // console.log('new Date().toLocaleTimeString() with milliseconds 1: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
      await sendAudioData(stateRef.current.meetingInstanceId)
      // await sleep(2000)

      let newLength = newFocusTracker.length
      let startTimestamp, endTimestamp, selectedUmtId, selectedObjId
      // the list of event is always 'click', 'stop' alternatively
      if (newLength >= 2) {
        let lastEvent = newFocusTracker[newLength - 1]
        let previousEvent = newFocusTracker[newLength - 2]

        if (newLength === 2) {
          startTimestamp = previousEvent.timestamp
          endTimestamp = lastEvent.timestamp
          selectedUmtId = lastEvent.umtId
          selectedObjId = lastEvent.objId
        } else if (newLength > 2) {
          if (lastEvent.eventType === 'stop') {
            startTimestamp = previousEvent.timestamp
            endTimestamp = lastEvent.timestamp
            selectedUmtId = previousEvent.umtId
            selectedObjId = previousEvent.objId
          } else {
            let previousEventStart = newFocusTracker[newLength - 3]
            // umtId = 555, we don't want to make second call if it's click - stop - click again
            // or if user stops the last tracking explicitly, and we are at a new 'click' state => skip
            if (previousEventStart.umtId === lastEvent.umtId || !previousEvent.isForced) {
              return
            }
            startTimestamp = previousEventStart.timestamp
            endTimestamp = previousEvent.timestamp
            selectedUmtId = previousEvent.umtId
            selectedObjId = previousEvent.objId
          }
        }
      }
      if (startTimestamp && endTimestamp && stateRef.current.meetingInstanceId) {
        console.log('generateActionsCommentsForTopicTimespan')
        // console.log('new Date().toLocaleTimeString() with milliseconds 2: ', new Date().toLocaleTimeString() + '.' + new Date().getMilliseconds())
        await generateActionsCommentsForTopicTimespan(
          startTimestamp,
          endTimestamp,
          selectedUmtId,
          selectedObjId,
          cardSelections,
          updateSingleTopicCard,
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <MeetingRecordingContext.Provider
      value={{ state, dispatch, startRecording, stopRecording, updateTopicFocusFn }}
    >
      {children}
    </MeetingRecordingContext.Provider>
  )
}

const useMeetingRecording = () => {
  const context = React.useContext(MeetingRecordingContext)
  if (!context) {
    throw new Error('useMeetingRecording must be used within a MeetingRecordingProvider.')
  }
  return context
}

export { MeetingRecordingProvider, useMeetingRecording }
