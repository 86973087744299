import React, { useState, useEffect, useRef } from 'react'
import { getAreaObjectives, getTeamObjectives } from '../../../api/services/objectives.services.jsx'
import useDeleteObjective_v2 from '../../../api/mutation/deleteObjectiveHome_v2'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/routes'
import InPageLoader from '../../../components/InPageLoader'
import { useTranslation } from 'react-i18next'
import useDeleteObjectiveCheck from '../../../api/mutation/useDeleteObjectiveCheck.js'
import useHigherObjectives from '../../../api/query/useHigherObjectives.js'
import ManageObjectiveModal from '../../Governance/components/ManageObjectives/components/ManageObjectiveModal.jsx'
import ErrorModal from '../../../components/ErrorModal/index.jsx'
import useDeleteHigherObjective from '../../../api/mutation/deleteHigherObjective.js'
import Toast from '../../../common/toast.js'
import { API_ERROR_MESSAGE } from '../../../api/constant/route.js'
import { hasRole } from '../../../utils/auth.js'
import CompletedObjectivesModal from '../../../components/CompletedObjectivesModal/index.jsx'
import useAllLeadersData from '../../../api/query/useAreaLeaderData.js'
import L1L2ObjectiveModal from '../../../components/L1L2ObjectiveModal'

const AreaObjective = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['Dashboard', 'Common'])

  const mutationDeleteHigherObjective = useDeleteHigherObjective()

  const [teamsObjective, setTeamsObjective] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [isOpen, setIsOpen] = React.useState(false)
  const [isEdit, setIsEdit] = React.useState(false)
  const [selectedObjective, setSelectedObjective] = React.useState(null)
  const [objectiveWidgetErrorMessage, setObjectiveWidgetErrorMessage] = React.useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false)

  const { data: allAreaLeaderData, isLoading: isAreaLeaderLoading } = useAllLeadersData()

  const [isCompletedObjectivesModalOpen, setIsCompletedObjectivesModalOpen] = React.useState(false)

  const {
    data: higherObjData,
    isLoading: isHigherObjLoading,
    error: higherObjError,
  } = useHigherObjectives()

  useEffect(() => {
    getObjectives()
  }, [])

  async function getObjectives() {
    setIsLoading(true)
    const result = await getAreaObjectives()
    setIsLoading(false)
    if (result && result.objectives.length > 0) {
      setTeamsObjective(result.objectives)
    } else {
      setTeamsObjective([])
    }
  }

  const handleDeleteObj = async (obj) => {
    if (window.confirm(t('objectivesCard.deleteAreaObjectiveMsg'))) {
      let postObj = { objId: parseInt(obj.id) }
      setIsLoading(true)
      await mutationDeleteHigherObjective.mutateAsync(postObj, {
        onSuccess: (response) => {
          if (!response.ok) {
            Toast.fire({
              icon: 'error',
              title: API_ERROR_MESSAGE,
            })
            setIsLoading(false)
          } else {
            setIsLoading(false)
            getObjectives()
          }
        },
        onError: () => {
          Toast.fire({
            icon: 'error',
            title: API_ERROR_MESSAGE,
          })
          setIsLoading(false)
        },
      })
    }
  }

  const handleEdit = async (objective) => {
    // navigate(`/${routes.demandintake}`, { state: { from: 'objectives', editObj: true, objective: objective } })
    setIsOpen(true)
    setIsEdit(true)
    setSelectedObjective(objective)
  }

  const handleCreate = async () => {
    // navigate(`/${routes.demandintake}`, { state: { createNewObj: true } })
    setIsOpen(true)
    setIsEdit(false)
    setSelectedObjective(null)
  }

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setObjectiveWidgetErrorMessage(null)
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={closeErrorModal}
        errorMessage={objectiveWidgetErrorMessage}
      />
      {hasRole('area') ? (
        //    <L1L2ObjectiveModal
        //    isOpen={isL1L2ModalOpen}
        //    mode={objModalMode}
        //    handleClose={() => {
        //      setIsL1L2ModalOpen(false)
        //      setIsAddL1Objective(false)
        //    }}
        //    objId={objId}
        //    objectiveType={
        //      isAddL1Objective === true ? 'enterprise' : lastClickedLevel === 1 ? 'cxo' : 'area'
        //    }
        //    leaders={[]}
        //    enterpriseObjectives={[]}
        //    isAreaDashboard={true}
        //    isCeo={isCEO || isCXO ? true : false}
        //    setEditedObj={setEditedObj}
        //    loadAgain={loadAgain}
        //    objData={objData}
        //    setObjData={setObjData}
        //    isFromObjectiveMap={true}
        //  />
        <ManageObjectiveModal
          enterpriseObjectives={higherObjData?.enterpriseObjectives}
          objectiveType={'area'}
          isCeo={false}
          objective={selectedObjective}
          isOpen={isOpen}
          isEdit={isEdit}
          isAreaDashboard={true}
          handleClose={() => {
            setIsOpen(false)
            setSelectedObjective(null)
            setIsEdit(false)
            getObjectives()
          }}
          setObjective={setSelectedObjective}
          setIsEdit={setIsEdit}
          leaders={allAreaLeaderData?.employees}
        ></ManageObjectiveModal>
      ) : (
        <ManageObjectiveModal
          enterpriseObjectives={higherObjData?.ceoObjectives}
          leaders={[]}
          objectiveType={'cxo'}
          isCeo={hasRole('ceo') || hasRole('cos')}
          objective={selectedObjective}
          isOpen={isOpen}
          isEdit={isEdit}
          isAreaDashboard={true}
          handleClose={() => {
            setIsOpen(false)
            setSelectedObjective(null)
            setIsEdit(false)
            getObjectives()
          }}
          setObjective={setSelectedObjective}
          setIsEdit={setIsEdit}
        ></ManageObjectiveModal>
      )}
      {/* {isLoading && (<InPageLoader />)} */}
      <div className="card">
        <div className="card_header">
          <h2 className="card_title">
            {hasRole('area')
              ? t('objectivesCard.areaObjectivesTitle')
              : t('objectivesCard.ceoObjectivesTitle')}
          </h2>
          {!isLoading && (
            <button
              type="button"
              className="fitted-button blue"
              style={{ background: 'white', padding: '0.4rem', fontWeight: 'bold' }}
              onClick={() => handleCreate()}
            >
              <span className="material-symbols-outlined icon-size">
                <span class="material-symbols-outlined" style={{ fontWeight: 'bold' }}>
                  add
                </span>
              </span>
            </button>
          )}
        </div>
        {isLoading ? (
          <InPageLoader />
        ) : (
          <div className="Team_card_body">
            {teamsObjective.map((data, index) => {
              return (
                <div className="card_item" key={data.id}>
                  <div
                    className="card_item_heading"
                    style={{ paddingRight: '2rem', wordBreak: 'break-word' }}
                  >
                    {data.statement}
                  </div>
                  <div className="card_item_body">
                    <span className="owner_name">
                      {' '}
                      {data.is_copy && data.is_original ? `Owner : ${data.ownerName}` : ''}{' '}
                    </span>
                    <span className="btn_box">
                      <button
                        type="button"
                        className="fitted-button blue"
                        onClick={(e) => {
                          handleEdit(data)
                        }}
                      >
                        <span className="material-symbols-outlined icon-size">edit </span>
                      </button>
                      {data.childObjCount == 0 && (
                        <button
                          type="button"
                          className="fitted-button blue"
                          onClick={(e) => {
                            handleDeleteObj(data)
                          }}
                        >
                          <span className="material-symbols-outlined icon-size">Close </span>
                        </button>
                      )}
                    </span>
                  </div>
                </div>
              )
            })}
            <span
              className="view_all"
              onClick={() => {
                setIsCompletedObjectivesModalOpen(true)
              }}
            >
              {t('Common:commonButtons.completedObjectives')}
            </span>
          </div>
        )}
      </div>
      {isCompletedObjectivesModalOpen && (
        <CompletedObjectivesModal
          isModalOpen={isCompletedObjectivesModalOpen}
          closeModal={() => {
            setIsCompletedObjectivesModalOpen(false)
            getObjectives()
          }}
          objLevel={2}
        />
      )}
    </>
  )
}
export default AreaObjective
