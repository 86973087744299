import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import insights from '../../phoenix/assets/tutorial_screen_3.png'
import mindmap from '../../phoenix/assets/tutorial_screen_2.png'
import problemStatement from '../../phoenix/assets/tutorial_screen_1.png'
import zisiLogo from '../../phoenix/assets/zisi_logo2.png'
import { useNavigate } from 'react-router-dom'
// import './styles.css' // CECILIA: Do we need this?
import { setSkipTutorialFlag } from '../../api/services/brainstorm.services'
import ApplicationLayout from '../../components/ApplicationLayout'

const Demo = () => {
  const { t } = useTranslation(['Phoenix'])
  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = React.useState(0)
  const [skipTutorial, setSkipTutorial] = React.useState(false)

  const steps = [
    {
      number: 1,
      title: t('demo.step1Title'),
      content: (
        <div className="space-y-4">
          <div className="bg-white rounded-lg w-full flex items-center justify-center">
            <img
              src={problemStatement}
              alt={t('demo.step1ImageAlt')}
              style={{ height: '28.5rem' }}
              className="object-contain"
            />
          </div>
        </div>
      ),
    },
    {
      number: 2,
      title: t('demo.step2Title'),
      content: (
        <div className="space-y-4">
          <div className="bg-white rounded-lg w-full flex items-center justify-center">
            <img
              src={mindmap}
              alt={t('demo.step2ImageAlt')}
              style={{ height: '27.5rem' }}
              className="object-contain"
            />
          </div>
        </div>
      ),
    },
    {
      number: 3,
      title: t('demo.step3Title'),
      content: (
        <div className="space-y-4">
          <div className="bg-white rounded-lg w-full flex items-center justify-center">
            <img
              src={insights}
              alt={t('demo.step3ImageAlt')}
              style={{ height: '27.5rem' }}
              className="object-contain"
            />
          </div>
        </div>
      ),
    },
    {
      number: 4,
      title: '',
      content: (
        <div className="space-y-6 h-full">
          <div className="bg-white rounded-lg w-full flex items-center justify-center h-40 mb-40">
            <img
              src={zisiLogo}
              alt={t('demo.step4ImageAlt')}
              className="object-contain w-full h-32"
            />
          </div>
          {/* <p className="text-gray-600">
              {t('demo.step4Content')}
            </p> */}

          {/* <div className="flex items-center mb-4 flex-row gap-2">
              <input
                type="checkbox"
                id="skipTutorial"
                checked={skipTutorial}
                onChange={(e) => setSkipTutorial(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label htmlFor="skipTutorial" className="ml-2 text-sm text-gray-600">
                {t('demo.dontShowAgain')}
              </label>
            </div> */}

          <button
            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center flex-row gap-2 mt-20"
            onClick={async () => {
              // await setSkipTutorialFlag(skipTutorial)
              navigate('/brainstorming')
            }}
          >
            {t('button.letsGo')}
            <span className="material-symbols-outlined">arrow_forward</span>
          </button>
        </div>
      ),
    },
  ]

  const nextStep = () => {
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))
  }

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0))
  }

  return (
    <ApplicationLayout>
      <div className="tailwind-scoped">
        <div className="flex flex-col justify-center items-center bg-gray-100 h-screen ">
          <div className="p-6 mt-12 mb-2 self-center">
            {/* Carousel Container */}
            <div
              className="bg-white rounded-xl shadow-sm border border-gray-200 p-8 mb-2 "
              style={{
                height: '35rem',
                width: '60rem',
              }}
            >
              {/* Step Content */}
              <div className="mb-3 flex justify-between flex-column h-full">
                <div className="flex items-start gap-1 mb-3 flex-row">
                  <h2 className="text-xl font-semibold text-gray-800">
                    {steps[currentStep].title}
                  </h2>
                </div>

                <div className="transition-opacity duration-300 h-[500px]">
                  {steps[currentStep].content}
                </div>
              </div>
            </div>

            {/* Step Indicators */}
            <div className="flex justify-center gap-2 flex-row">
              {steps.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentStep(index)}
                  className={`w-2 h-2 rounded-full transition-all ${
                    currentStep === index ? 'bg-blue-600 w-6' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>

            {/* Navigation Buttons */}
            {
              <div className="flex justify-between flex-row">
                <button
                  onClick={prevStep}
                  disabled={currentStep === 0}
                  className={`flex items-center py-2 rounded-lg ${
                    currentStep === 0
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <span className="material-symbols-outlined" style={{ fontSize: '20px' }}>
                    arrow_back
                  </span>
                  {t('button.previous')}
                </button>
                {currentStep !== steps.length - 1 && (
                  <button
                    onClick={nextStep}
                    disabled={currentStep === steps.length - 1}
                    className="flex items-center px-4 py-2 rounded-lg text-gray-600 hover:bg-gray-100"
                  >
                    {t('button.next')}
                    <span className="material-symbols-outlined" style={{ fontSize: '20px' }}>
                      arrow_forward
                    </span>
                  </button>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default Demo
