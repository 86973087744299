const objectiveDurationEnumValue = (objectiveDuration) => {
  switch (objectiveDuration) {
    case 'FULL_CYCLE':
      return 'Full Cycle'

    case 'HALF_CYCLE':
      return 'Half Cycle'

    case 'QUARTER_CYCLE':
      return 'Quarter Cycle'

    case 'EXCEEDS_CYCLE':
      return 'Greater than a full cycle'

    case 'UNKNOWN_CYCLE':
      return 'Unknown Cycle'

    default:
      return 'Unknown Cycle'
  }
}

const objDurationToFloat = (durationStr) => {
  if (durationStr === 'half_cycle') return 0.5
  if (durationStr === 'quarter_cycle') return 0.25

  return 1.0
}

const governanceCycleEscalationStatus = (escalationStatus) => {
  switch (escalationStatus) {
    case 'NOT_ESCALATED':
      return 'No'

    case 'ESCALATED':
      return 'Yes'

    default:
      return 'Unknown'
  }
}

const milestoneStatus = (escalationStatus) => {
  switch (escalationStatus) {
    case 'FOR_REVIEW':
      return 'For Review'

    case 'ON_TRACK':
      return 'On-track'

    case 'COMPLETED':
      return 'Completed'

    case 'DEPRIORITIZED':
      return 'Deprioritized'

    case 'NOT_STARTED':
      return 'Not Started'

    default:
      return 'Unknown'
  }
}

const backLogActivityProgress = (progress) => {
  switch (progress) {
    case 'BACKLOG_UNKNOWN_PROGRESS':
      return 0
    case 'CRITICAL':
      return 1
    case 'HIGH':
      return 2
    case 'MEDIUM':
      return 3
    case 'LOW':
      return 4
    case 'BACKLOG_DEPRIORITIZED':
      return 5
    default:
      return 0
  }
}

const tlActivityProgress = (progress) => {
  switch (progress) {
    case 'UNKNOWN_PROGRESS':
      return 0

    case 'COMPLETED':
      return 1

    case 'ON_TRACK':
      return 2

    case 'FOR_REVIEW':
      return 3

    case 'DEPRIORITIZED':
      return 4

    case 'NOT_STARTED':
      return 5

    case 'BACKLOG':
      return 6

    default:
      return 0
  }
}

const userConfigALEnum = (field) => {
  switch (field) {
    case 0:
      return 'Allow seeing L2 objectives'
    case 1:
      return 'Allow MEI'
    case 2:
      return 'Allow chatbot'
    case 3:
      return 'Allow admin topics'
    case 4:
      return 'Allow L2 Users see Peer Kpi Report'
    default:
      return 'Unknown'
  }
}

const userConfigTLEnum = (field) => {
  switch (field) {
    case 0:
      return 'Full'

    case 1:
      return 'None'

    case 2:
      return 'Update Only'

    case 3:
      return 'Capacity Only'

    case 4:
      return 'Can add/edit objectives'

    case 5:
      return 'Can change obj status'

    case 6:
      return 'Can change obj priority'

    case 7:
      return 'Can change initiative no.'

    case 8:
      return 'Allow adding L2 activities'

    case 9:
      return 'Allow MEI'

    case 10:
      return 'Allow chatbot'

    case 11:
      return 'Allow admin topics'

    default:
      return 'Unknown'
  }
}

const updateConfigEnum = (field) => {
  switch (field) {
    case 0:
      return 'Full'

    case 1:
      return 'None'

    case 2:
      return 'Update Only'

    case 3:
      return 'Capacity Only'
  }
}

const userConfigEnum = (field) => {
  switch (field) {
    case 0:
      return 'Full'

    case 1:
      return 'None'

    case 2:
      return 'Update Only'

    case 3:
      return 'Capacity Only'

    case 4:
      return 'Can add/edit objectives'

    case 5:
      return 'Can change obj status'

    case 6:
      return 'Can change obj priority'

    case 7:
      return 'Can change initiative no.'
  }
}

const companyConfigEnums = {
  productSettings: 'Product Settings',
  disableAi: 'Disable AI',
  showMeetingTopicsInUW: 'Show Meeting Topics in UW',
  skipTlWizIcsPage: 'Skip TL Wiz ICS Page',
  showL2Objectives: 'Show L2 Objectives',
  showLinkageSummOfObj: 'Show Linkage Summary of Objectives',
  showMeetingDeck: 'Show Meeting Deck',
  hideSooAdditionalFilters: 'Hide SOO Additional Filters',
  showKpiReportTabInMh: 'Show KPI Report Tab in MH',
  enableDecisionLog: 'Enable Decision Log',
  setLanguageToES: 'Set Language to Spanish',
  enableAutoAddNewUsersToAuth0: 'Enable Auto Add New Users to Auth0',
  enableGanttChartInMh: 'Enable Gantt Chart in MH',
  restrictKpiAccess: 'Restrict KPI Access',
}

const statusOptionsArray = [
  { value: 'NEW', label: 'Pre-PD0' },
  { value: 'NOT_STARTED', label: 'PD0' },
  { value: 'IN_PROGRESS', label: 'PD1' },
  { value: 'COMPLETE', label: 'PDf' },
  { value: 'DEPRIORITIZED', label: 'PDx' },
]

const meetingViewModesEnum = {
  OBJECTIVE_VIEW: 0,
  PEOPLE_VIEW: 1,
  ACTION_VIEW: 2,
  CUSTOM_MEETING: 3,
  CUSTOM_TAG: 4,
}

export {
  objectiveDurationEnumValue,
  objDurationToFloat,
  governanceCycleEscalationStatus,
  milestoneStatus,
  tlActivityProgress,
  userConfigEnum,
  userConfigTLEnum,
  userConfigALEnum,
  statusOptionsArray,
  updateConfigEnum,
  companyConfigEnums,
  backLogActivityProgress,
  meetingViewModesEnum,
}
