import React, { useState, useEffect } from 'react'
import InPageLoader from '../../../../components/InPageLoader'
import styled from 'styled-components'
import './styles.css'
import useUpdateShowInLog from '../../../../api/query/useUpdateShowInLog'
import Select from 'react-select'
import { dateToString } from '../../../../utils/time'
import { getHttpRequest, postHttpRequest } from '../../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import { getCompanyConfigSettings, hasRole } from '../../../../utils/auth'
import EditCommentActivityModal from '../../../../components/EditCommentActivityModal'
import Toast from '../../../../common/toast'
import { useTranslation } from 'react-i18next'
import { linkifyString, shortenName } from '../../../../utils/general'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption.jsx'
import { getAllEmployeeList, getObjectivUserList } from '../../../../api/services/employee.services'
import { Link } from 'react-router-dom'
import { removeMentionMarkupFromText } from '../../../../utils/parseMarkup'
import AssigneeActivity from '../MeetingTopics/common/assigneeActivity'
import { MentionsInput, Mention } from 'react-mentions'
import useUpdateDecisionLogOutcome from '../../../../api/mutation/useUpdateDecisionLogOutcome'
import { dayMonthFilter } from '../../../../utils/time'
import { formatName } from '../../../../utils/name.js'
import MeetingDeck from '../../../../pages/MeetingMultiView/components/PopupPage'
import { useOutletContext } from 'react-router-dom'
import DecisionLogSelectMenu from './DecisionLogSelectMenu'
import DecisonLogEmailModal from './DecisonLogEmailModal'
import UpdateMeetingTopicModal from '../MeetingSummary/sub-components/UpdateMeetingTopicModal'
import AddEditMeetingTopicModal from '../../../../components/AddEditMeetingTopicModal/index.jsx'
import logoWithoutText from '../../../../assets/transforml_image_no_padding.png'
import DecisionLogAddCommentMilestone from './DecisionLogAddCommentMilestone'
import {
  deleteTopic,
  meetingCommentAdd,
  meetingMilestonAdd,
} from '../../../../api/services/meetingTopic.services'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 1.5rem;
`
const Wrapper = styled.div`
  div > textarea {
    border: 1px solid lightgrey;
    rows: 3;
  }
  width: 100%;
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif;

  .body_bottom_text__control {
    height: 3rem !important;
    min-height: 3rem !important;
    max-height: 3rem !important;
    overflow-y: auto;
  }
`
const DecisionLog = ({
  selectedLog,
  setSelectedLog,
  decisionLogList,
  setDecisionLogList,
  isLogEditable,
  setIsLogEditable,
  decisionLogData,
  setDecisionLogData,
  logTopicList,
  getDecisionLogData,
  checkboxComments,
  checkboxMiles,
  setCheckboxComments,
  setCheckboxMiles,
  readOnly = false,
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])

  const isProductSettingsEnabled = getCompanyConfigSettings('productSettings')

  const mutationUpdateShowInLog = useUpdateShowInLog()
  const mutationUpdateItemText = useUpdateDecisionLogOutcome()

  // need to to reset MH view to default
  // const { setResetViewMode } = useOutletContext()

  const [isLoadingDecisionLogList, setIsLoadingDecisionLogList] = useState(false)

  const [isLoadingDecisionLogData, setIsloadingDecisionLogData] = useState(false)

  const [isEditCommentActivityModalOpen, setIsEditCommentActivityModalOpen] = useState(false)
  const [textInput, setTextInput] = useState('')
  const [commentIdToEdit, setCommentIdToEdit] = useState(0)
  const [activityIdToEdit, setActivityIdToEdit] = useState(0)
  // const [checkboxComments, setCheckboxComments] = useState({})
  // const [checkboxMiles, setCheckboxMiles] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [teamLeadersData, setTeamLeadersData] = React.useState(null)
  const [additionalLogViewersList, setAdditionalLogViewersList] = React.useState(null)
  const [tlId, setTlId] = React.useState(null)
  const [logViewersList, setLogViewersList] = React.useState([])
  const [peopleList, setPeopleList] = React.useState([])
  const [userList, setUserList] = React.useState([])
  const [logName, setLogName] = React.useState('')
  const [logNameErrorMessage, setLogNameErrorMessage] = React.useState(null)
  const loggedInName = localStorage.getItem('tfmnm')
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false)
  const [mentionState, setMentionState] = React.useState([])
  const [isMeetingDeckOpen, setIsMeetingDeckOpen] = useState(false)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [isUpdateMeetingTopicModalOpen, setIsUpdateMeetingTopicModalOpen] = useState(false)
  const [isAddMeetingTopicOpen, setIsAddMeetingTopicOpen] = useState(false)

  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)

  // these are for assignee activity modal
  const [selectedMilestoneId, setSelectedMilestoneId] = useState(null)
  const [selectedAssigneeList, setSelectedAssigneeList] = useState([])

  const updateItemText = React.useCallback((id, text) => {
    setDecisionLogData((prevData) => {
      const updateComments = (comments) => {
        return comments.map((comment) => {
          if (comment.commentId === id) {
            return { ...comment, commentText: text }
          }
          return comment
        })
      }

      const updateMilestones = (milestones) => {
        return milestones.map((milestone) => {
          if (milestone.milestoneId === id) {
            return { ...milestone, milestoneDescription: text }
          }
          return milestone
        })
      }

      const updateTopics = (topics) => {
        return topics.map((topic) => ({
          ...topic,
          comments: topic.comments ? updateComments(topic.comments) : undefined,
          milestones: topic.milestones ? updateMilestones(topic.milestones) : undefined,
        }))
      }

      return {
        ...prevData,
        objectives: prevData.objectives.map((objective) => ({
          ...objective,
          blockers: objective.blockers ? updateTopics(objective.blockers) : undefined,
          decisions: objective.decisions ? updateTopics(objective.decisions) : undefined,
          updates: objective.updates ? updateTopics(objective.updates) : undefined,
          topics: objective.topics ? updateTopics(objective.topics) : undefined,
        })),
      }
    })
  }, [])

  async function checkIfLogNameExists() {
    if (logName === '' || logName === null || logName === undefined) {
      setLogNameErrorMessage(t('decisionLog.logNameIsRequired'))
      return true
    }

    let currentDate = new Date()
    // Format current date to MM/DD/YYYY
    let formattedCurrentDate = `${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getFullYear()}`

    const postObj = {
      logName: `${formattedCurrentDate} - ${logName}`,
    }

    const headers = {
      'Content-Type': 'application/json', // Set Content-Type header to application/json
    }

    try {
      const response = await postHttpRequest('/check_decision_log_name_exists', postObj, headers)
      if (response && response.success) {
        if (response.queryResult === true) {
          setLogNameErrorMessage(t('decisionLog.logNameExists'))
          return true
        } else {
          setLogNameErrorMessage(null)
          return false
        }
      } else {
        // Handle case where log name doesn't exist
      }
    } catch (error) {
      setShowLoader(false) // Handle error appropriately
    }
  }

  async function sendLog() {
    setShowLoader(true)
    const postObj = {}

    let currentDate = new Date()
    // Format current date to MM/DD/YYYY
    let formattedCurrentDate = `${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getFullYear()}`

    postObj.logName = `${formattedCurrentDate} - ${logName}`
    postObj.logId = selectedLog.logId
    postObj.additionalLogViewers = logViewersList

    postHttpRequest('/send_decision_log', postObj)
      .then((response) => {
        if (response) {
          getDecisionLogListData()
          setLogName('')
          handleClearUnfinalizedLog(true)

          return
        } else {
        }
      })
      .catch((error) => {
        setShowLoader(false)
      })
  }

  const handleClearUnfinalizedLog = async (afterSendLog = false) => {
    const result = await Swal.fire({
      title: afterSendLog
        ? t('Common:modalMessages.doYouWantToSendAnotherLogFromThisList')
        : t('Common:modalMessages.areYouSureYouWantToClearThisLog'),
      text: afterSendLog
        ? t('Common:modalMessages.doYouWantToSendAnotherLogFromThisListMessage')
        : t('Common:modalMessages.areYouSureYouWantToClearThisLogMessage'),
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if ((result.isConfirmed && !afterSendLog) || (!result.isConfirmed && afterSendLog)) {
      clearUnfinalizedLog(afterSendLog)
    }

    if (result.isConfirmed && afterSendLog) {
      //Not clear after send log, keep on main
      handleChangeSelectedLog(selectedLog)
    }
  }

  async function clearUnfinalizedLog(afterSendLog) {
    const postObj = {}
    postObj.logId = selectedLog.logId
    postHttpRequest('/finalize_decision_log', postObj)
      .then((response) => {
        if (response) {
          getDecisionLogListData(tlId)
          setTimeout(() => {
            if (afterSendLog) {
              //if clear done after send log, load most recent child
              for (let log of decisionLogList?.decisionLogs) {
                if (!log.isMain) {
                  handleChangeSelectedLog(log)
                  return
                }
              }
            } else if (!afterSendLog && loggedInName === selectedLog.areaLeaderName) {
              // if clear done independently through its own button, load most recent main
              for (let log of decisionLogList?.decisionLogs) {
                if (log.isMain) {
                  handleChangeSelectedLog(log)
                  return
                }
              }
            }
          }, 3000)

          return
        }
      })
      .catch((error) => {})
  }

  const handleChangeSelectedLog = (value) => {
    setSelectedLog(value)
    getDecisionLogData(value.logId, value.isFinalized)
    setIsLogEditable(!value.isFinalized)
  }

  function getDecisionLogListData(teamLeaderId = null) {
    // setIsLoadingDecisionLogList(true)
    setShowLoader(true)
    let url =
      teamLeaderId != null ? `/get_decision_logs_list/${teamLeaderId}` : `/get_decision_logs_list`
    getHttpRequest(url)
      .then((response) => {
        setDecisionLogList(response)

        // setIsLoadingDecisionLogList(false)
        setShowLoader(false)
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
        // setIsLoadingDecisionLogList(false)
        setShowLoader(false)
      })
  }

  const handleChangeLogName = (e) => {
    setLogName(e.target.value)
  }

  const handleEdit = (id, text, type) => {
    type === 'comment' ? setCommentIdToEdit(id) : setActivityIdToEdit(id)
    setTextInput(text)
    setIsEditCommentActivityModalOpen(true)
  }

  async function getReportingLeaders() {
    const result = await getHttpRequest('/team_leaders_for_area_leader', {
      params: {
        notExcludeCos: true,
      },
    })
    let employees =
      result?.employees?.filter((emp) => emp.isDeactivated === false && emp.isDeleted === false) ??
      []
    setPeopleList(employees)
    setLogViewersList(employees.map((emp) => emp.eId))
  }

  const handleCloseEditCommentActivityModal = () => {
    setIsEditCommentActivityModalOpen(false)
    setCommentIdToEdit(0)
    setActivityIdToEdit(0)
    setTextInput('')
    getDecisionLogData(
      decisionLogList?.decisionLogs[0].logId,
      decisionLogList.decisionLogs[0].isFinalized,
    )
    setShowLoader(false)
  }

  if (showLoader) {
    return <InPageLoader />
  }

  const handleCheck = async (e, isComment) => {
    setShowLoader(false)
    if (isComment) {
      // await mutationUpdateShowInLog.mutateAsync({
      //   included: !checkboxComments[e.target.id],
      //   commentId: e.target.id,
      // })
      setCheckboxComments((prev) => ({
        ...prev,
        [e.target.id]: !prev[e.target.id],
      }))
    } else {
      // await mutationUpdateShowInLog.mutateAsync({
      //   included: !checkboxMiles[e.target.id],
      //   milestoneId: e.target.id,
      // })
      setCheckboxMiles((prev) => ({
        ...prev,
        [e.target.id]: !prev[e.target.id],
      }))
    }
  }

  const renderComments = (comment, objective, topic) => {
    return (
      <div key={comment.commentId} className="decision-log-inner-grid-area-view">
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 0.03fr', gap: '1rem' }}>
          <div
            className="decision-log-comment"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            {isLogEditable && (
              <div style={{}}>
                <input
                  type="checkbox"
                  id={comment.commentId}
                  name={comment.commentId}
                  checked={checkboxComments[comment.commentId]}
                  onChange={(e) => handleCheck(e, true)}
                />
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.3rem',
              }}
            >
              {comment.isAddedByAi && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '0.2rem',
                  }}
                >
                  <img
                    className="logo-new-folded"
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      marginRight: '0.3rem',
                    }}
                    src={logoWithoutText}
                    alt="TransforML Logo"
                  />
                  <span
                    style={{
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      color: 'rgb(91, 132, 203)',
                    }}
                  >
                    {t('decisionLog.ai')}
                  </span>
                </div>
              )}

              <div
                style={{
                  backgroundColor: 'white',
                  color: '#A9A9A9',
                  border: '1px solid #A9A9A9',
                  padding: '0.1rem 0.2rem',
                  borderRadius: '0.5rem',
                  width: '4.5rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '13.33px',
                  }}
                >
                  {comment.isDecision ? t('decisionLog.decision') : t('decisionLog.comment')}
                </div>
              </div>
            </div>
            {isLogEditable ? (
              <Wrapper>
                <MentionsInput
                  className="body_bottom_text"
                  value={comment.commentText}
                  onChange={(e, v, nv, mentions) => {
                    let id = 0
                    let mention = mentionState.find((mention) => mention.id === id)
                    if (mention) {
                      setMentionState((prev) =>
                        prev.map((item) =>
                          item.id === id ? { ...item, mentions: mentions } : item,
                        ),
                      )
                    } else {
                      setMentionState([...mentionState, { id: id, mentions: mentions }])
                    }

                    updateItemText(comment.commentId, e.target.value)
                  }}
                  onBlur={(e) => {
                    if (/\S/.test(comment.commentText)) {
                      updateOutcome(comment.commentId, 0, comment.commentText)
                    }
                  }}
                  allowSuggestionsAboveCursor={true}
                  style={{
                    height: '4.3rem',
                    padding: '0',
                    input: {
                      margin: 0,
                      overflow: 'auto',
                    },
                    suggestions: {
                      list: {
                        maxHeight: 80,
                        overflowY: 'auto',
                      },
                      item: {
                        '&focused': {
                          backgroundColor: '#d3d3d3',
                        },
                      },
                    },
                    highlighter: {
                      border: '4px solid transparent',
                    },
                  }}
                  spellCheck={false}
                  disabled={readOnly}
                >
                  <Mention
                    trigger="@"
                    data={userList}
                    // renderSuggestion={this.renderUserSuggestion}
                    markup="@[__display__](user:__id__)"
                    displayTransform={(id, display) => `@${display}`}
                    renderSuggestion={(suggestion, search, highlightedDisplay) => (
                      <div className="user">{highlightedDisplay}</div>
                    )}
                    appendSpaceOnAdd={true}
                    // style={{backgroundColor:'rgb(206, 228, 229)'}}
                  />
                </MentionsInput>
              </Wrapper>
            ) : (
              <div style={{ width: '40rem' }}>
                {linkifyString(removeMentionMarkupFromText(comment.commentText))}
              </div>
            )}
          </div>
          {/* {!isTeamMeetingView && isLogEditable && (
            <div
              class="fitted-button blue"
              onClick={() => {
                handleEdit(comment.commentId, comment.commentText, 'comment')
              }}
            >
              <span class="material-symbols-outlined icon-size-sm">edit</span>
            </div>
          )} */}
        </div>
        <div style={{ paddingLeft: '2rem' }}>{shortenName(comment.commentOwnerName)}</div>
      </div>
    )
  }

  const renderMilestones = (milestone, objective, topic) => {
    return (
      <div key={milestone.milestoneId} className="decision-log-inner-grid-area-view">
        <div
          key={milestone.milestoneId}
          style={{ display: 'grid', gridTemplateColumns: '1fr 0.03fr', gap: '1rem' }}
        >
          <div
            className="decision-log-milestone"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            {isLogEditable && (
              <div style={{}}>
                <input
                  type="checkbox"
                  id={milestone.milestoneId}
                  name={milestone.milestoneId}
                  checked={checkboxMiles[milestone.milestoneId]}
                  onChange={(e) => handleCheck(e, false)}
                />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.3rem',
              }}
            >
              {milestone.isAddedByAi && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '0.2rem',
                  }}
                >
                  <img
                    className="logo-new-folded"
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      marginRight: '0.3rem',
                    }}
                    src={logoWithoutText}
                    alt="TransforML Logo"
                  />
                  <span
                    style={{
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      color: 'rgb(91, 132, 203)',
                    }}
                  >
                    {t('decisionLog.ai')}
                  </span>
                </div>
              )}
              <div
                style={{
                  backgroundColor: '#A9A9A9',
                  color: 'white',
                  border: '1px solid  white',
                  padding: '0.1rem 0.2rem',
                  borderRadius: '0.5rem',
                  width: '4.5rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '13.33px',
                  }}
                >
                  {t('decisionLog.action')}
                </div>
              </div>
            </div>
            {isLogEditable ? (
              <Wrapper>
                <MentionsInput
                  className="body_bottom_text"
                  value={milestone.milestoneDescription}
                  onChange={(e, v, nv, mentions) => {
                    let id = milestone.milestoneId
                    let mention = mentionState.find((mention) => mention.id === id)
                    if (mention) {
                      setMentionState((prev) =>
                        prev.map((item) =>
                          item.id === id ? { ...item, mentions: mentions } : item,
                        ),
                      )
                    } else {
                      setMentionState([...mentionState, { id: id, mentions: mentions }])
                    }

                    updateItemText(milestone.milestoneId, e.target.value)
                  }}
                  onBlur={(e) => {
                    if (/\S/.test(milestone.milestoneDescription)) {
                      updateOutcome(0, milestone.milestoneId, milestone.milestoneDescription)
                    }
                  }}
                  allowSuggestionsAboveCursor={true}
                  style={{
                    height: '3rem',
                    padding: '0',

                    input: {
                      margin: 0,
                      overflow: 'auto',
                    },
                    suggestions: {
                      list: {
                        maxHeight: 80,
                        overflowY: 'auto',
                      },
                      item: {
                        '&focused': {
                          backgroundColor: '#d3d3d3',
                        },
                      },
                    },
                    highlighter: {
                      border: '4px solid transparent',
                    },
                  }}
                  spellCheck={false}
                  disabled={readOnly}
                >
                  <Mention
                    trigger="@"
                    data={userList}
                    // renderSuggestion={this.renderUserSuggestion}
                    markup="@[__display__](user:__id__)"
                    displayTransform={(id, display) => `@${display}`}
                    renderSuggestion={(suggestion, search, highlightedDisplay) => (
                      <div className="user">{highlightedDisplay}</div>
                    )}
                    appendSpaceOnAdd={true}
                    // style={{backgroundColor:'rgb(206, 228, 229)'}}
                  />
                </MentionsInput>
              </Wrapper>
            ) : (
              <div style={{ width: '40rem' }}>
                {linkifyString(removeMentionMarkupFromText(milestone.milestoneDescription))}
              </div>
            )}
          </div>
          {isLogEditable && (
            <div style={{}}>
              {/* <div
                class="fitted-button blue"
                onClick={() => {
                  handleEdit(milestone.milestoneId, milestone.milestoneDescription, 'activity')
                }}
              >
                <span class="material-symbols-outlined icon-size-sm">edit</span>
              </div> */}
              {
                <div
                  class="fitted-button blue"
                  onClick={() => {
                    assignActivity(milestone)
                  }}
                >
                  <span class="material-symbols-outlined icon-size">person_add</span>
                </div>
              }
            </div>
          )}
        </div>
        <div style={{ paddingLeft: isLogEditable ? '0' : '2rem' }}>
          {milestone?.assignees?.map((assignee, index) => (
            <span key={index}>
              {index > 0 && ', '}
              {shortenName(assignee.name)}
            </span>
          ))}
        </div>
      </div>
    )
  }

  async function assignActivity(milestone) {
    setSelectedMilestoneId(milestone.milestoneId)
    setIsAssignmentModalOpen(true)
    setSelectedAssigneeList(milestone?.assignees)
  }

  const closeAssigneeActivity = () => {
    setSelectedAssigneeList([])
    setSelectedMilestoneId(null)
    getDecisionLogData(
      decisionLogList?.decisionLogs[0].logId,
      decisionLogList.decisionLogs[0].isFinalized,
    )
  }

  const updateOutcome = async (commentId, activityId, textInput) => {
    let data = {}
    data.commentId = commentId
    data.activityId = activityId
    data.outcome = textInput
    let id = activityId === 0 ? commentId : activityId

    let mentions =
      mentionState.find((mention) => mention.id === id)?.mentions.map((m) => m.id) ?? null
    if (mentions) {
      data.mentions = mentions
    }

    await mutationUpdateItemText.mutateAsync(data, {
      onSuccess: () => {
        // Toast.fire({
        //   icon: 'success',
        //   title:
        //     commentId != 0
        //       ? t('Common:modalMessages.commentUpdatedSuccessfully')
        //       : t('Common:modalMessages.actionUpdatedSuccessfully'),
        // })
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
        })
      },
    })
  }

  const updateMilestoneAssignees = (milestoneId, newAssignees) => {
    const updateMilestone = (milestone) => {
      if (milestone.milestoneId === milestoneId) {
        milestone.assignees = newAssignees
        return true
      }
      return false
    }

    const updateTopics = (topics) => {
      for (const topic of topics) {
        if (topic.milestones && topic.milestones.some(updateMilestone)) {
          return true
        }
      }
      return false
    }

    for (const objective of decisionLogData.objectives) {
      const topicTypes = ['topics']

      for (const topicType of topicTypes) {
        if (objective[topicType] && updateTopics(objective[topicType])) {
          return
        }
      }
    }
  }

  const handleSelectAll = () => {
    const { comments, milestones } = extractItemsFromDecisionLog(decisionLogData)

    // Single state update for comments
    setCheckboxComments(
      Object.fromEntries(Object.keys(comments).map((commentId) => [commentId, true])),
    )

    // Single state update for milestones
    setCheckboxMiles(
      Object.fromEntries(Object.keys(milestones).map((milestoneId) => [milestoneId, true])),
    )
  }

  const handleSelectNone = () => {
    // Get only currently checked items
    const checkedComments = Object.entries(checkboxComments)
      .filter(([_, isChecked]) => isChecked)
      .map(([commentId]) => commentId)

    const checkedMilestones = Object.entries(checkboxMiles)
      .filter(([_, isChecked]) => isChecked)
      .map(([milestoneId]) => milestoneId)

    if (checkedComments.length === 0 && checkedMilestones.length === 0) {
      return
    }

    // Single state update for comments
    setCheckboxComments(Object.fromEntries(checkedComments.map((commentId) => [commentId, false])))

    // Single state update for milestones
    setCheckboxMiles(
      Object.fromEntries(checkedMilestones.map((milestoneId) => [milestoneId, false])),
    )
  }

  const extractItemsFromDecisionLog = (decisionLogData) => {
    const comments = {}
    const milestones = {}

    // Helper function to process topics
    const processTopics = (topics) => {
      topics?.forEach((topic) => {
        // Process comments
        topic.comments?.forEach((comment) => {
          comments[comment.commentId] = comment
        })

        // Process milestones
        topic.milestones?.forEach((milestone) => {
          milestones[milestone.milestoneId] = milestone
        })
      })
    }

    // Process all objectives and their topics
    decisionLogData?.objectives?.forEach((objective) => {
      if (objective.topics) {
        processTopics(objective.topics)
      }
    })

    return { comments, milestones }
  }

  const handleSelectOnlyAI = () => {
    const { comments, milestones } = extractItemsFromDecisionLog(decisionLogData)

    // Filter AI-generated items
    const aiComments = Object.entries(comments)
      .filter(([_, comment]) => comment.isAddedByAi)
      .map(([id]) => id)

    const aiMilestones = Object.entries(milestones)
      .filter(([_, milestone]) => milestone.isAddedByAi)
      .map(([id]) => id)

    // Set all items to false first
    setCheckboxComments(Object.fromEntries(Object.keys(comments).map((id) => [id, false])))
    setCheckboxMiles(Object.fromEntries(Object.keys(milestones).map((id) => [id, false])))

    // Then set AI items to true
    if (aiComments.length > 0) {
      setCheckboxComments((prev) => ({
        ...prev,
        ...Object.fromEntries(aiComments.map((id) => [id, true])),
      }))
    }

    if (aiMilestones.length > 0) {
      setCheckboxMiles((prev) => ({
        ...prev,
        ...Object.fromEntries(aiMilestones.map((id) => [id, true])),
      }))
    }
  }

  const handleSelectOnlyManuallyAdded = () => {
    const { comments, milestones } = extractItemsFromDecisionLog(decisionLogData)

    // Filter manually added items
    const manualComments = Object.entries(comments)
      .filter(([_, comment]) => !comment.isAddedByAi)
      .map(([id]) => id)

    const manualMilestones = Object.entries(milestones)
      .filter(([_, milestone]) => !milestone.isAddedByAi)
      .map(([id]) => id)

    // Set all items to false first
    setCheckboxComments(Object.fromEntries(Object.keys(comments).map((id) => [id, false])))
    setCheckboxMiles(Object.fromEntries(Object.keys(milestones).map((id) => [id, false])))

    // Then set manual items to true
    if (manualComments.length > 0) {
      setCheckboxComments((prev) => ({
        ...prev,
        ...Object.fromEntries(manualComments.map((id) => [id, true])),
      }))
    }

    if (manualMilestones.length > 0) {
      setCheckboxMiles((prev) => ({
        ...prev,
        ...Object.fromEntries(manualMilestones.map((id) => [id, true])),
      }))
    }
  }
  const deleteDecisionLogItems = async () => {
    const commentIds = Object.entries(checkboxComments)
      .filter(([_, isChecked]) => isChecked)
      .map(([commentId]) => commentId)

    const milestoneIds = Object.entries(checkboxMiles)
      .filter(([_, isChecked]) => isChecked)
      .map(([milestoneId]) => milestoneId)

    if (commentIds.length === 0 && milestoneIds.length === 0) {
      Toast.fire({
        icon: 'warning',
        title: t('Common:modalMessages.pleaseSelectAtLeastOneItem'),
      })
      return
    }

    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureYouWantToDeleteSelectedItems'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed && (commentIds.length > 0 || milestoneIds.length > 0)) {
      try {
        setShowLoader(true)
        const response = await postHttpRequest('/delete_decision_log_items', {
          commentIds,
          milestoneIds,
        })

        if (response?.success === true) {
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.deletedSuccessfully'),
          })

          await Promise.all([
            setCheckboxComments((prev) => {
              const newState = { ...prev }
              commentIds.forEach((id) => delete newState[id])
              return newState
            }),
            setCheckboxMiles((prev) => {
              const newState = { ...prev }
              milestoneIds.forEach((id) => delete newState[id])
              return newState
            }),
          ])

          // Refresh decision log data
          getDecisionLogData(selectedLog.logId, selectedLog.isFinalized)
        } else {
          Toast.fire({
            icon: 'error',
            title: t('Common:errorModal.text1'),
          })
        }
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('Common:errorModal.text1'),
        })
      } finally {
        setShowLoader(false)
      }
    }
  }

  const transferItemsToTopic = async (items, newTopic, oldTopic = null) => {
    const postObj = {
      commentIds: items.filter((item) => item.type === 'comment').map((item) => item.id),
      milestoneIds: items.filter((item) => item.type === 'milestone').map((item) => item.id),
      newTopicId: newTopic.umtId,
      goalId: newTopic.objId,
    }
    const response = await postHttpRequest('/move_items_to_topic', postObj)
    if (response?.success === true) {
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.updatedSuccessfully'),
      })

      // Clear selected items
      setCheckboxComments({})
      setCheckboxMiles({})

      // Close the modal
      setIsUpdateMeetingTopicModalOpen(false)

      // Refresh the data using selectedLog
      if (selectedLog) {
        getDecisionLogData(selectedLog.logId, selectedLog.isFinalized)
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:errorModal.text1'),
      })
    }
  }

  const getSelectedItems = () => [
    ...Object.entries(checkboxComments)
      .filter(([_, isChecked]) => isChecked)
      .map(([commentId]) => ({ type: 'comment', id: commentId })),
    ...Object.entries(checkboxMiles)
      .filter(([_, isChecked]) => isChecked)
      .map(([milestoneId]) => ({ type: 'milestone', id: milestoneId })),
  ]

  const getSelectedItemsWithInfo = () => {
    const selectedItems = {
      comments: [],
      milestones: [],
    }

    // Helper function to find item in objectives data
    const findItemInData = (itemId, itemType) => {
      for (const objective of decisionLogData?.objectives || []) {
        for (const topic of objective?.topics || []) {
          if (itemType === 'comment') {
            if (topic.comments?.length > 0) {
              const comment = topic.comments.find((c) => c.commentId === itemId)
              if (comment) {
                return {
                  ...comment,
                }
              }
            }
          } else {
            if (topic.milestones?.length > 0) {
              const milestone = topic.milestones.find((m) => m.milestoneId === itemId)
              if (milestone) {
                return {
                  ...milestone,
                }
              }
            }
          }
        }
      }
      return null
    }

    // Get selected comments with full info
    Object.entries(checkboxComments)
      .filter(([_, isChecked]) => isChecked)
      .forEach(([commentId]) => {
        const commentInfo = findItemInData(parseInt(commentId), 'comment')
        if (commentInfo) {
          selectedItems.comments.push(commentInfo)
        }
      })

    // Get selected milestones with full info
    Object.entries(checkboxMiles)
      .filter(([_, isChecked]) => isChecked)
      .forEach(([milestoneId]) => {
        const milestoneInfo = findItemInData(parseInt(milestoneId), 'milestone')
        if (milestoneInfo) {
          selectedItems.milestones.push(milestoneInfo)
        }
      })

    return selectedItems
  }

  const getSelectedItemOwnerIds = () => {
    const selectedItems = getSelectedItemsWithInfo()
    const selectedOwners = []
    // selectedItems.comments.forEach(c => selectedOwners.push({eId: c.commentOwnerId, name: c.commentOwnerName}))
    selectedItems.milestones.forEach((m) =>
      m?.assignees?.forEach((a) => selectedOwners.push({ eId: a.eId, name: a.name })),
    )
    //remove duplicates
    const uniqueSelectedOwners = selectedOwners.filter(
      (owner, index, self) => index === self.findIndex((t) => t.eId === owner.eId),
    )
    return uniqueSelectedOwners
  }

  const createItem = async (itemType, goalId, mentions = [], umtId, meetingInstanceId, logId) => {
    // Validate item type
    if (!['comment', 'decision', 'action'].includes(itemType)) {
      Toast.fire({
        icon: 'error',
        title: t('Common:errorModal.text1'),
      })
      return
    }

    const defaultText = t('MeetingHub:decisionLog.clickHereToEditTheText')
    try {
      if (itemType === 'action') {
        // Create milestone/action
        const formData = {
          milestoneDesc: defaultText,
          goalId: goalId,
          mentions: mentions.map((mention) => mention.id),
          umtId: umtId,
          meetingInstanceId: meetingInstanceId,
          logId: logId,
        }

        const result = await meetingMilestonAdd(formData)
        if (result) {
          // Find the objective and topic to add the milestone to
          const updatedObjectives = decisionLogData.objectives.map((objective) => {
            if (objective.objId === goalId) {
              const updatedTopics = objective.topics.map((topic) => {
                if (topic.umtId === umtId) {
                  return {
                    ...topic,
                    milestones: [
                      {
                        milestoneId: result.milestoneId,
                        milestoneDescription: defaultText,
                        assignees: [],
                        showInLog: true,
                        isAddedByAi: false,
                        logId: logId,
                      },
                      ...(topic.milestones || []),
                    ],
                  }
                }
                return topic
              })
              return { ...objective, topics: updatedTopics }
            }
            return objective
          })

          setDecisionLogData((prev) => ({
            ...prev,
            objectives: updatedObjectives,
          }))
        } else {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      } else {
        // Create comment or decision
        const formData = {
          commentText: defaultText,
          goalId: goalId,
          isDecision: itemType === 'decision',
          mentions: mentions.map((mention) => mention.id),
          umtId: umtId,
          meetingInstanceId: meetingInstanceId,
          logId: logId,
        }

        const result = await meetingCommentAdd(formData)
        if (result) {
          // Find the objective and topic to add the comment to
          const updatedObjectives = decisionLogData.objectives.map((objective) => {
            if (objective.objId === goalId) {
              const updatedTopics = objective.topics.map((topic) => {
                if (topic.umtId === umtId) {
                  return {
                    ...topic,
                    comments: [
                      {
                        commentId: result.commentId,
                        commentText: defaultText,
                        isDecision: itemType === 'decision',
                        showInLog: true,
                        isAddedByAi: false,
                        commentOwnerName: result.ownerName || '',
                        logId: logId,
                      },
                      ...(topic.comments || []),
                    ],
                  }
                }
                return topic
              })
              return { ...objective, topics: updatedTopics }
            }
            return objective
          })

          setDecisionLogData((prev) => ({
            ...prev,
            objectives: updatedObjectives,
          }))
        } else {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        }
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  console.log('decisionLogData?.objectives', decisionLogData?.objectives)
  console.log('uniqueSelectedOwners', getSelectedItemOwnerIds())

  const handleDeleteAiGeneratedTopic = async (topicId) => {
    console.log('topicId11', topicId)
    let result = await deleteTopic(topicId)
    console.log('result', result)
    if (result.success) {
      getDecisionLogData(selectedLog.logId, selectedLog.isFinalized)
    }
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {isEditCommentActivityModalOpen && (
          <EditCommentActivityModal
            isModalOpen={isEditCommentActivityModalOpen}
            closeModal={() => {
              handleCloseEditCommentActivityModal()
            }}
            commentId={commentIdToEdit}
            activityId={activityIdToEdit}
            textIn={textInput}
            userList={userList}
          />
        )}
        {isAssignmentModalOpen && selectedMilestoneId && (
          <AssigneeActivity
            objectiveId={-1}
            milestoneId={selectedMilestoneId}
            setModelView={setIsAssignmentModalOpen}
            assigneeList={selectedAssigneeList}
            view={isAssignmentModalOpen}
            loadAgain={closeAssigneeActivity}
            updateMilestoneAssignees={updateMilestoneAssignees}
          />
        )}
        {isMeetingDeckOpen && (
          <MeetingDeck
            closeModal={() => {
              setIsMeetingDeckOpen(false)
            }}
            umtId={selectedTopic.umtId}
            topicType={selectedTopic.topicType}
            readOnly={false}
            redirectTo="decisionLog"
            fromKanban={false}
          />
        )}
        {isEmailModalOpen && (
          <DecisonLogEmailModal
            isModalOpen={isEmailModalOpen}
            closeModal={() => setIsEmailModalOpen(false)}
            selectedLog={selectedLog}
            selectedComments={getSelectedItems()
              .filter((item) => item.type === 'comment')
              .map((item) => item.id)}
            selectedMilestones={getSelectedItems()
              .filter((item) => item.type === 'milestone')
              .map((item) => item.id)}
            selectedOwners={getSelectedItemOwnerIds()}
          />
        )}

        {isUpdateMeetingTopicModalOpen && (
          <UpdateMeetingTopicModal
            closeModal={() => setIsUpdateMeetingTopicModalOpen(false)}
            availableTopics={logTopicList}
            currentTopic={null}
            selectedItem={[
              ...Object.entries(checkboxComments)
                .filter(([_, isChecked]) => isChecked)
                .map(([commentId]) => ({ type: 'comment', id: commentId })),
              ...Object.entries(checkboxMiles)
                .filter(([_, isChecked]) => isChecked)
                .map(([milestoneId]) => ({ type: 'milestone', id: milestoneId })),
            ]}
            transferItemToTopic={transferItemsToTopic}
          />
        )}

        {isAddMeetingTopicOpen && (
          <AddEditMeetingTopicModal
            isModalOpen={isAddMeetingTopicOpen}
            closeModal={() => {
              setIsAddMeetingTopicOpen(false)
            }}
            loadAgain={() => {
              getDecisionLogData(selectedLog.logId, selectedLog.isFinalized)
            }}
            meetingInstanceId={selectedLog?.meetingInstanceId}
            isProductSettingsEnabled={isProductSettingsEnabled}
            teamLeadersList={teamLeadersData}
            //showMineButton={selectedLeaderToLoadMH == null}
            //selectedLeaderToLoadMH={selectedLeaderToLoadMH}
          />
        )}

        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
            {/* {hasRole('cxo') && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div style={{ fontWeight: 'bold' }}>{t('decisionLog.selectLeader')}</div>
                <SelectSingleField
                  value={
                    teamLeadersData?.employees?.filter(
                      (emp) => parseInt(emp.eId) === parseInt(tlId)
                    )[0]
                  }
                  options={teamLeadersData?.employees}
                  onChange={inputChangedHandler}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.eId}
                  width={'20rem'}
                />
              </div>
            )} */}
            {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <div style={{ fontWeight: 'bold' }}>{t('decisionLog.selectLog')}</div>
              <Select
                menuPortalTarget={document.body}
                placeholder={t('Common:filter.select') + '...'}
                value={selectedLog}
                onChange={handleChangeSelectedLog}
                getOptionLabel={(option) =>
                  `${option?.logName} ${
                    option?.isMain &&
                    (option?.logStatus === 'NOT_STARTED_DECISION_LOG_STATUS' ||
                      option?.logStatus === 'IN_PROGRESS_DECISION_LOG_STATUS')
                      ? '(' + t('decisionLog.inProgress') + ')'
                      : ''
                  }`
                }
                getOptionValue={(option) => option?.logId}
                options={decisionLogList?.decisionLogs}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '25rem',
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }),
                }}
              />
            </div> */}

            {/* {!((hasRole('team') && !isDelegateTl) || hasRole('ic')) && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div style={{ fontWeight: 'bold' }}>{t('decisionLog.logName')}</div>
                <input
                  placeholder={t('Common:filter.select') + '...'}
                  value={logName}
                  onChange={(e) => {
                    handleChangeLogName(e)
                  }}
                  style={{
                    width: '20rem',
                    height: '2.5rem',
                    backgroundColor: 'hsl(0, 0%, 100%)',
                    borderColor: 'hsl(0, 0%, 80%)',
                    borderRadius: '4px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
            )} */}
          </div>
          {/* {isLogEditable && (
            <>
              <div style={{ width: 'auto', fontWeight: 'bold', marginTop: '0.5rem' }}>
                {t('decisionLog.additionalLogRecipients')}
              </div>
              <Select
                isMulti={true}
                value={peopleList}
                options={additionalLogViewersList}
                onChange={handleAdditionalLogViewers}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.eId}
                isClearable={true}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '42rem',
                  }),
                }}
              />
            </>
          )} */}

          <div className="decision-log-actions-row">
            {isLogEditable && (
              <div className="select-menu-wrapper">
                <span class="material-symbols-outlined">
                  {isSelectMenuOpen ? 'indeterminate_check_box' : 'check_box_outline_blank'}
                </span>
                <DecisionLogSelectMenu
                  onSelectAll={handleSelectAll}
                  onSelectNone={handleSelectNone}
                  onSelectOnlyAI={handleSelectOnlyAI}
                  onSelectOnlyManuallyAdded={handleSelectOnlyManuallyAdded}
                />
              </div>
            )}
            {getSelectedItems().length > 0 && isLogEditable && (
              <div className="fitted-button blue" onClick={() => setIsEmailModalOpen(true)}>
                <span class="material-symbols-outlined">outgoing_mail</span>
              </div>
            )}
            {getSelectedItems().length > 0 && isLogEditable && (
              <div className="fitted-button blue" onClick={() => deleteDecisionLogItems()}>
                <span class="material-symbols-outlined">delete</span>
              </div>
            )}
            {getSelectedItems().length > 0 && isLogEditable && (
              <div
                class="fitted-button blue"
                onClick={() => {
                  setIsUpdateMeetingTopicModalOpen(true)
                }}
              >
                <span class="material-symbols-outlined">swap_vert</span>
              </div>
            )}
            {/* To be discusse if it will be implemented later */}
            {/* <div
                class="fitted-button blue"
                
              >
                <span class="material-symbols-outlined">person_add</span>
              </div> */}
            {isLogEditable && (
              <div
                className="fitted-button blue"
                onClick={() => {
                  setIsAddMeetingTopicOpen(true)
                }}
              >
                <span className="material-symbols-outlined">add</span>
              </div>
            )}
            {/* To be discusse if it will be implemented later */}
            {/* <div className='fitted-button blue'>
            <span className="material-symbols-outlined">
              flag
            </span>
            </div> */}
          </div>
        </div>

        {decisionLogData?.objectives?.length > 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              //marginTop: '1.2rem',
            }}
          >
            <div
              style={{
                marginBottom: '1rem',
                padding: readOnly ? '0 0rem 2rem 0rem' : '0 2rem 2rem 2rem',
              }}
            >
              <div className="decision-log-meeting-table">
                <div className="decision-log-meeting-header"></div>
                {/* <div className="decision-log-inner-grid-area-view">
                  <div className="decision-log-meeting-header">{t('decisionLog.outcome')}</div>
                  <div className="decision-log-meeting-header" style={{ paddingLeft: '0rem' }}>
                    {t('decisionLog.owner')}
                  </div>
                  {!isTeamMeetingView && isLogEditable && (
                    <div className="decision-log-meeting-header">{t('decisionLog.include')}?</div>
                  )}
                </div>

                <div className="divider decision-log-meeting-description"></div> */}

                {decisionLogData?.objectives?.map((objective, index) => {
                  const { topics } = objective
                  const totalRows = topics?.length ?? 0
                  return (
                    <React.Fragment key={`objective-${index}`}>
                      {totalRows > 0 && (
                        <React.Fragment>
                          <div
                            className="decision-log-meeting-description"
                            style={{ paddingTop: readOnly ? '0rem' : '2rem', maxWidth: '90%' }}
                          >
                            <b>
                              {/* only show initiative number if not general and initiative id exists */}
                              {isProductSettingsEnabled &&
                              !objective?.isGeneralObjective &&
                              objective?.initiativeId &&
                              objective.isGeneralObjective === false ? (
                                <span>
                                  {objective?.initiativeId.toString()?.padStart(5, '0')} -{' '}
                                  {objective.objDescription}
                                </span>
                              ) : (
                                <span>{objective.objDescription}</span>
                              )}
                            </b>
                          </div>
                          <div className="decision-log-meeting-description">
                            <div
                              className={`${readOnly ? 'divider-blue' : 'underline-meeting'}`}
                            ></div>
                          </div>
                          {topics?.length > 0 && (
                            <>
                              {topics.map((topic) => (
                                <>
                                  <div
                                    className="decision-log-meeting-description"
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      //gap: '1rem',
                                      alignItems: 'center',

                                      // justifyContent: 'space-between',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '1rem',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundColor:
                                            topic.topicType === 1
                                              ? 'rgb(237, 82, 46)'
                                              : topic.topicType === 2
                                                ? 'rgb(9, 38, 213)'
                                                : topic.topicType === 3
                                                  ? 'rgb(127, 131, 155)'
                                                  : '#9933ff',
                                          color: 'white',
                                          padding: '0.2rem 0.2rem',
                                          borderRadius: '0.5rem',
                                          width: '5rem',
                                          fontWeight: 'bold',
                                          height: 'fit-content',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '13.33px',
                                          }}
                                        >
                                          {topic.topicType === 1
                                            ? t('Common:filter.blocker')
                                            : topic.topicType === 2
                                              ? t('Common:filter.guidance')
                                              : topic.topicType === 3
                                                ? t('Common:filter.update')
                                                : t('Common:filter.admin')}
                                        </div>
                                      </div>
                                      <b style={{ width: '41.5rem' }}>
                                        {linkifyString(topic.description)}
                                      </b>
                                      {isLogEditable && (
                                        <DecisionLogAddCommentMilestone
                                          createItem={createItem}
                                          goalId={objective.objId}
                                          mentions={[]}
                                          umtId={topic.umtId}
                                          meetingInstanceId={selectedLog?.meetingInstanceId}
                                          logId={selectedLog?.logId}
                                        />
                                      )}
                                      <div>
                                        {topic.hasMeetingDeck && (
                                          <>
                                            <div
                                              class="fitted-button blue tooltip"
                                              onClick={() => {
                                                setSelectedTopic(topic)
                                                setIsMeetingDeckOpen(true)
                                              }}
                                            >
                                              <span class="material-symbols-outlined">
                                                slideshow
                                              </span>
                                              <span class="tooltiptext">
                                                {' '}
                                                {t('Common:tooltip.viewUploadedPresentation')}{' '}
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div style={{ fontWeight: 'normal' }}>
                                      {formatName(topic.topicOwnerName) +
                                        ' ' +
                                        t('decisionLog.on') +
                                        ' ' +
                                        dayMonthFilter(topic.openedTime)}
                                    </div>

                                    {topic.isAddedByAi && isLogEditable && (
                                      <div className="decision-log-meeting-description">
                                        <button
                                          className="fitted-button blue"
                                          style={{
                                            padding: '0.3rem 0.5rem',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            marginLeft: '2rem',
                                            backgroundColor: 'transparent',
                                          }}
                                          onClick={() => {
                                            handleDeleteAiGeneratedTopic(topic.umtId)
                                          }}
                                        >
                                          <span className="material-symbols-outlined">delete</span>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  <div className="decision-log-meeting-description">
                                    <div
                                      className={`${readOnly ? 'divider' : 'underline-meeting-light'}`}
                                    ></div>
                                  </div>
                                  {/* <div></div> */}
                                  {/* Bring back if we want indentation */}

                                  {/* <div className="decision-log-blocker">
                                      {linkifyString(topic.description)}
                                    </div> */}
                                  <div
                                    className="decision-log-meeting-description"
                                    style={{ fontWeight: 'normal' }}
                                  >
                                    {topic?.comments?.length > 0 && (
                                      <>
                                        {topic.comments.map((comment) => (
                                          <>{renderComments(comment, objective, topic)}</>
                                        ))}
                                      </>
                                    )}
                                    {topic?.milestones?.length > 0 && (
                                      <>
                                        {topic.milestones.map((milestone) => (
                                          <>{renderMilestones(milestone, objective, topic)}</>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                </>
                              ))}
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
            {logNameErrorMessage && (
              <div
                style={{
                  height: '1rem',
                  width: '100%',
                  textAlign: 'center',
                  paddingBottom: '0.5rem',
                }}
              >
                <span
                  className="validation-error"
                  style={{
                    fontSize: '14px',
                    visibility: logNameErrorMessage ? 'visible' : 'hidden',
                  }}
                >
                  {logNameErrorMessage}
                </span>
              </div>
            )}
            {/* {isLogEditable && (
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <div class="fitted-button blue" onClick={() => handleClearUnfinalizedLog()}>
                  <span>{t('decisionLog.clearLog')}</span>
                </div>
                <div class="fitted-button blue" onClick={() => handleSendDecisionLog()}>
                  <span>{t('decisionLog.sendLog')}</span>
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <div style={{ padding: readOnly ? '2rem 0rem 2rem 0rem' : '2rem 0rem 2rem 10rem' }}>
            {t('decisionLog.thereAreNoLogsAtThisTime')}
          </div>
        )}
      </div>
      <p>&nbsp;</p>
    </>
  )
}

export default DecisionLog
