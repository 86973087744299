import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import Toast from '../../../../common/toast'

import { postHttpRequest } from '../../../../api/query/dynamicAPI'

function BrainstormSessionDropdownMenu(props) {
  const { t } = useTranslation(['Common'])

  const handleDelete = (event) => {
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation()
    }
    Swal.fire({
      title: t('Common:modalMessages.areYouSureDeleteBrainstormSession'),
      text: t('Common:modalMessages.youWillNotBeAbleToRecoverThisSession'),
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    }).then((result) => {
      if (result.isConfirmed) {
        postHttpRequest('/brainstorm/delete_brainstorm_session', {
          brainstormId: props.brainstormId,
        })
          .then((response) => {
            if (response.success === true) {
              props.onDelete()
              Toast.fire({
                icon: 'success',
                title: t('Common:modalMessages.brainstormSessionDeleted'),
              })
            } else {
              Toast.fire({
                icon: 'error',
                title: t('Common:modalMessages.brainstormSessionDeleteFailed'),
              })
            }
          })
          .catch((error) => {
            console.error('Error deleting brainstorm session:', error)
            Toast.fire({
              icon: 'error',
              title: t('Common:modalMessages.brainstormSessionDeleteFailed'),
            })
          })
      }
    })
  }

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Menu
        menuClassName="brainstorm-session-dropdown-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: 'white',
              border: 'none',
              padding: '0',
              cursor: 'pointer',
            }}
          >
            <span
              style={{ alignSelf: 'center', transform: 'rotate(90deg)' }}
              className="material-symbols-outlined"
            >
              more_vert
            </span>
          </MenuButton>
        }
      >
        <MenuItem style={{ textAlign: 'left' }} onClick={handleDelete}>
          {t('commonButtons.delete')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default BrainstormSessionDropdownMenu
