import React from 'react'
import Modal from '../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { getHttpRequest, postHttpRequest } from '../../../../../api/query/dynamicAPI'
import Toast from '../../../../../common/toast'
import './styles.css'
import Select from 'react-select'

const EditDecisionLogModal = ({
  isModalOpen,
  closeModal,
  selectedLog,
  onSuccess,
  selectedMeeting,
  meetingTagsList,
  onMeetingChange,
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [logName, setLogName] = React.useState(selectedLog?.logName || '')
  const [logNameErrorMessage, setLogNameErrorMessage] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (selectedLog) {
      setLogName(selectedLog.logName)
    }
  }, [selectedLog])

  React.useEffect(() => {
    console.log('Modal meetingTagsList:', meetingTagsList)
    console.log('Modal selectedMeeting:', selectedMeeting)
  }, [meetingTagsList, selectedMeeting])

  const handleSubmit = async () => {
    if (!logName.trim()) {
      setLogNameErrorMessage(t('Common:validation.required'))
      return
    }

    setIsLoading(true)
    try {
      const response = await postHttpRequest('/update_decision_log', {
        logId: selectedLog.logId,
        logName: logName.trim(),
      })

      if (response.success) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.successfullyUpdated'),
        })
        onSuccess()
        closeModal()
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isModalOpen={isModalOpen} closeModal={closeModal} width={'40rem'}>
      <div className="modal-gray-box">
        <div className="modal-inner-content">
          <div className="edit-decision-log-modal-title">{t('MeetingHub:decisionLog.editLog')}</div>

          <div className="form-container">
            <div className="input-group">
              <label className="label" style={{ textAlign: 'left', width: '100%' }}>
                {t('MeetingHub:decisionLog.logName')}
              </label>
              <input
                type="text"
                className={`input ${logNameErrorMessage ? 'has-error' : ''}`}
                value={logName}
                onChange={(e) => {
                  setLogName(e.target.value)
                  setLogNameErrorMessage(null)
                }}
                placeholder={t('MeetingHub:decisionLog.enterLogName')}
                style={{ width: '19.4rem' }}
              />
              {logNameErrorMessage && <span className="error-message">{logNameErrorMessage}</span>}
            </div>

            <div className="input-group">
              <label className="label" style={{ textAlign: 'left', width: '100%' }}>
                {t('MeetingHub:decisionLog.meetingTag')}
              </label>
              <Select
                menuPortalTarget={document.body}
                placeholder={t('Common:filter.select') + '...'}
                value={selectedMeeting}
                onChange={onMeetingChange}
                getOptionLabel={(option) => option?.meetingName || ''}
                getOptionValue={(option) => option?.meetingId || ''}
                options={meetingTagsList || []}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    width: '21rem',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    textAlign: 'left',
                    width: '21rem',
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    textAlign: 'left',
                    width: '21rem',
                  }),
                }}
                isSearchable={true}
                menuPosition="fixed"
                menuPlacement="auto"
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: '1rem',
                gap: '1rem',
              }}
            >
              <button
                className="sleek-button sleek-blue"
                onClick={closeModal}
                disabled={isLoading}
                style={{
                  cursor: 'pointer',
                  height: '2.2rem',
                  backgroundColor: 'white',
                }}
              >
                {t('Common:commonButtons.cancel')}
              </button>
              <button
                className="sleek-button sleek-blue"
                onClick={handleSubmit}
                disabled={isLoading}
                style={{
                  cursor: 'pointer',
                  height: '2.2rem',
                  backgroundColor: 'white',
                }}
              >
                {t('Common:commonButtons.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditDecisionLogModal
