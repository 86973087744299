import React, { useEffect, useState, useRef } from 'react'
import BrainstormAudioButton from '../../../components/BrainstormAudioButton'
import { useTranslation } from 'react-i18next'
import { BrainstormProvider, useBrainstorm } from '../Recorder/BrainstormContext'
import ChatBoxBrainstorm from '../ChatBoxPoc'
import DoneButton from './DoneButton'
import TextArea from 'rc-textarea'
//import ObjectiveMapBrainstorm from '../../objectiveFlow/BrainstormMode'
import { useMicVAD } from '@ricky0123/vad-react'
import MindMap from '../../../components/MindMap'
import SpeakingAnimationCircle from '../../../components/SpeakingAnimations/SpeakingAnimationCircle'
import odinRobot from '../../../assets/Robot.jpg'
import OdinCircle from './OdinCircle'
import './style.css'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCompanyConfigSettings, getConsolidatedConfigSettings } from '../../../utils/auth'
import SelectField from '../../../components/CustomMultipleSelect/singleOption'
import Toast from '../../../common/toast'
import { routes } from '../../../utils/routes'
import BrainstormMeetingTagModal from '../Component/Modal/BrainstormMeetingTagModal'
import { convertTimeStamToMMDDYYYYDash } from '../../../utils/time'
import Select from 'react-select'
import {
  generateOverallSummary,
  getOwnerOfMeeting,
  getRemainingTimeAndSubscriptionStatus,
  getSummarizedActionPlans,
} from '../../../api/services/brainstorm.services'
import { useReactFlow } from '@xyflow/react'
import html2canvas from 'html2canvas'
import InPageLoader from '../../../phoenix/components/Loader'
// import SpeechBubble from './SpeechBubble'
import InsightsViewer from './InsightsViewer'
import SubQuestions from './SubQuestions'
import PauseButton from './PauseButton'
import TimeRemainingAlert from '../../../phoenix/components/TimeRemainingAlert'
import LoadingModal from '../../../components/LoadingModal'
import BrainstormingChatBot from '../../../phoenix/components/BrainstormingChatBot'
import TextInputModal from './Modal/TextInputModal'
import { useNavigationContext } from '../Context/NavigationContext'
import { useAuth } from '../../../phoenix/authContext'

// const translations = {
//   sentenceOnPageLoad: {
//     en: "Hi, welcome to Decision+! We're here to help you structure and solve problems. Click 'Start' to begin!",
//     es: "Hola, bienvenido a Decision+! Estamos aquí para ayudarte a estructurar y resolver problemas. Haz clic en 'Comenzar' para empezar.",
//   },
//   problemStatement: {
//     en: "Let's define your problem statement. You can type it in the space on the left and click 'Continue'.",
//     es: "Definamos la declaración del problema. Puedes escribirla en el espacio de la izquierda y hacer clic en 'Continuar",
//   },
//   exploration: {
//     en: "Up here, I've identified some questions you should explore. You can refresh one of them if it isn't quite what you're looking for.",
//     es: 'Aquí arriba, he identificado algunas preguntas que deberías explorar. Puedes actualizar una si no es exactamente lo que estás buscando.',
//   },
//   draftAgain: {
//     en: "Here's your updated problem statement, anything else to change? Otherwise click finalize.",
//     es: 'Aquí tienes tu enunciado del problema actualizado, ¿hay algo más que cambiar? Si no, haz clic en Finalizar.',
//   },
//   imThinking: {
//     en: "I'm thinking...",
//     es: 'Estoy pensando...',
//   },
//   letMeThink: {
//     en: 'let me think',
//     es: 'Déjame pensar',
//   },
//   giveMeASecond: {
//     en: 'Give me a second',
//     es: 'Dame un segundo',
//   },
//   justAMoment: {
//     en: 'Just a moment',
//     es: 'Un momento',
//   },
//   letMeProcessThat: {
//     en: 'Let me process that',
//     es: 'Déjame procesarlo',
//   },
//   refine: {
//     en: "Here's what I've gathered as your problem statement. You can refine it or go ahead with this one. Click below.",
//     es: 'Esto es lo que he reunido como tu enunciado del problema. Puedes refinarlo o seguir adelante con esta opción. Haz clic abajo.',
//   },
//   update: {
//     en: 'How would you like to update it?',
//     es: '¿Cómo te gustaría actualizarlo?',
//   },
// }

// const BrainstormVadCompomnent = ({
//   stream = undefined,
//   onSpeechStartcallBack = () => {},
//   onSpeechEndcallBack = () => {},
//   isPaused = false,
//   setHasStarted = () => {},
// }) => {
//   const brainstormVad = useMicVAD({
//     stream: stream,
//     ortConfig: (ort) => {
//       ort.env.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/'
//     },
//     startOnLoad: false,
//     onSpeechStart: (audio) => {
//       console.log('**** Speech start detected ***')
//       setHasStarted(true)
//       onSpeechStartcallBack()
//     },
//     onSpeechEnd: (audio) => {
//       console.log('**** Speech end detected ***')
//       if (!isPaused) {
//         onSpeechEndcallBack()
//       } else {
//         console.log('isPaused: ', isPaused)
//       }
//     },
//     redemptionFrames: 10,
//     positiveSpeechThreshold: 0.85,
//     negativeSpeechThreshold: 0.6,
//   })
//   // vadRef.current = brainstormVad
//   // setVad(brainstormVad)
//   // setIsVadStarted(true)

//   if (brainstormVad && !brainstormVad.listening) {
//     brainstormVad.start()
//   }

//   return <></>
// }

// const areEqual = (prevProps, nextProps) => {
//   const propsChanged = Object.entries(nextProps).some(([key, val]) => prevProps[key] !== val)
//   if (propsChanged) {
//     console.log(
//       'Changed prop:',
//       Object.fromEntries(Object.entries(nextProps).filter(([key, val]) => prevProps[key] !== val)),
//     )
//   }
//   return !propsChanged
// }

// const MemoBrainstormVadCompomnent = React.memo(BrainstormVadCompomnent, areEqual)

const DESKTOP_APP_INITIAL_CHECK_DELAY = 7000 // 7 second delay for initial registration check
const TIME_ALERT_DURATION = 10000 // 10 seconds
const CONNECTION_POLLING_INTERVAL = 1000 // 1 second

const BrainstormComponent = ({ initialSetup, isFullVersion }) => {
  const { t } = useTranslation(['Common', 'ObjectiveMap'])
  const [isOwner, setIsOwner] = useState(false)

  const isSpanish = getCompanyConfigSettings('languageSettings') === 'es'
  const auth = useAuth() // Always call the hook

  // const [cancelVoice, setCancelVoice] = useState(false)
  const audioRef = React.useRef(null)

  const {
    state,
    dispatch,
    accumulateDataChunks,
    sendAudioData,
    exploreProblems,
    startRecording,
    stopRecording,
    source,
    removeDuplicateItems,
    removeContradictoryItems,
    initAddUserTopic,
    reDistributeParkingLot,
  } = useBrainstorm()

  const { dispatch: applicationDispatch } = useNavigationContext()

  const languageSetting = getCompanyConfigSettings('languageSettings')
  const [language] = React.useState(
    state.language ? state.language : languageSetting ? languageSetting : 'en',
  )
  const [isBackgroundLoading, setIsBackgroundLoading] = useState(true)
  const [isExploringProblems, setIsExploringProblems] = useState(false)

  const { authStatus, setAuthStatus } =
    source === 'zisi'
      ? auth
      : {
          authStatus: {
            userData: {
              show_chatbot: localStorage.getItem('show_chatbot') === 'true',
            },
          },
          setAuthStatus: () => {},
        }

  useEffect(() => {
    dispatch({ type: 'SET_LANGUAGE', language: language })
  }, [language])

  // const [bubbleTextIndex, setBubbleTextIndex] = useState(0)
  const [hasStarted, setHasStarted] = useState(false)
  const [isThinking, setIsThinking] = useState(false)

  const [userStopsSpeaking, setUserStopsSpeaking] = useState(false)

  const timeoutRef = useRef(null)
  const interventionTimeoutRef = useRef(null)
  const isTimeoutCancelled = useRef(false)

  const [isGeneratingPromptSuggestion, setIsGeneratingPromptSuggestion] = useState(false)

  const [refreshButtonLoading, setRefreshButtonLoading] = useState({})
  const [collapseStates, setCollapseStates] = useState({})
  // const audioTimeout = useRef(null)
  const shouldNotHaveAudio = useRef(false)

  const [hoverIndex, setHoverIndex] = useState(-1)
  const [sessionsHoverIndex, setSessionsHoverIndex] = useState(-1)
  const [isDesktopAppRegistered, setIsDesktopAppRegistered] = useState(true)
  const { getNodes, getNodesBounds, fitBounds, fitView } = useReactFlow()

  const numberList = [5, 6, 7, 8, 9]
  const destkopAppRegisterationPollingIntervalRef = useRef(null)

  const [remainingTime, setRemainingTime] = useState(2.3)
  const [subscriptionStatus, setSubscriptionStatus] = useState('onTrial')

  const [isSaving, setIsSaving] = useState(false)
  const [showTimeAlert, setShowTimeAlert] = useState(true)

  const [isTextInputModalOpen, setIsTextInputModalOpen] = useState(false)
  const [connectionStatusAlreadyChecked, setConnectionStatusAlreadyChecked] = useState(false)
  const isDevEnv = process.env.REACT_APP_DEVELOPER_ENV == 'true'

  useEffect(() => {
    const checkOwnership = async () => {
      if (state.meetingInstanceId) {
        try {
          const ownershipResponse = await getOwnerOfMeeting(state.meetingInstanceId)
          setIsOwner(ownershipResponse.isOwner)
        } catch (error) {
          console.error('Error checking meeting ownership:', error)
        }
      }
    }

    checkOwnership()
  }, [state.meetingInstanceId])

  React.useEffect(() => {
    setIsBackgroundLoading(true)
    if (initialSetup) {
      // if there is a selected session, set the selected brainstorm session
      if (initialSetup.activeTab === 'recent') {
        setSelectedBrainstormSession(initialSetup.selectedSession)
        setIsBackgroundLoading(false)
      } else if (initialSetup.activeTab === 'new') {
        setIsBackgroundLoading(false)
        if (state.primaryProblem && state.primaryProblem.trim() !== '') {
          dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'exploration' })
          let placeholder = {
            problem: state.primaryProblem,
            action_plans: [],
          }
          dispatch({ type: 'SET_BRAINSTORM_MAP', brainstormMap: placeholder })
          setIsExploringProblems(true)
          setTimeout(() => {
            exploreProblemsAsync()
          }, 1500)
        } else {
          dispatch({ type: 'SET_PROGRESS_STATE', progressState: 'exploration' })
          let placeholder = {
            problem: '',
            action_plans: [],
          }
          dispatch({ type: 'SET_BRAINSTORM_MAP', brainstormMap: placeholder })

          setIsBackgroundLoading(false)
        }
      }
    }
  }, [initialSetup])

  const exploreProblemsAsync = async () => {
    try {
      setIsThinking(true)
      await exploreProblems()
    } catch (error) {
      console.error('Error exploring problems:', error)
    } finally {
      setIsThinking(false)
      setIsBackgroundLoading(false)
      setIsExploringProblems(false)
    }
  }

  // const [messages, setMessages] = useState([
  //   {
  //     text: t('Common:brainstorming.sentenceOnPageLoad'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.problemStatement'),
  //     sender: 'other',
  //   },
  //   {
  //     text: '',
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.exploration'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.draftAgain'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.imThinking'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.letMeThink'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.giveMeASecond'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.justAMoment'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.letMeProcessThat'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.refine'),
  //     sender: 'other',
  //   },
  //   {
  //     text: t('Common:brainstorming.update'),
  //     sender: 'other',
  //   },
  //   // { text: `I've organized your actions and decisions here, take a look!`, sender: 'other' },
  // ])
  // const hasMessageUpdate = useRef(0)
  const [isBrainstormMeetingTagModalOpen, setIsBrainstormMeetingTagModalOpen] = useState(false)
  // things that users have access to
  const [availableTags, setAvailableTags] = useState([])
  const [availableMeetings, setAvailableMeetings] = useState([])
  // tags/meetings that are selected by the user in the current brainstorm session when save
  const [selectedTagsCurrentSession, setSelectedTagsCurrentSession] = useState([])
  const [selectedMeetingsCurrentSession, setSelectedMeetingsCurrentSession] = useState([])

  // things that are attached to the past brainstorm sessions
  const [tagsOnSessions, setTagsOnSessions] = useState({})
  const [meetingsOnSessions, setMeetingsOnSessions] = useState({})
  // tags/meetings that are selected by the user to filter the brainstorm sessions
  const [selectedTagsOnSessions, setSelectedTagsOnSessions] = useState([])
  const [selectedMeetingsOnSessions, setSelectedMeetingsOnSessions] = useState([])

  const shouldHideTag = getConsolidatedConfigSettings('disable_tag_brainstorming') === true
  const allowBulkConvertToMH =
    getConsolidatedConfigSettings('categorize_brainstorming_topics_and_save_to_mh') === true

  const dotStyle = {
    // fontSize: '24px',
    fontWeight: 'bold',
    // color: '#3B82F6', // a shade of blue
    opacity: 0,
    transition: 'opacity 0.2s',
    //margin: '0 2px',
  }

  const activeDotStyle = {
    ...dotStyle,
    opacity: 1,
  }

  const currentLocation = useLocation()
  const navigate = useNavigate()
  const [primaryProblemState, setPrimaryProblemState] = useState(state.primaryProblem)
  const userId = localStorage.getItem('tfei')
  const [brainstormSessions, setBrainstormSessions] = React.useState([])
  const [filteredBrainstormSessions, setFilteredBrainstormSessions] = React.useState([])
  const [selectedBrainstormSession, setSelectedBrainstormSession] = React.useState(null)
  const [isQuestionsCollapsed, setIsQuestionsCollapsed] = React.useState(false)
  const [hasSaved, setHasSaved] = React.useState(false)
  const [subQuestionState, setSubQuestionState] = React.useState(state.subQuestions)

  const [isInsightsCollapsed, setIsInsightsCollapsed] = React.useState(true)
  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState(false) //set to false

  const [insightsList, setInsightsList] = React.useState([])
  const [mindMapBlur, setMindMapBlur] = React.useState(false) //set to false
  const [insightModal, setInsightModal] = React.useState(false) //set to false

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (state.research_content?.response_statement && state.research_content?.response_title) {
      let tempInsightsList = JSON.parse(JSON.stringify(insightsList))
      tempInsightsList.push({
        text: state.research_content?.response_statement,
        title: state.research_content?.response_title,
        date: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      })
      setInsightsList(tempInsightsList)
      setIsInsightsCollapsed(false)
      setIsQuestionsCollapsed(true)
      setMindMapBlur(true)
      setIsMenuCollapsed(true)
      setInsightModal(true)

      const duration = 15000 // 15 seconds
      const interval = 10 // Update every 10ms
      const steps = duration / interval
      let currentStep = 0

      const timer2 = setInterval(() => {
        currentStep += 1
        setProgress((currentStep / steps) * 100)

        if (currentStep >= steps) {
          clearInterval(timer2)
          setMindMapBlur(false)
          setIsInsightsCollapsed(false)
          setIsQuestionsCollapsed(true)
          setIsMenuCollapsed(false)
          setInsightModal(false)
        }
      }, interval)

      return () => clearInterval(timer2)
      // Set a timeout to collapse insights and open questions after 30 seconds
      // const timer = setTimeout(() => {
      //   setIsInsightsCollapsed(true)
      //   setIsQuestionsCollapsed(false)
      //   setMindMapBlur(false)
      //   setIsMenuCollapsed(false)
      //   setInsightModal(false)
      // }, 15000) // 15 seconds in milliseconds

      // Cleanup timer on component unmount or when effect re-runs
      //return () => clearTimeout(timer)
    }
  }, [state.research_content?.response_statement])

  useEffect(() => {
    setPrimaryProblemState(state.primaryProblem)
  }, [state.primaryProblem])

  useEffect(() => {
    setSubQuestionState(state.subQuestions)
  }, [state.subQuestions])

  const handleBeforeUnload = (e) => {
    e.preventDefault()
    //do not show the default message
    e.returnValue = ''
  }

  useEffect(() => {
    if (selectedBrainstormSession) {
      applicationDispatch({ type: 'SET_PREVENT_DEFAULT_WHILE_NAVIGATING', payload: false })
    } else {
      applicationDispatch({ type: 'SET_PREVENT_DEFAULT_WHILE_NAVIGATING', payload: true })
      window.addEventListener('beforeunload', handleBeforeUnload)
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [selectedBrainstormSession])

  // Handle browser back button
  useEffect(() => {
    if (!selectedBrainstormSession) {
      const handlePopState = (event) => {
        event.preventDefault()
        applicationDispatch({ type: 'SET_PREVENT_DEFAULT_WHILE_NAVIGATING', payload: false })
      }
      window.addEventListener('popstate', handlePopState)
      return () => {
        window.removeEventListener('popstate', handlePopState)
      }
    } else if (selectedBrainstormSession) {
      window.history.pushState(null, '', window.location.pathname)
      //redirect to the home page after click on the back button
      const handlePopState = (event) => {
        // Navigate to home page when back button is clicked with a selected session
        navigate('/', { replace: true })
      }

      // Add event listener
      window.addEventListener('popstate', handlePopState)

      // Clean up
      return () => {
        window.removeEventListener('popstate', handlePopState)
      }
    }
  }, [selectedBrainstormSession])

  useEffect(() => {
    if (state.autoTermination) {
      //save the session
      saveBrainstormSession()
    }
  }, [state.autoTermination])

  useEffect(() => {
    const wasmFile =
      'https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/ort-wasm-simd-threaded.wasm' // Function to preload the WASM file
    const preloadWasm = async () => {
      try {
        const response = await fetch(wasmFile)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        // Wait for the download to complete
        await response.arrayBuffer()
      } catch (error) {
        console.error('Error loading WASM file:', error)
        // Optionally, handle error state here
      }
    }

    // Call the function to preload the WASM file
    preloadWasm()

    // const interval = setInterval(() => {
    //   setActiveDot((prevDot) => (prevDot + 1) % 3)
    // }, 300)

    getBrainstormSessions()
    setDestkopAppRegistrationCheckPolling()
    // https://stackoverflow.com/questions/40099431/how-do-i-clear-location-state-in-react-router-on-page-reload
    // navigate(currentLocation.pathname, { replace: true })
    if (source === 'zisi') {
      getTimeAndSubscriptionStatus()

      return () => {
        // clearInterval(interval)
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        // if(audioTimeout.current){
        //   clearTimeout(audioTimeout.current)
        // }
      }
    }
  }, [])

  const getTimeAndSubscriptionStatus = () => {
    //TO-DO: add getter that returns values for the remaining time and subscription status
    const getData = async () => {
      const response = await getRemainingTimeAndSubscriptionStatus()
      setRemainingTime(response.remaining_time / 60 / 60)
      setSubscriptionStatus(response.subscription_status)
    }
    getData()
  }

  const setDestkopAppRegistrationCheckPolling = () => {
    // Only start polling if we have a meetingInstanceId and aren't already registered
    if (state.hasDesktopApp && state.meetingInstanceId && !connectionStatusAlreadyChecked) {
      // Check immediately with a 7 second delay
      setTimeout(() => {
        checkIfDesktopAppRegisteredBrainstormSession()

        // Start polling every 1 second after the initial check
        destkopAppRegisterationPollingIntervalRef.current = setInterval(() => {
          checkIfDesktopAppRegisteredBrainstormSession()
        }, CONNECTION_POLLING_INTERVAL)
      }, DESKTOP_APP_INITIAL_CHECK_DELAY)
    }

    // Cleanup function
    return () => {
      if (destkopAppRegisterationPollingIntervalRef.current) {
        clearInterval(destkopAppRegisterationPollingIntervalRef.current)
        destkopAppRegisterationPollingIntervalRef.current = null
      }
    }
  }

  const getBrainstormSessions = async (selectedBrainstormId = null) => {
    try {
      const response = await getHttpRequest(`/brainstorm/get_brainstorm_sessions`, {
        params: {
          brainstormId: selectedBrainstormId,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      if (selectedBrainstormId && response) {
        return response
      } else {
        setBrainstormSessions(response.topLevelProblems)
        setFilteredBrainstormSessions(response.topLevelProblems)

        const meetingsMap = new Map()
        const tagsMap = new Map()

        response.topLevelProblems.forEach((problem) => {
          if (problem.meetings) {
            problem.meetings.forEach((meeting) => {
              if (!meetingsMap.has(meeting.meetingId)) {
                meetingsMap.set(meeting.meetingId, meeting)
              }
            })
          }

          if (problem.tags) {
            problem.tags.forEach((tag) => {
              if (!tagsMap.has(tag.tagId)) {
                tagsMap.set(tag.tagId, tag)
              }
            })
          }
        })

        setMeetingsOnSessions(Array.from(meetingsMap.values()))
        setTagsOnSessions(Array.from(tagsMap.values()))
      }
    } catch (error) {
      console.log('getBrainstormSessions error', error)
    }
    return null
  }

  React.useEffect(() => {
    ;(async () => {
      if (selectedBrainstormSession) {
        let response = await getBrainstormSessions(selectedBrainstormSession.brainstormId)
        //   navigate(`/${routes.brainstorm}`, {
        //     state: {
        //         brainstormData: response
        //       }
        // })
        if (selectedBrainstormSession.isBsCompleted) {
          setHasSaved(true)
        }
        dispatch({
          type: 'RESTORE_BRAINSTORM_SESSION',
          brainstormSession: response,
        })
        // setBubbleTextIndex(3)
      }
    })()
  }, [selectedBrainstormSession])

  const refreshQuestion = async (questionNeededRefresh, ind) => {
    console.log('refreshQuestion')
    setRefreshButtonLoading((prev) => ({ ...prev, [ind]: true }))
    try {
      await exploreProblems(questionNeededRefresh, true)
    } catch (error) {
      console.log('error: ', error)
    }
    setRefreshButtonLoading((prev) => ({ ...prev, [ind]: false }))
  }

  useEffect(() => {
    // update the question ideas storage to db
    if (
      state.isBrainstormingStarted &&
      state.meetingInstanceId &&
      state.questionIdeasStorage &&
      Object.keys(state.questionIdeasStorage).length > 0
    ) {
      ;(async () => {
        await postHttpRequest(`/brainstorm/update_brainstorm_instance/${state.meetingInstanceId}`, {
          questionIdeas: JSON.stringify(state.questionIdeasStorage),
        })
      })()
    }
  }, [state.questionIdeasStorage])

  const takeScreenshot = React.useCallback(async () => {
    // Get all nodes and find the topmost and bottommost y coordinates
    const nodes = getNodes()
    if (!nodes.length) return null

    const filteredNodes = nodes.filter((node) => node.type == 'mindmapTopicNode').slice(0, 5)
    if (filteredNodes.length === 0) {
      fitView({ duration: 800 })
      return null
    }

    const flowContainer = document.querySelector('.react-flow__renderer')
    const viewport = document.querySelector('.react-flow__viewport')
    if (!flowContainer || !viewport) return null

    const bounds = getNodesBounds(filteredNodes)
    const adjustedBounds = {
      x: bounds.x - 20,
      y: bounds.y,
      width: bounds.width + 40,
      height: bounds.height,
    }

    viewport.style.transition = 'none'
    viewport.style.transform = `translate(${-adjustedBounds.x}px, ${-adjustedBounds.y}px) scale(1)`

    await new Promise((resolve) => requestAnimationFrame(resolve))

    // Take screenshot
    const canvas = await html2canvas(flowContainer, {
      onclone: (clonedDocument) => {
        // Handle textareas in the cloned document
        Array.from(clonedDocument.querySelectorAll('textarea.rc-textarea')).forEach((textArea) => {
          const div = clonedDocument.createElement('div')
          div.innerText = textArea.value

          div.style.resize = textArea.style.resize
          div.style.minHeight = textArea.style.minHeight
          div.style.maxHeight = textArea.style.maxHeight
          div.style.width = '155px'
          div.style.backgroundColor = textArea.style.backgroundColor
          div.style.borderRadius = textArea.style.borderRadius
          div.style.fontFamily = textArea.style.fontFamily
          div.style.fontSize = textArea.style.fontSize
          div.style.height = textArea.style.height
          div.style.transitionProperty = 'none'
          div.style.whiteSpace = 'pre-wrap'
          div.style.overflow = 'auto'
          div.style.wordWrap = 'break-word'

          textArea.style.display = 'none'
          textArea.parentElement.appendChild(div)
        })
      },
      scale: 2,
      useCORS: true,
      logging: false,
      backgroundColor: '#ffffff',
      allowTaint: true,
    })

    const dataUrl = canvas.toDataURL('image/png')
    return { image: dataUrl }
  }, [fitView, getNodes, getNodesBounds])

  const removeContradictoryItemsRequest = async () => {
    try {
      await removeContradictoryItems(state.meetingInstanceId)
    } catch (error) {
      console.log('removeContradictoryItemsRequest error: ', error)
    }
  }

  const reDistributeParkingLotRequest = async () => {
    try {
      await reDistributeParkingLot(state.meetingInstanceId)
    } catch (error) {
      console.log('reDistributeParkingLotRequest error: ', error)
    }
  }

  const saveBrainstormSession = async (selectedTags = [], selectedMeetings = []) => {
    setIsSaving(true)
    try {
      // if (!state.isRemovingDuplicateItems) {
      //   await removeDuplicateItems(state.meetingInstanceId, true)
      //   await new Promise(resolve => setTimeout(resolve, 500))
      // }
      let screenshot = await takeScreenshot()
      await reDistributeParkingLotRequest()
      await removeContradictoryItemsRequest()
      await new Promise((resolve) => setTimeout(resolve, 500))
      // let summarizedBrainstormMap = await generateSummarizedBrainstormMap()
      await postHttpRequest(`/brainstorm/save_brainstorm_session`, {
        meetingInstanceId: state.meetingInstanceId,
        // discardedList: state.discardedList,
        highlightedSubProblems: state.highlightedQuestions,
        selectedMeetings: selectedMeetings.map((item) => item?.meetingId).filter((item) => item),
        selectedTags: selectedTags.filter((item) => item),
        screenshot: screenshot,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // summarizedBrainstormMap: JSON.stringify(summarizedBrainstormMap)
      })
      Toast.fire({
        icon: 'success',
        title: 'Brainstorming session saved successfully',
      })
      setHasSaved(true)
      //navigate to meeting minutes page
      applicationDispatch({ type: 'SET_PREVENT_DEFAULT_WHILE_NAVIGATING', payload: false })
      await sendNote()

      return true
    } catch (error) {
      console.log('saveBrainstormSession error: ', error)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong! Please try again later.',
      })
      return false
    } finally {
      setIsSaving(false)
    }
  }

  const saveBrainstormSessionCheck = async () => {
    const result = await Swal.fire({
      title:
        state.language === 'es'
          ? '¿Estás seguro que deseas finalizar la sesión?'
          : 'Are you sure want to end the session?',
      text:
        state.language === 'es'
          ? 'Tu sesión se guardará automáticamente cuando termines.'
          : 'Your session will be saved automatically if you finish.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    })

    if (result.isConfirmed) {
      dispatch({
        type: 'SET_IS_PAUSED',
        isPaused: true,
      })

      if (state.discardedList.length > 0) {
        await postHttpRequest(`/brainstorm/update_discarded_list/${state.meetingInstanceId}`, {
          discardedList: state.discardedList,
        })
      }

      if (state.hasDesktopApp) {
        stopRecording()
        let saved = await saveBrainstormSession()
        if (saved) {
          //   const iframe = document.createElement('iframe')
          //   iframe.style.display = 'none'
          //   iframe.src = `${process.env.REACT_APP_ZISI_APP_NAME || 'zisiapp'}://stopRecording`
          //   document.body.appendChild(iframe)
          //   setTimeout(() => document.body.removeChild(iframe), 500)
          dispatch({ type: 'SET_READ_ONLY', payload: true })
        }
      } else {
        stopRecording()
        let tempAvailableTags = []
        let tempAvailableMeetings = []
        try {
          if (!shouldHideTag) {
            let response = await getHttpRequest('/get_company_tags')
            // setAvailableTags(response.tags)
            tempAvailableTags = response.tags.map((tag) => ({
              value: tag.tagId,
              label: tag.tagName,
            }))
            setAvailableTags(tempAvailableTags)
            // to handle case when user creates a new tag, saves, closes the modal then open modal again.
            // replace the -1 tag with the actual tag
            setSelectedTagsCurrentSession((prev) => {
              // let previous_new_tags = prev.filter(tag => tag.value == -1)
              return prev
                .map((tag) => {
                  if (tag.value == -1) {
                    let existingTag = tempAvailableTags.find(
                      (t) => t.label.trim() == tag?.label?.trim(),
                    )
                    // users save it then open again
                    if (existingTag) {
                      return existingTag
                    }
                    // users don't save it, then open again
                    return tag
                  }
                  return tag
                })
                .filter((tag) => tag)
            })
          }
          let response = await getHttpRequest('/get_meetings_by_user')
          setAvailableMeetings(response.meetings)
          tempAvailableMeetings = response.meetings
          dispatch({ type: 'SET_READ_ONLY', payload: true }) //Cecilia: Check if thsi works and if it is necesary in reducer
        } catch (error) {
          console.log('error saveBrainstormSessionCheck: ', error)
        }
        // first check - if users can't see any tags or meetings, save the session directly
        if (tempAvailableTags.length === 0 && tempAvailableMeetings.length === 0) {
          await saveBrainstormSession()
        } else {
          setIsBrainstormMeetingTagModalOpen(true)
        }
      }
    }
  }

  const generateSummarizedBrainstormMap = async () => {
    // setIsBackgroundLoading(true)
    try {
      // find the parking lot topic and remove it from the action_plans
      const parking_lot_topic = state.brainstormMap.action_plans.find(
        (topic) => topic.is_parking_lot,
      )
      if (parking_lot_topic) {
        state.brainstormMap.action_plans = state.brainstormMap.action_plans.filter(
          (topic) => topic.topic !== parking_lot_topic.topic,
        )
      }
      const summarizedActionPlans = await getSummarizedActionPlans({
        action_plans: state.brainstormMap.action_plans,
        language_setting: state.language,
      })
      // console.log('summarizedActionPlans: ', summarizedActionPlans)
      let copyOfBrainstormMap = JSON.parse(JSON.stringify(state.brainstormMap))
      //filter is_parking_lot topics
      copyOfBrainstormMap.action_plans = summarizedActionPlans.summarized_action_plans.action_plans
      // remove the comments of every topic in action_plans and add one cmment in each topic as summary_comment in the summarized_action_plans
      //check if there is at least 1 summary_comment in the summarized_action_plans is not empty
      copyOfBrainstormMap.action_plans = copyOfBrainstormMap.action_plans.map((topic) => {
        if (topic.summary_comment?.trim() !== '') {
          return {
            ...topic,
            comments: [
              ...topic.comments,
              {
                id: 'summary_comment_' + topic.topic,
                isAccepted: true,
                text: topic?.summary_comment, // Each topic has its own summary comment
                isAddedInLastRound: true,
                is_converted: false,
                is_summary_comment: true,
              },
            ],
          }
        } else {
          return topic
        }
      })
      // add parking lot topic back to the mindmap
      if (parking_lot_topic) {
        copyOfBrainstormMap.action_plans.push(parking_lot_topic)
      }
      console.log('copyOfBrainstormMap: ', copyOfBrainstormMap)
      return copyOfBrainstormMap
    } catch (error) {
      console.log('getSummarizedBrainstormMap error: ', error)
      return state.brainstormMap
    } finally {
      setIsBackgroundLoading(false)
    }
  }

  const retrieveSummarizedBrainstormMap = async () => {
    try {
      let summarizedBrainstormMap = await getHttpRequest(
        `/brainstorm/retrieve_summarized_action_plans/${state.meetingInstanceId}`,
      )
      if (summarizedBrainstormMap?.summarized_action_plans) {
        return JSON.parse(summarizedBrainstormMap.summarized_action_plans)
      }
      return state.brainstormMap
    } catch (error) {
      console.log('retrieveSummarizedBrainstormMap error: ', error)
      return state.brainstormMap
    }
  }

  const validateBrainstormMap = (brainstormMap) => {
    let validatedMap = structuredClone(brainstormMap)

    // Filter and validate action plans
    validatedMap.action_plans = validatedMap.action_plans
      .filter((topic) => topic.topic) // Remove topics without topic field
      .map((topic) => {
        // Filter out invalid comments/decisions/actions
        let validatedTopic = {
          ...topic,
          comments: topic.comments?.filter((comment) => comment.text) || [],
          decisions: topic.decisions?.filter((decision) => decision.text) || [],
          actions: topic.actions?.filter((action) => action.text) || [],
        }

        // Add summary comment if it exists
        if (topic.summary?.trim()) {
          validatedTopic.comments.push({
            isAccepted: true,
            text: topic.summary,
            is_summary_comment: true,
          })
        }

        return validatedTopic
      })

    return validatedMap
  }

  const sendNote = async () => {
    console.log('state.brainstormMap: ', state.brainstormMap)
    //set Loading
    // let copyOfBrainstormMap = await retrieveSummarizedBrainstormMap()
    let copyOfBrainstormMap = validateBrainstormMap(state.brainstormMap)
    //filter out empty topics
    copyOfBrainstormMap.action_plans = copyOfBrainstormMap.action_plans.filter(
      (topic) =>
        topic.comments.length > 0 || topic.decisions.length > 0 || topic.actions.length > 0,
    )
    setIsBackgroundLoading(false)
    console.log('copyOfBrainstormMap: ', copyOfBrainstormMap)
    let res = null
    if (!state.overallSummary) {
      let topicSummaryMapping = []

      for (let i = 0; i < copyOfBrainstormMap.action_plans.length; i++) {
        //filter out parking lot topic
        if (copyOfBrainstormMap.action_plans[i].is_parking_lot) {
          continue
        }
        topicSummaryMapping.push({
          topic: copyOfBrainstormMap.action_plans[i].topic,
          summary:
            copyOfBrainstormMap.action_plans[i].comments.find(
              (comment) => comment.is_summary_comment,
            )?.text ?? '',
        })
      }

      console.log('topicSummaryMapping: ', topicSummaryMapping)
      res = await generateOverallSummary({
        topicSummaryMapping: topicSummaryMapping,
        language_setting: state.language,
        meetingInstanceId: state.meetingInstanceId,
      })
      console.log('res: ', res?.overall_summary)
    }
    navigate(`/${routes.meetingMinutes}`, {
      state: {
        mindmap: copyOfBrainstormMap,
        brainstormInstanceId: state.brainstormInstanceId,
        meetingInstanceId: state.meetingInstanceId,
        language: state.language,
        zisiLinkId: state.brainstormLinkId,
        overallSummary: state.overallSummary ? state.overallSummary : res?.overall_summary,
      },
    })
  }

  const updateSelectedTagsCurrentSession = (tags) => {
    setSelectedTagsCurrentSession(tags)
  }

  const updateSelectedMeetingsCurrentSession = (meetings) => {
    setSelectedMeetingsCurrentSession(meetings)
  }

  useEffect(() => {
    if (initialSetup && initialSetup?.language) {
      dispatch({
        type: 'SET_LANGUAGE',
        language: initialSetup.language.toLowerCase(),
      })
    }
  }, [initialSetup])

  const checkIfDesktopAppRegisteredBrainstormSession = async () => {
    try {
      const response = await postHttpRequest(
        '/brainstorm/check_is_brainstorm_registered_with_desktop_app',
        {
          meetingInstanceId: state.meetingInstanceId,
        },
      )
      if (response) {
        setIsDesktopAppRegistered(response.success)
        if (response.success) {
          setConnectionStatusAlreadyChecked(true)
          if (destkopAppRegisterationPollingIntervalRef.current) {
            clearInterval(destkopAppRegisterationPollingIntervalRef.current)
            destkopAppRegisterationPollingIntervalRef.current = null
          }
        }
      }
    } catch (error) {
      console.log('checkIfDesktopAppRegisteredBrainstormSession error', error)
    }
  }

  const handleTryAgain = () => {
    if (source == 'zisi' && state.hasDesktopApp) {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = `${
        process.env.REACT_APP_ZISI_APP_NAME || 'zisiapp'
      }://startRecording?meetingInstanceId=${state.meetingInstanceId}`
      document.body.appendChild(iframe)
      setTimeout(() => document.body.removeChild(iframe), 500)
    }
  }

  useEffect(() => {
    if (showTimeAlert) {
      const timer = setTimeout(() => {
        setShowTimeAlert(false)
      }, TIME_ALERT_DURATION)

      return () => clearTimeout(timer)
    }
  }, [showTimeAlert])

  const handleTextInputSave = async (text) => {
    console.log('Saving text:', text)
    await initAddUserTopic(text)
    setIsTextInputModalOpen(false)
  }

  return (
    <>
      {isBrainstormMeetingTagModalOpen &&
        (availableMeetings.length > 0 || availableTags.length > 0) && (
          <BrainstormMeetingTagModal
            availableMeetings={availableMeetings}
            availableTags={availableTags}
            closeModal={() => setIsBrainstormMeetingTagModalOpen(false)}
            saveBrainstormSession={saveBrainstormSession}
            shouldHideTag={shouldHideTag}
            setSelectedTagsCurrentSession={updateSelectedTagsCurrentSession}
            setSelectedMeetingsCurrentSession={updateSelectedMeetingsCurrentSession}
            selectedMeetingsCurrentSession={selectedMeetingsCurrentSession}
            selectedTagsCurrentSession={selectedTagsCurrentSession}
            meetingInstanceId={state.meetingInstanceId}
            discardedList={state.discardedList}
            highlightedSubProblems={state.highlightedQuestions}
            allowBulkConvertToMH={allowBulkConvertToMH}
            setHasSaved={setHasSaved}
          />
        )}
      {isTextInputModalOpen && (
        <TextInputModal
          isModalOpen={isTextInputModalOpen}
          closeModal={() => setIsTextInputModalOpen(false)}
          onSave={handleTextInputSave}
          language={state.language}
        />
      )}
      {/* To push down the contents of this page to avoid overlapping with the header, 
      we add a div with a margin-top of 3rem. */}
      <div style={{ marginTop: isFullVersion ? '3rem' : '60px' }}></div>

      {isBackgroundLoading && (
        <InPageLoader inComponent={true} /> //Cheat to make loader work, we need to fix this if changing talk to Cecilia
      )}
      {!isBackgroundLoading && (
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '0.5rem' }}>
          {/* {state.stream && (
            <MemoBrainstormVadCompomnent
              stream={state.stream}
              onSpeechStartcallBack={onSpeechStartcallBack}
              onSpeechEndcallBack={onSpeechEndcallBack}
              // isGeneratingProblems={state.isGeneratingProblems}
              isPaused={state.isPaused}
              setHasStarted={setHasStarted}
            />
          )} */}

          {/* <audio
            ref={audioRef}
            style={{ display: 'block' }}
            onEnded={handleAudioEnded}
            muted={state.isMuted}
          ></audio> */}
          {state.hasDesktopApp && !isDesktopAppRegistered && (
            <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50">
              <div className="inline-flex items-center justify-center space-x-2 bg-red-50 border border-red-200 text-red-800 px-6 py-2 rounded-lg shadow-lg">
                <span className="material-symbols-outlined text-red-600">warning</span>
                <span className="text-sm font-medium">
                  Failed to connect to desktop application.
                </span>
                <button
                  onClick={() => handleTryAgain()}
                  className="text-red-600 hover:text-red-800 underline text-sm"
                >
                  Try Again
                </button>
              </div>
            </div>
          )}
          {/* //TO-DO: Add right conditions for the time remaining alert */}
          {/* //state.hasDesktopApp && !showConnectionError && ??? */}
          {source == 'zisi' && showTimeAlert && remainingTime != 100 && (
            <div
              className="fixed top-0 left-1/2 transform -translate-x-1/2"
              style={{
                zIndex: 2000,
              }}
            >
              <TimeRemainingAlert
                remainingTime={remainingTime}
                subscriptionStatus={subscriptionStatus}
              />
            </div>
            //TO-DO: add getter that returns values for the remaining time and subscription status
          )}

          <div
            className="brainstorm-background-container"
            style={{
              overflowY: 'auto', // Add scrollbar
              maxHeight: 'calc(100vh - 3rem)', // Account for top margin
            }}
          >
            <div
              className="brainstorm-left-container flex"
              style={{
                width: isMenuCollapsed ? '100%' : '80%',
                marginRight: '0.5rem',
                overflow: 'auto',
              }}
            >
              {state.brainstormMap && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div
                    className="brainstorm-problem-top-container"
                    style={{
                      height: 'fit-content',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      position: 'relative',
                    }}
                  >
                    <h2
                      className="text-2xl font-bold mb-4 w-full text-left"
                      style={{ maxWidth: 'unset', margin: '0 0 1rem 0', padding: '0.5rem' }}
                    >
                      {state.primaryProblem}
                    </h2>
                    {/* {!state.readOnly && <div
                      class="fitted-button"
                      style={{
                        width: '40px',
                        color: 'black',
                        padding: '2px',
                        fontSize: '30px',
                        height: '40px',
                        border: '3px solid #aaaaaa',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsTextInputModalOpen(true)}
                    >
                      <span class="material-symbols-outlined" style={{ fontSize: '32px' }}>
                        add
                      </span>
                    </div>} */}

                    {state.progressState === 'exploration' && state.brainstormMap && hasSaved && (
                      <button
                        onClick={() => {
                          const link =
                            window.location.origin + '/shared_session/' + state.brainstormLinkId
                          navigator.clipboard.writeText(link)
                          Toast.fire({
                            icon: 'success',
                            title: 'Link copied to clipboard!',
                          })
                        }}
                        className="flex items-center gap-2 px-3 py-1 text-gray-600 hover:text-gray-800 rounded"
                      >
                        <span className="material-symbols-outlined">share</span>
                        <span>Share</span>
                      </button>
                    )}
                  </div>

                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    {insightModal && (
                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-4 rounded-lg w-1/2">
                          <div className="flex flex-row justify-between">
                            <h2 className="text-lg font-bold mb-2">
                              {state.language === 'en' ? 'Zisi Says...' : 'Zisi dice...'}
                            </h2>

                            <span
                              className="material-symbols-outlined cursor-pointer"
                              onClick={() => {
                                setMindMapBlur(false)
                                setIsInsightsCollapsed(false)
                                setIsQuestionsCollapsed(true)
                                setIsMenuCollapsed(false)
                                setInsightModal(false)
                              }}
                            >
                              close
                            </span>
                          </div>
                          <div
                            className={`w-full self-start bg-blue-100 rounded-tr-xl rounded-tl-xl rounded-br-xl rounded-bl-none p-3 shadow-sm`}
                          >
                            {insightsList[insightsList.length - 1]?.title && (
                              <h3 className="text-sm font-semibold text-gray-900 mb-2">
                                {insightsList[insightsList.length - 1].title}
                              </h3>
                            )}
                            <p className="text-sm text-gray-800">
                              {insightsList[insightsList.length - 1]?.text}
                            </p>
                            <span className="text-xs text-gray-500 mt-1 block text-right">
                              {insightsList[insightsList.length - 1]?.date}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Commeted out until something about the prompts gets fixed */}
                    {/* {!state.readOnly && state.brainstormMap && (
                      <div
                        className="brainstorm-add-topic-container"
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          zIndex: 10,
                        }}
                        onClick={() => {
                          const newTopic = {
                            topic: 'New: click to edit',
                            user_added: true,
                            decisions: [],
                            comments: [],
                            actions: [],
                            newTopic: true,
                            type: 'Guidance',
                            what_it_is_about: '',
                          }

                          dispatch({
                            type: 'SET_BRAINSTORM_MAP',
                            brainstormMap: {
                              ...state.brainstormMap,
                              action_plans: [...state.brainstormMap.action_plans, newTopic],
                            },
                          })
                        }}
                      >
                        <span className="brainstorm-add-topic-text">
                          {state.language === 'en' ? 'Add Topic' : 'Agregar Tema'}
                        </span>
                        <span className="material-symbols-outlined">add</span>
                      </div>
                    )} */}
                    <div
                      style={{
                        ...(mindMapBlur ? { filter: 'blur(10px)' } : {}),
                      }}
                    >
                      <MindMap
                        jsonData={state.brainstormMap}
                        key={JSON.stringify(state.brainstormMap.problem)}
                        readOnly={state.readOnly}
                        brainstormId={selectedBrainstormSession}
                        allowBulkConvertToMH={allowBulkConvertToMH}
                        source={'zisi'}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isMenuCollapsed && (
              <div className="menu-min-container menu-arrow" style={{ right: '5px', top: '5px' }}>
                <span
                  className="material-symbols-outlined cursor-pointer"
                  onClick={() => {
                    setIsMenuCollapsed(false)
                  }}
                >
                  {'menu_open'}
                </span>
              </div>
            )}
            <div
              className="brainstorm-right-container flex flex-row"
              style={{
                width: isMenuCollapsed ? '0%' : '20%',
                visibility: isMenuCollapsed ? 'hidden' : 'visible',
              }}
            >
              <div className="flex flex-column gap-2" style={{ width: '100%' }}>
                <div
                  className="brainstorm-open-close-container "
                  style={{ width: 'fit-content', alignSelf: 'flex-end', marginRight: '0' }}
                >
                  <span
                    className="material-symbols-outlined cursor-pointer"
                    onClick={() => {
                      setIsMenuCollapsed(true)
                    }}
                  >
                    {'arrow_menu_open'}
                  </span>
                </div>
                <div className="brainstorm-interaction-container border border-gray-200 rounded-lg p-6 shadow-sm bg-white">
                  {state.isBrainstormingStarted === true && !state.readOnly && !isThinking && (
                    <PauseButton source={source} />
                  )}

                  {state.progressState === 'exploration' &&
                    (!state.readOnly || (state.readOnly && !state.isBsCompleted)) &&
                    state.brainstormMap &&
                    !state?.autoTermination && (
                      <div
                        className={`flex flex-row justify-center items-center bg-blue-500 hover:bg-blue-600
              bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-lg shadow-md transform transition-transform duration-200 
              h-9 w-16 cursor-pointer tooltip`}
                        onClick={saveBrainstormSessionCheck}
                      >
                        {/* <span className="material-symbols-outlined">{'save'}</span> */}
                        <span>{state.language === 'es' ? 'Finalizar' : 'Finish'}</span>
                        <span
                          className="tooltiptext"
                          style={{
                            fontSize: '16px',
                            width: '8rem',
                            alignSelf: 'center',
                            textAlign: 'center',
                            left: '50%',
                            marginLeft: '-4rem', // Half of width to center
                            position: 'absolute',
                            bottom: '125%',
                            // Add peak centering styles
                            '::after': {
                              content: '""',
                              position: 'absolute',
                              top: '100%',
                              left: '50%',
                              marginLeft: '-5px',
                              borderWidth: '5px',
                              borderStyle: 'solid',
                              borderColor: '#555 transparent transparent transparent',
                            },
                          }}
                        >
                          {state.language === 'en' ? 'Save' : 'Guardar'}
                        </span>
                      </div>
                    )}

                  {((state.progressState === 'exploration' && state.brainstormMap && hasSaved) ||
                    state?.autoTermination) && (
                    <div
                      className={`flex flex-row justify-center items-center 
                  bg-[#4472C4] hover:bg-[#3a62aa] text-white px-4 py-2 rounded-lg shadow-md 
                  transform transition-transform duration-200 h-9 w-16 cursor-pointer tooltip`}
                      onClick={sendNote}
                    >
                      <span className="material-symbols-outlined">{'email'}</span>

                      <span
                        className="tooltiptext"
                        style={{
                          fontSize: '16px',
                          width: '8rem',
                          alignSelf: 'center',
                          textAlign: 'center',
                          left: '50%',
                          marginLeft: '-4rem', // Half of width to center
                          position: 'absolute',
                          bottom: '125%',
                          // Add peak centering styles
                          '::after': {
                            content: '""',
                            position: 'absolute',
                            top: '100%',
                            left: '50%',
                            marginLeft: '-5px',
                            borderWidth: '5px',
                            borderStyle: 'solid',
                            borderColor: '#555 transparent transparent transparent',
                          },
                        }}
                      >
                        {state.language === 'en' ? 'Email' : 'Email'}
                      </span>
                    </div>
                  )}
                </div>

                {(subQuestionState?.length > 0 || isExploringProblems) && (
                  <div className="brainstorm-questions-container border border-gray-200 rounded-lg p-6 shadow-sm bg-white">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span className="questions-title">
                        {state.language === 'es'
                          ? 'Preguntas de Discusión'
                          : 'Discussion Questions'}
                      </span>
                      {isQuestionsCollapsed === true && (
                        <span
                          className="material-symbols-outlined ml-1 cursor-pointer"
                          onClick={() => {
                            setIsQuestionsCollapsed(false)
                            setIsInsightsCollapsed(true)
                          }}
                        >
                          {'arrow_drop_down'}
                        </span>
                      )}
                      {isQuestionsCollapsed === false && (
                        <span
                          className="material-symbols-outlined ml-1 cursor-pointer"
                          onClick={() => {
                            setIsQuestionsCollapsed(true)
                          }}
                        >
                          {'arrow_drop_up'}
                        </span>
                      )}
                    </div>

                    {isQuestionsCollapsed === false && (
                      <SubQuestions
                        subQuestionState={subQuestionState}
                        refreshQuestion={refreshQuestion}
                        refreshButtonLoading={refreshButtonLoading}
                        collapseStates={collapseStates}
                        isExploringProblems={isExploringProblems}
                      />
                    )}
                  </div>
                )}

                {!state.readOnly && (
                  <div className="brainstorm-research-container border border-gray-200 rounded-lg  shadow-sm bg-white">
                    <div
                      className="flex flex-row justify-between"
                      style={{
                        padding: isInsightsCollapsed
                          ? '1.5rem 1.5rem 1.5rem 1.5rem'
                          : '0.5rem 1.5rem 0 1.5rem',
                      }}
                    >
                      <div className="research-title">
                        {state.language === 'es' ? 'Insights' : 'Insights'}
                      </div>
                      {isInsightsCollapsed === true && (
                        <span
                          className="material-symbols-outlined ml-1 cursor-pointer"
                          onClick={() => {
                            setIsInsightsCollapsed(false)
                            setIsQuestionsCollapsed(true)
                          }}
                        >
                          {'arrow_drop_down'}
                        </span>
                      )}
                      {isInsightsCollapsed === false && (
                        <span
                          className="material-symbols-outlined ml-1 cursor-pointer"
                          onClick={() => {
                            setIsInsightsCollapsed(true)
                          }}
                        >
                          {'arrow_drop_up'}
                        </span>
                      )}
                    </div>
                    {isInsightsCollapsed === false && (
                      <InsightsViewer insightsList={insightsList} language={state.language} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isSaving && (
        <LoadingModal
          message={
            state.language === 'es'
              ? 'Guardando sesión de lluvia de ideas'
              : 'Saving brainstorming session'
          }
          cleanupMsg={state.language === 'es' ? 'Limpiándolo...' : 'Cleaning it up...'}
        />
      )}
      {console.log('isOwner', isOwner)}
      {console.log('authStatus', authStatus)}
      {authStatus?.userData?.show_chatbot && state.meetingInstanceId && isOwner && (
        <BrainstormingChatBot />
      )}
    </>
  )
}

export default BrainstormComponent
