import React from 'react'
import { getHttpRequest } from '../../../../api/query/dynamicAPI'
import SelectField from '../../../../components/CustomMultipleSelect/singleOption'
import { useTranslation } from 'react-i18next'
import DecisionLog from './index'
import TranscriptSummary from '../MeetingSummary/sub-components/TranscriptSummary'
import InPageLoader from '../../../../components/InPageLoader'
import { useOutletContext } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import Toast from '../../../../common/toast'
import { getCompanyConfigSettings, hasRole } from '../../../../utils/auth'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption'
import { getAllEmployeeList, getObjectivUserList } from '../../../../api/services/employee.services'
import { getMeetingTagsListForUser } from '../../../../api/services/meetingTopic.services'
import EditDecisionLogModal from './EditDecisionLogModal'

const DecisionLogTabs = () => {
  const [meetingTranscripts, setMeetingTranscripts] = React.useState([])
  const [selectedTranscript, setSelectedTranscript] = React.useState(null)
  const [tabOption, setTabOption] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedMeetingSummary, setSelectedMeetingSummary] = React.useState('')
  const [selectedMeetingGeneratedItems, setSelectedMeetingGeneratedItems] = React.useState([])
  const [availableTopics, setAvailableTopics] = React.useState([])
  const [topicsOwnedByUser, setTopicsOwnedByUser] = React.useState([])
  const [isSelectedTranscriptFinalized, setIsSelectedTranscriptFinalized] = React.useState(false)
  const [meetingNoteName, setMeetingNoteName] = React.useState('')
  const location = useLocation()
  const meetingInstanceId = location.state?.meetingInstanceId
  const [fromCadenceReport, setFromCadenceReport] = React.useState(true)
  const [selectedLog, setSelectedLog] = React.useState(null)
  const [teamLeadersData, setTeamLeadersData] = React.useState(null)
  const [tlId, setTlId] = React.useState(null)
  const [decisionLogList, setDecisionLogList] = React.useState(null)
  const [isLogEditable, setIsLogEditable] = React.useState(false)
  const [showLoader, setShowLoader] = React.useState(false)
  const [decisionLogData, setDecisionLogData] = React.useState(null)
  const [logTopicList, setLogTopicList] = React.useState([])
  const [checkboxComments, setCheckboxComments] = React.useState({})
  const [checkboxMiles, setCheckboxMiles] = React.useState({})
  const [hideNotesTab, setHideNotesTab] = React.useState(false)
  const [userList, setUserList] = React.useState([])

  const { t } = useTranslation(['Common', 'MeetingHub'])
  // need to to reset MH view to default
  const { setResetViewMode } = useOutletContext()

  const [meetingTagsList, setMeetingTagsList] = React.useState([])
  const [selectedMeeting, setSelectedMeeting] = React.useState(null)
  const [editDecisionLogModalOpen, setEditDecisionLogModalOpen] = React.useState(false)
  const [existingMeetingId, setExistingMeetingId] = React.useState(null)
  React.useEffect(() => {
    getMeetingTranscripts()
    if (hasRole('cxo')) {
      getHttpRequest(`/get_decision_log_emp_list`, {}).then((response) => {
        setTeamLeadersData(response)
        let leader = response.employees.find((emp) => emp.name === localStorage.getItem('tfmnm'))
        if (!leader && response.employees.length > 0) {
          leader = response.employees[0]
        }
        setTlId(parseInt(leader.eId))
        getDecisionLogListData(leader.eId)
      })
    }
    getAllEmployees()
    setResetViewMode((p) => p + 1)
    getMeetingTagsList()
  }, [])

  React.useEffect(() => {
    if (meetingInstanceId !== (null || undefined) && fromCadenceReport !== (null || undefined)) {
      setSelectedMeetingGeneratedItems([])
      setSelectedMeetingSummary(null)
      setIsLoading(true)
      getSingleMeetingTranscript(meetingInstanceId, true)
      setFromCadenceReport(false)
    } else {
      if (selectedLog?.meetingInstanceId) {
        setSelectedMeetingGeneratedItems([])
        setSelectedMeetingSummary(null)
        setIsLoading(true)
        getSingleMeetingTranscript(selectedLog?.meetingInstanceId)
      }
    }
  }, [selectedLog, meetingInstanceId])

  React.useEffect(() => {
    if (decisionLogList?.decisionLogs && decisionLogList?.decisionLogs.length > 0) {
      //get log id from url
      let logId = parseInt(new URLSearchParams(window.location.search).get('decisionLogId'))
      let isFinalized = new URLSearchParams(window.location.search).get('isFinalized') == 'true'
      if (logId) {
        setSelectedLog(decisionLogList?.decisionLogs.find((log) => log.logId === logId))
        getDecisionLogData(logId, isFinalized)
        setIsLogEditable(!isFinalized)
      } else {
        setSelectedLog(decisionLogList?.decisionLogs[0])
        getDecisionLogData(
          decisionLogList.decisionLogs[0].logId,
          decisionLogList.decisionLogs[0].isFinalized,
        )
        setIsLogEditable(!decisionLogList.decisionLogs[0].isFinalized)
      }
    } else {
      setSelectedLog(null)
      setDecisionLogData(null)
    }
  }, [decisionLogList, location.search])

  React.useEffect(() => {
    let objs = decisionLogData?.objectives
    if (objs !== undefined && selectedLog) {
      const newCheckboxComments = {}
      const newCheckboxMiles = {}

      for (let obj of objs) {
        const { topics } = obj
        topics?.forEach((topic) => {
          // Initialize comments
          topic.comments?.forEach((comment) => {
            if (!comment.isAddedByAi) {
              newCheckboxComments[comment.commentId] = true
            }
          })

          // Initialize milestones
          topic.milestones?.forEach((milestone) => {
            if (!milestone.isAddedByAi) {
              newCheckboxMiles[milestone.milestoneId] = true
            }
          })
        })
      }

      setCheckboxComments(newCheckboxComments)
      setCheckboxMiles(newCheckboxMiles)
    }
  }, [selectedLog, decisionLogData])

  const getMeetingTranscripts = async () => {
    setMeetingNoteName('')
    let response = await getHttpRequest('/meeting/get_all_meeting_transcripts')
    setMeetingTranscripts(response.meetingTranscripts)
    setTopicsOwnedByUser(response.topicsOwned)

    if (response.meetingTranscripts.length > 0 && !fromCadenceReport) {
      setSelectedTranscript(response.meetingTranscripts[0])
    }
  }

  const getMeetingTagsList = async () => {
    let response = await getMeetingTagsListForUser()
    setMeetingTagsList(response?.meetings ?? [])
  }

  const getSingleMeetingTranscript = async (meetingInstanceId, fromCadenceReport = false) => {
    if (!meetingInstanceId) return
    console.log('meetingInstanceId in single meeting transcript: ', meetingInstanceId)
    let response = await getHttpRequest(
      `/meeting/get_items_generated_in_meeting?meetingInstanceId=${meetingInstanceId}&fromCadenceReport=${fromCadenceReport}`,
    )
    //console.log("response: ", response)
    setSelectedMeetingGeneratedItems(response?.meetingTranscriptObjLevel)
    // setSelectedMeetingGeneratedItems(
    //   response?.meetingTranscriptGeneratedItemWrappers?.filter(
    //     (topic) => topic.meetingTranscriptGeneratedItems.length > 0 || topic.isAddedCurrentMeeting,
    //   ) ?? [],
    // )

    setSelectedMeetingSummary(response?.currentSummary ?? '')
    setIsSelectedTranscriptFinalized(response?.isFinalized ?? false)

    let trackedTopics =
      response?.meetingTranscriptGeneratedItemWrappers
        ?.filter((topic) => topic.umtId !== -1)
        ?.map((topic) => ({
          umtDescription: topic.umtDescription,
          umtId: topic.umtId,
          goalId: topic.goalId,
        })) ?? []

    let newAvailableTopics = [...trackedTopics, ...topicsOwnedByUser]

    // remove duplidate topics
    setAvailableTopics(
      newAvailableTopics?.filter(
        (topic, index, arr) => arr.findIndex((val) => val.umtId === topic.umtId) === index,
      ),
    )
    setIsLoading(false)
  }

  const getAllEmployees = async () => {
    const result = await getObjectivUserList(0)
    if (result?.employees)
      setUserList(result.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
  }

  const handleMeetingTranscriptChange = (e) => {
    setSelectedTranscript(e)
  }

  const reload = async () => {
    setIsLoading(true)
    getDecisionLogData(selectedLog.logId, selectedLog.isFinalized)
    getSingleMeetingTranscript(selectedLog?.meetingInstanceId)
    setIsLoading(false)
  }

  const handleChangeMeetingNoteName = (e) => {
    setMeetingNoteName(e.target.value)
  }

  const getDecisionLogData = async (logId, isFinalized) => {
    setShowLoader(true)
    if (logId != undefined) {
      try {
        const response = await getHttpRequest(`/get_decision_log/${logId}?finalized=${isFinalized}`)
        if (response) {
          setDecisionLogData(response)
          setLogTopicList(response?.objectives?.map((objective) => objective?.topics).flat())
          setIsLogEditable(!isFinalized)
          if (response?.meetingId) {
            setExistingMeetingId(response?.meetingId)
            let meeting = meetingTagsList.find(
              (meeting) => meeting.meetingId === response?.meetingId,
            )
            setSelectedMeeting(meeting)
          } else {
            setExistingMeetingId(null)
            setSelectedMeeting(null)
          }
        }
        setShowLoader(false)
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
        setShowLoader(false)
      }
    }
  }

  const handleChangeSelectedLog = async (value) => {
    setSelectedLog(value)
    if (value) {
      setCheckboxComments({})
      setCheckboxMiles({})
      await getDecisionLogData(value.logId, value.isFinalized)
    }
  }

  const handleChangeSelectedMeeting = async (value) => {
    debugger
    console.log('value: ', value)
    setSelectedMeeting(value)
    console.log('selectedMeeting: ', selectedMeeting)
    console.log('decisionLogData: ', decisionLogData)
    let newMeeting = value.meetingId
    if (newMeeting !== existingMeetingId) {
      let response = await getHttpRequest(
        `/update_meetingtag_for_meeting_instance?newMeetingId=${newMeeting}&logId=${selectedLog?.logId}`,
      )
      console.log('response: ', response)
      if (response.success) {
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.meetingTagUpdatedSuccessfully'),
        })
      }
    }
  }

  const getDecisionLogListData = async (teamLeaderId = null) => {
    setShowLoader(true)
    let url =
      teamLeaderId != null ? `/get_decision_logs_list/${teamLeaderId}` : `/get_decision_logs_list`
    try {
      const response = await getHttpRequest(url)
      setDecisionLogList(response)

      // If there are logs, select the first one by default
      if (response?.decisionLogs && response.decisionLogs.length > 0) {
        const firstLog = response.decisionLogs[0]
        setSelectedLog(firstLog)
        await getDecisionLogData(firstLog.logId, firstLog.isFinalized)
      }
      setShowLoader(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
      setShowLoader(false)
    }
  }

  const inputChangedHandler = (e) => {
    let tlId = parseInt(e.eId)
    setTlId(tlId)
    getDecisionLogListData(tlId)
  }

  React.useEffect(() => {
    if (!decisionLogList) {
      getDecisionLogListData()
    }
  }, [])

  // Hide notes tab if the log is not main/ *temporary measure until we send logs in email*
  React.useEffect(() => {
    if (selectedLog && !selectedLog?.isMain) {
      setHideNotesTab(true)
      setTabOption(0)
    } else {
      setHideNotesTab(false)
    }
  }, [selectedLog])

  React.useEffect(() => {
    console.log('meetingTagsList: ', meetingTagsList)
    console.log('decisionLogData: ', decisionLogData)
  }, [meetingTagsList, decisionLogData])

  return (
    <>
      <div style={{ width: '90%', paddingTop: '1rem', paddingLeft: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '2rem',
              width: '90%',
            }}
          >
            {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '40%' }}>
              <div style={{ fontWeight: 'bold' }}>{t('MeetingHub:transcript.selectNote')}</div>
              <Select
                menuPortalTarget={document.body}
                placeholder={t('formPlaceHolder.select') + '...'}
                value={meetingTranscripts?.filter(
                  (mt) => mt.meetingInstanceId === selectedTranscript?.meetingInstanceId,
                )}
                options={meetingTranscripts}
                onChange={handleMeetingTranscriptChange}
                getOptionLabel={(option) => option.meetingInstanceName}
                getOptionValue={(option) => option.meetingInstanceId}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <div style={{ fontWeight: 'bold' }}>{t('MeetingHub:decisionLog.selectLog')}</div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                <Select
                  menuPortalTarget={document.body}
                  placeholder={t('Common:filter.select') + '...'}
                  value={selectedLog}
                  onChange={handleChangeSelectedLog}
                  getOptionLabel={(option) =>
                    `${option?.logName} ${
                      option?.isMain &&
                      (option?.logStatus === 'NOT_STARTED_DECISION_LOG_STATUS' ||
                        option?.logStatus === 'IN_PROGRESS_DECISION_LOG_STATUS')
                        ? '(' + t('MeetingHub:decisionLog.original') + ')'
                        : ''
                    }`
                  }
                  getOptionValue={(option) => option?.logId}
                  options={decisionLogList?.decisionLogs}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '25rem',
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }),
                  }}
                />
                {isLogEditable && (
                  <div
                    className="fitted-button blue tooltip"
                    style={{
                      backgroundColor: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      alignSelf: 'center',
                    }}
                    onClick={() => {
                      setEditDecisionLogModalOpen(true)
                    }}
                  >
                    <span className="material-symbols-outlined">edit</span>
                  </div>
                )}
                {/* {isLogEditable && <Select
                  menuPortalTarget={document.body}
                  placeholder={t('Common:filter.select') + '...'}
                  value={selectedMeeting}
                  onChange={handleChangeSelectedMeeting}
                  getOptionLabel={(option) => option?.meetingName}
                  getOptionValue={(option) => option?.meetingId}
                  options={meetingTagsList}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '25rem',
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }),
                  }}
                />} */}
              </div>
            </div>

            {/* {hasRole('cxo') && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div style={{ fontWeight: 'bold' }}>{t('MeetingHub:decisionLog.selectLeader')}</div>
                <SelectSingleField
                  value={
                    teamLeadersData?.employees?.filter(
                      (emp) => parseInt(emp.eId) === parseInt(tlId),
                    )[0]
                  }
                  options={teamLeadersData?.employees}
                  onChange={inputChangedHandler}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.eId}
                  width={'20rem'}
                />
              </div>
            )} */}
          </div>

          <div className="kpi-report-nav-container" style={{ marginTop: '1rem' }}>
            <div
              className={`nav-option-kpi-report ${tabOption === 0 ? 'active' : ''}`}
              onClick={() => setTabOption(0)}
              style={{ marginLeft: '0' }}
            >
              <span className="nav-option-text-kpi-report">{t('MeetingHub:decisionLog.log')}</span>
            </div>
            {!hideNotesTab && (
              <div
                className={`nav-option-kpi-report ${tabOption === 1 ? 'active' : ''}`}
                onClick={() => setTabOption(1)}
              >
                <span className="nav-option-text-kpi-report">
                  {t('MeetingHub:decisionLog.notes')}
                </span>
              </div>
            )}
          </div>

          {isLoading || showLoader ? (
            <InPageLoader />
          ) : (
            <>
              {selectedMeetingGeneratedItems && tabOption === 0 && (
                <DecisionLog
                  reload={reload}
                  selectedLog={selectedLog}
                  setSelectedLog={setSelectedLog}
                  decisionLogList={decisionLogList}
                  setDecisionLogList={setDecisionLogList}
                  isLogEditable={isLogEditable}
                  setIsLogEditable={setIsLogEditable}
                  decisionLogData={decisionLogData}
                  setDecisionLogData={setDecisionLogData}
                  logTopicList={logTopicList}
                  getDecisionLogData={getDecisionLogData}
                  checkboxComments={checkboxComments}
                  checkboxMiles={checkboxMiles}
                  setCheckboxComments={setCheckboxComments}
                  setCheckboxMiles={setCheckboxMiles}
                />
              )}
              {selectedMeetingSummary && tabOption === 1 && (
                <TranscriptSummary
                  meetingSummary={selectedMeetingSummary}
                  meetingId={selectedLog?.meetingInstanceId}
                  reload={reload}
                  //selectedLog={selectedLog}
                />
              )}
            </>
          )}
        </div>
      </div>

      {editDecisionLogModalOpen && (
        <EditDecisionLogModal
          isModalOpen={editDecisionLogModalOpen}
          closeModal={() => setEditDecisionLogModalOpen(false)}
          selectedLog={selectedLog}
          selectedMeeting={selectedMeeting}
          meetingTagsList={meetingTagsList}
          onMeetingChange={(value) => {
            handleChangeSelectedMeeting(value)
          }}
          onSuccess={() => {
            getDecisionLogListData()
          }}
        />
      )}
    </>
  )
}

export default DecisionLogTabs
