import styled from 'styled-components'

const StepContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  margin: 1rem 5rem;
  padding-bottom: 1rem;
`

const CardContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: ${(props) => props.basis || '100%'};
  padding: 1rem 2rem;
  // box-shadow: 0 5px 15px #ccc; commenting out for now until we see we can remove it
  word-break: break-word;
`

const ErrorMessage = styled.div`
  padding-top: 1rem;
  text-align: center;
  font-weight: bold;
  color: rgb(255, 0, 0);
`

const CardHeader = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  font-size: 1.25rem;
  font-weight: bold;
  gap: 1rem;
  padding-bottom: 0.5rem;
`

const CardContent = styled.div`
  display: flex;

  justify-content: center;
  flex-direction: ${(props) => props.direction || 'column'};
  gap: 1rem;
  margin: 1rem auto;
`

const StepHeader = styled.div`
  font-size: 1.7rem;
  font-weight: bold;
  margin: 2rem 0 1.5rem 0;
  text-align: center;
`

const StepCaption = styled.div`
  text-align: center;
  margin: 0 0 2rem 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`

const ObjectiveGrid = styled.div`
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 0.7fr 2.5fr;
  margin-top: 1rem;
`

const GridItem = styled.div`
  border-bottom: 1px solid #eee;
  padding-right: 1rem;
  padding-bottom: 1rem;
`

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  height: auto;
  border-bottom: 1px solid #eee;
`

export {
  StepContainer,
  CardContainer,
  CardHeader,
  CardContent,
  StepHeader,
  StepCaption,
  ButtonContainer,
  ObjectiveGrid,
  GridItem,
  ItemRow,
  ErrorMessage,
}
