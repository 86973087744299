import React from 'react'

const MindMapLoader = () => {
  const styles = {
    svg: {
      width: '100%',
      maxWidth: '400px',
    },
  }

  return (
    <div className="flex justify-center items-center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 340" style={styles.svg}>
        <style>
          {`
            @keyframes fadeIn {
              0% { opacity: 0; transform: translateY(-10px); }
              15% { opacity: 1; transform: translateY(0); }
              85% { opacity: 1; transform: translateY(0); }
              100% { opacity: 0; transform: translateY(-10px); }
            }
            .topic, .subtopic {
              opacity: 0;
              rx: 6;
              ry: 6;
              animation: fadeIn 2.5s ease-out infinite;
            }
          `}
        </style>

        <rect
          className="topic"
          x="40"
          y="50"
          width="90"
          height="45"
          fill="rgb(180,190,200)"
          style={{ animationDelay: '0s' }}
        />
        <rect
          className="subtopic"
          x="40"
          y="120"
          width="90"
          height="35"
          fill="rgb(210,220,230)"
          style={{ animationDelay: '0.15s' }}
        />
        <rect
          className="subtopic"
          x="40"
          y="160"
          width="90"
          height="35"
          fill="rgb(210,220,230)"
          style={{ animationDelay: '0.3s' }}
        />

        <rect
          className="topic"
          x="155"
          y="50"
          width="90"
          height="45"
          fill="rgb(160,170,180)"
          style={{ animationDelay: '0.2s' }}
        />
        <rect
          className="subtopic"
          x="155"
          y="120"
          width="90"
          height="35"
          fill="rgb(190,200,210)"
          style={{ animationDelay: '0.35s' }}
        />
        <rect
          className="subtopic"
          x="155"
          y="160"
          width="90"
          height="35"
          fill="rgb(190,200,210)"
          style={{ animationDelay: '0.5s' }}
        />
        <rect
          className="subtopic"
          x="155"
          y="200"
          width="90"
          height="35"
          fill="rgb(190,200,210)"
          style={{ animationDelay: '0.65s' }}
        />

        <rect
          className="topic"
          x="270"
          y="50"
          width="90"
          height="45"
          fill="rgb(140,150,160)"
          style={{ animationDelay: '0.4s' }}
        />
        <rect
          className="subtopic"
          x="270"
          y="120"
          width="90"
          height="35"
          fill="rgb(170,180,190)"
          style={{ animationDelay: '0.55s' }}
        />
      </svg>
    </div>
  )
}

export default MindMapLoader
