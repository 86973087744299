import React from 'react'
import Modal from '../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import EmailComposerDecisionLog from '../EmailComposerDecisionLog'

const DecisonLogEmailModal = ({
  closeModal,
  isModalOpen,
  selectedLog,
  selectedComments,
  selectedMilestones,
  selectedOwners,
}) => {
  const { t } = useTranslation(['MeetingHub'])

  const [loading, setLoading] = React.useState(true)

  return (
    <>
      <Modal
        modalContainerStyle={{ width: '80%' }}
        title={t('decisionLog.composeDecisionLogEmail')}
        titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
        modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
        closeModal={() => {
          closeModal()
        }}
        width={'60rem'}
      >
        <EmailComposerDecisionLog
          closeModal={closeModal}
          selectedLog={selectedLog}
          selectedComments={selectedComments}
          selectedMilestones={selectedMilestones}
          selectedOwners={selectedOwners}
        />
      </Modal>
    </>
  )
}
export default DecisonLogEmailModal
