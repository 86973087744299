import React, { useState, useEffect } from 'react'
import Modal from '../../../../../components/Modal/index'
import {
  getMeetingTagsListForUser,
  updateMeetingInstanceForMeeting,
} from '../../../../../api/services/meetingTopic.services'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../../../components/CustomMultipleSelect/singleOption'
import { ModalSaveButtonFitted } from '../../../../../components/ModalSubmitButton'
import { useMeetingRecording } from '../../../../../utils/meetingRecordingContext/meetingRecordingContext'
import { getConsolidatedConfigSettings } from '../../../../../utils/auth'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'

const SelectMeetingTagsModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsAiMeeting,
  onModalClose = () => {
    setIsModalOpen(false)
  },
  onCancel = () => {
    setIsModalOpen(false)
  },
  showReviewButton,
}) => {
  const { t } = useTranslation(['Common'])
  const [meetings, setMeetings] = useState([])
  const [extendedMeetings, setExtendedMeetings] = useState([])
  const [inputChanged, setInputChanged] = useState(false)

  const [formValues, setFormValues] = useState({ meetings: null })
  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true

  const [selectedButton, setSelectedButton] = useState('review')

  React.useEffect(() => {
    getMeetingTags()
  }, [])

  const getMeetingTags = async () => {
    let res = await getMeetingTagsListForUser()
    setMeetings(res?.meetings)
    setExtendedMeetings([
      { meetingId: null, meetingName: t('Common:selectMeetingModal.none') },
      ...res?.meetings,
    ])
  }

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  function inputChangedHandler(e, data) {
    console.log('e', e)
    console.log('data', data)
    // Get name of changed input, and its corresponding value
    let name
    let value
    if (data) {
      if (data.name == 'meetings') {
        name = data.name
        value = e
      }
    }
    updateFormHandler(name, value)
    setInputChanged(true)
  }

  function updateFormHandler(name, value) {
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  async function onCustomSubmit() {
    if (selectedButton == 'review') {
      onCancel()
    } else {
      if (formValues.meetings != null && formValues.meetings.meetingId != null) {
        console.log('formValues', formValues)
        let res = await postHttpRequest('/get_meeting_details', {
          meetingId: formValues.meetings.meetingId,
        })
        console.log('res', res)
        onModalClose(
          formValues.meetings.meetingId,
          res?.last_meeting_ts,
          res?.last_meeting_instance_id,
          res?.active_user_names,
          res?.last_meeting_is_not_ai_meeting,
          formValues?.meetings?.meetingName,
        )
      } else {
        onModalClose()
      }
    }
  }

  return (
    <div className="kanban-modal">
      <Modal
        title={t('selectMeetingModal.selectMeeting')}
        isModalOpen={isModalOpen}
        data-testid="Assigne"
        closeModal={() => {
          onCancel()
        }}
        width={'55rem'}
        style={{ color: 'rgb(91, 132, 203)' }}
      >
        {
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div style={{ gap: '1rem', display: 'flex', marginBottom: '1rem' }}>
              {showReviewButton && (
                <div
                  className="option-button"
                  style={{
                    backgroundColor: selectedButton === 'review' ? '#4472c4' : '#a6a6a6',
                    width: '14rem',
                    height: '3rem',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setSelectedButton('review')
                  }}
                >
                  {t('Common:selectMeetingModal.reviewTopics')}
                </div>
              )}
              <div
                className="option-button"
                style={{
                  backgroundColor: selectedButton === 'manual' ? '#4472c4' : '#a6a6a6',
                  width: '14rem',
                  height: '3rem',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setSelectedButton('manual')
                  setIsAiMeeting(false)
                }}
              >
                {t('Common:selectMeetingModal.startManualMeeting')}
              </div>
              {canUseMeetingSummarization && (
                <div
                  className="option-button"
                  style={{
                    backgroundColor: selectedButton === 'ai' ? '#4472c4' : '#a6a6a6',
                    width: '14rem',
                    height: '3rem',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setSelectedButton('ai')
                    setIsAiMeeting(true)
                  }}
                >
                  {t('Common:selectMeetingModal.startAiMeeting')}
                </div>
              )}
            </div>
            {selectedButton == 'manual' && (
              <div
                style={{
                  width: '100%',
                  fontWeight: 'normal',
                  fontSize: '0.8rem',
                  color: 'black',
                  marginBottom: '1rem',
                }}
              >
                {t('Common:selectMeetingModal.explainatoryTextManual')}
              </div>
            )}
            {selectedButton == 'ai' && (
              <div
                style={{
                  width: '100%',
                  fontWeight: 'normal',
                  fontSize: '0.8rem',
                  color: 'black',
                  marginBottom: '1rem',
                }}
              >
                {t('Common:selectMeetingModal.explainatoryTextAI')}
              </div>
            )}
            {meetings?.length > 0 && (selectedButton == 'manual' || selectedButton == 'ai') && (
              <div
                className="column-input-objective"
                style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                {' '}
                {t('Common:selectMeetingModal.isThisPredefinedMeeting')}
                <div className="row-kanban-inputs">
                  <div className="form-field action-field form-field action-field--kanban">
                    <div style={{ width: '100%', marginTop: '0.5rem' }}>
                      <SelectField
                        placeholder={t('formPlaceHolder.select')}
                        name="meetings"
                        value={formValues.meetings}
                        options={extendedMeetings}
                        onChange={inputChangedHandler}
                        getOptionLabel={(option) => option.meetingName}
                        getOptionValue={(option) => option.meetingId}
                        menuShouldScrollIntoView={false}
                        menuPosition="fixed"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="sleek-button sleek-blue"
              style={{ marginLeft: 'auto', marginRight: '1rem' }}
              onClick={() => {
                onCustomSubmit()
              }}
            >
              {t('Common:commonButtons.ok')}
            </div>
          </div>
        }
      </Modal>
    </div>
  )
}
export default SelectMeetingTagsModal
