import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const TimeRemainingAlert = ({
  remainingTime, // in hours
  subscriptionStatus,
  onDismiss,
}) => {
  const { t } = useTranslation('Phoenix')
  const [visible, setVisible] = useState(true)

  // Format remaining time for display
  const formatTime = (hours) => {
    let wholeHours = Math.floor(hours)
    let minutes = Math.round((hours - wholeHours) * 60)
    //if minutes is 60, then set minutes to 0 and add 1 to wholeHours
    if (minutes === 60) {
      minutes = 0
      wholeHours += 1
    }

    if (wholeHours === 0) {
      return `${minutes} ${t('timeRemainingAlert.minutes')}`
    } else if (minutes === 0) {
      return `${wholeHours} ${t('timeRemainingAlert.hours')}`
    }
    return `${wholeHours} ${t('timeRemainingAlert.hours')} ${t('timeRemainingAlert.and')} ${minutes} ${t('timeRemainingAlert.minutes')}`
  }

  // Set up auto-dismiss timer
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setVisible(false);
  //     if (onDismiss) onDismiss();
  //   }, 10000); // 10 seconds

  //   return () => clearTimeout(timer);
  // }, [onDismiss]);

  const handleClose = () => {
    setVisible(false)
    if (onDismiss) onDismiss()
  }

  if (!visible) return null

  return (
    <div className="bg-amber-50 border border-amber-200 rounded-lg p-3 shadow-sm">
      <div className="flex flex-row gap-3">
        <span className="material-symbols-outlined text-amber-400">schedule</span>
        <div className="flex flex-row items-center gap-2 justify-center items-center mb-2">
          {/* <h5 className="text-lg font-semibold text-amber-500">
          {t('timeRemainingAlert.title')}
        </h5> */}
          <p className="text-amber-500">
            {t('timeRemainingAlert.youHave')}
            &nbsp;
            <span className="font-bold">{formatTime(remainingTime)}</span>
            &nbsp;
            {t('timeRemainingAlert.remainingIn')}
            &nbsp;
            {subscriptionStatus === 'subscribed'
              ? t('timeRemainingAlert.thisBillingCycle')
              : t('timeRemainingAlert.inYourTrial')}
          </p>
        </div>
        <button
          onClick={handleClose}
          className="text-amber-400 hover:text-amber-500 focus:outline-none"
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>
    </div>
  )
}

export default TimeRemainingAlert
