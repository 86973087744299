import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Toast from '../../common/toast'
import { postHttpRequest } from '../../api/query/dynamicAPI'
import './style.scss'

const ExcelUploadButton = ({ onUploadSuccess, buttonText, isUploading, setIsUploading }) => {
  const { t } = useTranslation(['Common'])
  const fileInputRef = useRef(null)

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (e) => {
    const files = e.target.files
    if (!files || files.length === 0) return

    const file = files[0]
    const fileExtension = file.name.split('.').pop().toLowerCase()
    console.log('fileExtension', fileExtension)

    // Check if file is an Excel spreadsheet
    if (!['xls', 'xlsx', 'csv'].includes(fileExtension)) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.invalidFileFormat'),
      })
      return
    }

    let response
    try {
      setIsUploading(true)
      const formData = new FormData()
      formData.append('file', file, file.name)

      // Send the file to the backend
      response = await postHttpRequest('/import_from_kpi_spreadsheet', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.fileUploadSuccess'),
      })

      if (onUploadSuccess) {
        onUploadSuccess(response)
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      console.log('errorr.response', error.response)
      console.log('response:', response)
      if (error.response.status === 400) {
        if (error.response?.data?.error === '1') {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.wrongTemplate'),
          })
        } else {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.fileIsNotComplete'),
          })
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } finally {
      setIsUploading(false)
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  return (
    <div>
      <div
        className={`${isUploading ? 'disabled' : ''}`}
        onClick={isUploading ? null : handleUploadClick}
      >
        {isUploading ? (
          <div
            style={{
              width: '0.5rem',
              height: '0.5rem',
              // margin: '0 auto'
              borderTopColor: 'rgb(9, 38, 213)',
            }}
            className="upload-loading-spinner"
          ></div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.1rem' }}>
            <span class="material-symbols-outlined icon-size">upload</span>
            <div>{buttonText || t('Common:commonButtons.uploadTemplate')}</div>
          </div>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept=".xls,.xlsx,.csv"
      />
    </div>
  )
}

export default ExcelUploadButton
