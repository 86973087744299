import React, { useState, useRef, useEffect } from 'react'
import './styles.scss'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import {
  getAllEmployeeList,
  getObjectivUserList,
} from '../../../../../api/services/employee.services'
import { getHttpRequest, postHttpRequest } from '../../../../../api/query/dynamicAPI'
import InPageLoader from '../../../../../components/InPageLoader'
import Toast from '../../../../../common/toast'
import { API_ERROR_MESSAGE } from '../../../../../api/constant/route'

const EmailComposer = ({
  closeModal,
  selectedLog,
  selectedComments,
  selectedMilestones,
  selectedOwners,
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [isLoading, setIsLoading] = useState(false)
  const [peopleList, setPeopleList] = React.useState(selectedOwners)
  const [additionalLogViewersList, setAdditionalLogViewersList] = React.useState(null)
  const [logViewersList, setLogViewersList] = React.useState([])
  const [errors, setErrors] = React.useState({ recipients: null, subject: null, dlName: null })
  const todayDate = new Date().toLocaleDateString('en-GB')
  const [emailData, setEmailData] = useState({
    recipients: peopleList,
    subject: t('MeetingHub:decisionLog.decisionLog') + ' ' + todayDate,
    body: '',
    includeNotes: false,
  })

  // Distribution List States
  const [showDlModal, setShowDlModal] = useState(false)
  const [dlName, setDlName] = useState('')
  const [distributionLists, setDistributionLists] = useState([])
  const [showDlDropdown, setShowDlDropdown] = useState(false)
  const dlDropdownRef = useRef(null)
  const dlButtonRef = useRef(null)

  // Add new state for editing
  const [editingDl, setEditingDl] = useState(null)
  const [selectedDl, setSelectedDl] = useState(null)

  React.useEffect(() => {
    async function fetchData() {
      await getAdditionalLogViewersList()
      await getDistributionLists()
    }
    fetchData()

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (
        dlDropdownRef.current &&
        !dlDropdownRef.current.contains(event.target) &&
        dlButtonRef.current &&
        !dlButtonRef.current.contains(event.target)
      ) {
        setShowDlDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // New useEffect to handle initial setup when additionalLogViewersList is populated
  React.useEffect(() => {
    if (additionalLogViewersList && distributionLists.length > 0) {
      const defaultDl = distributionLists.find((dl) => dl.isDefault)
      if (defaultDl) {
        const selectedEmployees = additionalLogViewersList.filter((emp) =>
          defaultDl.members.includes(emp.eId),
        )
        const combinedList = [...selectedOwners, ...selectedEmployees]
        const uniqueList = combinedList.filter(
          (emp, index, self) => index === self.findIndex((t) => t.eId === emp.eId),
        )
        handleAdditionalLogViewers(uniqueList)
        setSelectedDl(defaultDl)
      }
    }
  }, [additionalLogViewersList, distributionLists])

  async function getAdditionalLogViewersList() {
    setIsLoading(true)
    // List includes IC's
    const result = await getAllEmployeeList()
    // await getReportingLeaders()
    if (result?.employees) setAdditionalLogViewersList(result.employees)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  async function getReportingLeaders() {
    const result = await getHttpRequest('/team_leaders_for_area_leader', {
      params: {
        notExcludeCos: true,
      },
    })
    let employees =
      result?.employees?.filter((emp) => emp.isDeactivated === false && emp.isDeleted === false) ??
      []
    if (!selectedDl) {
      //combine selectedOwners and employees and remove duplicates
      const combinedList = [...selectedOwners, ...employees]
      const uniqueList = combinedList.filter(
        (emp, index, self) => index === self.findIndex((t) => t.eId === emp.eId),
      )
      setPeopleList(uniqueList)
      setLogViewersList(uniqueList.map((emp) => emp.eId))
    } else {
      setPeopleList(employees)
      setLogViewersList(employees.map((emp) => emp.eId))
    }
  }

  const handleAdditionalLogViewers = (selectedPeople) => {
    if (selectedPeople) {
      setLogViewersList(selectedPeople.map((emp) => emp.eId))
      setPeopleList(selectedPeople)
      setEmailData((prev) => ({
        ...prev,
        recipients: selectedPeople,
      }))
    } else {
      // Handle clearing the selection
      setLogViewersList([])
      setPeopleList([])
      setEmailData((prev) => ({
        ...prev,
        recipients: [],
      }))
    }
    // Clear the error when user makes a selection
    setErrors((prev) => ({ ...prev, recipients: null }))
  }

  React.useEffect(() => {
    setEmailData((prev) => ({
      ...prev,
      recipients: peopleList,
    }))
  }, [peopleList])

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setEmailData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Email Data:', emailData)
    // Here you would typically handle the email sending logic
  }

  const errorCheck = () => {
    let hasError = false
    const newErrors = { recipients: null, subject: null }
    console.log('emailData.recipients', emailData.recipients)
    if (!emailData.recipients || emailData.recipients.length === 0) {
      newErrors.recipients = t('Common:formError.selectAtLeastOneRecipient')
      hasError = true
    }

    if (!emailData.subject || emailData.subject.trim().length === 0) {
      newErrors.subject = t('Common:formError.enterSubject')
      hasError = true
    }

    setErrors(newErrors)
    return !hasError
  }

  const sendLog = () => {
    if (errorCheck() === true) {
      let postObj = {}
      postObj.logName = emailData.subject
      postObj.body = emailData.body.length > 0 ? emailData.body : null
      postObj.logId = selectedLog.logId
      postObj.additionalLogViewers = logViewersList
      postObj.includeNotes = emailData.includeNotes
      postObj.commentIds = selectedComments
      postObj.milestoneIds = selectedMilestones

      postHttpRequest('/send_decision_log', postObj)
        .then((response) => {
          if (response) {
            Toast.fire({
              icon: 'success',
              title: t('Common:modalMessages.emailSentSuccessfully'),
            })
          } else {
            Toast.fire({
              icon: 'error',
              title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
            })
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
          })
        })
      closeModal()
    }
  }

  // Distribution List Functions
  async function getDistributionLists() {
    try {
      const result = await getHttpRequest('/get_distribution_list', {})
      if (result?.distributionLists) {
        let distributionLists = []
        for (let dl of result.distributionLists) {
          distributionLists.push({
            id: dl.dlId,
            name: dl.dlName,
            members: dl.members.map((member) => member.eId),
            isDefault: dl.isDefault,
          })
        }
        console.log('distributionLists', distributionLists)
        setDistributionLists(distributionLists)
        return distributionLists
      }
    } catch (error) {
      console.error('Error fetching distribution lists:', error)
    }
  }

  const selectDistributionList = async (dl) => {
    setIsLoading(true)
    try {
      if (additionalLogViewersList) {
        const selectedEmployees = additionalLogViewersList.filter((emp) =>
          dl.members.includes(emp.eId),
        )
        console.log('selectedEmployees', selectedEmployees)

        // Only use DL members when selecting from dropdown
        handleAdditionalLogViewers(selectedEmployees)
      }
      setSelectedDl(dl) // Set the selected distribution list
      setShowDlDropdown(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  // Add function to handle edit
  const handleEditDl = async (dl) => {
    // setEditingDl(dl)
    // setDlName(dl.name)
    // setShowDlModal(true)
    setShowDlDropdown(false)
    console.log('dl', dl)
    try {
      const result = await postHttpRequest('/update_distribution_list', {
        dlId: dl.id,
        members: peopleList.map((p) => p.eId),
      })
      await getDistributionLists()
      console.log('result', result)
    } catch (error) {
      console.error('Error updating distribution list:', error)
    }
  }

  const handleMakeDefaultDl = async (dl) => {
    console.log('dl', dl)
    const result = await postHttpRequest('/make_default_distribution_list', {
      dlId: dl.id,
      makeDefault: true,
    })
    await getDistributionLists()
    console.log('result', result)
  }

  const saveDistributionList = async () => {
    // Validate DL name
    if (!dlName || dlName.trim() === '') {
      setErrors((prev) => ({ ...prev, dlName: t('Common:formError.enterListName') }))
      return
    }

    // Validate recipients
    if (!peopleList || peopleList.length === 0) {
      setErrors((prev) => ({
        ...prev,
        recipients: t('Common:formError.selectAtLeastOneRecipient'),
      }))
      return
    }

    setIsLoading(true)
    try {
      const newDl = {
        name: dlName,
        members: peopleList.map((p) => p.eId),
      }

      // In production, this would be a real API call
      // Placeholder for the actual API implementation
      const result = await postHttpRequest('/save_distribution_list', newDl)

      if (result?.success) {
        // Use the returned DL with server-generated ID
        let latestDistributionLists = await getDistributionLists()
        setSelectedDl(latestDistributionLists.find((dl) => dl.id === result.distribution_list_id))
        Toast.fire({
          icon: 'success',
          title: t('Common:modalMessages.distributionListSaved'),
        })
      }
      setDlName('')
      setShowDlModal(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <InPageLoader />}
      {!isLoading && (
        <div className="email-composer">
          <form onSubmit={handleSubmit} className="email-composer__form">
            <div className="email-composer__content">
              <div className="form-group">
                <label className="form-label">{t('decisionLog.recipients')}&nbsp;*</label>

                <div className="recipients-container">
                  <div className="recipients-select-container">
                    <Select
                      isMulti={true}
                      value={peopleList}
                      options={additionalLogViewersList}
                      onChange={handleAdditionalLogViewers}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.eId}
                      isClearable={true}
                      placeholder={t('Common:formPlaceHolder.typeToAdd')}
                      className="recipients-select"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          textAlign: 'left',
                          borderWidth: state.isFocused ? '2px' : '1px',
                          borderColor: state.isFocused ? '#2563eb' : base.borderColor,
                          boxShadow: 'none',
                          minHeight: '38px',
                          '&:hover': {
                            borderColor: state.isFocused ? '#2563eb' : base.borderColor,
                          },
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          padding: '2px 8px',
                        }),
                        multiValue: (base) => ({
                          ...base,
                          textAlign: 'left',
                          margin: '2px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          fontSize: '0.875rem',
                          color: '#6B7280',
                          fontFamily: 'Roboto, sans-serif',
                        }),
                        option: (base) => ({
                          ...base,
                          textAlign: 'left',
                        }),
                        singleValue: (base) => ({
                          ...base,
                          textAlign: 'left',
                        }),
                      }}
                      maxMenuHeight={200}
                    />
                    {selectedDl && (
                      <div className="current-dl">
                        <span className="current-dl-label">{t('decisionLog.currentList')}</span>
                        <span>{selectedDl.name}</span>
                        <button
                          type="button"
                          className="edit-dl-button"
                          onClick={() => handleEditDl(selectedDl)}
                        >
                          {t('Common:commonButtons.update')}
                        </button>
                        {selectedDl && !selectedDl.isDefault && <span> | </span>}
                        {selectedDl && !selectedDl.isDefault && (
                          <button
                            type="button"
                            className="edit-dl-button"
                            onClick={() => handleMakeDefaultDl(selectedDl)}
                          >
                            {t('Common:commonButtons.makeDefault')}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="distribution-list-controls">
                    <button
                      type="button"
                      ref={dlButtonRef}
                      className="dl-button"
                      onClick={() => setShowDlDropdown(!showDlDropdown)}
                    >
                      {t('decisionLog.distributionLists')}
                    </button>
                    {showDlDropdown && (
                      <div className="dl-dropdown" ref={dlDropdownRef}>
                        <div className="dl-dropdown-content">
                          {distributionLists.length > 0 ? (
                            distributionLists.map((dl) => (
                              <div
                                key={dl.id}
                                className="dl-item"
                                onClick={() => selectDistributionList(dl)}
                              >
                                <span>{dl?.name}</span>
                                <span className="dl-member-count">
                                  {dl?.members?.length} {t('decisionLog.members')}
                                </span>
                              </div>
                            ))
                          ) : (
                            <div className="dl-no-lists">{t('decisionLog.noListsFound')}</div>
                          )}
                        </div>
                        <div
                          className="dl-create-new"
                          onClick={() => {
                            setShowDlDropdown(false)
                            setShowDlModal(true)
                          }}
                        >
                          + {t('decisionLog.createNewList')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {errors.recipients && <div className="validation-error">{errors.recipients}</div>}
              </div>

              <div className="form-group">
                <label className="form-label">{t('decisionLog.subject')}&nbsp;*</label>
                <input
                  type="text"
                  name="subject"
                  value={emailData.subject}
                  onChange={handleInputChange}
                  className="form-input"
                  placeholder={t('Common:formPlaceHolder.enterSubject')}
                />
                {errors.subject && <div className="validation-error">{errors.subject}</div>}
              </div>

              <div className="form-group">
                <label className="form-label">{t('decisionLog.bodyOptionalLabel')}</label>
                <textarea
                  name="body"
                  value={emailData.body}
                  onChange={handleInputChange}
                  className="form-input form-textarea"
                  placeholder={t('Common:formPlaceHolder.writeYourMessageHere')}
                />
              </div>
            </div>

            <div className="email-composer__footer">
              <button
                type="button"
                className="submit-button"
                onClick={() => {
                  sendLog()
                }}
              >
                {t('decisionLog.sendLog')}
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Distribution List Modal */}
      {showDlModal && (
        <div className="modal-overlay">
          <div className="dl-modal">
            <div className="dl-modal-header">
              <h3>
                {editingDl
                  ? t('decisionLog.editDistributionList')
                  : t('decisionLog.createDistributionList')}
              </h3>
              <button
                type="button"
                className="close-button"
                onClick={() => {
                  setShowDlModal(false)
                  setDlName('')
                  setErrors((prev) => ({ ...prev, dlName: null }))
                }}
              >
                ×
              </button>
            </div>
            <div className="dl-modal-content">
              <div className="dl-form-group">
                <label className="dl-form-label">
                  {t('decisionLog.listName')}
                  <span>&nbsp;*</span>
                </label>
                <input
                  type="text"
                  value={dlName}
                  onChange={(e) => {
                    setDlName(e.target.value)
                    if (e.target.value) {
                      setErrors((prev) => ({ ...prev, dlName: null }))
                    }
                  }}
                  className="dl-form-input"
                  placeholder={t('decisionLog.enterListName')}
                />
                {errors.dlName && <div className="dl-validation-error">{errors.dlName}</div>}
              </div>

              <div className="dl-form-group">
                <label className="dl-form-label">
                  {t('decisionLog.selectedMembers')} ({peopleList.length})
                </label>
                <div className="dl-members-container">
                  {peopleList.length > 0 ? (
                    peopleList.map((person) => (
                      <div key={person.eId} className="dl-member-item">
                        <span>{person.name}</span>
                      </div>
                    ))
                  ) : (
                    <div className="dl-no-members">{t('decisionLog.noMembersSelected')}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="dl-modal-footer">
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  setShowDlModal(false)
                  setDlName('')
                }}
              >
                {t('Common:commonButtons.cancel')}
              </button>
              <button type="button" className="save-button" onClick={saveDistributionList}>
                {t('Common:commonButtons.save')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EmailComposer
