import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ApplicationLayout from '../../components/ApplicationLayout'

const NotFound = () => {
  const { t } = useTranslation(['Phoenix'])
  const navigate = useNavigate()
  return (
    <ApplicationLayout>
      <div className="tailwind-scoped">
        <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
          <div className="max-w-2xl mx-auto text-center">
            <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-8 mb-8">
              {/* 404 Header */}
              <div className="mb-8">
                <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
                <div className="text-xl font-semibold text-gray-700 mb-2">
                  {t('notFound.subtitle')}
                  &nbsp; &nbsp;
                  <span className="text-3xl"> 🚧 </span>
                </div>
                <p className="text-gray-600">{t('notFound.message1')}</p>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <button
                  className="w-50  inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors gap-2"
                  onClick={() => (window.location.href = '/')}
                >
                  <span className="material-symbols-outlined">home</span>

                  {t('button.returnHome')}
                </button>

                {/* <button className="w-50 inline-flex items-center justify-center px-6 py-3 border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors gap-2"
            onClick={() => window.history.back()}
            >
              <span className="material-symbols-outlined">arrow_back</span>
              {t('button.goBack')}
            </button> */}
                <button
                  className="w-50 inline-flex items-center justify-center px-6 py-3 border-2 border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors gap-2"
                  onClick={() => window.history.back()}
                >
                  <span className="material-symbols-outlined">arrow_back</span>
                  {t('button.goBack')}
                </button>
              </div>

              {/* Quick Links */}
              <div className="mt-8 pt-6 border-t border-gray-200 flex flex-col items-center">
                <div className="text-sm text-gray-600">{t('notFound.popularDestinations')}</div>
                <div className="mt-3 flex flex-wrap justify-center gap-2 flex-row">
                  <span
                    className="h-9 inline-flex items-center px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm hover:bg-gray-200 transition-colors gap-2 cursor-pointer"
                    onClick={() => (window.location.href = '/contact-us')}
                  >
                    <span className="material-symbols-outlined text-m">contact_support</span>
                    {t('footer.contactUs')}
                  </span>
                  <span
                    className="h-9 inline-flex items-center px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm hover:bg-gray-200 transition-colors gap-2 cursor-pointer"
                    onClick={() => (window.location.href = '/faq')}
                  >
                    <span className="material-symbols-outlined text-m">question_mark</span>
                    {t('footer.faq')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default NotFound
