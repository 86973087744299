import React from 'react'
import { CardContainer, StepHeader } from '../../styles'

import EditMeetingTopicModal from './EditMeetingTopicModal'
import {
  getCompanyConfigSettings,
  getConsolidatedConfigSettings,
  isAreaLeader,
} from '../../../../utils/auth'
import useObjectiveRagUpdate from '../../../../api/mutation/useObjectiveRagUpdate'
import { useTranslation } from 'react-i18next'
import useUploadMeetingDeck from '../../../../api/mutation/useUploadMeetingDeck'
import Toast from '../../../../common/toast'
import { useUpdateContext } from '../../context/updateContext'
import useAreaLeaderTeamLeaders from '../../../../api/query/useAreaLeaderTeamLeaders'
import SelectSingleField from '../../../../components/CustomMultipleSelect/singleOption.jsx'
import InPageLoader from '../../../../components/InPageLoader'
import {
  getMeetingTopicForBGU,
  manageTopicUW,
  postCloseMeetingTopics,
  useUpdateMeetingLogs,
} from '../../../../api/services/meetingTopic.services'
import Modal from '../../../../components/Modal'
import Swal from 'sweetalert2'
import moment from 'moment'
import Select from 'react-select'
import ResolveMeetingTopicModal from './ResolveMeetingTopicModal'
import ObjectiveDetailsModal from '../../../MeetingMultiView/components/MeetingTopics/common/component/OjbectiveDetailsModal'
import WizardDateModal from '../../../../components/WizardDateModal'
import { PriorityFlagIcon } from '../../../MeetingMultiView/components/MeetingTopics/common/Card'
import { dateFilter, formatDateToMMDD, dayMonthFilter } from '../../../../utils/time'
import {
  getSameLevelL2Leaders,
  getTeamLeadersForAreaLeaders,
} from '../../../../api/services/employee.services'
import KPIModal from '../../../../components/KPI/KPIModal'
import CardModal from '../../../../components/CardModal'
import { STATUS, EVENTS } from 'react-joyride'
import MultiAddTextModal from '../../../../components/MultiAddTextModal'
import ReviewGeneratedTopicsModal from '../../../../components/ReviewGeneratedTopicsModal'
import MultiAddUsingAudioModal from '../../../../components/MultiAddUsingAudioModal'
import { shortenName } from '../../../../utils/general'
import BGUDropdownMenu from './BGUDropdownMenu'
import TagsManagementModal from '../../../../components/TagsManagementModal'

const MeetingTopics = () => {
  const { t } = useTranslation(['Common'])
  const { state, dispatch } = useUpdateContext()
  // const { data: teamLeadersData, isLoading: teamLeadersIsLoading } = useAreaLeaderTeamLeaders()
  const [teamLeadersData, setTeamLeadersData] = React.useState([])
  const [eId, setEid] = React.useState(null)
  const [meetingTopicData, setMeetingTopicData] = React.useState(null)
  const [topicsIsLoading, setTopicsIsLoading] = React.useState(false)
  const [reloadTracker, setReloadTracker] = React.useState(false)
  const [hasLoadedInitially, setHasLoadedInitially] = React.useState(false)
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const productSettings = getCompanyConfigSettings('productSettings')
  const [isObjDetailsOpen, setIsObjDetailsOpen] = React.useState(false)
  const [isKPIModalOpen, setIsKPIModalOpen] = React.useState(false)
  const initiativesData = [...new Set(meetingTopicData?.objectives?.map((obj) => obj))]
  const [objectiveFilterValue, setObjectiveFilterValue] = React.useState([])

  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [umtId, setUmtId] = React.useState(null)
  const [topicType, setTopicType] = React.useState(null)
  const [wizardDateModalOpen, setIsWizardDateModalOpen] = React.useState(false)

  const handleChangeObjectiveFilter = (value) => {
    setObjectiveFilterValue(value)
  }
  const [objToShow, setObjToShow] = React.useState([])
  const [isResolveMeetingTopicsModalOpen, setIsResolveMeetingTopicsModalOpen] =
    React.useState(false)

  const meetingDeckUploadRefsBlockers = []
  const meetingDeckUploadRefsDecisions = []
  const meetingDeckUploadRefsUpdates = []
  const allowL2UsersSeeAllObjsUnderCxo = getConsolidatedConfigSettings(
    'allow_l2_users_see_all_objs_under_cxo',
  )

  const [showL3Objectives, setShowL3Objectives] = React.useState(false)
  const [isMultiAddTopicsModalOpen, setisMultiAddTopicsModalOpen] = React.useState(false)
  const [isMultiAddTopicsUsingAudioModalOpen, setisMultiAddTopicsUsingAudioModalOpen] =
    React.useState(false)
  const [generatedTopics, setGeneratedTopics] = React.useState([])
  const [isReviewGeneratedTopicsModalOpen, setIsReviewGeneratedTopicsModalOpen] =
    React.useState(false)
  const [recentlyClosedMultiAddModal, setRecentlyClosedMultiAddModal] = React.useState(null)
  const [objOwnerId, setObjOwnerId] = React.useState(null)

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    teamLeadersForAreaLeaders()
    if (allowL2UsersSeeAllObjsUnderCxo) {
      setShowL3Objectives(true)
    }
  }, [])

  React.useEffect(() => {
    console.log('inside useEffect for eId')
    console.log('eId', eId)
    if (
      eId === null &&
      state?.locationState?.isFromDashBoard &&
      (isDelegateTl || allowL2UsersSeeAllObjsUnderCxo) &&
      teamLeadersData?.employees
    ) {
      return
    }

    setTopicsIsLoading(true)

    getMeetingTopicForBGU(eId, showL3Objectives)
      .then((response) => {
        const data = response
        if (data) {
          console.log('data =', data)
          setMeetingTopicData(data)
          setTopicsIsLoading(false)
          setHasLoadedInitially(true)
        }

        setIsLoading(false)
      })
      .catch((error) => {})
  }, [eId, reloadTracker, showL3Objectives])

  React.useEffect(() => {
    // only set to current loggedin user if coming from dashboard, is delegate TL
    // and team leaders data is available (if it's delegate TL then teamLeadersData?.employees will at least has one element)
    if ((isDelegateTl || allowL2UsersSeeAllObjsUnderCxo) && teamLeadersData?.employees) {
      let leader = teamLeadersData?.employees?.filter(
        (emp) => emp.name == localStorage.getItem('tfmnm'),
      )[0]
      setEid(parseInt(leader?.eId))
    }
  }, [teamLeadersData])

  const [isEditMeetingTopicsModalOpen, setIsEditMeetingTopicsModalOpen] = React.useState(false)
  const [buttonClicked, setButtonClicked] = React.useState({})
  const [isLoading, setIsLoading] = React.useState({})

  const [invalidateButtons, setInvalidateButtons] = React.useState(false)

  const [textInput, setTextInput] = React.useState(
    Array.from({ length: meetingTopicData?.objectives.length }, (v, i) => ''),
  )
  const [charCount, setCharCount] = React.useState([0])

  const [isManageTagsModalOpen, setIsManageTagsModalOpen] = React.useState(false)

  const [selectedMeetingTopic, setSelectedMeetingTopic] = React.useState(null)
  const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true

  const mutationObjRagUpdate = useObjectiveRagUpdate()
  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()

  const rags = {
    GREEN: 0,
    YELLOW: 1,
    RED: 2,
  }

  const buttonStatusStyle = {
    boxShadow: 'inset orange 0 0 0 5px',
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#009688',
  }

  React.useEffect(() => {
    if (meetingTopicData?.objectives?.length > 0) {
      for (let obj of meetingTopicData.objectives) {
        if (obj !== undefined) {
          setButtonClicked((prev) => ({
            ...prev,
            [obj.objId]: rags[obj.rag],
          }))
        }
      }
    }

    //code  that takes care filter for objectives remain after reload of data
    if (meetingTopicData) {
      if (objectiveFilterValue.length === 0) {
        setObjToShow(meetingTopicData?.objectives)
      } else {
        let objIds = []
        for (let obj of objectiveFilterValue) {
          objIds.push(obj.objId)
        }

        let objsFiltesObjs = []
        for (let obj of meetingTopicData?.objectives ?? []) {
          for (let objId of objIds) {
            if (obj.objId === objId) {
              objsFiltesObjs.push(obj)
            }
          }
        }
        setObjToShow(objsFiltesObjs)
      }
    }
  }, [meetingTopicData])

  // React.useEffect(() => {
  //   startTutorial(objToShow.length > 0)
  // }, [objToShow])

  React.useEffect(() => {
    if (generatedTopics && generatedTopics.length > 0) {
      setIsReviewGeneratedTopicsModalOpen(true)
    }
  }, [generatedTopics])

  //console.log('objToShow: ', objToShow)

  const reload = () => {
    setReloadTracker((reloadTracker) => !reloadTracker)
  }

  const textInputChangeHandler = (event, objIndex) => {
    const value = event.target.value
    if (value.length <= 300) {
      let tempobjIndex = [...textInput]
      tempobjIndex[objIndex] = event.target.value
      setTextInput(tempobjIndex)
      let newCharCount = [...charCount]
      newCharCount[objIndex] = value.length
      setCharCount(newCharCount)
    }
  }

  const mutationUpload = useUploadMeetingDeck()

  const manageTopic = async (topicType, action, obj, index) => {
    let data = {}
    data.action = action

    if (topicType === 1) {
      data.blocker = obj
    } else if (topicType === 2) {
      data.decision = obj
    } else if (topicType === 3) {
      data.update = obj
    }
    setTopicsIsLoading(true)

    manageTopicUW(topicType, data)
      .then((response) => {
        const data = response
        if (data) {
          let tempobjIndex = [...textInput]
          tempobjIndex[index] = ''
          setTextInput(tempobjIndex)
          setInvalidateButtons(false)
          setReloadTracker((reloadTracker) => !reloadTracker)
        }
      })
      .catch((error) => {})
  }

  const handleRagChange = (obj) => async (e) => {
    let ids = e.target.id.split('-')
    let rag = ids[ids.length - 2]
    let id = ids[ids.length - 1]

    if (buttonClicked[obj.objId] == id) {
      return
    }

    setButtonClicked((prev) => ({
      ...prev,
      [obj.objId]: parseInt(id),
    }))

    await mutationObjRagUpdate.mutateAsync({
      rag,
      objectiveId: obj.objId,
    })
  }

  async function onChangePpt(e, data) {
    const files = e.target.files

    if (files.length !== 0) {
      var formData = new FormData()
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        [data.mtId]: true,
        type: data.topicType,
      }))
      formData.append('umtId', data.mtId)

      formData.append('file', files[0], files[0].name)
      await mutationUpload.mutateAsync(formData, {
        onSuccess: () => {
          setIsLoading((prevLoading) => ({
            ...prevLoading,
            [data.mtId]: false,
            type: 0,
          }))
          Toast.fire({
            icon: 'success',
            title: t('modalMessages.updatedSuccessfully'),
          })
        },
        onError: () => {
          setIsLoading((prevLoading) => ({
            ...prevLoading,
            [data.mtId]: false,
            type: 0,
          }))
          Toast.fire({
            icon: 'error',
            title: t('modalMessages.somethingWentWrongPleaseTryAgain'),
          })
        },
      })
    }
  }

  const [objId, setObjId] = React.useState(null)
  const [meetingTopicsToClose, setMeetingTopicsToClose] = React.useState(null)

  const handleOwnerChange = (e) => {
    setEid(parseInt(e?.eId))
  }

  const handleUploadMeetingDeckClick = (index, type) => {
    let refs
    if (type === 'blockers') {
      refs = meetingDeckUploadRefsBlockers
    } else if (type === 'decisions') {
      refs = meetingDeckUploadRefsDecisions
    } else if (type === 'updates') {
      refs = meetingDeckUploadRefsUpdates
    }

    if (refs && refs[index]) {
      refs[index].click()
    }
  }

  if (topicsIsLoading && !hasLoadedInitially) {
    return <InPageLoader inComponent={true} />
  }

  const updateObjsToShow = () => {
    if (objectiveFilterValue.length === 0) {
      setObjToShow(meetingTopicData?.objectives)
    } else {
      setObjToShow(objectiveFilterValue)
    }
  }

  const applyFilters = () => {
    updateObjsToShow()
  }

  async function createFlagStatus(data, type, index) {
    const formData = {}
    if (type == 'flag') {
      formData.isPriority = !data?.isPriority
    } else if (type == 'status') {
      formData.status = !data?.status
    }

    formData.umtId = data?.umtId

    await mutationUpdateMeetingLogs.mutateAsync(formData, {
      onSuccess: () => {
        setReloadTracker((reloadTracker) => !reloadTracker)
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  async function teamLeadersForAreaLeaders() {
    let result = []
    if (allowL2UsersSeeAllObjsUnderCxo) {
      result = await getSameLevelL2Leaders()
    } else {
      result = await getTeamLeadersForAreaLeaders()
    }
    if (result?.employees) setTeamLeadersData(result)
  }

  const handleL2L3Toggle = (value) => {
    setObjectiveFilterValue([])
    setShowL3Objectives(value)
  }

  const setGeneratedTopicsWithIncludedTrue = (arg) => {
    let updatedTopics = [...arg]
    updatedTopics = updatedTopics.map((topic) => {
      topic.include = true
      return topic
    })
    setGeneratedTopics(updatedTopics)
  }
  const dateModalSave = (objId, startDate, dueDate) => {
    // startDate = dateStringToTimestamp(startDate)
    setIsWizardDateModalOpen(false)
    setMeetingTopicData((prev) => {
      return {
        ...prev,
        objectives: prev.objectives.map((element) => {
          if (element.objId === objId) {
            return {
              ...element,
              startDate: startDate,
              dueDate: dueDate,
            }
          }
          return element
        }),
      }
    })
  }

  const meetingTopicsBody = (obj, objIndex) => {
    return (
      <div
        key={objIndex}
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '3rem',
          backgroundColor: 'white',
        }}
      >
        <div
          key={objIndex}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '16rem',
            margin: '3rem 1rem',
          }}
        >
          <span>
            <b>
              {productSettings && obj.isGeneralObjective === false
                ? `${(obj?.initiativeId ?? 0).toString().padStart(5, '0')} - ${obj.description}`
                : `${obj.description}`}
            </b>
          </span>
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: 'grey',
              fontSize: '14px',
            }}
          >
            <span>{shortenName(obj.objOwner)}</span>
            {obj.level === 3 && (
              <span style={{ textAlign: 'right' }}>
                {(obj.startDate !== 0 || obj.startDate || obj.dueDate) &&
                  !(obj.startDate == '' && obj.dueDate == '') && (
                    <span>
                      <span>
                        {obj.startDate === 0 || !obj.startDate
                          ? '--'
                          : typeof obj.startDate == 'string'
                            ? formatDateToMMDD(obj.startDate)
                            : dayMonthFilter(obj.startDate)}
                      </span>
                      <span> {t('date.to')} </span>
                      <span>{obj.dueDate ? formatDateToMMDD(obj.dueDate) : '--'}</span>
                    </span>
                  )}
                {(obj.startDate === 0 || !obj.startDate) && !obj.dueDate && (
                  <span>{t('date.noDate')}</span>
                )}
              </span>
            )}
          </span>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                justifyItems: 'flex-end',
              }}
            >
              <div
                class="fitted-button blue"
                onClick={() => {
                  setObjId(obj.objId)
                  setisMultiAddTopicsUsingAudioModalOpen(true)
                }}
                // style={{ display: 'flex', alignItems: 'center' }}
                style={{ margin: '1rem 0' }}
                id="voice-button"
              >
                <span class="material-symbols-outlined icon-size">mic</span>
              </div>
            </div>

            <div
              class="fitted-button blue"
              style={{ margin: '1rem 0' }}
              onClick={() => {
                setObjId(obj.objId)
                setIsObjDetailsOpen(true)
              }}
            >
              <span class="material-symbols-outlined icon-size">history</span>
            </div>
            {obj.level === 3 && (
              <div
                class="fitted-button blue"
                style={{ margin: '1rem 0' }}
                onClick={() => {
                  setObjId(obj.objId)
                  setIsWizardDateModalOpen(true)
                }}
              >
                <span class="material-symbols-outlined icon-size"> edit_calendar </span>
              </div>
            )}
            {console.log('obj111', obj)}
            {/* {
              <div
                class="fitted-button blue"
                style={{
                  margin: '1rem 0',
                  height: '1.3rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  wordBreak: 'keep-all',
                  fontSize: '14px',
                }}
                onClick={() => {
                  setObjId(obj.objId)
                  setObjOwnerId(obj.objOwnerId)
                  setIsKPIModalOpen(true)
                }}
                id="kpi-button"
              >
                <span className="extra-lateral-spacing"> {t('Common:kpi.kpis')} </span>
              </div>
            } */}

            {/* <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '1rem 0',
                color: obj?.isKpiUpdateOverdue ? 'red' : 'black',
              }}
            >
              {obj?.mostRecentKpiUpdateTs > 0 && (
                <>
                  <div>{t('meetingTopicWizStep.lastUpdate')}</div>
                  <div>{dateFilter(obj.mostRecentKpiUpdateTs)}</div>
                </>
              )}
              {obj?.isKpiUpdateOverdue && (
                <div> {t('meetingTopicWizStep.kpiNeedsToBeUpdated')} </div>
              )}
            </span> */}
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
            <div
              class="fitted-button blue"
              onClick={() => {
                setObjId(obj.objId)
                setisMultiAddTopicsModalOpen(true)}}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span class="material-symbols-outlined icon-size">add</span>
              {t('Common:commonButtons.multiple')}
            </div>
          </div> */}
          {productSettings && !obj.isGeneralObjective && (
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '2rem' }}>
              <button
                id={`rag-btn-GREEN-0`}
                style={
                  buttonClicked[obj.objId] === 0
                    ? {
                        ...buttonStatusStyle,
                        backgroundColor: 'green',
                        width: '2rem',
                        height: '2rem',
                      }
                    : { backgroundColor: 'green', width: '2rem', height: '2rem' }
                }
                onClick={(e) => handleRagChange(obj)(e)}
              ></button>
              <button
                id={`rag-btn-YELLOW-1`}
                style={
                  buttonClicked[obj.objId] === 1
                    ? {
                        ...buttonStatusStyle,
                        backgroundColor: 'yellow',
                        width: '2rem',
                        height: '2rem',
                      }
                    : { backgroundColor: 'yellow', width: '2rem', height: '2rem' }
                }
                onClick={(e) => handleRagChange(obj)(e)}
              ></button>
              <button
                id={`rag-btn-RED-2`}
                style={
                  buttonClicked[obj.objId] === 2
                    ? {
                        ...buttonStatusStyle,
                        backgroundColor: 'red',
                        width: '2rem',
                        height: '2rem',
                      }
                    : { backgroundColor: 'red', width: '2rem', height: '2rem' }
                }
                onClick={(e) => handleRagChange(obj)(e)}
              ></button>
            </div>
          )}
          <textarea
            data-testid={`multiSubActivityText-${objIndex}`}
            rows="5"
            id="multiSubActivityText"
            name="multiSubActivityText"
            value={textInput[objIndex]}
            onChange={(e) => {
              textInputChangeHandler(e, objIndex)
            }}
            style={{
              resize: 'none',
              marginTop: '1rem',
              border: 'none',
              borderRadius: '10px',
              padding: '0.5rem',
              backgroundColor: 'rgb(242, 242, 242)',
              fontSize: '14px',
            }}
          />
          <div
            style={{
              textAlign: 'right',
              marginTop: '0.5rem',
              fontSize: '12px',
              color: charCount[objIndex] == 300 ? 'red' : 'gray',
            }}
          >
            {charCount[objIndex] ?? 0}/300
          </div>
          <div id="meeting-topics-add-topics-group">
            <div
              id={`meeting-topics-add-topics-group-${obj.isGeneralObjective}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor: 'white',
                marginTop: '5px',
                gap: '0.5rem',
              }}
            >
              <div
                style={{
                  width: 'fit-content',
                  height: '0.6rem',
                  fontSize: '12px',
                  alignSelf: 'flex-end',
                  marginBottom: '8px',
                  marginTop: '1rem',
                  backgroundColor: '#ED522E',
                }}
                className="add-btn"
                data-testid={`blocker-${objIndex}`}
                onClick={() => {
                  if (
                    invalidateButtons === false &&
                    !(textInput[objIndex] === undefined || textInput[objIndex].trim() === '')
                  ) {
                    setInvalidateButtons(true)
                    manageTopic(
                      1,
                      'add',
                      {
                        description: textInput[objIndex],
                        objId: obj.objId,
                        topicType: 'blocker',
                        tags: obj.tags.map((tag) => ({ ...tag, inherit: true })),
                      },
                      objIndex,
                    )
                  }
                }}
              >
                <span>{t('meetingTopicWizStep.blocker')}</span>
              </div>
              <div
                data-testid={`guidance-${objIndex}`}
                style={{
                  width: 'fit-content',
                  height: '0.6rem',
                  fontSize: '12px',
                  alignSelf: 'flex-end',
                  marginBottom: '8px',
                  marginTop: '1rem',
                }}
                className="add-btn"
                onClick={() => {
                  if (
                    invalidateButtons === false &&
                    !(textInput[objIndex] === undefined || textInput[objIndex].trim() === '')
                  ) {
                    setInvalidateButtons(true)
                    manageTopic(
                      2,
                      'add',
                      {
                        description: textInput[objIndex],
                        objId: obj.objId,
                        topicType: 'decision',
                        tags: obj.tags.map((tag) => ({ ...tag, inherit: true })),
                      },
                      objIndex,
                    )
                  }
                }}
              >
                <span>{t('meetingTopicWizStep.guidance')}</span>
              </div>
              <div
                data-testid={`update-${objIndex}`}
                style={{
                  width: 'fit-content',
                  height: '0.6rem',
                  fontSize: '12px',
                  alignSelf: 'flex-end',
                  marginBottom: '8px',
                  marginTop: '1rem',
                  backgroundColor: '#44546A',
                }}
                className="add-btn"
                onClick={() => {
                  if (
                    invalidateButtons === false &&
                    !(textInput[objIndex] === undefined || textInput[objIndex].trim() === '')
                  ) {
                    setInvalidateButtons(true)
                    manageTopic(
                      3,
                      'add',
                      {
                        description: textInput[objIndex],
                        objId: obj.objId,
                        topicType: 'update',
                        tags: obj.tags.map((tag) => ({ ...tag, inherit: true })),
                      },
                      objIndex,
                    )
                  }
                }}
              >
                <span>{t('meetingTopicWizStep.update')}</span>
              </div>
            </div>
          </div>

          {/* {(obj?.blockers?.length > 0 ||
          obj?.decisions.length > 0 ||
          obj?.updates?.length > 0) && (
          <span
            style={{ color: '#4472C4', cursor: 'pointer' }}
            onClick={() => {
              setMeetingTopicsToClose(['blockers', 'decisions', 'updates'])
              setObjId(obj.objId)
              resolveAllMeetingTopics(obj.objId)
            }}
          >{t('meetingTopicWizStep.closeAllTopics')}</span>} */}
        </div>

        <div
          className="bgu-column"
          style={{ display: 'flex', flexDirection: 'column', width: '22%' }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '1rem',
              alignItems: 'center',
            }}
            id={`meeting-topics-obj-blocker-header-${obj.isGeneralObjective}`}
          >
            <b id="blockers-faced">{t('meetingTopicWizStep.blockerTitle')}</b>
            {objIndex === 0 && (
              <div class="fitted-button blue tooltip" type="button">
                <span class="material-symbols-outlined icon-size">info</span>
                <span class="tooltiptext">{t('Common:infoDefinition.blockerDefinition')}</span>
              </div>
            )}
          </span>
          <hr width="100%" color="#ED522E" style={{ height: '2px', margin: '0.5rem 0' }} />
          {/* <hr style={{ height: "2rem", borderWidth: "0", color: "red", backgroundColor: "gray" }}/ ></hr> */}

          {obj.blockers.map((blk, blkIndex) => (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  className="actions-description-cell" //style={{height: '5rem', overflow: 'auto'}}
                  onClick={() => {
                    setTopicType(blk.topicType)
                    setUmtId(blk.umtId)
                    setIsTopicModalOpen(true)
                  }}
                >
                  <span>{blk.description}</span>
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: '1rem',
                    columnGap: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <div
                    class="fitted-button blue"
                    type="button"
                    onClick={(e) => createFlagStatus(blk, 'flag', blkIndex)}
                  >
                    <PriorityFlagIcon isPriority={blk.isPriority} isIconSize={true} />
                  </div>
                  <div
                    data-testid={`deleteBlocker-${blk.mtId}-${blkIndex}`}
                    class="fitted-button checklist-done"
                  >
                    <span
                      className={`material-symbols-outlined icon-size  ${blk.isOpen ? 'checklist-done-grey' : 'checklist-done-green'}`}
                      onClick={() => {
                        setSelectedMeetingTopic(blk)
                        // resolveSingleMeetingTopic(blk)
                        setIsResolveMeetingTopicsModalOpen(true)
                      }}
                    >
                      done
                    </span>
                  </div>
                  <span
                    class="material-symbols-outlined icon-size"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedMeetingTopic(blk)
                      setIsManageTagsModalOpen(true)
                    }}
                  >
                    sell
                  </span>
                  {isLoading[blk.mtId] && isLoading.type == 1 ? (
                    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                      <div
                        className="loading-spinner"
                        style={{ width: '1rem', height: '1rem', marginLeft: '1rem' }}
                      ></div>
                    </div>
                  ) : (
                    <BGUDropdownMenu
                      onClickEdit={() => {
                        setSelectedMeetingTopic(blk)
                        setIsEditMeetingTopicsModalOpen(true)
                      }}
                      onClickUpload={(blk) => {
                        setSelectedMeetingTopic(blk)
                        handleUploadMeetingDeckClick(blk.mtId, 'blockers')
                      }}
                      showMeetingDeck={showMeetingDeck}
                      onChangePpt={onChangePpt}
                      selectedMeetingTopic={selectedMeetingTopic}
                      meetingTopic={blk}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: '0.5rem 0 1rem 0',
                  fontSize: '14px',
                  color: 'gray',
                }}
              >
                <div>{shortenName(blk.topicOwnerName)}</div>
                <div>{dayMonthFilter(blk.openedTime)}</div>
              </div>
              <hr width="100%" color="gray" style={{ height: '1px', margin: '0 0 1rem 0' }} />
            </>
          ))}
        </div>

        <div
          className="bgu-column"
          style={{ display: 'flex', flexDirection: 'column', width: '22%' }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '1rem',
              alignItems: 'center',
            }}
          >
            <b>{t('meetingTopicWizStep.guidanceTitle')}</b>
            {objIndex === 0 && (
              <div class="fitted-button blue tooltip" type="button">
                <span class="material-symbols-outlined icon-size">info</span>
                <span class="tooltiptext">{t('Common:infoDefinition.guidanceDefinition')}</span>
              </div>
            )}
          </span>
          <hr width="100%" color="#0926D5" style={{ height: '2px', margin: '0.5rem 0' }} />
          {obj.decisions.map((dcs, dcsIndex) => (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  className="actions-description-cell" //style={{height: '5rem', overflow: 'auto'}}
                  onClick={() => {
                    setTopicType(dcs.topicType)
                    setUmtId(dcs.umtId)
                    setIsTopicModalOpen(true)
                  }}
                >
                  <span>{dcs.description}</span>
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: '1rem',
                    columnGap: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <div
                    class="fitted-button blue"
                    type="button"
                    onClick={(e) => createFlagStatus(dcs, 'flag', dcsIndex)}
                  >
                    <PriorityFlagIcon isPriority={dcs.isPriority} isIconSize={true} />
                  </div>
                  <div
                    data-testid={`deleteGuidance-${dcs.mtId}-${dcsIndex}`}
                    class="fitted-button checklist-done"
                  >
                    <span
                      className={`material-symbols-outlined icon-size  ${dcs.isOpen ? 'checklist-done-grey' : 'checklist-done-green'}`}
                      onClick={() => {
                        setSelectedMeetingTopic(dcs)
                        // resolveSingleMeetingTopic(dcs)
                        setIsResolveMeetingTopicsModalOpen(true)
                      }}
                    >
                      done
                    </span>
                  </div>
                  <span
                    class="material-symbols-outlined icon-size"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedMeetingTopic(dcs)
                      setIsManageTagsModalOpen(true)
                    }}
                  >
                    sell
                  </span>

                  {isLoading[dcs.mtId] && isLoading.type === 2 ? (
                    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                      <div
                        className="loading-spinner"
                        style={{ width: '1rem', height: '1rem', marginLeft: '1rem' }}
                      ></div>
                    </div>
                  ) : (
                    <BGUDropdownMenu
                      onClickEdit={() => {
                        setSelectedMeetingTopic(dcs)
                        setIsEditMeetingTopicsModalOpen(true)
                      }}
                      onClickUpload={(dcs) => {
                        setSelectedMeetingTopic(dcs)
                        handleUploadMeetingDeckClick(dcs.mtId, 'decisions')
                      }}
                      showMeetingDeck={showMeetingDeck}
                      meetingDeckUploadRefsDecisions={meetingDeckUploadRefsDecisions}
                      onChangePpt={onChangePpt}
                      selectedMeetingTopic={selectedMeetingTopic}
                      meetingTopic={dcs}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: '0.5rem 0 1rem 0',
                  fontSize: '14px',
                  color: 'gray',
                }}
              >
                <div>{shortenName(dcs.topicOwnerName)}</div>
                <div>{dayMonthFilter(dcs.openedTime)}</div>
              </div>
              <hr width="100%" color="gray" style={{ height: '1px', margin: '0 0 1rem 0' }} />
            </>
          ))}
        </div>
        <div
          className="bgu-column"
          style={{ display: 'flex', flexDirection: 'column', width: '22%' }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '0.5rem',
              alignItems: 'center',
            }}
          >
            <b>{t('meetingTopicWizStep.updateTitle')}</b>
            {objIndex === 0 && (
              <div class="fitted-button blue tooltip" type="button">
                <span class="material-symbols-outlined icon-size">info</span>
                <span class="tooltiptext">{t('Common:infoDefinition.updateDefinition')}</span>
              </div>
            )}
          </span>
          <hr width="100%" color="#44546A" style={{ height: '2px', margin: '0.5rem 0' }} />
          {/* Commented out for now while we analyze how we want to close update by objective or all owned by an user*/}
          {/* {obj?.updates?.length > 0 && <span style={{ color: "#4472C4", padding: "0.5rem 0 0.5rem 0", cursor: "pointer" }} onClick={() => {
          setMeetingTopicsToClose(["updates"])
          setObjId(obj.objId)
          setIsResolveMeetingTopicsModalOpen(true)
        }}>{t('meetingTopicWizStep.closeAllUpdates')}</span>} */}
          {obj.updates.map((upd, updIndex) => (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  className="actions-description-cell" //style={{height: '5rem', overflow: 'auto'}}
                  onClick={() => {
                    setTopicType(upd.topicType)
                    setUmtId(upd.umtId)
                    setIsTopicModalOpen(true)
                  }}
                >
                  <span>{upd.description}</span>
                </span>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: '1rem',
                    columnGap: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <div
                    class="fitted-button blue"
                    type="button"
                    onClick={(e) => createFlagStatus(upd, 'flag', updIndex)}
                  >
                    <PriorityFlagIcon isPriority={upd.isPriority} isIconSize={true} />
                  </div>
                  <div
                    data-testid={`deleteUpdate-${upd.mtId}-${updIndex}`}
                    class="fitted-button checklist-done"
                  >
                    <span
                      className={`material-symbols-outlined icon-size  ${upd.isOpen ? 'checklist-done-grey' : 'checklist-done-green'}`}
                      onClick={() => {
                        setSelectedMeetingTopic(upd)
                        // resolveSingleMeetingTopic(upd)
                        setIsResolveMeetingTopicsModalOpen(true)
                      }}
                    >
                      done
                    </span>
                  </div>
                  <span
                    class="material-symbols-outlined icon-size"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedMeetingTopic(upd)
                      setIsManageTagsModalOpen(true)
                    }}
                  >
                    sell
                  </span>
                  {isLoading[upd.mtId] && isLoading.type == 3 ? (
                    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                      <div
                        className="loading-spinner"
                        style={{ width: '1rem', height: '1rem', marginLeft: '1rem' }}
                      ></div>
                    </div>
                  ) : (
                    <BGUDropdownMenu
                      onClickEdit={() => {
                        setSelectedMeetingTopic(upd)
                        setIsEditMeetingTopicsModalOpen(true)
                      }}
                      onClickUpload={(upd) => {
                        setSelectedMeetingTopic(upd)
                        handleUploadMeetingDeckClick(upd.mtId, 'updates')
                      }}
                      showMeetingDeck={showMeetingDeck}
                      meetingDeckUploadRefsDecisions={meetingDeckUploadRefsUpdates}
                      onChangePpt={onChangePpt}
                      selectedMeetingTopic={selectedMeetingTopic}
                      meetingTopic={upd}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: '0.5rem 0 1rem 0',
                  fontSize: '14px',
                  color: 'gray',
                }}
              >
                <div>{shortenName(upd.topicOwnerName)}</div>
                <div>{dayMonthFilter(upd.openedTime)}</div>
              </div>
              <hr width="100%" color="gray" style={{ height: '1px', margin: '0 0 1rem 0' }} />
            </>
          ))}
        </div>
      </div>
    )
  }

  const startTutorial = (shouldStart) => {
    if (state.isTutorialOff) return
    if (!state.joyRide.isToursActive[2]) return
    console.log('startTutorial: ', shouldStart)
    dispatch({
      type: 'UPDATE_TOUR_STEP_NAME',
      stepName: 'meetingTopics',
    })
    dispatch({
      type: 'UPDATE_TOUR_CALLBACK',
      customJoyRideCallBackFn: handleJoyrideCallback,
    })
    dispatch({
      type: 'UPDATE_TOUR_STEP_INDEX',
      tourStepIndex: 2,
    })
    dispatch({
      type: 'SET_IS_TOUR_ACTIVE',
      isTourActive: true,
      tourIndex: 2,
    })
  }

  const handleJoyrideCallback = (data) => {
    console.log('handleJoyrideCallback actions: ', data)
    const { type, status, index, action } = data
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      dispatch({
        type: 'UPDATE_TOUR_INNER_STEP_INDEX',
        tourInnerStepIndex: 0,
      })
      dispatch({
        type: 'SET_IS_TOUR_ACTIVE',
        isTourActive: false,
        tourIndex: 2,
      })
    } else if (type === EVENTS.STEP_AFTER) {
      let ind = index + (action === 'prev' ? -1 : 1)
      console.log('new ind: ', ind)
      dispatch({
        type: 'UPDATE_TOUR_INNER_STEP_INDEX',
        tourInnerStepIndex: ind,
      })
    }
  }
  return (
    <>
      {topicsIsLoading && hasLoadedInitially ? (
        <Modal
          title={t('meetingTopicWizStep.updatingModalTitle')}
          includeCloseIcon={false}
          width={'50vw'}
        >
          <br />
          <br />
          <br />
          <InPageLoader inComponent={true} />
          <br />
          <br />
          <br />
        </Modal>
      ) : null}

      {isResolveMeetingTopicsModalOpen && (selectedMeetingTopic || meetingTopicsToClose) && (
        <ResolveMeetingTopicModal
          isModalOpen={isResolveMeetingTopicsModalOpen}
          closeModal={() => {
            setIsResolveMeetingTopicsModalOpen(false)
            setMeetingTopicsToClose(null)
            setObjId(null)
          }}
          meetingTopic={selectedMeetingTopic}
          meetingTopicsToClose={meetingTopicsToClose}
          objId={objId}
          setReloadTracker={setReloadTracker}
          reloadTracker={reloadTracker}
          triggerLoadingModal={() => setTopicsIsLoading(true)}
        />
      )}
      {isEditMeetingTopicsModalOpen && selectedMeetingTopic && (
        <EditMeetingTopicModal
          isModalOpen={isEditMeetingTopicsModalOpen}
          closeModal={() => {
            setIsEditMeetingTopicsModalOpen(false)

            if (setReloadTracker !== null) {
              setTimeout(() => {
                reload()
              }, 500)
            }
          }}
          meetingTopic={selectedMeetingTopic}
        />
      )}
      {isObjDetailsOpen && (
        <ObjectiveDetailsModal
          isModalOpen={isObjDetailsOpen}
          closeModal={() => {
            setIsObjDetailsOpen(false)
          }}
          objIdIn={objId}
          showInitiativesId={productSettings}
        />
      )}
      {console.log('selectedMeetingTopic', selectedMeetingTopic)}
      {isKPIModalOpen && (
        <KPIModal
          objId={objId}
          handleCancel={() => {
            setIsKPIModalOpen(false)
          }}
          reloadBackground={reload}
          objOwnerId={objOwnerId}
        />
      )}

      {isMultiAddTopicsModalOpen && (
        <MultiAddTextModal
          isModalOpen={isMultiAddTopicsModalOpen}
          type="meetingTopic"
          setGeneratedText={setGeneratedTopicsWithIncludedTrue}
          closeModal={() => {
            setisMultiAddTopicsModalOpen(false)
            setRecentlyClosedMultiAddModal('text')
          }}
        />
      )}

      {isMultiAddTopicsUsingAudioModalOpen && (
        <MultiAddUsingAudioModal
          isModalOpen={isMultiAddTopicsUsingAudioModalOpen}
          type="meetingTopic"
          setGeneratedText={setGeneratedTopicsWithIncludedTrue}
          closeModal={() => {
            setisMultiAddTopicsUsingAudioModalOpen(false)
            setRecentlyClosedMultiAddModal('audio')
          }}
        />
      )}

      {isReviewGeneratedTopicsModalOpen && (
        <ReviewGeneratedTopicsModal
          isModalOpen={isReviewGeneratedTopicsModalOpen}
          closeModal={() => {
            setIsReviewGeneratedTopicsModalOpen(false)
            if (setReloadTracker !== null) {
              setReloadTracker((reloadTracker) => !reloadTracker)
            }
            setObjId(null)
            setGeneratedTopics(null)
          }}
          handleTryAgain={() => {
            setGeneratedTopics(null)
            setIsReviewGeneratedTopicsModalOpen(false)
            recentlyClosedMultiAddModal === 'text'
              ? setisMultiAddTopicsModalOpen(true)
              : setisMultiAddTopicsUsingAudioModalOpen(true)
          }}
          generatedTopics={generatedTopics}
          objId={objId}
        />
      )}

      {isTopicModalOpen === true && umtId && topicType && (
        <CardModal
          isModalOpen={isTopicModalOpen}
          closeModal={() => {
            reload()
            setIsTopicModalOpen(false)
            setUmtId(null)
            setTopicType(null)
          }}
          umtId={umtId}
          topicType={topicType}
          hasTopicType={true}
          fromHomePage={false}
        />
      )}
      {wizardDateModalOpen === true && (
        <WizardDateModal
          meetingTopicData={meetingTopicData}
          objId={objId}
          closeModal={() => {
            setIsWizardDateModalOpen(false)
          }}
          onSave={dateModalSave}
        />
      )}

      {isManageTagsModalOpen && (
        <TagsManagementModal
          closeModal={() => {
            reload()
            setIsManageTagsModalOpen(false)
          }}
          meetingtopic={true}
          meetingtopicId={selectedMeetingTopic.mtId}
        />
      )}

      <StepHeader>{t('meetingTopicWizStep.title')}</StepHeader>
      {(isDelegateTl || allowL2UsersSeeAllObjsUnderCxo) && (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '7rem', width: '75%' }}>
            {allowL2UsersSeeAllObjsUnderCxo && (
              <div style={{ display: 'flex', flexDirection: 'column', columnGap: '1rem' }}>
                <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      width: 'fitContent',
                      height: 'fitContent',
                      borderRadius: '4px',
                      color: 'white',
                      padding: '0.3rem 1rem 0.3rem 1rem',
                      backgroundColor: showL3Objectives ? '#4472c4' : '#a6a6a6',
                    }}
                    onClick={() => {
                      handleL2L3Toggle(true)
                    }}
                  >
                    {t('Common:commonButtons.L3')}
                  </div>

                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      width: 'fitContent',
                      height: 'fitContent',
                      borderRadius: '4px',
                      color: 'white',
                      padding: '0.3rem 1rem 0.3rem 1rem',
                      backgroundColor: !showL3Objectives ? '#4472c4' : '#a6a6a6',
                    }}
                    onClick={() => {
                      handleL2L3Toggle(false)
                    }}
                  >
                    {t('Common:commonButtons.L2')}
                  </div>
                </div>
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2rem' }}>
              <div style={{ whiteSpace: 'nowrap', fontSize: '20px', marginRight: '1rem' }}>
                <b>{t('meetingTopicWizStep.ownerLabel')}</b>
              </div>
              <SelectSingleField
                placeholder={t('formPlaceHolder.owner')}
                name="selectOwner"
                value={teamLeadersData?.employees?.filter(
                  (person) => parseInt(person.eId) == parseInt(eId),
                )}
                options={teamLeadersData?.employees}
                onChange={handleOwnerChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.eId}
                width={'100%'}
              />
            </div>
          </div>
        </>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
        {/* {!isLoading && ( */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '0.5rem',
            alignItems: 'baseline',
          }}
        >
          <label for="teamLeadersFilter">
            <b>{t('Common:filter.selectObjectives')}:</b>
          </label>
          <div data-testid="multi-options-2" style={{ width: '25rem' }}>
            <Select
              value={objectiveFilterValue}
              isMulti={true}
              onChange={handleChangeObjectiveFilter}
              maxMenuHeight={100}
              getOptionLabel={(option) =>
                productSettings && option.isGeneralObjective === false
                  ? `${(option?.initiativeId ?? 0).toString().padStart(5, '0')} - ${
                      option.description
                    }`
                  : `${option.description}`
              }
              getOptionValue={(option) => option?.objId}
              options={initiativesData}
            />
          </div>
          <div
            style={{ alignSelf: 'end' }}
            class="fitted-button blue"
            onClick={() => {
              applyFilters()
            }}
          >
            <span className="extra-lateral-spacing"> {t('Common:commonButtons.apply')}</span>
          </div>
        </div>
        {/* )} */}
      </div>
      {objToShow?.length === 0 ? (
        <div>{t('meetingTopicWizStep.noObjectivesMsg')}</div>
      ) : (
        <CardContainer>
          <div style={{ display: 'flex', flexDirection: 'column', margin: '0 1rem' }}>
            <b>{t('meetingTopicWizStep.objectives')}</b>
          </div>
          {objToShow
            ?.filter((obj) => obj.isObjOriginalOwner === true)
            ?.map((obj, objIndex) => meetingTopicsBody(obj, objIndex))}

          {objToShow?.filter((obj) => obj.isObjOriginalOwner === false)?.length > 0 && (
            <>
              <span
                style={{
                  margin: '0',
                  fontSize: '18px',
                  fontWeight: '700',
                  lineHeight: '24px',
                  display: 'flex',
                  alignSelf: 'center',
                  marginTop: '2rem',
                  color: '#9a9a9a',
                  marginBottom: '1rem',
                  textTransform: 'uppercase',
                }}
              >
                {t('Common:sharedObjective.objsSharedWithMe')}
              </span>
              {objToShow
                ?.filter((obj) => obj.isObjOriginalOwner === false)
                ?.map((obj, objIndex) => meetingTopicsBody(obj, objIndex))}
            </>
          )}
        </CardContainer>
      )}
    </>
  )
}

export default MeetingTopics
