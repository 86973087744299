import React from 'react'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import SelectSingleField from '../../components/CustomMultipleSelect/singleOption'
import { useTranslation } from 'react-i18next'
import ApplicationLayout from '../../components/ApplicationLayout'
import InPageLoader from '../../components/InPageLoader'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import Toast from '../../common/toast'
import './style.scss'
import Select from 'react-select'
import ConfigureKPIDashboardModal from '../../components/KPI/ConfigureKPIDashboardModal'
import KpiReportNewTable from './KpiReportNewTable'
import PieCharCustomizedLabel from '../../components/PieCharCustomizedLabel'
import ExcelUploadButton from '../../components/ExcelUploadButton'
import KpiReviewModal from '../../components/KpiReviewModal'

const KpiReportByLeaderV2 = ({ hideTitle = false, selectedDaysForShowingWorseKpis = null }) => {
  const { t } = useTranslation(['Common', 'Dashboard'])

  const [isLoading, setIsLoading] = React.useState(false)
  const [filtersSectionOpen, setFiltersSectionOpen] = React.useState(false)
  const [isConfigureKPIDashboardModalOpen, setIsConfigureKPIDashboardModalOpen] =
    React.useState(false)
  const [kpiObjList, setKpiObjList] = React.useState([])
  const [expandAll, setExpandAll] = React.useState(false)
  const [filteredKpiObjList, setFilteredKpiObjList] = React.useState([])

  const [objectiveFilterValue, setObjectiveFilterValue] = React.useState([])
  const [objectiveOwenerFilterValue, setObjectiveOwnerFilterValue] = React.useState(null)
  const [colorFilterValue, setColorFilterValue] = React.useState([])
  const [tagsFilterValue, setTagsFilterValue] = React.useState([])
  const [tagsFilterOptions, setTagsFilterOptions] = React.useState([])
  const [objectiveFilterOptions, setObjectiveFilterOptions] = React.useState([])
  const [kpiOwnerOptions, setKpiOwnerOptions] = React.useState([])
  const [kpiOwnerValue, setKpiOwnerValue] = React.useState([])
  const [objectiveOwnerFilterOptions, setObjectiveOwnerFilterOptions] = React.useState([])
  const [kpiDashboardListDropdown, setKpiDashboardListDropdown] = React.useState([])
  const [kpiDashBoardSelected, setKpiDashBoardSelected] = React.useState(null)

  const [shouldShowCompleted, setShouldShowCompleted] = React.useState(false)
  const canManageKpiDashboards = getConsolidatedConfigSettings('manage_kpi_dashboards') === true

  const [pieChartData, setPieChartData] = React.useState([])
  const [chartSectionOpen, setChartSectionOpen] = React.useState(false)

  const [isKpiReviewModalOpen, setIsKpiReviewModalOpen] = React.useState(false)
  const [kpiDataState, setKpiDataState] = React.useState(null)
  const [showActionDropdown, setShowActionDropdown] = React.useState(false)
  const actionDropdownRef = React.useRef(null)
  const [isUploadingExcel, setIsUploadingExcel] = React.useState(false)

  const showGovernanceMgmntFeatures =
    getConsolidatedConfigSettings('show_governance_mgmt_features') === true

  const [defaultKpiDashboardId, setDefaultKpiDashboardId] = React.useState(
    getConsolidatedConfigSettings('default_kpi_dashboard_id'),
  )

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (actionDropdownRef.current && !actionDropdownRef.current.contains(event.target)) {
        setShowActionDropdown(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    // Create and execute async function to handle initialization
    ;(async () => {
      await getUsersKPIDashboardAccessList()
      await getL2KpiReport()
    })()

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // React.useEffect(() => {
  //   // Handle filtering logic for "My KPI Dashboards" when it's selected
  //   if (
  //     kpiDashBoardSelected?.kpiDashboardId === -1 &&
  //     !showGovernanceMgmntFeatures &&
  //     kpiObjList.length > 0
  //   ) {
  //     const loggedInUserName = localStorage.getItem('tfmnm')
  //     const loggedInUser = kpiOwnerOptions.find((owner) => owner.name === loggedInUserName)

  //     if (loggedInUser) {
  //       const filteredData = filterOutKpisMutiFiltersRecursively(
  //         kpiObjList,
  //         [loggedInUser.eId],
  //         'ownerEId',
  //       )
  //       setKpiObjList(filteredData)
  //       setFilteredKpiObjList(filteredData)
  //     }
  //   }
  // }, [kpiDashBoardSelected, kpiOwnerOptions, kpiObjList, showGovernanceMgmntFeatures])

  React.useEffect(() => {
    //clear all other filters when kpi dashboard is selected
    if (kpiDashBoardSelected) {
      getKpiDashboardData()
    } else if (!defaultKpiDashboardId) {
      getL2KpiReport()
    }
  }, [kpiDashBoardSelected, defaultKpiDashboardId])

  async function getUsersKPIDashboardAccessList() {
    setIsLoading(true)
    try {
      const data = await getHttpRequest(`/get_my_kpi_dashboards_list`)
      if (data.kpiDashboards) {
        setKpiDashboardListDropdown(data.kpiDashboards)
        if (defaultKpiDashboardId) {
          const defaultKpiDashboard = data.kpiDashboards.find(
            (kpiDashboard) => kpiDashboard.kpiDashboardId === defaultKpiDashboardId,
          )
          if (defaultKpiDashboard) {
            resetFilter()
            setKpiDashBoardSelected(defaultKpiDashboard)
          }
        }
      }
    } catch (e) {
      Toast.fire({
        icon: 'error',
      })
    }
    setIsLoading(false)
  }

  async function getKpiDashboardData() {
    setIsLoading(true)
    try {
      const data = await getHttpRequest(
        `/get_kpi_dashboard_data/${kpiDashBoardSelected.kpiDashboardId}`,
      )
      setKpiObjList(data.objKpiList)
      setFilteredKpiObjList(data.objKpiList)
      setExpandAll(true)
    } catch (e) {
      Toast.fire({
        icon: 'error',
      })
    }
    setIsLoading(false)
  }

  const getL2KpiReport = async () => {
    if (defaultKpiDashboardId) {
      setDefaultKpiDashboardId(null)
      return
    }
    try {
      setIsLoading(true)
      let response
      response = await getHttpRequest(`/get_kpi_report_v2`)
      setIsLoading(false)
      setKpiObjList(response.objKpiList)
      setFilteredKpiObjList(response.objKpiList)
      setExpandAll(true)
      let tags = computeTagsListRecursively(response.objKpiList, [])
      setTagsFilterOptions(tags)
      let kpiOwners = computeKpiOwnerRecursively(response.objKpiList, [])
      setKpiOwnerOptions(kpiOwners)
      let objectives = computeObjectivesRecursively(response.objKpiList, [])
      setObjectiveFilterOptions(objectives)
      let objectiveOwners = computeObjectiveOwenerListRecursively(response.objKpiList, [])
      setObjectiveOwnerFilterOptions(objectiveOwners)
    } catch (error) {
      setIsLoading(false)
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong! Please try again later.',
      })
    }
  }

  const colorFilterOptions = [
    { value: 'red', label: t('Common:ragColors.red') },
    { value: 'yellow', label: t('Common:ragColors.amber') },
    { value: 'green', label: t('Common:ragColors.green') },
    { value: 'grey', label: t('Common:ragColors.grey') },
  ]

  const handleChangeObjectiveFilter = (value) => {
    setObjectiveFilterValue(value)
  }

  const handleChangeObjectiveOwnerFilter = (value) => {
    setObjectiveOwnerFilterValue(value?.eId)
  }

  const handleChangeColorFilter = (value) => {
    setColorFilterValue(value)
  }

  const handleChangeTagsFilter = (value) => {
    if (value === null) {
      setTagsFilterValue([])
      return
    }
    setTagsFilterValue([value])
  }

  const handleChangeKpiOwnerFilter = (selectedOptions) => {
    setKpiOwnerValue(selectedOptions)
  }

  const computeTagsListRecursively = (objList, tagsList) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      obj.objective.tags.forEach((tag) => {
        if (!tagsList.find((t) => t.tagId === tag.tagId)) {
          tagsList.push(tag)
        }
      })
      computeTagsListRecursively(obj.objList, tagsList)
    }
    return tagsList
  }

  const computeKpiOwnerRecursively = (objList, kpiOwnerList) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      obj.kpiList.forEach((kpi) => {
        if (!kpiOwnerList.find((t) => t.eId === kpi.ownerEId)) {
          kpiOwnerList.push({
            name: kpi.ownerName,
            eId: kpi.ownerEId,
          })
        }
      })
      computeKpiOwnerRecursively(obj.objList, kpiOwnerList)
    }
    return kpiOwnerList
  }

  const computeObjectivesRecursively = (objList, objectiveList) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      if (obj.objective.id > 0) {
        objectiveList.push(obj.objective)
      }

      computeObjectivesRecursively(obj.objList, objectiveList)
    }
    return objectiveList
  }

  const computeObjectiveOwenerListRecursively = (objList, objectiveOwenerList) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      if (!objectiveOwenerList.find((t) => t.eId === obj.objective.ownerId)) {
        if (obj.objective.ownerId > 0) {
          objectiveOwenerList.push({
            name: obj.objective.ownerName,
            eId: obj.objective.ownerId,
          })
        }
      }
      computeObjectiveOwenerListRecursively(obj.objList, objectiveOwenerList)
    }
    return objectiveOwenerList
  }

  React.useEffect(() => {
    let res = JSON.parse(JSON.stringify(kpiObjList))
    if (colorFilterValue.length > 0) {
      let mappedColorFilterValue = colorFilterValue.map((color) => {
        if (color.value === 'red') {
          return 2
        } else if (color.value === 'green') {
          return 0
        } else if (color.value === 'yellow') {
          return 1
        } else if (color.value === 'grey') {
          return -1
        }
      })
      res = filterOutKpisMutiFiltersRecursively(res, mappedColorFilterValue, 'ragStatus')
    }
    if (tagsFilterValue.length > 0) {
      res = filterOutObjsRecursivelyByTags(res, tagsFilterValue[0])
    }
    if (kpiOwnerValue.length > 0) {
      res = filterOutKpisMutiFiltersRecursively(
        res,
        kpiOwnerValue.map((owner) => owner.eId),
        'ownerEId',
      )
    }
    if (shouldShowCompleted) {
      res = filterOutKpisRecursively(res, true, 'isCompleted')
    } else {
      res = filterOutKpisRecursively(res, false, 'isCompleted')
    }
    if (objectiveOwenerFilterValue) {
      res = filterOutObjsRecursivelyByOwner(res, objectiveOwenerFilterValue)
    }
    if (objectiveFilterValue.length > 0) {
      res = filterOutObjsRecursivleyByObjective(res, objectiveFilterValue)
    }
    setFilteredKpiObjList(res)
    const pieChartData = buildPieChartDataRecursively(res)
    if (pieChartData.red + pieChartData.green + pieChartData.yellow + pieChartData.grey === 0) {
      setPieChartData([])
      return
    }

    setPieChartData([
      {
        name: 'Red',
        value: pieChartData.red,
      },
      {
        name: 'Yellow',
        value: pieChartData.yellow,
      },
      {
        name: 'Green',
        value: pieChartData.green,
      },
      {
        name: 'Grey',
        value: pieChartData.grey,
      },
    ])
  }, [
    colorFilterValue,
    tagsFilterValue,
    kpiOwnerValue,
    objectiveFilterValue,
    objectiveOwenerFilterValue,
    shouldShowCompleted,
    kpiObjList,
  ])

  const buildPieChartDataRecursively = (objList) => {
    if (!objList || objList.length === 0) {
      return {
        red: 0,
        yellow: 0,
        green: 0,
        grey: 0,
      }
    }

    let red = 0
    let yellow = 0
    let green = 0
    let grey = 0

    for (let obj of objList) {
      if (obj.kpiList) {
        // Use reduce instead of forEach to avoid closure issues
        const kpiCounts = obj.kpiList.reduce(
          (acc, kpi) => {
            if (kpi.ragStatus === 2) {
              acc.red += 1
            } else if (kpi.ragStatus === 1) {
              acc.yellow += 1
            } else if (kpi.ragStatus === 0) {
              acc.green += 1
            } else if (kpi.ragStatus === -1) {
              acc.grey += 1
            }
            return acc
          },
          { red: 0, yellow: 0, green: 0, grey: 0 },
        )

        red += kpiCounts.red
        yellow += kpiCounts.yellow
        green += kpiCounts.green
        grey += kpiCounts.grey
      }

      if (obj.objList) {
        const res = buildPieChartDataRecursively(obj.objList)
        red += res.red
        yellow += res.yellow
        green += res.green
        grey += res.grey
      }
    }

    return {
      red,
      yellow,
      green,
      grey,
    }
  }

  const filterOutKpisRecursively = (objList, value, key) => {
    if (objList.length === 0) {
      return []
    }
    for (let obj of objList) {
      obj.kpiList = obj.kpiList.filter((kpi) => kpi[key] == value)
      filterOutKpisRecursively(obj.objList, value, key)
    }
    return objList
  }

  const filterOutKpisMutiFiltersRecursively = (objList, filterValueArray, key) => {
    if (objList.length === 0) {
      return []
    }

    const filteredList = objList.flatMap((obj) => {
      // Filter KPIs based on the filter criteria
      obj.kpiList = obj.kpiList.filter((kpi) => filterValueArray.includes(kpi[key]))

      // Recursively filter child objectives
      const filteredChildren = filterOutKpisMutiFiltersRecursively(
        obj.objList,
        filterValueArray,
        key,
      )
      obj.objList = filteredChildren

      // Only keep this objective if it has KPIs or its children have KPIs
      if (obj.kpiList.length > 0 || filteredChildren.length > 0) {
        return [
          {
            ...obj,
            objList: filteredChildren,
          },
        ]
      }
      return []
    })

    return filteredList
  }

  const filterOutObjsRecursivelyByTags = (objList, tag) => {
    if (!objList || objList.length === 0) {
      return []
    }

    const filteredList = objList.flatMap((obj) => {
      const matchesTag = obj.objective.tags.some((t) => t.tagId === tag.tagId)

      if (matchesTag) {
        //filter out children has at least 1 kpi
        const filteredChildren = obj.objList.filter((child) => child.kpiList.length > 0)
        // Only return if this objective or its children have KPIs
        if (obj.kpiList.length > 0 || filteredChildren.length > 0) {
          return {
            ...obj,
            objList: filteredChildren,
          }
        }
        return []
      }

      const filteredChildren = filterOutObjsRecursivelyByTags(obj.objList, tag)

      if (filteredChildren.length > 0) {
        obj.kpiList = []
        return {
          ...obj,
          objList: filteredChildren,
        }
      }

      return []
    })

    return filteredList
  }

  const filterOutObjsRecursivelyByOwner = (objList, ownerId) => {
    if (!objList || objList.length === 0) {
      return []
    }

    const filteredList = objList.flatMap((obj) => {
      const matchesOwner = parseInt(obj.objective.ownerId) === parseInt(ownerId)

      if (matchesOwner) {
        const filteredChildren = obj.objList.filter((child) => child.kpiList.length > 0)
        // Only return if this objective or its children have KPIs
        if (obj.kpiList.length > 0 || filteredChildren.length > 0) {
          return {
            ...obj,
            objList: filteredChildren,
          }
        }
        return []
      }

      const filteredChildren = filterOutObjsRecursivelyByOwner(obj.objList, ownerId)

      if (filteredChildren.length > 0) {
        obj.kpiList = []
        return {
          ...obj,
          objList: filteredChildren,
        }
      }
      return []
    })

    return filteredList
  }

  const filterOutObjsRecursivleyByObjective = (objList, objectiveList) => {
    if (!objList || objList.length === 0) {
      return []
    }

    const filteredList = objList.flatMap((obj) => {
      const matchesObjective = objectiveList.some((t) => t.id === obj.objective.id)
      if (matchesObjective) {
        const filteredChildren = obj.objList
        return {
          ...obj,
          objList: filteredChildren,
        }
      }

      const filteredChildren = filterOutObjsRecursivleyByObjective(obj.objList, objectiveList)

      if (filteredChildren.length > 0) {
        obj.kpiList = []
        return {
          ...obj,
          objList: filteredChildren,
        }
      }

      return []
    })

    return filteredList
  }

  const resetFilter = () => {
    setTagsFilterValue([])
    setColorFilterValue([])
    setKpiOwnerValue([])
    setObjectiveOwnerFilterValue(null)
    setObjectiveFilterValue([])
  }

  async function onExportCSV(isKpiSpreadsheetTemplate = false) {
    let selectedIds = objectiveFilterValue.map((obj) => obj.id)
    const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
    const queryParams = new URLSearchParams()

    if (selectedIds.length > 0) {
      queryParams.append('objectives', JSON.stringify(selectedIds))
    }

    // Add color filter values if any are selected
    if (colorFilterValue.length > 0) {
      queryParams.append(
        'colors',
        JSON.stringify(
          colorFilterValue.map((color) => {
            if (color.value === 'red') {
              return 2
            } else if (color.value === 'green') {
              return 0
            } else if (color.value === 'yellow') {
              return 1
            } else if (color.value === 'grey') {
              return -1
            }
            return -1
          }),
        ),
      )
    }

    // Add tags filter if selected
    if (tagsFilterValue.length > 0) {
      queryParams.append('tags', JSON.stringify(tagsFilterValue.map((tag) => tag.tagId)))
    }

    // Add KPI owner filter values if any are selected
    if (kpiOwnerValue.length > 0) {
      queryParams.append('kpiOwners', JSON.stringify(kpiOwnerValue.map((owner) => owner.eId)))
    }

    // Add objective owner filter if selected
    if (objectiveOwenerFilterValue) {
      queryParams.append('objectiveOwner', objectiveOwenerFilterValue)
    }

    // Add completed filter
    queryParams.append('shouldShowCompleted', shouldShowCompleted)

    queryParams.append('isKpiSpreadsheetTemplate', isKpiSpreadsheetTemplate)
    queryParams.append('kpiDashBoardSelected', kpiDashBoardSelected?.kpiDashboardId ?? null)

    const url =
      BASE_URL +
      `/export_kpi_report_spread_sheet_v2${queryParams.toString() ? '?' + queryParams.toString() : ''}`
    window.open(url, '_blank')
  }

  const closeKpiReviewModal = (isUpdated) => {
    setIsKpiReviewModalOpen(false)
    setKpiDataState(null)
    if (isUpdated) {
      if (kpiDashBoardSelected) {
        getKpiDashboardData()
      } else {
        getL2KpiReport()
      }
    }
  }

  const setDefaultKpiDashBoard = async () => {
    if (!kpiDashBoardSelected) {
      return
    }
    console.log('setDefaultKpiDashBoard', kpiDashBoardSelected)
    const res = await postHttpRequest(`/edit_employees_user_preferences`, {
      kpiDashboardId: kpiDashBoardSelected.kpiDashboardId,
    })
    //set consolidated config settings local storage
    let cv = localStorage.getItem('cfg')
    let config = JSON.parse(cv)
    config.default_kpi_dashboard_id = kpiDashBoardSelected.kpiDashboardId
    let updatedConfig = JSON.stringify(config)
    localStorage.setItem('cfg', updatedConfig)
    console.log('res', res)
  }

  return (
    <>
      {isKpiReviewModalOpen && kpiDataState !== null && (
        <KpiReviewModal kpiData={kpiDataState} onClose={closeKpiReviewModal} />
      )}
      {isConfigureKPIDashboardModalOpen && (
        <ConfigureKPIDashboardModal
          closeModal={() => {
            setIsConfigureKPIDashboardModalOpen(false)
            getUsersKPIDashboardAccessList()
          }}
        />
      )}
      <div
        style={{
          paddingTop: selectedDaysForShowingWorseKpis ? '0rem' : '3rem',
          paddingLeft: '1rem',
        }}
      >
        {!hideTitle && (
          <>
            <div style={{ textAlign: 'left' }}>
              <b style={{ justifySelf: 'flex-start', alignSelf: 'center', fontSize: '20px' }}>
                {t('kpi.kpiReportTitle')}
              </b>
            </div>
            <div className="divider" style={{ marginBottom: '0.4rem', marginTop: '1rem' }}></div>
          </>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {selectedDaysForShowingWorseKpis === null && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '1rem',
                  marginTop: '1rem',
                }}
              ></div>
              <div className="divider" style={{ marginBottom: '0.4rem', marginTop: '1rem' }}></div>
            </>
          )}

          {isLoading && <InPageLoader />}
          {!isLoading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '0.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '0.5rem',
                    marginRight: 'auto', // Add margin-right: auto to push element to left
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>
                        {t('Common:formPlaceHolder.selectKPIDashboard')}:
                      </span>
                    </label>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                      <div
                        data-testid="multi-options-1"
                        style={{ width: '17vw' }}
                        className="tooltip"
                      >
                        <span class="tooltiptext" style={{ fontSize: '16px' }}>
                          {t('Common:tooltip.selectKPIDashboard')}
                        </span>
                        <SelectSingleField
                          placeholder={t('Common:formPlaceHolder.selectKPIDashboard')}
                          name="selectLeader"
                          value={kpiDashboardListDropdown?.filter(
                            (dashboard) =>
                              dashboard?.kpiDashboardId === kpiDashBoardSelected?.kpiDashboardId,
                          )}
                          options={kpiDashboardListDropdown}
                          onChange={(value) => {
                            resetFilter()
                            setKpiDashBoardSelected(value)
                          }}
                          getOptionLabel={(option) => option.kpiDashboardName}
                          getOptionValue={(option) => option.kpiDashboardId}
                          isClearable={true}
                        />
                      </div>
                      <div
                        style={{
                          width: '20rem',
                          fontSize: '14px',
                          textAlign: 'flex-start',
                          paddingTop: '0.5rem',
                          marginLeft: '0.5rem',
                          cursor: 'pointer',
                          color: '#0066cc',
                          textDecoration: 'underline',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={setDefaultKpiDashBoard}
                      >
                        {t('Dashboard:chartsCard.makeThisDashboardDefault')}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="sleek-button sleek-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShouldShowCompleted((prev) => !prev)
                  }}
                >
                  {shouldShowCompleted ? t('kpi.showUncompleted') : t('kpi.showCompleted')}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '8rem',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className="sleek-button sleek-blue"
                    onClick={() => {
                      resetFilter()
                    }}
                  >
                    <span>{t('Common:commonButtons.clearFilters')}</span>
                  </div>
                </div>
                <div style={{ position: 'relative' }} ref={actionDropdownRef}>
                  <div
                    className="sleek-button sleek-blue"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowActionDropdown(!showActionDropdown)
                    }}
                  >
                    {t('Common:commonButtons.actions')}
                    <span
                      className="material-symbols-outlined"
                      style={{
                        transform: showActionDropdown ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                    >
                      expand_more
                    </span>
                  </div>
                  {showActionDropdown && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '80%',
                        right: 0,
                        backgroundColor: 'white',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        zIndex: 1,
                        borderRadius: '4px',
                        minWidth: '250px',
                      }}
                    >
                      <div
                        className="dropdown-item"
                        style={{
                          padding: '10px 16px',
                          cursor: 'pointer',
                          borderBottom: '1px solid #e0e0e0',
                          color: '#0066cc',
                          fontSize: '14px',
                        }}
                        onClick={() => {
                          onExportCSV()
                          setShowActionDropdown(false)
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.1rem' }}>
                          <span class="material-symbols-outlined icon-size">download</span>
                          <div>{t('Common:commonButtons.downloadKpiReport')}</div>
                        </div>
                      </div>
                      <div
                        className="dropdown-item"
                        style={{
                          padding: '10px 16px',
                          cursor: 'pointer',
                          borderBottom: '1px solid #e0e0e0',
                          color: '#0066cc',
                          fontSize: '14px',
                        }}
                        onClick={() => {
                          onExportCSV(true)
                          setShowActionDropdown(false)
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.1rem' }}>
                          <span class="material-symbols-outlined icon-size">download</span>
                          <div>{t('Common:commonButtons.getTemplate')}</div>
                        </div>
                      </div>
                      <div
                        className="dropdown-item"
                        style={{
                          padding: '10px 16px',
                          cursor: 'pointer',
                          borderBottom: '1px solid #e0e0e0',
                          color: '#0066cc',
                          fontSize: '14px',
                        }}
                      >
                        <ExcelUploadButton
                          buttonText={t('Common:commonButtons.uploadTemplate')}
                          onUploadSuccess={(response) => {
                            setKpiDataState(response.kpiUpdateLists)
                            setIsKpiReviewModalOpen(true)
                            setShowActionDropdown(false)
                          }}
                          isUploading={isUploadingExcel}
                          setIsUploading={setIsUploadingExcel}
                        />
                      </div>
                      {canManageKpiDashboards && (
                        <div
                          className="dropdown-item"
                          style={{
                            padding: '10px 16px',
                            cursor: 'pointer',
                            color: '#0066cc',
                            fontSize: '14px',
                          }}
                          onClick={() => {
                            setIsConfigureKPIDashboardModalOpen(true)
                            setShowActionDropdown(false)
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', gap: '0.1rem' }}>
                            <span class="material-symbols-outlined icon-size">summarize</span>
                            <div>{t('Common:kpi.manageDashboards')}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div style={{ borderTop: '1px solid #e0e0e0', margin: '0.5rem 0' }} />
              <div
                style={{
                  color: 'rgb(0, 102, 204)',
                }}
              >
                {t('Common:filter.selectOneFilter')}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0.7rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>{t('Common:filter.l1Obj')}:</span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '18vw' }} className="tooltip">
                    <span class="tooltiptext" style={{ fontSize: '16px' }}>
                      {t('Common:tooltip.KRsRelatedToObjective')}
                    </span>
                    <SelectSingleField
                      placeholder={t('formPlaceHolder.selectObjective')}
                      name="selectLeader"
                      value={objectiveFilterValue.filter((obj) => obj.level === 1)}
                      options={objectiveFilterOptions.filter((obj) => obj.level === 1)}
                      onChange={handleChangeObjectiveFilter}
                      getOptionLabel={(option) => option.statement}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      isMulti
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>{t('Common:filter.l2Obj')}:</span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '18vw' }} className="tooltip">
                    <span class="tooltiptext" style={{ fontSize: '16px' }}>
                      {t('Common:tooltip.pillar')}
                    </span>
                    <SelectSingleField
                      placeholder={t('formPlaceHolder.selectObjective')}
                      name="selectLeader"
                      value={objectiveFilterValue.filter((obj) => obj.level === 2)}
                      options={objectiveFilterOptions.filter((obj) => obj.level === 2)}
                      onChange={handleChangeObjectiveFilter}
                      getOptionLabel={(option) => option.statement}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      isMulti
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <label for="teamLeadersFilter">
                    <span style={{ fontSize: '14px' }}>{t('Common:filter.l3Obj')}:</span>
                  </label>
                  <div data-testid="multi-options-1" style={{ width: '18vw' }} className="tooltip">
                    <span class="tooltiptext" style={{ fontSize: '16px' }}>
                      {t('Common:tooltip.macroInitiative')}
                    </span>
                    <SelectSingleField
                      placeholder={t('formPlaceHolder.selectObjective')}
                      name="selectLeader"
                      value={objectiveFilterValue.filter((obj) => obj.level === 3)}
                      options={objectiveFilterOptions.filter((obj) => obj.level === 3)}
                      onChange={handleChangeObjectiveFilter}
                      getOptionLabel={(option) => option.statement}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      isMulti
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                  onClick={() => {
                    setFiltersSectionOpen(!filtersSectionOpen)
                  }}
                >
                  {t('Common:filter.advancedFilter')}
                  <span
                    className="material-symbols-outlined"
                    style={{
                      transform: filtersSectionOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  >
                    expand_more
                  </span>
                </div>
              </div>
              {filtersSectionOpen && (
                <div style={{ borderTop: '1px solid #e0e0e0', margin: '0.5rem 0' }} />
              )}
              {filtersSectionOpen && (
                <div
                  style={{
                    color: 'rgb(0, 102, 204)',
                  }}
                >
                  {t('Common:filter.advancedFilter')}
                </div>
              )}
              {filtersSectionOpen && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>{t('Common:filter.objectiveOwner')}:</span>
                    </label>
                    <div
                      data-testid="multi-options-1"
                      style={{ width: '13vw' }}
                      className="tooltip"
                    >
                      <span class="tooltiptext" style={{ fontSize: '16px' }}>
                        {t('Common:tooltip.leaderObjective')}
                      </span>
                      <SelectSingleField
                        placeholder={t('formPlaceHolder.selectLeader')}
                        name="selectLeader"
                        value={objectiveOwnerFilterOptions?.filter(
                          (person) => parseInt(person.eId) === parseInt(objectiveOwenerFilterValue),
                        )}
                        options={objectiveOwnerFilterOptions}
                        onChange={handleChangeObjectiveOwnerFilter}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.eId}
                        isClearable={true}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>{t('Common:filter.kpiStatus')}:</span>
                    </label>
                    <div data-testid="multi-options-1" style={{ width: '8vw' }} className="tooltip">
                      <span class="tooltiptext" style={{ fontSize: '16px' }}>
                        {t('Common:tooltip.KPIStatus')}
                      </span>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        value={colorFilterValue}
                        isMulti
                        onChange={handleChangeColorFilter}
                        getOptionLabel={(option) => option?.label}
                        getOptionValue={(option) => option?.value}
                        options={colorFilterOptions}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>{t('Common:filter.tags')}:</span>
                    </label>
                    <div data-testid="multi-options-1" style={{ width: '8vw' }} className="tooltip">
                      <span class="tooltiptext" style={{ fontSize: '16px' }}>
                        {t('Common:tooltip.KPIKeywords')}
                      </span>
                      <Select
                        placeholder={t('Common:formPlaceHolder.select') + '...'}
                        value={tagsFilterValue}
                        isMulti={false}
                        onChange={handleChangeTagsFilter}
                        getOptionLabel={(option) => option?.tagName}
                        getOptionValue={(option) => option?.tagId}
                        options={tagsFilterOptions}
                        isClearable={true}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                    <label for="teamLeadersFilter">
                      <span style={{ fontSize: '14px' }}>{t('Common:filter.kpiOwner')}:</span>
                    </label>
                    <div
                      data-testid="multi-options-1"
                      style={{ width: '10vw' }}
                      className="tooltip"
                    >
                      <span class="tooltiptext" style={{ fontSize: '16px' }}>
                        {t('Common:tooltip.leaderInchargeKPI')}
                      </span>
                      <Select
                        placeholder={t('Common:filter.select') + '...'}
                        value={kpiOwnerValue}
                        isMulti={true}
                        onChange={handleChangeKpiOwnerFilter}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.eId}
                        options={kpiOwnerOptions}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* charts */}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                  onClick={() => {
                    setChartSectionOpen(!chartSectionOpen)
                  }}
                >
                  {t('Common:commonButtons.charts')}
                  <span
                    className="material-symbols-outlined"
                    style={{
                      transform: chartSectionOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  >
                    expand_more
                  </span>
                </div>
              </div>
              {chartSectionOpen && (
                <div style={{ borderTop: '1px solid #e0e0e0', margin: '0.5rem 0' }} />
              )}
              {chartSectionOpen && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  {pieChartData.length > 0 ? (
                    <PieCharCustomizedLabel data={pieChartData} kpi={true} />
                  ) : (
                    <p
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {t('Dashboard:chartsCard.notChartData')}
                    </p>
                  )}
                </div>
              )}

              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.5rem',
                    paddingLeft: '0.8rem',
                  }}
                ></div>
              </div>
            </div>
          )}
          <div style={{ borderTop: '1px solid #e0e0e0', margin: '0.3rem 0' }} />
          <div style={{ textAlign: 'left', marginBottom: '1rem' }}>
            <span
              onClick={() => setExpandAll(!expandAll)}
              style={{
                color: '#0066cc',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {expandAll
                ? t('Common:commonButtons.collapseAll')
                : t('Common:commonButtons.expandAll')}
            </span>
          </div>
          {!isLoading && (
            <KpiReportNewTable
              data={filteredKpiObjList}
              expandAll={expandAll}
              reload={kpiDashBoardSelected ? getKpiDashboardData : getL2KpiReport}
              kpiDashBoardSelected={kpiDashBoardSelected}
            />
          )}
        </div>
      </div>
    </>
  )
}

const KpiReportByLeaderWithContextv2 = () => {
  return <ApplicationLayout>{<KpiReportByLeaderV2 showLeaderDropdown={true} />}</ApplicationLayout>
}

export { KpiReportByLeaderV2, KpiReportByLeaderWithContextv2 }
