// StreamAudioRecorder.js
import React, { useEffect } from 'react'
import { useMeetingRecording } from '../../utils/meetingRecordingContext/meetingRecordingContext'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { hasRole } from '../../utils/auth'
import { routes, meetingMultiViewSubRoutes } from '../../utils/routes'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import ActivateSettingsTutorialModal from '../ActivateSettingsTutorialModal'
import {
  getMeetingTagsListForUser,
  updateMeetingInstanceForMeeting,
} from '../../api/services/meetingTopic.services'
import SelectMeetingTagsModal from '../../pages/MeetingMultiView/components/MeetingTopics/common/SelectMeetingTagsModal'

let hasShownMeetingPopup = false
let previousPath = ''

const StreamAudioRecorder = ({}) => {
  const { state, startRecording, stopRecording, dispatch } = useMeetingRecording()
  const location = useLocation()

  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()
  const isRecordingStop = React.useRef(state.isRecordingStop)
  const [isStartModalOpen, setIsStartModalOpen] = React.useState(false)
  const brainstormingTutorialOff = getConsolidatedConfigSettings('brainstorming_tutorial_off')
  const [meetings, setMeetings] = React.useState([])
  const [isSelectMeetingTagModalOpen, setIsSelectMeetingTagModalOpen] = React.useState(false)
  const [meetingInstanceId, setMeetingInstanceId] = React.useState(null)
  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true

  const [isAiMeeting, setIsAiMeeting] = React.useState(false)

  const [meetingId, setMeetingId] = React.useState(null)

  const [showReviewButton, setShowReviewButton] = React.useState(false)

  const [meetingName, setMeetingName] = React.useState('')

  const [useExistingMeetingInstanceInTutorial, setUseExistingMeetingInstanceInTutorial] =
    React.useState(null)

  // this useEffect will be triggered everytime you go to different page
  // because it's part of the navigation, and it will be re-initialized,
  // since <Navigation /> is part of the ApplicationLayout/>...
  React.useEffect(() => {
    ;(async () => {
      if (!isAiMeeting && !state.meetingTerminated) {
        return
      }
      if (!isAiMeeting && !state.meetingTerminated) {
        return
      }
      if (state.isRecordingStop && !isRecordingStop.current && state.showPopupAfterStopRecording) {
        const result = await Swal.fire({
          title: t('Common:transcription.doYouWantToReviewActionDecision'),
          showCancelButton: true,
          confirmButtonText: t('Common:transcription.yes'),
          cancelButtonText: t('Common:transcription.no'),
          reverseButtons: true,
        })
        // dispatch({ type: 'STOP_RECORDING' })
        if (result.isConfirmed) {
          let url = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.decisionLog}?decisionLogId=${state.decisionLog.logId}&isFinalized=${state.decisionLog.isFinalized}`
          dispatch({ type: 'SET_DECISION_LOG', decisionLog: null })
          navigate(url)
          navigate(0)
        }
      }
      isRecordingStop.current = state.isRecordingStop
    })()
  }, [state.isRecordingStop])

  React.useEffect(() => {
    getMeetingTags()
  }, [])

  React.useEffect(() => {
    if (state.isPreProcessingCompleted) {
      const currentPath = location.pathname.toLowerCase()
      const isTargetPage =
        currentPath.endsWith('meetingtopics') || currentPath.endsWith('meetingmultiview')

      // Reset the popup flag if we're coming from a different page
      if (previousPath !== currentPath && isTargetPage) {
        hasShownMeetingPopup = false
      }
      previousPath = currentPath
      if (
        !state.hasSummarizationStarted &&
        !hasShownMeetingPopup &&
        !hasRole('ic') &&
        isTargetPage
      ) {
        hasShownMeetingPopup = true
        setShowReviewButton(true)
        setIsSelectMeetingTagModalOpen(true)
      }
    }
  }, [state.isPreProcessingCompleted])

  const getMeetingTags = async () => {
    let res = await getMeetingTagsListForUser()
    setMeetings(res?.meetings)
  }

  const closeSelectMeetingTagsModal = async (
    meetingId,
    lastMeetingTs,
    lastMeetingInstanceId = null,
    activeUserNames = [],
    lastMeetingIsNotAiMeeting = false,
    meetingName = '',
  ) => {
    let dateNow = Math.floor(Date.now() / 1000)
    console.log('lastMeetingTs', lastMeetingTs)
    console.log('dateNow', dateNow - 60 * 60 * 2)
    if (meetingName != '') {
      setMeetingName(meetingName)
    }
    if (lastMeetingTs > dateNow - 60 * 60 * 2 && lastMeetingIsNotAiMeeting === !isAiMeeting) {
      let result = await Swal.fire({
        title: t('Common:transcription.continueWithMeeting'),
        text:
          t('Common:transcription.continueWithMeetingText') +
          ' ' +
          activeUserNames.join(', ') +
          '?',
        confirmButtonText: t('Common:transcription.continue'),
        cancelButtonText: t('Common:transcription.startNewMeeting'),
        showCancelButton: true,
      })
      if (result.isConfirmed) {
        if (isAiMeeting && brainstormingTutorialOff !== true) {
          setMeetingId(meetingId)
          setIsSelectMeetingTagModalOpen(false)
          setUseExistingMeetingInstanceInTutorial(lastMeetingInstanceId)
          setIsStartModalOpen(true)
        } else {
          startMeeting(meetingId, lastMeetingInstanceId)
        }
      } else {
        if (isAiMeeting && brainstormingTutorialOff !== true) {
          setMeetingId(meetingId)
          setIsSelectMeetingTagModalOpen(false)
          setIsStartModalOpen(true)
        } else {
          startMeeting(meetingId)
        }
      }
    } else {
      if (isAiMeeting && brainstormingTutorialOff !== true) {
        setMeetingId(meetingId)
        setIsSelectMeetingTagModalOpen(false)
        setIsStartModalOpen(true)
      } else {
        startMeeting(meetingId)
      }
    }
  }

  const startMeeting = async (meetingId, lastMeetingInstanceId = null) => {
    let { response, previousMeetings } = await startRecording(!isAiMeeting, lastMeetingInstanceId)
    if (!response) {
      return
    }
    setMeetingId(meetingId)
    setMeetings(previousMeetings?.meetings)
    setMeetingInstanceId(response?.meetingInstanceId)
    let res = null
    if (meetingId) {
      if (!lastMeetingInstanceId) {
        res = await updateMeetingInstanceForMeeting(meetingId, response?.meetingInstanceId)
      }
      if (res?.success || lastMeetingInstanceId) {
        dispatch({
          type: 'SET_MEETING_ID',
          meetingId: meetingId,
        })
      }
    }

    setIsSelectMeetingTagModalOpen(false)
  }

  return (
    <>
      {isStartModalOpen && (
        //need to change here
        <ActivateSettingsTutorialModal
          isModalOpen={isStartModalOpen}
          closeModal={() => {
            setIsStartModalOpen(false)
            startMeeting(meetingId, useExistingMeetingInstanceInTutorial)
          }}
          startRecording={startRecording}
          setMeetings={setMeetings}
          setMeetingInstanceId={setMeetingInstanceId}
          setIsSelectMeetingTagModalOpen={setIsSelectMeetingTagModalOpen}
          fromAiMeeting={true}
        />
      )}
      {isSelectMeetingTagModalOpen && (
        <SelectMeetingTagsModal
          isModalOpen={isSelectMeetingTagModalOpen}
          setIsModalOpen={setIsSelectMeetingTagModalOpen}
          // meetings={meetings}
          setIsAiMeeting={setIsAiMeeting}
          onModalClose={closeSelectMeetingTagsModal}
          showReviewButton={showReviewButton}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'row', marginRight: '1rem' }}>
        {state.hasSummarizationStarted ? (
          <div
            className="fitted-button blue"
            onClick={async () => {
              setMeetingName('')
              await stopRecording()
            }}
            style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          >
            {state.isStoppingRecording ? (
              <span
                className="loading-spinner"
                style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }}
              ></span>
            ) : (
              <span
                className="material-symbols-outlined icon-size"
                style={{ marginRight: '0.1rem' }}
              >
                stop_circle
              </span>
            )}
            <span
              style={{
                display: 'flex',
                fontSize: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {' '}
              {meetingName}{' '}
              {state.isStoppingRecording
                ? t('Common:transcription.processingEnd')
                : t('Common:transcription.summarizing')}
            </span>
          </div>
        ) : (
          <>
            <div
              className="fitted-button blue"
              onClick={() => {
                if (!canUseMeetingSummarization && meetings.length === 0) {
                  startMeeting()
                } else {
                  setShowReviewButton(false)
                  setIsSelectMeetingTagModalOpen(true)
                }
              }}
              style={{ pointerEvents: 'auto', cursor: 'pointer' }}
            >
              <span
                className="material-symbols-outlined icon-size"
                style={{ marginRight: '0.1rem' }}
              >
                play_arrow
              </span>
              <span
                style={{
                  display: 'flex',
                  fontSize: '16px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {' '}
                {t('Common:transcription.startRecordingMeeting')}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default StreamAudioRecorder
